Prepis mi prosim kod z Vue 2 do Vue 3 (composition API):

<template>
  <b-container class="pb-5">
    <b-row class="mt-5">
      <b-col
        class="m-auto"
        cols="10"
      >
        <div class="text-center mb-4">
          <img
            alt="Pobo Page Builder logo"
            class="img-fluid user-logo-page"
            src="https://pobo-cdn.b-cdn.net/static/logo.png"
          >
        </div>
        <b-col cols="12" />
        <h2 class="mt-4 pt-4 mb-4 text-center mb-4 pb-3 font-weight-normal">
          Kontrola kompatibility doplňku s Vaším prohlížečem
        </h2>

        <b-container class="bg-white rounded mb-4 p-4 shadow-sm">
          <p class="fs-16 lh-24">
            <strong>Aby Pobo Page Builder mohl pracovat s Vaším prohlížečem, je nutná
              ruční kontrola kompatibility</strong>. Pokud dochází k potížím s přihlášením
            nebo načítáním stránky, je nutné ručně zkontrolovat, zda Váš prohlížeč
            podporuje všechny potřebné technologie.
          </p>

          <p class="fs-16 lh-24">
            <strong>Klikněte prosím na následující tlačítko níže</strong>, poté se stáhne soubor, který nám prosím
            zašlete na <a
              href="mailto:podpora@pobo.cz"
            >podpora@pobo.cz</a>.
          </p>

          <b-textarea
            v-model:value="browser"
            class="mt-4"
            readonly
            rows="10"
          />

          <b-row>
            <b-col cols="6">
              <b-btn
                variant="secondary"
                class="mt-4"
                block
                @click="downloadFile"
              >
                Stáhnout soubor o kompatibilitě
              </b-btn>


              <small class="d-block text-center mt-2">
                Tento soubor nám zašlete na e-mail podpora@pobo.cz
              </small>
            </b-col>

            <b-col
              cols="6"
              class="text-right"
            >
              <template v-if="clickCache">
                <a
                  href="https://www.pobo.cz/login"
                  class="btn btn-secondary btn-block mt-4"
                >Přejít na přihlášení</a>
              </template>
              <template v-else>
                <b-btn
                  variant="secondary"
                  class="mt-4"
                  block
                  @click="deleteCookies"
                >
                  Resetovat přihlášení do Pobo Page Builder
                </b-btn>


                <small class="d-block text-center mt-2">
                  Provede tzv. reset přihlášení a vyčistí se mezipamět
                </small>
              </template>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ref, computed } from 'vue'
import Bowser from "bowser";
import EshopModel from "@/model/EshopModel";

export default {
  name: "LayoutLogin",

  setup() {
    const clickCache = ref(false);
    const browser = computed(() => Bowser.getParser(window.navigator.userAgent));

    const downloadFile = () => {
      let myJson = JSON.stringify(browser.value);
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(myJson));
      element.setAttribute('download', 'debug-pobo-page-builder.json');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    const deleteCookies = () => {
      EshopModel.logout();
      window.location.href = "/";
    }

    return {
      clickCache,
      browser,
      downloadFile,
      deleteCookies
    }
  }
};
</script>