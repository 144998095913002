<template>
  <div class="rounded-0 browser-panel">
    <div class="browser-panel__container">
      <div class="browser-panel__panel">
        <a
          v-for="tab in tabs.getGlobalTabs(type)"
          :key="tab.id"
          href="#"
          class="browser-panel__panel-item"
          :class="{ active: tab.id === tabs.getGlobalActiveIndex(type) && tabs.getGlobalTabs(type).length > 1 && primaryModalOpen }"
          @click.prevent="setActivePanel(tab.id)"
        >
          <span class="browser-panel__img">
            <b-img
              v-if="tab.image_preview"
              :src="tab.image_preview"
              class="img-fluid browser-panel__img-img"
            />
            <b-img
              v-else-if="type === 'blog'"
              src="https://pobo-cdn.b-cdn.net/blog-panel.png"
              class="img-fluid browser-panel__img-static"
            />
            <b-img
              v-else-if="type === 'share'"
              src="https://pobo-cdn.b-cdn.net/share-label.png"
              class="img-fluid browser-panel__img-static"
            />
            <b-img
              v-else
              src="https://pobo-cdn.b-cdn.net/static/no-image-icon.png"
              class="img-fluid browser-panel__img-img"
            />
          </span>

          <span class="browser-panel__name">
            {{ tab.name }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {useTabsStore} from "@/store/TabsStore";

export default {

  props:{
    primaryModalOpen:{
      default: false,
      type: Boolean
    },
    type:{
      required: true,
      type: String,
    }
  },

  computed:{
    tabs(){
      return useTabsStore();
    }
  },

  methods:{
    setActivePanel(id){
      this.$emit('setActivePanel', id);
    }
  }

}
</script>