<template>
  <div class="bg-white p-4">
    <h3 class="pb-2">
      Šablony - vzhledy popisků
    </h3>

    <b-alert
      variant="info"
      show
    >
      Po upravení designu je potřeba udělat purge na soubor, viz.
      <a
        href="https://dash.bunny.net/purge"
        class="text-info"
        target="_blank"
      >dash.bunny.net/purge</a>.
    </b-alert>

    <b-btn
      variant="secondary mb-4"
      @click="isOpenCreate = true"
    >
      Create design
    </b-btn>

    <b-modal
      v-model="isOpenCreate"
      title="Create design"
      hide-footer
      size="lg"
    >
      <modal-detail @handle-close="refresh" />
    </b-modal>

    <table class="table table-bordered table-striped">
      <thead>
        <tr
          class="font-weight-bolder bg-light"
          style="font-size: 14px"
        >
          <th>ID</th>
          <th style="width: 220px">
            Vytvořeno
          </th>
          <th>Název šablony</th>
          <th>Vzdálený název šablony</th>
          <th>Design type</th>
          <th>Viditelnost</th>
          <th>Náhled šablony</th>
          <th style="width: 220px">
            Akce
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in data"
          :key="item.id"
        >
          <td>{{ item.id }}</td>
          <td>
            {{ datetime(item.created_at) }}
            <small class="text-muted mt-1 d-block fs-13">Upraveno: {{ datetime(item.updated_at) }}</small>
          </td>
          <td>
            {{ item.name }}
            <div
              v-if="item.connected.length"
              class="p-1 bg-light mt-1"
            >
              <small class="text-muted fs-11">Přidané pro e-shopy</small>
              <ul
                v-for="eshop in item.connected"
                :key="eshop.url"
                class="text-muted fs-13 pl-3 mt-0"
              >
                <li>{{ eshop.url.replace(/^https?:\/\//, "") }}</li>
              </ul>
            </div>
          </td>
          <td>{{ item.remote_name || 'Design has no remote name' }}</td>
          <td>
            {{ item.type === 'public' ? 'Design for all (free)' : 'Custom design ($$$)' }}
            <code class="d-block fs-12 mt-2">https://image.pobo.cz/design/{{ item.id }}.css</code>
          </td>
          <td :class="item.visible ? 'text-success' : 'text-danger'">
            {{ item.visible ? "Visible" : "Hidden" }}
          </td>
          <td class="text-center">
            <a
              :href="item.image_preview"
              target="_blank"
            >
              <b-img-lazy
                height="50"
                :src="item.image_preview"
              />
            </a>
          </td>
          <td>
            <b-btn-group>
              <b-btn
                variant="secondary"
                size="sm"
                @click="openEditModal(item)"
              >
                Upravit
              </b-btn>
              <confirm-delete
                :id="item.id"
                @delete="actionDelete"
              >
                <template #trigger>
                  <i class="ico ico-component-delete" /> {{ $t("Delete design") }}
                </template>
              </confirm-delete>
            </b-btn-group>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-if="isOpenEdit"
      class="modal-editor"
    >
      <modal-main
        :item="selectedItem"
        @handle-update="fetchData"
        @handle-close="isOpenEdit = false"
      />
    </div>
  </div>
</template>

<script>
import DesignModel from "@/model/admin/DesignModel";
import ModalMain from "@/components/admin/template/modal-main.vue";
import ModalDetail from "@/components/admin/template/modal-detail.vue";
import ConfirmDelete from "@/share/confirm-delete.vue";
import { datetime } from "@/filter/vue";
import { ref, onMounted } from "vue";

export default {
  name: "TemplateDesign",
  components: {ModalDetail, ModalMain, ConfirmDelete},
  setup() {
    const selectedItem = ref({});
    const isOpenEdit = ref(false);
    const isOpenCreate = ref(false);
    const data = ref([]);

    const fetchData = async () => {
      data.value = await DesignModel.findAll();
    };

    onMounted(fetchData);

    const refresh = (newData) => {
      data.value = newData;
      isOpenCreate.value = false;
    };

    const actionDelete = async (id) => {
      const item = data.value.find((item) => item.id === id);
      if (item) {
        const updateData = {
          name: item.name,
          mode: item.mode || 'default_mode',
          type: item.type || 'default_type',
          visible: item.visible,
          image_preview: item.image_preview,
          css_typo: item.css_typo,
          css_widget: item.css_widget,
          remote_name: item.remote_name,
          info: item.info,
          config: item.config,
          delete: true
        };

        await DesignModel.update(item.id, updateData);
        await fetchData();
      }
    };

    const openEditModal = (item) => {
      selectedItem.value = {...item, users: []};
      isOpenEdit.value = true;
    };

    return {
      datetime,
      selectedItem,
      isOpenEdit,
      isOpenCreate,
      data,
      refresh,
      actionDelete,
      openEditModal,
      fetchData,
    };
  }
};
</script>
