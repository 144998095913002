<template>
  <div class="bg-white p-4">
    <div class="row text-center align-items-baseline">
      <div class="col-4 col-md-2">
        <div class="p-4">
          <h6>Celkový počet uživatelů</h6>
          <h4 class="mt-3">
            {{ user.total }}x
          </h4>
          <div class="text-muted fs-13 mt-2">
&nbsp;
          </div>
        </div>
      </div>

      <div class="col-4 col-md-2">
        <div class="p-4">
          <h6>Aktivní uživatelé</h6>
          <h4 class="mt-3">
            {{ user.active }}x
          </h4>
          <div class="text-muted fs-13 mt-2">
&nbsp;
          </div>
        </div>
      </div>

      <div class="col-4 col-md-2">
        <div class="p-4">
          <h6>Neaktivní uživatelé</h6>
          <h4 class="mt-3">
            {{ user.no_active }}x
          </h4>
          <div class="text-muted fs-13 mt-2">
            (Odinstalace uživatelem)
          </div>
        </div>
      </div>

      <div class="col-4 col-md-2">
        <div class="p-4">
          <h6>Potvrdili účet</h6>
          <h4 class="mt-3">
            {{ user.confirm }}x
          </h4>
          <div class="text-muted fs-13 mt-2">
            (Vyplnili heslo)
          </div>
        </div>
      </div>

      <div class="col-4 col-md-2">
        <div class="p-4">
          <h6>Nepotvrdili účet</h6>
          <h4 class="mt-3">
            {{ user.no_confirm }}x
          </h4>
          <div class="text-muted fs-13 mt-2">
            (Nevyplnili heslo)
          </div>
        </div>
      </div>

      <div class="col-4 col-md-2">
        <div class="p-4">
          <h6>Nové tarify přes Shoptet</h6>
          <h4 class="mt-3">
            {{ user.new_tariff }}x
          </h4>
        </div>
      </div>
    </div>

    <h4 class="mt-5 mb-3">
      Stav notifikací
    </h4>

    <b-btn
      class="mb-4"
      @click="setActiveNotification"
    >
      Aktivovat notifikace všem uživatelům
    </b-btn>

    <router-link
      :to="{ name: 'notification-setting' }"
      class="mb-4 btn btn-md btn-secondary"
    >
      Nastavení notifikací
    </router-link>

    <b-row>
      <b-col
        class="text-center"
        cols="4"
        md="3"
      >
        <h6>Hlavička (aktivní)</h6>
        <h4 class="mt-3">
          {{ notification.header.active }}
        </h4>
      </b-col>

      <b-col
        class="text-center"
        cols="4"
        md="3"
      >
        <h6>Hlavička (neaktivní)</h6>
        <h4 class="mt-3">
          {{ notification.header.disable }}
        </h4>
      </b-col>

      <b-col
        class="text-center"
        cols="4"
        md="3"
      >
        <h6>Modalní okno (aktivní)</h6>
        <h4 class="mt-3">
          {{ notification.modal.active }}
        </h4>
      </b-col>

      <b-col
        class="text-center"
        cols="4"
        md="3"
      >
        <h6>Modalní okno (neaktivní)</h6>
        <h4 class="mt-3">
          {{ notification.modal.disable }}
        </h4>
      </b-col>
    </b-row>

    <h4 class="mt-5 mb-3">
      Dashboard produktů, sdílených popisků a blogu
    </h4>

    <div class="row text-center align-items-baseline">
      <div class="col-4 col-md-3">
        <div class="p-4">
          <h6>Počet produktů s Pobo popisků</h6>
          <h4 class="mt-3">
            {{ product.has_description }}x
          </h4>
          <div class="text-muted fs-13 mt-2">
&nbsp;
          </div>
        </div>
      </div>

      <div class="col-4 col-md-3">
        <div class="p-4">
          <h6>Počet produktů bez popisků</h6>
          <h4 class="mt-3">
            {{ product.no_description }}x
          </h4>
          <div class="text-muted fs-13 mt-2">
&nbsp;
          </div>
        </div>
      </div>

      <div class="col-4 col-md-3">
        <div class="p-4">
          <h6>Počet sdílených popisků</h6>
          <h4 class="mt-3">
            {{ product.share }}x
          </h4>
          <div class="text-muted fs-13 mt-2">
&nbsp;
          </div>
        </div>
      </div>

      <div class="col-4 col-md-3">
        <div class="p-4">
          <h6>Kumulativní počet článků</h6>
          <h4 class="mt-3">
            {{ blog.count_article }}x
          </h4>
        </div>
      </div>
    </div>

    <h4 class="mt-5 mb-3">
      Dashboard kategorií (od 16.4.2022)
    </h4>

    <div class="row text-center align-items-baseline">
      <div class="col-4 col-md-3">
        <div class="p-4">
          <h6>Počet kategorií s Pobo popisky</h6>
          <h4 class="mt-3">
            {{ category.has_description }}x
          </h4>
          <div class="text-muted fs-13 mt-2">
&nbsp;
          </div>
        </div>
      </div>

      <div class="col-4 col-md-3">
        <div class="p-4">
          <h6>Počet kategorií bez popisků</h6>
          <h4 class="mt-3">
            {{ category.no_description }}x
          </h4>
          <div class="text-muted fs-13 mt-2">
&nbsp;
          </div>
        </div>
      </div>
    </div>

    <h4 class="mt-5 mb-3">
      Aktivita uživatelů
    </h4>

    <div class="row text-center align-items-center">
      <div class="col-4 col-md-3">
        <div class="p-4">
          <h6>Počet požadavků na API AI</h6>
          <h4 class="mt-3">
            {{ copy_request.count_request }}x
          </h4>
        </div>
      </div>

      <div class="col-4 col-md-3">
        <div class="p-4">
          <h6>Počet přihlášení (formulář)</h6>
          <h4 class="mt-3">
            {{ user.loggedForm }}x
          </h4>
        </div>
      </div>

      <div class="col-4 col-md-3">
        <div class="p-4">
          <h6>Počet přihlášení (přes e-shop)</h6>
          <h4 class="mt-3">
            {{ user.loggedRemote }}x
          </h4>
        </div>
      </div>

      <div class="col-4 col-md-3">
        <div class="p-4">
          <h6>Počet oblíbených widgetů</h6>
          <h4 class="mt-3">
            {{ user.favouriteWidget }}x
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex bg-white mt-3 p-2 flex-column">
    <h3 class="fs-23 mt-3 ml-3">
      Tvorba e-mailového podpisu pro Pobo
    </h3>

    <b-form-group
      label="Vyberte variantu podpisu:"
      class="mt-1 ml-3"
    >
      <b-form-radio-group
        v-model="selectedVariant"
        name="variantOptions"
      >
        <b-form-radio :value="0">
          1. varianta
        </b-form-radio>
        <b-form-radio :value="1">
          2. varianta
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <div class="d-flex ml-3 mt-3">
      <b-form-group
        id="name"
        class="w-25"
      >
        <b-form-input
          v-for="(value, key) in signature"
          :id="`signature-${key}`"
          :key="key"
          v-model="signature[key]"
          type="text"
          class="mt-2"
        />
      </b-form-group>

      <div class="w-75 ml-5 mr-5 d-flex justify-content-center align-items-center border border-primary">
        <table
          v-if="selectedVariant === 0"
          border="0"
          cellpadding="0"
          cellspacing="0"
          style="width: 100%;max-width: 560px; padding: 20px;background-repeat: no-repeat;background-size:50px 100%;word-break: break-word"
        >
          <tbody>
            <tr>
              <td
                width="40%"
                valign="top"
                style="border-right: 1px solid #f52727; padding-right: 10px;"
              >
                <img
                  :src="signature.photo"
                  style="width: 100%; min-width: 140px;display: block; position:relative;"
                >

                <img
                  src="https://pobo-cdn.b-cdn.net/static/logo.png"
                  style="width: 80px;display: inline-block;margin-left: 7px; padding-top: 5px; position: absolute; top:100; margin-left:20px;"
                >
                <p style="text-align: right; margin:10px 0 0;line-height: 1.2">
                  <a
                    v-if="signature.twitter.length > 1"
                    :href="signature.twitter"
                    style="text-decoration: none"
                  >
                    <img
                      src="https://pobo-cdn.b-cdn.net/static/twitter-ico.png"
                      style="width: 18px;display: inline-block;margin-left: 7px;"
                    >
                  </a>

                  <a
                    v-if="signature.linkedin.length > 1"
                    :href="signature.linkedin"
                    style="text-decoration: none"
                  >
                    <img
                      src="https://pobo-cdn.b-cdn.net/static/linkedin-ico.png"
                      style="width: 18px;display: inline-block;margin-left: 7px; "
                    >
                  </a>
                </p>
              </td>
              <td
                width="60%"
                valign="top"
                style="padding:20px 0 20px 20px"
              >
                <h3
                  style="margin:0 0 5px;font-size: 20px;color:#f52727

             ;line-height: 1.2;font-family: sans-serif;"
                >
                  {{ signature.name }}
                </h3>
                <h5 style="margin:0 0 5px;font-size: 14px;color:#585858;line-height: 1.2;font-family: sans-serif;font-weight: normal;">
                  {{ signature.position }}
                </h5>

                <div
                  class="line"
                  style="height: 1px;width:100%;margin:10px 0"
                />

                <p style="margin:0 0 5px;line-height: 1.2;font-family: sans-serif;color:#555555;font-size: 14px; line-height: 135%;">
                  <img
                    src="https://pobo-cdn.b-cdn.net/static/phone-ico.png"
                    style="width: 16px;display: inline-block;vertical-align: middle;margin-right: 5px; padding: 3px 0px"
                  >
                  <a
                    :href="'tel:' + signature.phone"
                    style="color:#555555; text-decoration: none!important; font-style: none; border: none; "
                  > {{ signature.phone }}</a>
                </p>


                <p style="margin:0 0 5px;line-height: 1.2;font-family: sans-serif;color:#555555;font-size: 14px; line-height: 135%;">
                  <img
                    src="https://pobo-cdn.b-cdn.net/static/mail-ico.png"
                    style="width: 15px;display: inline-block;vertical-align: middle;margin-right: 5px; padding: 3px 0px"
                  >
                  <a
                    :href="'mailto:' + signature.mail"
                    style="color:#555555; text-decoration: none !important; font-style: none; border: none; "
                  > {{ signature.mail }}</a>
                </p>

                <p style="margin:0 0 5px;line-height: 1.2;font-family: sans-serif;color:#555555;font-size: 14px; line-height: 135%;">
                  <img
                    src="https://pobo-cdn.b-cdn.net/static/adress-ico.png"
                    style="width: 15px;display: inline-block;vertical-align: middle;margin-right: 5px; padding: 3px 0px"
                  >
                  {{ signature.adress }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          v-if="selectedVariant === 1"
          border="0"
          cellpadding="0"
          cellspacing="0"
          style="width: 100%; max-width: 250px; padding: 20px; background-repeat: no-repeat; background-size: cover; word-break: break-word;"
        >
          <tbody>
            <tr>
              <td
                valign="top"
                style="padding: 10px 0;"
              >
                <h3
                  style="margin: 0; font-size: 15px; color: #333; line-height: 1.2; font-family: sans-serif;"
                >
                  {{ signature.name }} (<span style="font-weight: normal;">{{ signature.position }}</span>)
                </h3>

                <a
                  :href="'mailto:' + signature.mail"
                  style="color: #555; text-decoration: none; font-size: 13px; line-height: 1.2; font-family: sans-serif;"
                >
                  {{ signature.mail }}
                </a>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
              >
                <img
                  src="https://pobo-cdn.b-cdn.net/static/logo.png"
                  style="width: 100px; display: block;"
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardModel from "@/model/DashboardModel.js";
import NotificationModel from "@/model/NotificationModel.js";

export default {
  name: "Dashboard",
  mounted() {
    this.fetchData();
  },

  data() {
    return {
      user: Array,
      product: Array,
      blog: Array,
      category: Array,
      copy_request: Array,
      selectedVariant: 0,
      notification: {
        header: {
          active: 0,
          disable: 0
        },
        modal: {
          active: 0,
          disable: 0
        }
      },
      signature:{
        name: 'Michal Štikar',
        position: 'PPC specialista',
        phone: '+420 777 282 465',
        mail: 'info@pobo.cz',
        adress: 'Pražská tržnice, Bubenské nábřeží 306/13\n' + 'Praha 7 (hala 24)',
        twitter: "https://twitter.com/stikar_michal",
        linkedin: 'https://cz.linkedin.com/in/stikar',
        photo: 'https://pobo-cdn.b-cdn.net/static/michal-stikar-team-2.png',
      }
    };
  },

  methods: {
    async fetchData() {
      await DashboardModel.findDashboard(
        () => {
        },
        (data) => {
          this.user = data.user;
          this.product = data.product;
          this.category = data.category;
          this.blog = data.blog;
          this.copy_request = data.copy_request;
          this.notification = data.notification;
        }
      );
    },

    async setActiveNotification() {
      await NotificationModel.runAllNotification(
        () => {
        },
        (data) => {
        }
      );
    }
  }
};
</script>
