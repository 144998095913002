<template>
  <router-view />
</template>

<script>
export default {
  created() {
    this.loadTheme();
    const loadingElement = document.getElementById("hero-loading");
    if (loadingElement) {
      loadingElement.remove();
    }
  },

  methods:{
    async loadTheme() {
      try {
        await import('@/scss/theme/light.scss');
      } catch (error) {
        console.error('Chyba při načítání motivu:', error);
      }
    },
  },
}
</script>