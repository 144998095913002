<template>
  <div>
    <h2 class="my-4 p-0">
      {{ $t("guide.import.welcome") }}
    </h2>

    <p class="mb-4">
      {{ $t("guide.import.descriptionOne") }}
      <strong> {{ $t("guide.import.descriptionOneStrong") }} </strong>
      {{ $t("guide.import.descriptionTwo") }}
      <strong> {{ $t("guide.import.descriptionTwoStrong") }} </strong>
      {{ $t("guide.import.descriptionThree") }}
      <strong> {{ $t("guide.import.descriptionThreeStrong") }} </strong>
      {{ $t("guide.import.descriptionFourth") }}
      <strong> {{ $t("guide.import.descriptionFourthStrong") }} </strong>.
    </p>

    <template v-if="enableRequest">
      <b-btn
        class="btn btn-secondary"
        @click="actionCreate"
      >
        {{ $t("guide.import.button") }} <i class="ico ico-arrow-right" />
      </b-btn>
    </template>
    <div
      v-else
      class="bg-white shadow-sm px-4 py-3 rounded"
    >
      <b-row>
        <b-col cols="6">
          <template v-if="lastRequest">
            <span class="d-block text-muted pb-2">
              <b-spinner
                class="mb-1 mr-1"
                small
              />
              {{ $t("guide.import.load") }}
            </span>

            <b-progress
              :max="100"
              :value="lastRequest.added_progress"
              animateht="15px"
              striped="striped"
              variant="secondary"
            />

            <small class="d-block w-100 text-muted mt-1">
              {{ $t("guide.import.done") }}
              <strong>{{ lastRequest.added_progress }}% / 100%</strong>
            </small>
          </template>
          <template v-else>
            <b-skeleton-img
              height="70px"
              no-aspect
              width="100%"
            />
          </template>
        </b-col>

        <b-col cols="6">
          <template v-if="lastRequest">
            <span class="d-block text-muted pb-2">
              <b-spinner
                class="mb-1 mr-1"
                small
              />
              {{ $t("guide.import.download") }}
            </span>

            <b-progress
              :max="100"
              :value="lastRequest.sync_progress"
              animateht="15px"
              striped="striped"
              variant="secondary"
            />

            <small class="d-block w-100 text-muted mt-1">
              {{ $t("guide.import.done") }}
              <strong>{{ lastRequest.sync_progress }}% / 100%</strong>
            </small>
          </template>
          <template v-else>
            <b-skeleton-img
              height="70px"
              no-aspect
              width="100%"
            />
          </template>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ImportModel from "@/model/ImportModel.js";

export default {
  name: "import",

  async mounted() {
    await this.actionRead();

    setInterval(async () => {
      await this.actionRead();
    }, 5000);
  },

  data() {
    return {
      submitRequest: false,

      cron: {
        stats: [],
        enable_request: true,
        list: [],
      },
    };
  },

  computed: {
    enableRequest() {
      const { cron } = this;
      return cron.enable_request;
    },

    lastRequest() {
      const { cron } = this;
      const { list } = cron;
      return list ? list[0] : null;
    },
  },

  methods: {
    async actionCreate() {
      await ImportModel.create(
        () => {},
        (data) => {
          this.submitRequest = true;
          this.cron = data;
        }
      );
    },

    async actionRead() {
      await ImportModel.findAll(
        () => {},
        (data) => {
          const { list } = data;
          const { submitRequest } = this;

          this.cron = data;

          if (list.length > 0 && submitRequest) {
            if (list[0].is_complete) {
              this.$emit("update-step", 3);
            }
          }
        }
      );
    },
  },
};
</script>