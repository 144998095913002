<template>
  <div>
    <layout-grid>
      <template #title>
        {{ $t("template.title") }}
      </template>

      <template #welcome>
        <form-adaptation
          class="mb-4"
          @action-created="handleTemplateCreated"
        />
      </template>

      <template #content>
        <div class="grid-layout__content">
          <div>
            <div class="designer-table__head">
              <div style="width: 200px">
                {{ $t("template.table.part1") }}
              </div>
              <div colspan="3">
                {{ $t("template.table.part2") }}
              </div>
            </div>

            <template v-if="isLoaded">
              <template v-if="template.length > 0">
                <div
                  v-for="tpl in template"
                  :key="tpl.id"
                  class="designer-table"
                >
                  <div>
                    <strong>{{ tpl.name }}</strong>

                    <small
                      v-if="tpl.created_at"
                      class="text-muted d-block mt-1"
                    >
                      {{ when(tpl.created_at) }}
                    </small>
                  </div>

                  <div>
                    <template v-if="tpl.product">
                      <a :href="tpl.product.url">
                        {{ tpl.product.name }}
                        <i class="ico ico-external" />
                      </a>
                      <small
                        v-if="
                          eshop.template_id === tpl.id
                        "
                        class="text-success d-block"
                      >{{
                        $t("template.error.part1")
                      }}</small>
                    </template>
                  </div>
                  <div
                    class="d-flex justify-content-end text-right"
                  >
                    <div
                      style="width: 350px"
                      class="text-right"
                    >
                      <b-btn
                        block
                        size="sm"
                        class="mr-2"
                        @click="
                          handleOpenDebugger(
                            tpl,
                            true
                          )
                        "
                      >
                        <i class="ico ico-edit" />
                        {{
                          $t("template.buttons.part1")
                        }}
                      </b-btn>
                      <small
                        class="text-muted d-block pt-1 text-center"
                      >
                        {{
                          $t("template.buttons.part1")
                        }}
                      </small>
                    </div>
                    <div style="width: 120px">
                      <confirm-delete
                        :id="tpl.id"
                        :disable="
                          eshop.template_id === tpl.id
                        "
                        class="text-right"
                        @delete="actionDelete"
                      >
                        <template #trigger>
                          <i
                            class="ico ico-component-delete"
                          />
                          {{
                            $t(
                              "template.table.delete"
                            )
                          }}
                        </template>
                      </confirm-delete>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div>
                  <div
                    colspan="2"
                    class="text-center text-muted"
                  >
                    {{ $t("template.error.part2") }}
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div
                v-for="n in 10"
                :key="n"
              >
                <div colspan="2">
                  <b-skeleton-img
                    no-aspect
                    height="30px"
                    width="100%"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </layout-grid>

    <b-modal
      v-model="isDebuggerOpen"
      size="xs"
      hide-footer
      title="Přizpůsobte si vzhled popisků podle svého"
      no-close-on-backdrop
      hide-header
      no-close-on-esc
    >
      <form-projector
        :template="selectedTemplate"
        @save="actionUpdate"
        @close="handleOpenDebugger({}, false)"
      />
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import FormAdaptation from "@/components/account/form-adaptation.vue";
import FormProjector from "@/components/account/form-projector.vue";
import { when } from "@/filter/vue";
import ProjectorModel from "@/model/ProjectorModel.js";
import eshopData from "@/mixin/eshopData";

export default {

  mixins: [eshopData],

  async mounted() {
    await this.actionRead();
    this.isLoaded = true;
  },

  data() {
    return {
      isLoaded: false,
      template: [],
      selectedProduct: null,
      selectedTemplate: null,
      isDebuggerOpen: false
    };
  },

  computed: {
    filterTemplate() {
      return this.template;
    }
  },

  components: {
    "form-adaptation": FormAdaptation,
    "form-projector": FormProjector
  },

  methods: {
    when,
    async handleTemplateCreated() {
      await this.actionRead();
      const { template } = this;
      this.handleOpenDebugger(template[0], true);
    },

    handleOpenDebugger(template, state) {
      this.selectedTemplate = template;
      this.isDebuggerOpen = state;
    },

    handleOpenPreview(template) {
      this.selectedTemplate = template;
    },

    async actionUpdate(data) {
      await ProjectorModel.saveMe(
        data,
        () => {
        },
        () => {
          this.handleOpenDebugger(null, false);
        }
      );
    },

    async actionDelete(id) {
      await axios.delete(`/api/v2/template/detail/${id}`).then(async () => {
        await this.actionRead();
      })
    },

    async actionRead() {
      await axios.get("/api/v2/template/grid").then(({ data }) => {
        this.template = data;
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
