<template>
  <div class="login-page">
    <div class="login-page__video-overlay" />
    <iframe
      src="https://www.youtube.com/embed/HgXHB1IK6Xg?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=HgXHB1IK6Xg&modestbranding=1"
      class="login-page__video-embed"
    />
    <div class="login-page__panel-container">
      <div class="login-page__panel">
        <b-row class="justify-content-center">
          <b-col cols="10">
            <div class="text-center mb-4">
              <img
                alt="Pobo Page Builder logo"
                class="img-fluid user-logo-page"
                src="https://pobo-cdn.b-cdn.net/static/logo.png"
              >
            </div>

            <h2 class="mt-4 pt-4 mb-4 text-center pb-3 font-weight-normal text-white">
              {{ $t("Thank you for using Pobo Page Builder") }}
            </h2>

            <b-container class="bg-white rounded mb-4 p-4 shadow-sm">
              <strong>{{ $t("Important information:") }}</strong>
              {{ $t("After installing the plugin in Shoptet, we have sent an activation link to your email.") }}
              <br>
              {{ $t("If you did not receive an activation email, please email us at") }}
              <a
                class="login-link"
                href="mailto:podpora@pobo.cz"
              >podpora@pobo.cz</a>
            </b-container>

            <b-alert
              v-if="loginErrorMessage"
              show
              variant="danger"
            >
              {{ loginErrorMessage }}
            </b-alert>

            <b-row>
              <b-col
                cols="12"
                md="6"
                class="d-flex"
              >
                <b-card class="shadow-sm h-100 bg-white rounded flex-fill">
                  <h4 class="mb-4 mt-1">
                    {{ $t("Log in") }}
                  </h4>
                  <login-form
                    :disabled="isLoading"
                    @submit="loginUser"
                  />
                </b-card>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="d-flex pt-4 pt-md-0"
              >
                <b-card class="shadow-sm h-100 bg-white rounded flex-fill">
                  <h4 class="mb-4 mt-1">
                    {{ $t("Forgot your password?") }}
                  </h4>
                  <div class="pb-4 lh-22">
                    {{ $t("If you forgot your password or did not receive the account creation link (we send it after the plugin is installed), please enter your email in the form.") }}
                  </div>
                  <b-alert
                    v-if="resetErrorMessage"
                    show
                    variant="danger"
                    class="mb-4"
                  >
                    {{ resetErrorMessage }}
                  </b-alert>
                  <b-alert
                    v-if="resetSuccessMessage"
                    show
                    variant="success"
                    class="mb-4"
                  >
                    {{ resetSuccessMessage }}
                  </b-alert>
                  <reset-form-password
                    :disabled="isLoading"
                    @reset="resetPassword"
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/forms/LoginForm.vue";
import ResetPasswordForm from "@/components/forms/ResetPasswordForm.vue";
import AuthModel from "@/model/AuthModel";
import Cookies from "js-cookie";

export default {
  name: "LayoutLogin",
  created() {
    this.loadTheme();
    const loadingElement = document.getElementById("hero-loading");
    if (loadingElement) {
      loadingElement.remove();
    }
  },
  data() {
    return {
      loginErrorMessage: "",
      resetErrorMessage: "",
      resetSuccessMessage: "",
      isLoading: false,
    };
  },
  components: {
    "login-form": LoginForm,
    "reset-form-password": ResetPasswordForm,
  },
  methods: {
    async loadTheme() {
      try {
        await import("@/scss/theme/light.scss");
      } catch (error) {
        console.error("Chyba při načítání motivu:", error);
      }
    },

    async loginUser(formData) {
      this.loginErrorMessage = "";
      await AuthModel.login(formData.email, formData.password, 352)
        .then((response) => {
          if (response.token) {
            Cookies.set("access_token", response.token);
            this.$router.push({ name: "product" });
          } else {
            this.loginErrorMessage = this.$t("The login failed. Please check your email and password.");
          }
        })
        .catch((error) => {
          this.loginErrorMessage = this.$t("An error occurred during login. Please try again.");
        });
    },

    async resetPassword(email) {
      this.resetErrorMessage = "";
      this.resetSuccessMessage = "";
      this.isLoading = true;

      try {
        const result = await AuthModel.resetPassword(
          email,
          () => {
          },
          () => (this.isLoading = false)
        );

        if (result) {
          this.resetSuccessMessage = this.$t("A reset link has been successfully sent to your email.");
        } else if (result.error) {
          this.resetErrorMessage =
              result.error === "USER_NOT_FOUND"
                ? this.$t("The user was not found.")
                : this.$t("Invalid email address.");
        }
      } catch (error) {
        this.resetErrorMessage = this.$t("An error occurred while sending the reset request. Please try again.");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
