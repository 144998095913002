import { toast } from 'vue3-toastify';
import i18n from '@/plugins/i18n'

export function showMessage(message, type = 'success') {
  toast(i18n.global.t(message), {
    position: toast.POSITION.BOTTOM_RIGHT,
    theme: toast.THEME.COLORED,
    type: type,
    transition: toast.TRANSITIONS.ZOOM,
    delay: 0,
    closeOnClick: true,
    pauseOnHover: true,
    // todo doporucuju nastavit vyssi hodnotu pro testy
    autoClose: 1750,
  });
}

export function showErrorMessage(message, type = 'error') {
  toast(i18n.global.t(message), {
    position: toast.POSITION.BOTTOM_RIGHT,
    theme: toast.THEME.COLORED,
    type: type,
    transition: toast.TRANSITIONS.ZOOM,
    autoClose: 1750,
  });
}