<template>
  <b-form
    class="p-0"
    novalidate
    @submit.prevent="actionCreate"
  >
    <help-box
      author="sara"
      type="ai"
      classes="mt-4"
    >
      <h2 class="mb-4">
        {{ $t("How to use AI to generate widgets") }}
      </h2>
      <p class="fs-16 lh-26">
        {{ $t("Pobo Page Builder uses ChatGPT's capabilities to create complete widgets with headings, paragraphs and images.") }}
        {{ $t("This feature facilitates fast and efficient content creation, increases productivity and allows users to easily generate attractive and engaging web pages.") }}
      </p>
    </help-box>

    <div
      v-if="isLocked"
      class="bg-white rounded-lg shadow-sm p-5 mb-4"
      style="
                background: url('https://pobo-cdn.b-cdn.net/static/michal-stikar-team-2.png?width=200&height=200&auto_optimize=high')
                    right bottom / contain no-repeat;
                border-image: initial;
            "
    >
      <h4 class="mt-0 mb-3">
        Bohužel nemáte dostatek kreditů na automatické vytvoření popisku
      </h4>

      <p class="mb-0 fs-16 lh-28 d-block">
        Přejděte prosím do sekce „<router-link
          :to="{ name: 'credit' }"
          style="text-decoration: underline"
          class="text-secondary"
        >
          {{ $t("translation.modal.empty.button") }}
        </router-link>“, {{ $t("translation.modal.empty.postfix") }}<br>
        Pokud máte dotaz, ozvěte se nám na
        <a
          href="mailto:podpora@pobo.cz"
          class="text-secondary"
          style="text-decoration: underline"
        >podpora@pobo.cz</a>, rádi vám pomůžeme.
      </p>

      <small class="mb-0 mt-2 text-muted d-block">
        (Cena za vytvoření popisku pomocí ChatGPT je 5 kreditů).
      </small>
    </div>

    <div class="bg-white rounded-lg shadow-sm p-3 mb-4">
      {{
        $t(
          "Select the widget layout, review the information and submit the form.  Pobo Page Builder will download the current product information into the form and if it is not available, you need to fill it in manually."
        )
      }}
    </div>

    <b-row>
      <b-col cols="7">
        <template v-if="isLoaded">
          <template v-if="widget.length > 1">
            <div id="preview-widget-container">
              <b-row>
                <b-col
                  v-for="(detail, index) in widget"
                  :key="index"
                  cols="6"
                >
                  <span
                    class="font-weight-bolder fs-17 lh-22 d-block mb-4 pr-5"
                  >
                    {{ $t("translation.variant." + index) }}
                  </span>
                </b-col>
              </b-row>
              <b-row
                class="bg-white rounded-lg shadow-sm"
                no-gutters
              >
                <b-col
                  v-for="(detail, index) in widget"
                  :key="index"
                  cols="6"
                >
                  <a
                    :class="[
                      layout === detail.id
                        ? 'active'
                        : '',
                      'template-trigger p-4 rounded d-block',
                    ]"
                    @click.prevent="layout = detail.id"
                  >
                    <div
                      v-for="widget in detail.list"
                      :key="widget.id"
                    >
                      <div
                        class="preview-widget pb-4"
                        v-html="widget.html_preview"
                      />
                    </div>
                  </a>
                </b-col>
              </b-row>
            </div>
          </template>
        </template>
        <template v-else>
          <b-skeleton-img
            height="400px"
            no-aspect
          />
        </template>
      </b-col>
      <b-col cols="5">
        <template v-if="isLoaded">
          <b-form-group
            id="name"
            :label="$t('product.name')"
            size="sm"
            :disabled="isLocked"
          >
            <b-form-input
              id="name"
              v-model="formData.name"
              :class="errors.name ? 'is-invalid' : ''"
              required
              size="sm"
              type="text"
            />

            <small
              v-for="(error, index) in errors.name"
              :key="index"
              class="text-danger d-block"
              v-html="$t(error)"
            />
          </b-form-group>

          <b-form-group
            id="content"
            :label="$t('product.shortDescription')"
            size="sm"
            :disabled="isLocked"
          >
            <b-form-textarea
              id="content"
              v-model="formData.short_description"
              :class="
                errors.short_description ? 'is-invalid' : ''
              "
              required
              rows="3"
              size="sm"
            />

            <small
              v-for="(error, index) in errors.short_description"
              :key="index"
              class="text-danger d-block"
              v-html="$t(error)"
            />
          </b-form-group>

          <b-form-group
            id="parameters"
            :label="$t('product.parameters')"
            size="sm"
            :disabled="isLocked"
          >
            <b-form-textarea
              id="parameters"
              v-model="formData.parameters"
              :class="errors.parameters ? 'is-invalid' : ''"
              required
              rows="3"
              size="sm"
            />

            <small
              v-for="(error, index) in errors.parameters"
              :key="index"
              class="text-danger d-block"
              v-html="$t(error)"
            />
          </b-form-group>

          <b-form-group
            :description="$t('translation.optional.widget.help')"
          >
            <b-form-checkbox
              v-model="formData.remove_exist_widget"
              :disabled="isLocked"
              class="mb-3"
            >
              {{ $t("translation.optional.widget.info") }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mt-2">
            <b-btn
              block
              variant="secondary"
              :disabled="isLocked"
              type="submit"
            >
              {{ $t("copyai.idle") }}
            </b-btn>
          </b-form-group>
        </template>
        <template v-else>
          <b-skeleton-img
            height="400px"
            no-aspect
          />
        </template>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import ProductModel from "@/model/ProductModel.js";
import GeneratorModel from "@/model/GeneratorModel.js";
import { useGeneratorStore } from "@/store/GeneratorStore.js";
import { useCreditStore } from "@/store/CreditStore.js";
import eshopData from "@/mixin/eshopData.js";
import { showMessage } from "@/plugins/flashMessage";
import HelpBox from "@/share/help-box.vue";

export default {
  name: "ModalCopyAI",

  mixins: [eshopData],

  props: {
    productId: {
      type: Number,
      required: true,
    },
  },

  async mounted(){
    const { productId } = this;

    await useCreditStore().findHistory();

    await ProductModel.findCurrent(
      productId,
      () => {},
      (data) => {
        const { product, widget } = data;
        const { name, short_description, parameters } = product;
        this.product = product;

        // Fill form
        this.formData.name = name.trim();
        this.formData.short_description = short_description
          ? short_description
            .replace(/<[^>]+>|&nbsp;/g, " ")
            .replace(/\s+/g, " ")
            .trim()
          : null;

        // Fill widget and layout
        this.widget = widget;
        this.layout = widget[0].id;

        const remappingParameter = [];

        if (parameters) {
          parameters.map(({ name, value }) => {
            const formattedName = (
              name.charAt(0).toUpperCase() + name.slice(1)
            )
              .trim()
              .replace(/:$/, "");
            const formattedValue = value
              .replace(/\s+/g, " ")
              .trim();
            remappingParameter.push(
              `${formattedName}: ${formattedValue}`
            );
          });
        }

        this.formData.parameters = remappingParameter
          ? remappingParameter.join(", ")
          : "";

        this.isLoaded = true;
      }
    );
  },

  data() {
    return {
      isLoaded: false,
      layout: null,
      errors: [],
      widget: [],
      formData: {
        name: null,
        short_description: null,
        remove_exist_widget: true,
        parameters: [],
      },
      product: {
        parameters: [],
      },
    };
  },

  computed: {
    isLocked() {
      const { allowGenerateContent, isShoptet } = this;
      return !allowGenerateContent && isShoptet;
    },
  },
  components: { HelpBox },

  methods: {
    async actionCreate() {
      if(!this.validateForm()){
        return;
      }
      const { formData, layout, productId } = this;
      formData["product_id"] = productId;

      await GeneratorModel.create(
        { ...formData, ...{ layout } },
        () => {
        },
        (data) => {

          if (data.response?.status === 422) {
            this.errors = data.response.data.errors
          } else {
            showMessage("flash.generator.product");
            useGeneratorStore().check();

            this.$emit("create", {
              id: productId,
            });
          }
        }
      );
    },

    validateForm(){
      if(this.formData.short_description.length < 1){
        this.errors.short_description = true;
        return false;
      }else if(this.formData.parameters < 1){
        this.errors.parameters = true;
        return false;
      }else if(this.formData.name < 1){
        this.errors.name = true;
        return false;
      }
      this.errors.short_description = false;
      this.errors.parameters = false;
      this.errors.name = false;
      return true;
    },
  },
};
</script>
