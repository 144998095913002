<template>
  <div class="px-5 mx-5">
    <grid-search
      size="md"
      @input="updateQuery"
      @submit="searchPhoto"
    />
  </div>

  <div class="photo-bank__images">
    <template v-if="photos.length === 0">
      <div
        class="d-flex w-100 h-100 align-items-center justify-content-center"
      >
        <b-spinner
          v-if="loading"
          variant="black"
        />
        <h6>{{ $t("widgetTree.modal.photobank.loading") }}</h6>
      </div>
    </template>
    <template v-else>
      <b-row>
        <b-col
          v-for="image in photos"
          :key="image.id"
          cols="4"
        >
          <div
            class="content-editor__image-container shadow-sm rounded mb-4"
          >
            <div class="content-editor__image">
              <a
                href="#"
                class="content-editor__zoom"
                @click.prevent="showImage(image.image.large)"
              >
                <i
                  class="ico ico-zoom"
                />
              </a>
              <a
                class="content-editor__image-link"
                :data-text="$t('widgetTree.modal.photobank.download')"
                data-cy="photo-bank-download-image-btn"
                href="#"
                @click.prevent="downloadImage(image)"
              >
                <b-img-lazy
                  :src="image.image.small"
                  class="content-editor__img"
                />
              </a>
            </div>

            <b-form>
              <tier-control
                :allow-tariff="['profi', 'premium', 'saas']"
                minimum-tariff="profi"
                position="bottom"
              >
                <b-button
                  size="sm"
                  class="w-100"
                  type="secondary"
                  @click.prevent="downloadImage(image)"
                >
                  {{ $t("widgetTree.modal.photobank.download") }}
                </b-button>
              </tier-control>
            </b-form>
          </div>
        </b-col>
      </b-row>

      <div class="text-center">
        <b-button
          v-if="photos.length > 0 && showNext"
          :disabled="loading"
          size="md"
          type="secondary"
          @click="nextPage"
        >
          {{ $t("widgetTree.modal.photobank.load") }}
          <b-spinner
            v-show="loadingNextPage"
            small
            variant="black"
          />
        </b-button>
      </div>
    </template>
  </div>

  <b-modal
    v-model="isShowImageModal"
    :title="$t('widgetTree.modal.image.preview')"
    hide-footer
    size="ai"
  >
    <div class="content-editor__image-preview">
      <b-img-lazy :src="bigImage" />
    </div>
  </b-modal>
</template>

<script>
import GridSearch from "@/share/grid-search.vue";
import ImageModel from "@/model/ImageModel.js";
import TierControl from "@/share/tier-control.vue";
import { showMessage } from "@/plugins/flashMessage";
import eshopData from "@/mixin/eshopData";

export default {
  name: "ImagePhotoBank",

  mixins: [eshopData],

  data() {
    return {
      search: "",
      photos: [],
      loading: false,
      showNext: true,
      count: -1,
      page: 1,
      loadingNextPage: false,
      isShowImageModal: false,
      bigImage: "",
    };
  },

  components: {
    "grid-search": GridSearch,
    "tier-control": TierControl,
  },

  methods: {
    async nextPage() {
      const { search } = this;
      this.loading = true;
      this.page = this.page + 1;

      const params = {
        query: search,
        page: this.page,
      };

      await ImageModel.findMe(
        params,
        () => {},
        (data) => {
          const { images } = data;

          if (images.length === 0) {
            this.showNext = false;
          }

          Array.from(images).forEach((img) => {
            this.photos.push(img);
          });
        }
      );

      this.loading = false;
    },

    updateQuery(query) {
      this.search = query;
    },

    async searchPhoto() {
      this.loading = true;
      const { search, page } = this;

      const params = {
        query: search,
        page: page,
      };

      await ImageModel.findMe(
        params,
        () => {},
        (data) => {
          const { images } = data;
          this.photos = images;
          this.showNext = true;
        }
      );
      this.loading = false;
    },

    async downloadImage(image) {
      const { id } = image;
      const { eshopTariff } = this;

      if (eshopTariff !== "start") {
        await ImageModel.create(
          { id },
          () => {
          },
          (data) => {
            showMessage("flash.photoBank.downloaded");
          }
        );
      }
    },

    showImage(image) {
      this.isShowImageModal = true;
      this.bigImage = image;
    },
  },
};
</script>
