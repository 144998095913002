<template>
  <div class="skeleton-editor shadow-sm border bg-light">
    <div class="skeleton-editor__left">
      <small class="text-muted fs-12 d-block mb-2">Products list</small>
      <div
        v-for="n in 15"
        :key="n"
        class="skeleton-editor__skeleton-item skeleton-editor__skeleton-item--product"
      />
    </div>
    <div class="skeleton-editor__center">
      <div>
        <slot name="calculator" />
      </div>

      <div :style="calculatePadding">
        <small class="text-muted fs-12 d-block mb-2">Content widget</small>
        <div
          v-for="n in 22"
          :key="n"
          class="skeleton-editor__skeleton-item skeleton-editor__skeleton-item--content"
        />
      </div>
    </div>
    <div class="skeleton-editor__right">
      <div>
        <slot name="flag" />
      </div>
      <small class="text-muted fs-12 d-block mb-2">Widget list</small>
      <div
        v-for="n in 10"
        :key="n"
        class="skeleton-editor__skeleton-item skeleton-editor__skeleton-item--widget"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "EditorSkeleton",

  props:{
    formData: {
      type: Object,
      required: false,
      default: () => ({
        editor_width: 1200,
      }),
    },
  },

  computed: {
    calculatePadding() {
      const maxWidth = 1200;
      const padding = (maxWidth - this.formData.editor_width) / 7;
      return {
        padding: `0 ${padding}px`,
      };
    },
  }
}
</script>