<template>
  <div class="product-autocomplete w-70">
    <div class="product-autocomplete__input">
      <template v-if="selected">
        <span
          class="form-control"
          @click="selected = null"
        >
          {{ formatUrl(selected.url) }}
        </span>
      </template>
      <template v-else>
        <form
          class="input-group position-relative"
          @submit.prevent="actionRead"
        >
          <input
            id="id"
            v-model="params.query"
            type="text"
            class="form-control"
            placeholder="Vyhledejte eshop..."
            autocomplete="off"
            @click="handleOpen(true)"
          >
          <div class="input-group-append">
            <button
              class="btn btn-secondary"
              type="submit"
            >
              <i class="ico ico-search" />
            </button>
          </div>
        </form>
      </template>
    </div>

    <div
      v-if="isOpen && !selected"
      class="product-autocomplete__container"
      style="z-index: 9"
    >
      <div
        v-for="user in users"
        :key="user.id"
        class="product-autocomplete__products"
      >
        <div class="product-autocomplete__title">
          <a
            :href="user.url"
            target="_blank"
          >
            {{ formatUrl(user.url) }}
            <i class="ico ico-external" />
          </a>
        </div>

        <div class="product-autocomplete__button">
          <a
            class="btn btn-secondary btn-sm btn-block text-white"
            @click="handleSelect(user)"
          >
            Vybrat
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardModel from "@/model/DashboardModel.js";

export default {
  name: "eshop-autocomplete",

  async mounted(){
    await this.actionRead();
  },

  data() {
    return {
      users: [],
      params: {
        query: "",
        limit: 5,
        offset: 0,
        filter: 1,
      },
      selected: null,
      isOpen: false,
      url: "",
    };
  },

  methods: {
    formatUrl(url) {
      if (url) {
        url = url.replace(/^(https?:\/\/)/, "");
        url = url.replace(/^www\./, "");
        url = url.replace(/\/$/, "");
      }
      return url;
    },
    handleSelect(eshop) {
      this.selected = eshop;
      this.$emit("select-eshop", eshop);
    },

    handleOpen(state) {
      this.isOpen = state;
    },

    async actionRead() {
      const { params } = this;

      await DashboardModel.findAll(
        params,
        () => {},
        (data) => {
          this.users = data.data.users;
        }
      );
    },
  },
};
</script>

<style scoped></style>
