<template>
  <b-btn
    :disabled="runningGenerator || isLocked || isEditLocked"
    size="sm"
    :attrs="$attrs"
    variant="secondary"
    @click="$emit('open-editor', item)"
  >
    <template v-if="runningGenerator">
      <b-spinner
        class="mr-1"
        small
        style="width: 12px; height: 12px; margin: 0px 0 2px 0"
      />
      {{ $t("grid.generate") }}
    </template>
    <template v-else>
      <i class="ico ico-edit"><!-- ico --></i>
      {{ $t("grid.createContent") }}
    </template>
  </b-btn>
</template>
<script>
import generatorData from "@/mixin/generatorData";
import permission from "@/mixin/permission";
import lockedData from "@/mixin/lockedData";

export default {
  name: "LayoutEditButton",

  mixins: [generatorData, permission, lockedData],

  props: {
    item: {
      type: Object,
      require: true,
    },

    ignoreGeneratorState: {
      type: Boolean,
      require: false,
      default: true,
    }
  },

  computed: {
    runningGenerator() {
      const { isLockedItemId, isDeleted, ignoreGeneratorState } = this;
      if (ignoreGeneratorState) return false
      return isLockedItemId || isDeleted;
    },

    isEditLocked() {
      const { item, expireTariff } = this;
      const { content } = item
      const { standard } = content

      return standard === 0 && expireTariff;
    }
  },
}
</script>