import axios from "axios";

export default class CreditModel {
  static async findHistory(
    params = {},
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    const query = {
      action: params.action || "remove",
    };

    return await axios
      .post("/api/v2/eshop/credit/grid", query)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch credit [findHistory()]", error);
        errorCallback(error);
        return error;
      });
  }
}
