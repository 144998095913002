<template>
  <table
    v-if="selectedBilling"
    class="table table-sm text-left mb-0 mt-3"
  >
    <tbody>
      <tr>
        <td>{{ selectedBilling.name }}</td>
      </tr>
      <tr>
        <td>{{ selectedBilling.street }}</td>
      </tr>
      <tr>
        <td>{{ selectedBilling.city }}</td>
      </tr>
      <tr>
        <td>{{ selectedBilling.zip }}</td>
      </tr>
      <tr>
        <td v-if="selectedBilling.ic">
          {{ $t("tariff.step.two.ic") }}: {{ selectedBilling.ic }}
        </td>
      </tr>
      <tr>
        <td v-if="selectedBilling.dic">
          {{ $t("tariff.step.two.dic") }}: {{ selectedBilling.dic }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "BillingTable",
  displayName: "BillingTable",

  props: {
    selectedBilling: {
      type: Object,
      required: true,
    },
  },
};
</script>
