<template>
  <transition name="fade">
    <div v-if="isLoaded">
      <template v-for="(category, indexCategory) in filterCategory">
        <div
          v-if="category.widget.length > 0"
          :key="category.id"
        >
          <small
            :class="[indexCategory === 0 ? 'mt-0' : 'mt-4', 'font-weight-bolder d-block mb-1 mb-2 fs-16']"
          >
            {{ category.name }}
          </small>

          <div class="content-editor__component-list">
            <draggable
              v-if="category.widget"
              v-model="category.widget"
              :group="{ name: 'people', pull: 'clone', put: true }"
              :sort="false"
              item-key="id"
              @end="$emit('move-end', false)"
              @start="$emit('move-start', true)"
            >
              <template #item="{ element }">
                <div
                  :class="[
                    element.html_preview
                      ? 'content-editor__component-preview'
                      : 'content-editor__component-item',
                  ]"
                  :data-cy="'widget-list-item-' + element.id"
                >
                  <template v-if="element.html_preview">
                    <div
                      :style="getZoom(element.zoom)"
                      class="content-editor__preview-inner"
                      v-html="element.html_preview"
                    />
                  </template>
                  <template v-else>
                    <div
                      v-if="element.ico"
                      class="content-editor__component-ico"
                      v-html="element.ico"
                    />
                    <div class="content-editor__component-name">
                      {{ element.name }}
                    </div>
                  </template>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </template>
    </div>
  </transition>
</template>

<script>
import draggable from "vuedraggable";
import { widget } from "@/filter/vue";

draggable.compatConfig = { MODE: 3 };

export default {
  name: "WidgetStandard",

  props: {
    list: {
      require: true,
      type: Array,
    },

    isLoaded: {
      require: true,
      type: Boolean,
    },

    activeCategoryId: {
      require: true,
      type: [Number, null],
      default: null,
    },
  },

  computed: {
    filterCategory() {
      const { activeCategoryId, list } = this;

      if (activeCategoryId) {
        return list.filter((item) => item.id === activeCategoryId);
      }

      return list;
    },
  },

  components: {
    draggable,
  },

  methods: {
    widget,
    getZoom(zoom) {
      return `zoom: ${zoom}%`;
    },
  },
};
</script>

<style scoped></style>