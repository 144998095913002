<template>
  <div class="text-center">
    <b-icon
      icon="check-circle-fill"
      size="40"
    />
    <p class="text-center pb-2">
      {{ $t("Click to approve the content of this product and export it.") }}
    </p>
    <b-btn
      block
      variant="secondary"
      @click="actionUpdate"
    >
      {{ $t("Approve label") }}
    </b-btn>
  </div>
</template>

<script>

import ProductModel from "@/model/ProductModel";
import {showMessage} from "@/plugins/flashMessage";


export default {
  name: "ModalApproveProduct",

  props:{
    product:{
      type: Object,
      required: true,
    }
  },

  methods:{
    async actionUpdate() {
      const { product } = this;
      product.status = 'ready';

      await ProductModel.update(
        product.id,
        {
          data: {
            name: product.name,
            status: product.status,
            short_description: product.short_description,
            is_reference: product.is_reference,
          },
        },
        () => {},
        () => {
          this.$emit('approveProduct', product);
          showMessage(`flash.module.${content}`);
        }
      );
    },
  }
}
</script>