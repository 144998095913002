<template>
  <div>
    <table class="position-relative">
      <thead>
        <tr>
          <td class="fs-26">
            {{ $t("tariff.head.title") }}
          </td>
          <td
            v-for="(item, index) in data.header"
            :key="index"
            class="price-list-table__tariff pt-5 pb-2"
          >
            <div
              v-if="item.best"
              class="price-list-table__most-favourite"
            >
              <span class="text-center fs-12 lh-24 text-white">
                {{ $t("tariff.head.favourite") }}
              </span>
            </div>

            <strong class="d-block fs-26 position-relative">
              {{ item.name }}
            </strong>
            <span class="d-block fs-12 font-weight-light">
            &nbsp;
              <template v-if="item.showFreeTime && data.region !== 'en'">
                {{ $t("tariff.head.free") }}
              </template>
            &nbsp;
            </span>

            <span class="fs-22">
              <template v-if="data.price[item.type][periodType]">
                {{
                  showPriceWithVat
                    ? (
                      data.price[item.type][periodType] * 0.21 +
                      data.price[item.type][periodType]
                    ).toFixed(0)
                    : data.price[item.type][periodType]
                }}

                {{ data.price.currency }}

                <div
                  v-if="!data.periodViewYear"
                  class="fs-14 font-weight-light"
                >
                  {{
                    item.showPrice
                      ? $t("tariff.head.forMonth")
                      : "&nbsp;"
                  }}
                </div>
                <div
                  v-else
                  class="fs-14 font-weight-light text-lowercase"
                >
                  {{
                    item.showPrice
                      ? $t("tariff.head.forYear")
                      : "&nbsp;"
                  }}
                </div>
              </template>
              <template v-else>
                {{ data.price[item.type].custom ? $t("tariff.head.contact") : $t("Forever FREE") + ' ❤️' }}
              </template>
            </span>

            <template v-if="data.info[item.name.toLowerCase()]">
              <div
                class="d-block py-3 my-2 fs-14 lh-16 font-weight-bold"
              >
                * {{ data.info[item.name.toLowerCase()] }}
              </div>
            </template>

            <div
              v-if="data.config.showLogo"
              class="price-list-table__logo mb-4"
            >
              <template v-if="item.logo[data.region]">
                <img
                  class="price-list-table__logo-img"
                  :src="item.logo[data.region]"
                >
              </template>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <template v-if="data.config.showLimit">
          <tr>
            <td>
              <span class="d-block fs-16 pl-2 font-weight-bolder">
                {{ $t('Count product, blog post and category') }}
              </span>

              <span class="d-block fs-13 pl-2 font-weight-light">
                {{ $t('Includes only edited products, articles and categories') }}
              </span>
            </td>

            <template
              v-for="(limit, index) in data.limit"
              :key="index"
            >
              <td class="pt-3 px-3 fs-15">
                <div
                  v-for="(type, typeIndex) in ['products', 'categories', 'posts']"
                  :key="typeIndex"
                >
                  <div class="pl-4">
                    <template v-if="limit[type]">
                      <strong>{{ $t(limit[type]) }}</strong> {{ $t(`limiter.${type}`) }}
                    </template>
                    <template v-else>
                      {{ $t('Unlimited') }} {{ $t(`limiter.${type}`) }}
                    </template>
                  </div>
                </div>
              </td>
            </template>
          </tr>
        </template>

        <tr>
          <td>
            <span class="d-block fs-16 pl-2 font-weight-bolder">
              {{ $t("tariff.credits") }}
            </span>
          </td>

          <td
            v-for="(item, index) in data.header"
            :key="index"
            class="text-center"
          >
            {{ item.freeCredit[data.region] }}
            <span
              v-if="item.freeCredit[data.region] > 1"
            >
              {{ $t('tariff.unit') }} </span>
          </td>
        </tr>

        <tr
          v-for="(item, index) in data.table"
          :key="index"
        >
          <td>
            <span class="d-block fs-16 pl-2 font-weight-bolder">
              {{ item.translate.name }}
              <span
                v-if="item.planning"
                class="badge badge-secondary"
              >
                {{ $t("tariff.head.plan") }}</span>
            </span>
            <span class="d-block fs-13 pl-2 font-weight-light">
              {{ item.translate.info }}
            </span>
          </td>
          <td
            v-for="(tariff, indexTariff) in ['start', 'profi', 'premium', 'saas']"
            :key="indexTariff"
            class="text-center"
          >
            <img
              v-if="!item.disableTariff.includes(tariff)"
              alt="Yes"
              src="https://pobo-cdn.b-cdn.net/static/yes.png"
            >

            <img
              v-if="item.disableTariff.includes(tariff)"
              alt="No"
              src="https://pobo-cdn.b-cdn.net/static/no.png"
            >
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr class="price-list-table__last-row">
          <th />

          <td
            v-for="(item, index) in data.footer"
            :key="index"
            class="pt-3 px-3"
          >
            <b-btn
              v-if="item.type === 'external'"
              :disabled="isLockedTariff(item.tariff)"
              block
              variant="secondary"
              @click="openLink(item.link)"
            >
              {{ $t("tariff.head.buy") }}
            </b-btn>

            <b-btn
              v-if="item.type === 'internal'"
              :disabled="isLockedTariff(item.tariff)"
              block
              variant="secondary"
              @click.prevent="handleOpenPayModal(item)"
            >
              {{ $t("tariff.head.buy") }}
            </b-btn>

            <b-btn
              v-if="item.type === 'contact'"
              :disabled="isLockedTariff(item.tariff)"
              block
              variant="info"
              @click="openLink('/contact')"
            >
              {{ $t("tariff.head.contact") }}
            </b-btn>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import eshopData from "@/mixin/eshopData";
export default {
  name: "PriceTable",

  mixins: [eshopData],

  props: {
    data: {
      type: Object,
      required: true,
    },

    showPriceWithVat: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  computed: {
    periodType() {
      return this.data.periodViewYear ? 'year' : 'month';
    },
  },

  methods: {
    isLockedTariff(type) {
      const { eshop } = this;
      const { tariff, platform } = eshop;

      if (platform === "shopify") {
        return type === tariff;
      }

      const dennyList = {
        start: ["start"],
        profi: ["start", "profi"],
        premium: ["start", "profi", "premium"],
      };

      return dennyList[tariff].includes(type);
    },

    openLink(link) {
      window.open(link);
    },

    handleOpenPayModal(item) {
      const { tariff } = item;
      this.$emit("open-modal", {
        selectedPackage: {
          tariff_paid: tariff,
          person: {
            use_reverse_charge: true,
          },
        },
      });
    },
  },
};
</script>
