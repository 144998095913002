<template>
  <div class="overlay-loading">
    <div class="overlay-loading__spinner" />
    <h2 class="mt-4 fs-17 font-weight-bolder">
      {{ text }}
    </h2>
  </div>
</template>


<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.overlay-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;

  h2 {
    color: white;
  }
}

.overlay-loading__spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #649b0d;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spinLoading 2s linear infinite;
}

@keyframes spinLoading {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
