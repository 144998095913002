<template>
  <div
    class="bg-white border border-bottom-0 p-2"
    style="
      position: fixed;
      right: 0;
      bottom: 0;
      width: 300px;
      border-radius: 4px 0 0 0;
      height: 50px;
    "
  >
    <b-button
      block
      class="d-flex align-items-center justify-content-between"
      variant="secondary"
      @click="toggleHistory"
    >
      <span class="font-weight-bolder"> Stav generování popisků (100%) </span>
      <b-spinner
        small
        style="width: 13px; height: 13px"
      />
    </b-button>
  </div>

  <div
    v-if="is_open"
    class="bg-white border-left p-3"
    style="
      position: fixed;
      right: 0;
      top: 0;
      height: calc(100% - 48px);
      width: 300px;
      z-index: 90;
      overflow-y: auto;
    "
  >
    <div class="fs-18 lh-24 font-weight-bolder mb-3">
      Popisky produktů čekající na vygenerování pomocí AI
    </div>
    <div
      v-for="row in list"
      :key="row.id"
      class="border-bottom py-2 d-flex align-items-center w-100 justify-content-between"
    >
      <div>
        {{ row.product.name }}
      </div>
      <div>
        <b-spinner
          small
          style="width: 13px; height: 13px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useGeneratorStore } from "@/store/GeneratorStore";
import { mapWritableState } from "pinia";

export default {
  name: "Wrapper",

  mounted() {
    useGeneratorStore().check();
  },

  computed: {
    ...mapWritableState(useGeneratorStore, {
      list: "list",
      reaming: "reaming",
      locked: "locked",
      is_open: "is_open",
    }),
  },

  methods: {
    toggleHistory() {
      this.is_open = !this.is_open;
    },
  },
};
</script>