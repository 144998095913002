<template>
  <b-form
    class="pb-1"
    @submit.prevent="changeStatus"
  >
    <p
      v-if="products.length > 1"
      class="text-center"
    >
      {{ $t('All selected products') }} ({{ products.length }}) {{ $t('mark it as:') }}
    </p>
    
    <h6>
      {{ $t('Product status') }}
    </h6>
    <div
      class="bg-white p-4 shadow-sm rounded"
    >
      <b-form-group>
        <b-form-radio
          v-for="(state, index) in states"
          :key="index"
          v-model="currentStatus"
          class="mb-2 border-bottom"
          :value="state.value"
          data-cy="product-detail-change-status"
        >
          {{ $t(state.lang) }}

          <small class="d-block text-black-50">
            {{ $t(state.info) }}
          </small>
        </b-form-radio>
      </b-form-group>
    </div>

    <b-form-group>
      <b-btn
        variant="secondary"
        type="submit"
        class="mt-4"
        data-cy="product-detail-change-status-submit-btn"
        block
      >
        {{ $t("Save") }}
      </b-btn>
    </b-form-group>
  </b-form>
</template>

<script>
export default {
  name: "ModalChangeStatus",

  props: {
    products: {
      type: Array,
      required: false,
    },
  },

  mounted() {
    this.currentStatus = this.products[0].status
  },

  data() {
    return {
      currentStatus: "ready",
      states: [
        {
          value: "draft",
          lang: "Draft",
          info: "Product will not be exported in bulk export"
        },
        {
          value: "ready",
          lang: "Ready",
          info: "The product is exported in bulk export"
        }
      ]
    };
  },

  methods: {
    changeStatus() {
      const {  currentStatus } = this;
      this.$emit("change-status", currentStatus);
    },
  },
};
</script>

