<template>
  <div class="product-autocomplete">
    <div class="product-autocomplete__input">
      <template v-if="selected">
        <span
          class="form-control"
          @click="selected = null"
        >
          {{ selected.name }}
        </span>
      </template>
      <template v-else>
        <form
          class="input-group position-relative"
          @submit.prevent="actionRead"
        >
          <input
            id="id"
            v-model="params.query"
            :placeholder="$t('productAutoComplete.placeholder')"
            autocomplete="off"
            class="form-control"
            type="text"
            @click="handleOpen(true)"
          >
          <div class="input-group-append">
            <button
              class="btn btn-secondary"
              type="submit"
            >
              {{ $t("productAutoComplete.button") }}
            </button>
          </div>
        </form>
      </template>
    </div>

    <div
      v-if="onlyVisibleProduct && isOpen && !selected"
      class="product-autocomplete__container"
    >
      <div
        v-for="product in onlyVisibleProduct"
        :key="product.id"
        class="product-autocomplete__products"
      >
        <div class="product-autocomplete__title">
          <a
            :href="product.url"
            target="_blank"
          >
            {{ product.name }} <i class="ico ico-external" />
          </a>
        </div>

        <div class="product-autocomplete__button">
          <a
            class="btn btn-secondary btn-sm btn-block text-white"
            @click="handleSelect(product)"
          >
            {{ $t("productAutoComplete.choose") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductModel from "@/model/ProductModel.js";

export default {
  name: "product-autocomplete",

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      products: {
        product: [],
        all: 0,
        total: 0,
      },
      params: {
        query: "",
        offset: 1,
        limit: 10,
        total: 0,
        filter: 1,
      },
      selected: null,
      isOpen: false,
      url: "",
    };
  },

  computed: {
    onlyVisibleProduct() {
      const { products } = this;
      return products.product.filter((pr) => pr.is_visible === true);
    },
  },

  methods: {
    handleSelect(product) {
      this.selected = product;
      this.$emit("select-product", product);
    },

    handleOpen(state) {
      this.isOpen = state;
    },

    async actionRead() {
      const { params } = this;

      await ProductModel.findAll(
        params,
        () => {},
        (data) => {
          this.products = data;
        }
      );
    },
  },
};
</script>

<style scoped></style>