import axios from "axios";

export default class LiveDesigner {
  static async create(data ={}, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .post("/api/v2/live-designer/detail", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create live designer [create()]", error);
        return error;
      });
  }

  static async update(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .put("/api/v2/live-designer/detail", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error during LiveDesigner update", error);
        endCallback(null);
        return error;
      });
  }
}
