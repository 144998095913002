<template>
  <widget-all />
</template>

<script>
import WidgetAll from "@/components/component/part/widget-all.vue";

export default {
  components: {
    "widget-all": WidgetAll,
  },
};
</script>
