import axios from "axios";

export default class BlogModel {
  static async findMe(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/blog/detail/" + id)
      .then(({ data }) => {
        endCallback();
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch detail blog [findMe()]", error);
        return error;
      });
  }

  static async findAll(
    params,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/blog/grid?query=" + params)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch all blog [findAll()]", error);
        return error;
      });
  }

  static async create(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .post("/api/v2/blog/detail", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create blog post [create()]", error);
        return error;
      });
  }

  static async delete(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .delete("/api/v2/blog/detail/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error delete blog [delete()]", error);
        return error;
      });
  }

  static async update(id, data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .put("/api/v2/blog/detail/" + id, data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error blog update [update()]", error);
        return error;
      });
  }

  static async findHtml(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/blog/html", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch detail blog [findHtml()]", error);
        return error;
      });
  }

  static async findNotification(
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/notification/blog")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch detail blog [findNotification()]", error);
        return error;
      });
  }
}
