<template>
  <b-form @submit.prevent="actionUpdate">
    <layout-setting
      anchor="lang"
    >
      <template #header>
        {{ $t("lang.header") }}
      </template>

      <template #content>
        <p class="text-sm mb-3">
          {{ $t("lang.content") }}
        </p>
        <b-form-group>
          <b-form-radio
            v-for="(lang, index) in ['cs', 'sk', 'en', 'hu']"
            :key="index"
            v-model="form.lang"
            :value="lang"
            class="mb-2"
          >
            {{ $t(`lang.list.${lang}`) }}
          </b-form-radio>
        </b-form-group>

        <b-form-group class="mt-4">
          <b-button type="submit">
            {{ $t("lang.save") }}
            <i class="ico ico-arrow-right" />
          </b-button>
        </b-form-group>
      </template>
    </layout-setting>

    <layout-setting
      anchor="lang"
    >
      <template #header>
        {{ $t("lang.header") }}
      </template>

      <template #content>
        <p class="text-sm m-0 pb-4">
          {{ $t("customize.form.ai_lang") }}.
          {{ $t("customize.info.ai_lang") }}
        </p>

        <b-form-group>
          <b-form-radio
            v-for="lang in ['cs', 'sk', 'en', 'hu']"
            :key="lang"
            v-model="form.ai_lang"
            :name="lang"
            :value="lang"
            class="mb-2"
          >
            {{ $t(`customize.list.${lang}`) }}
          </b-form-radio>
        </b-form-group>

        <div
          v-if="errors"
          class="mt-1"
        >
          <small
            v-for="(error, index) in errors.ai_lang"
            :key="index"
            class="text-danger d-block"
            v-html="$t(error)"
          />
        </div>

        <b-form-group class="pt-3">
          <b-btn
            type="submit"
            variant="secondary"
          >
            {{ $t("customize.save") }} <i class="ico ico-arrow-right" />
          </b-btn>
        </b-form-group>
      </template>
    </layout-setting>
  </b-form>
</template>

<script>
import { useEshopStore } from "@/store/EshopStore";
import cloneDeep from "lodash.clonedeep";
import eshopData from "@/mixin/eshopData";

export default {
  name: "EditorLang",

  mixins: [eshopData],

  async mounted() {
    const { eshop } = this;
    this.form = cloneDeep(eshop);
  },

  data() {
    return {
      form: {},
      errors: {},
    };
  },

  methods: {
    async actionUpdate() {
      const { form } = this;
      await useEshopStore().updateEshop({
        data: form
      });

      this.$i18n.locale = form.lang;
    },
  },
};
</script>