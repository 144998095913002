<template>
  <div class="checked-translate">
    <b-overlay :show="showTranslateLoading">
      <template #default>
        <p>{{ $t("translate.modal.form.info") }}</p>

        <tier-control
          :allow-tariff="['premium', 'saas']"
          minimum-tariff="premium"
        >
          <credit-charge
            :count-lang="formData.target_lang.length"
            :count-product="1"
            :is-locked="isLockedTranslate"
            :type="options.content"
          />

          <b-form @submit.prevent="actionCreate">
            <div class="editor-bg my-4 d-flex align-items-center">
              <div>
                <!-- todo add from lang -->
              </div>
              <div>
                <b-form-group class="mb-0">
                  <b-form-checkbox-group
                    v-model="formData.target_lang"
                    :options="flagList"
                  />
                </b-form-group>
              </div>
            </div>

            <b-form-group>
              <b-btn
                :disabled="
                  isLockedTranslate || formData.target_lang.length === 0
                "
                block
                type="submit"
                variant="secondary"
              >
                {{ $t("translate.modal.form.submit") }}
              </b-btn>
            </b-form-group>
          </b-form>
        </tier-control>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import CreditCharge from "@/share/credit-charge.vue";
import lang from "@/js/config/lang.js";
import TierControl from "@/share/tier-control.vue";
import eshopData from "@/mixin/eshopData";
import axios from "axios";
import { useCreditStore } from "@/store/CreditStore";
import { showMessage } from "@/plugins/flashMessage";

export default {
  name: "ModalTranslate",

  mixins: [eshopData],

  props: {
    options: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  data() {
    return {
      lang: lang,
      showTranslateLoading: false,
      formData: {
        target_lang: [],
      },
    };
  },

  computed: {
    isLockedTranslate() {
      const { summary, price, formData } = this;
      const { target_lang } = formData;
      const { translate } = price;
      return summary.reaming < target_lang.length * translate;
    },

    flagList() {
      const { eshop } = this;
      const { editor_lang, lang_list } = eshop;

      return lang
        .filter((flag) => flag !== editor_lang && lang_list.includes(flag))
        .map((flag) => {
          return {
            text: this.$t(`translate.lang.${flag}`),
            value: flag,
          };
        });
    },
  },

  components: {
    "tier-control": TierControl,
    "credit-charge": CreditCharge,
  },

  methods: {
    actionCreate() {
      const { formData } = this;
      const { target_lang } = formData;
      this.$emit("action-translate", target_lang);
      // todo translate

    },
  },
};
</script>