<template>
  <div>
    <b-form
      data-cy="image-link-form"
      @submit.prevent="update"
    >
      <b-form-group
        :description="$t('widgetTree.modal.image.link.description')"
      >
        <b-input-group>
          <b-form-input
            v-model="value"
            data-cy="image-link-input"
            :placeholder="$t('widgetTree.modal.image.menu.link')"
            type="url"
          />
          <b-input-group-append>
            <b-button
              type="submit"
              variant="secondary"
            >
              {{ $t("widgetTree.modal.image.link.submit") }}
              <i class="ico ico-save" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
export default {
  name: "ImageLink",

  props: {
    link: {
      type: String,
      default: null,
      required: false,
    },
  },

  mounted() {
    this.value = this.link;
  },

  data() {
    return {
      value: "",
    };
  },
  methods: {
    update() {
      const { value } = this;
      this.$emit("update-link", value);
    },
  },
};
</script>
