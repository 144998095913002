import axios from "axios";

export default class ImportModel {
  static async findAll(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/cron/import")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch import [findAll()]", error);
        return error;
      });
  }

  static async create(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .post("/api/v2/cron/import")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch import [create()]", error);
        return error;
      });
  }

  static async delete(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    const { id } = data;

    return await axios
      .delete("/api/v2/cron/import", {
        data: {
          id,
        },
      })
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error delete import [delete()]", error);
        return error;
      });
  }
}
