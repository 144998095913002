<template>
  <div>
    <div class="container mt-2 mb-2">
      <div class="row">
        <div class="col-md-12 mb-4 pl-1">
          <div class="card">
            <div
              class="card-body pb-0 text-center"
              style="background: #e7e7e7"
            >
              <h5 class="card-title">
                {{ item.name }} – ID: {{ item.id }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-form @submit.prevent="actionCreate">
      <b-form-group
        label="Vyberte e-shop ke kterému chcete šablonu přiřadit"
      >
        <b-row>
          <b-col md="9">
            <eshop-autocomplete @select-eshop="selectEshop" />
          </b-col>
          <b-col
            md="3"
            class="m-0"
          >
            <b-btn
              variant="secondary"
              type="submit"
            >
              <i class="ico ico-save m-0" /> Uložit
            </b-btn>
          </b-col>
        </b-row>
        <small
          v-if="success"
          class="pt-2 pl-1"
          style="color: darkgreen"
        >
          Šablona byla úspěšně přiřazena k e-shopu
        </small>
      </b-form-group>
    </b-form>

    <template v-if="users.length > 0">
      <p class="mt-5 mb-0">
        <strong>
          Tato šablona je již propojena s těmito e-shopy
        </strong>
      </p>
      <div class="table-responsive">
        <table class="table table-striped table-bordered mt-3">
          <thead class="bg-white">
            <tr>
              <th>ID</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="eshop in users"
              :key="eshop.id"
            >
              <td>{{ eshop.id }}</td>
              <td>{{ eshop.url }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <template v-else>
      <h6
        class="text-center pt-5 pb-5"
        style="text-transform: uppercase"
      >
        Tato šablona nebyla prozatím přiřazena žádnému e-shopu
      </h6>
    </template>
  </div>
</template>

<script>
import EshopAutocomplete from "@/components/component/part/eshop-autocomplete.vue";
import DesignModel from "@/model/admin/DesignModel";

export default {
  name: "ModalConnect",

  props: {
    item: {
      type: Object,
      required: false,
      default: () => null
    }
  },

  async mounted() {
    this.users = await DesignModel.findConnections();
  },

  data(){
    return {
      name: "",
      users: [],
      isLoaded: false,
      params: {
        query: "",
        limit: 5,
        offset: 0,
        filter: 1,
      },
      selectedEshop: null,
      success: false,
    };
  },

  components: {
    "eshop-autocomplete": EshopAutocomplete,
  },

  methods: {
    async selectEshop(eshop) {
      this.selectedEshop = eshop;
    },

    async actionCreate(){
      const { selectedEshop, item } = this;

      await DesignModel.connectMe(
        {
          eshop_id: selectedEshop.id,
          item_id: item.id,
        },
        () => {},
        (data) => {
          if (data.result) {
            this.users.push(selectedEshop);
            this.success = true;
            this.$emit("update");
          } else {
            this.success = false;
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
