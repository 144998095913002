<template>
  <b-form
    class="input-group w-100"
    data-cy="product-form-search"
    @submit.prevent="$emit('submit', query)"
  >
    <b-input
      v-model="query"
      :placeholder="$t('grid.placeholder')"
      :size="size"
      class="form-control"
      data-cy="product-input-search"
      type="text"
      @keyup="$emit('input', query)"
    />
    <div class="input-group-prepend">
      <b-btn
        size="sm"
        style="border-radius: 0 0.25rem 0.25rem 0"
        type="submit"
        variant="secondary"
      >
        <i class="ico ico-search" />
      </b-btn>
    </div>
  </b-form>
</template>

<script>
export default {
  name: "GridSearch",

  props: {
    size: {
      type: String,
      default: "sm",
    },
  },

  data() {
    return {
      query: "",
    };
  },
};
</script>
