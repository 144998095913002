<template>
  <b-card
    :class="['mb-3', selectedClass]"
    :title="design.name"
    :sub-title="design.remote_name"
    :img-alt="design.name"
    img-top
    tag="article"
  >
    <div class="design-preview shadow-sm mb-2 p-0s">
      <a
        href="#"
        class="d-block mb-4"
        @click.prevent="$emit('open-preview', design)"
      >
        <b-card-img
          class="border-light"
          :src="design.image_preview + '?width=600'"
        />
      </a>
    </div>

    <div class="pt-2" />

    <b-dropdown
      text="Nastavení a zkouška šablony"
      variant="secondary"
      size="sm"
      block
    >
      <b-dropdown-item @click="$emit('open-preview', design)">
        <b-icon
          icon="images"
          size="14"
          class="mr-1"
        />
        {{ $t('Show template preview in window') }}
      </b-dropdown-item>
      <b-dropdown-item
        @click="tryPreview(design)"
      >
        <b-icon
          icon="eye"
          size="14"
          class="mr-1"
        /> {{ $t('Try the look on the product') }}
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item @click="selectDesign(design.id)">
        <b-icon
          icon="check-circle"
          size="14"
          class="mr-1"
        /> {{ design.id === eshop.design_id ? $t('Selected') : $t('Select this design') }}
      </b-dropdown-item>
    </b-dropdown>
  </b-card>
</template>
<script>
import eshopData from "@/mixin/eshopData.js";

export default {
  name: "DesignPreview",

  mixins: [eshopData],

  props: {
    design: {
      type: Object,
      required: true,
    }
  },

  computed: {
    selectedClass() {
      const { design, designId } = this
      const { id } = design

      return id === designId ? 'design-layout-active' : null;
    },

    isLocked() {
      const { eshopTariff  } = this;
      return eshopTariff === 'start';
    }
  },

  methods: {
    selectDesign(design) {
      this.$emit("select-design", design);
    },

    tryPreview(design) {
      this.$emit("try-preview", design);
    }
  }
}
</script>
