<template>
  <div>
    <b-row align-v="center">
      <b-col cols="6">
        <h3>{{ $t("analytics.page.head") }}</h3>
        <small class="text-muted"><i class="ico ico-tooltip-help" />
          {{ $t("analytics.page.info") }}
        </small>
      </b-col>
      <b-col
        class="text-right"
        cols="6"
      >
        <div
          v-if="showAnalytics"
          class="bg-white d-block shadow-sm rounded pb-2 pt-3 px-3"
        >
          <b-row>
            <b-col cols="7">
              <b-form-input
                v-model="limit"
                max="180"
                min="2"
                size="xs"
                type="range"
                @change="actionRead"
              />
            </b-col>
            <b-col cols="5">
              <span class="mb-1 text-center d-block fs-14">
                {{ $t("analytics.page.input") }}
                <strong>{{ limit }} {{ $t("analytics.page.days") }}</strong></span>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <template v-if="showAnalytics">
      <div class="analytics-wrap">
        <div class="analytics-wrap__item">
          <template v-if="isLoaded">
            <mini-chart
              :active="setClass('seriesVisit')"
              :series="seriesVisit"
              type="seriesVisit"
              @select-panel="setActivePanel"
            >
              <template #header>
                {{ $t("analytics.eshop.panel.visitor.head") }}
              </template>
              <template #info>
                {{ $t("analytics.eshop.panel.visitor.info") }}
              </template>
              <template #with>
                {{ count(panel.pobo.page_view) }}
              </template>
              <template #without>
                {{ count(panel.old.page_view) }}
              </template>
            </mini-chart>
          </template>
          <template v-else>
            <b-skeleton-img
              height="149px"
              no-aspect
            />
          </template>
        </div>
        <div class="analytics-wrap__item">
          <template v-if="isLoaded">
            <mini-chart
              :active="setClass('seriesBasket')"
              :series="seriesBasket"
              type="seriesBasket"
              @select-panel="setActivePanel"
            >
              <template #header>
                {{ $t("analytics.eshop.panel.addCart.head") }}
              </template>
              <template #info>
                {{ $t("analytics.eshop.panel.addCart.info") }}
              </template>
              <template #with>
                {{ panel.pobo.basket_counter }}x
              </template>
              <template #without>
                {{ panel.old.basket_counter }}x
              </template>
            </mini-chart>
          </template>
          <template v-else>
            <b-skeleton-img
              height="149px"
              no-aspect
            />
          </template>
        </div>
        <div class="analytics-wrap__item">
          <template v-if="isLoaded">
            <mini-chart
              :active="setClass('seriesRate')"
              :series="seriesRate"
              type="seriesRate"
              @select-panel="setActivePanel"
            >
              <template #header>
                {{ $t("analytics.eshop.panel.conversion.head") }}
              </template>
              <template #info>
                {{ $t("analytics.eshop.panel.conversion.info") }}
              </template>
              <template #with>
                {{ percent(panel.pobo.conversion_rate) }}
              </template>
              <template #without>
                {{ percent(panel.old.conversion_rate) }}
              </template>
            </mini-chart>
          </template>
          <template v-else>
            <b-skeleton-img
              height="149px"
              no-aspect
            />
          </template>
        </div>
        <div class="analytics-wrap__item">
          <template v-if="isLoaded">
            <mini-chart
              :active="setClass('seriesTimer')"
              :series="seriesTimer"
              type="seriesTimer"
              @select-panel="setActivePanel"
            >
              <template #header>
                {{ $t("analytics.eshop.panel.timer.head") }}
              </template>
              <template #info>
                {{ $t("analytics.eshop.panel.timer.info") }}
              </template>
              <template #with>
                {{ second(panel.pobo.session_timer) }}
              </template>
              <template #without>
                {{ second(panel.old.session_timer) }}
              </template>
            </mini-chart>
          </template>
          <template v-else>
            <b-skeleton-img
              height="149px"
              no-aspect
            />
          </template>
        </div>
        <div class="analytics-wrap__item">
          <template v-if="isLoaded">
            <mini-chart
              :active="setClass('seriesScroll')"
              :series="seriesScroll"
              type="seriesScroll"
              @select-panel="setActivePanel"
            >
              <template #header>
                {{ $t("analytics.eshop.panel.scroll.head") }}
              </template>
              <template #info>
                {{ $t("analytics.eshop.panel.scroll.info") }}
              </template>
              <template #with>
                {{ percent(panel.pobo.scroll) }}
              </template>
              <template #without>
                {{ percent(panel.old.scroll) }}
              </template>
            </mini-chart>
          </template>
          <template v-else>
            <b-skeleton-img
              height="149px"
              no-aspect
            />
          </template>
        </div>
        <div class="analytics-wrap__item">
          <template v-if="isLoaded">
            <mini-chart
              :active="setClass('seriesPrice')"
              :series="seriesPrice"
              type="seriesPrice"
              @select-panel="setActivePanel"
            >
              <template #header>
                {{ $t("analytics.eshop.panel.value.head") }}
              </template>
              <template #info>
                {{ $t("analytics.eshop.panel.value.info") }}
              </template>
              <template #with>
                {{ price(panel.pobo.price) }}
              </template>
              <template #without>
                {{ price(panel.old.price) }}
              </template>
            </mini-chart>
          </template>
          <template v-else>
            <b-skeleton-img
              height="149px"
              no-aspect
            />
          </template>
        </div>
        <div class="analytics-wrap__item">
          <template v-if="isLoaded">
            <mini-chart
              :active="setClass('seriesProduct')"
              :series="seriesProduct"
              type="seriesProduct"
              @select-panel="setActivePanel"
            >
              <template #header>
                {{ $t("analytics.eshop.panel.products.head") }}
              </template>
              <template #info>
                {{ $t("analytics.eshop.panel.products.info") }}
              </template>
              <template #with>
                {{ count(panel.pobo.product) }}
              </template>
              <template #without>
                {{ count(panel.old.product) }}
              </template>
            </mini-chart>
          </template>
          <template v-else>
            <b-skeleton-img
              height="149px"
              no-aspect
            />
          </template>
        </div>
      </div>

      <h5 class="mt-5">
        {{ series[0].name }} vs. {{ series[1].name }}
      </h5>
      <small class="text-muted"><i class="ico ico-tooltip-help" />
        {{ $t("Statistics are updated every night at 2:00 (UTC)") }}
      </small>

      <div class="mt-4 p-4 shadow-sm bg-white rounded">
        <template v-if="isLoaded">
          <chart
            :options="chartOptions"
            :series="series"
            height="420"
          />
        </template>
        <template v-else>
          <b-skeleton-img
            height="435px"
            no-aspect
          />
        </template>
      </div>

      <div class="text-right pt-4">
        <router-link
          :to="{ name: 'setting-editor', hash: '#erasure' }"
          class="text-muted"
        >
          {{ $t("Data deletion options") }} »
        </router-link>
      </div>
    </template>
    <template v-else>
      <div class="py-5 mt-4 shadow-sm bg-white rounded text-center">
        <h3>{{ $t("analytics.notFound.header") }}</h3>
        <p>{{ $t("analytics.notFound.info") }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import cs from "apexcharts/dist/locales/cs.json";
import en from "apexcharts/dist/locales/en.json";
import { count, percent, price, second } from "@/filter/vue";
import MiniChart from "@/components/analytics/mini-chart.vue";
import AnalyticsModel from "@/model/AnalyticsModel.js";

export default {
  name: "Analytics",

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      data: Object,
      panel: Object,
      limit: 30,
      activePanel: "seriesVisit",
      productsPobo: 25,
      productsOld: 996,
      isLoaded: false,

      series: [
        {
          name: this.$t("analytics.graph.views.without"),
          data: [],
        },
        {
          name: this.$t("analytics.graph.views.with"),
          data: [],
        },
      ],

      seriesScroll: [
        {
          name: this.$t("analytics.graph.scroll.without"),
          data: [],
        },
        {
          name: this.$t("analytics.graph.scroll.with"),
          data: [],
        },
      ],

      seriesVisit: [
        {
          name: this.$t("analytics.graph.visit.without"),
          data: [],
        },
        {
          name: this.$t("analytics.graph.visit.with"),
          data: [],
        },
      ],

      seriesBasket: [
        {
          name: this.$t("analytics.graph.basket.without"),
          data: [],
        },
        {
          name: this.$t("analytics.graph.basket.with"),
          data: [],
        },
      ],

      seriesRate: [
        {
          name: this.$t("analytics.graph.conversion.without"),
          data: [],
        },
        {
          name: this.$t("analytics.graph.conversion.with"),
          data: [],
        },
      ],

      seriesPrice: [
        {
          name: this.$t("analytics.graph.price.without"),
          data: [],
        },
        {
          name: this.$t("analytics.graph.price.with"),
          data: [],
        },
      ],

      seriesProduct: [
        {
          name: "Produkty bez Pobo", // this.$t("analytics.graph.price.without"),
          data: [],
        },
        {
          name: "Produkty s Pobo ",
          data: [],
        },
      ],

      seriesTimer: [
        {
          name: this.$t("analytics.graph.timer.without"),
          data: [],
        },

        {
          name: this.$t("analytics.graph.timer.with"),
          data: [],
        },
      ],

      chartOptions: {
        colors: ["#ff0000", "#649b0d"],

        chart: {
          height: 450,
          type: "area",
          locales: [cs, en],
          // todo set
          defaultLocale: "en",

          animations: {
            enabled: true,
          },

          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },

        grid: {
          padding: {
            bottom: 40,
            left: 20,
            right: 20,
            top: 40,
          },
        },

        legend: {
          onItemClick: {
            toggleDataSeries: false,
          },
          showForSingleSeries: false,
          showForNullSeries: false,
          showForZeroSeries: false,
          position: "top",
          horizontalAlign: "right",
          fontSize: "16px",
          fontFamily: "Roboto",

          style: {
            fontSize: "16px",
            fontFamily: "Roboto",
          },
        },

        tooltip: {
          shared: true,
          intersect: false,
          followCursor: true,
          inverseOrder: false,
          theme: "light",
          style: {
            fontSize: "15px",
            fontFamily: "Roboto",
          },
          y: {
            formatter: function (
              value,
              { series, seriesIndex, dataPointIndex, w }
            ) {
              if (seriesIndex === 0) {
                return value.toFixed(0);
              } else if (seriesIndex === 1) {
                return value.toFixed(0);
              } else if (seriesIndex === 2) {
                return value.toFixed(2);
              } else if (seriesIndex === 3) {
                return value.toFixed(2);
              } else if (seriesIndex === 4) {
                return value.toFixed(2);
              }
            },
          },

          x: {
            show: true,
          },
          z: {
            show: false,
          },
          marker: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },

        yaxis: {
          labels: {
            offsetX: -10,
            offsetY: -50,
            formatter: (value) => {
              switch (this.activePanel) {
              case "seriesVisit":
                return count(value);
              case "seriesScroll":
                return percent(value);
              case "seriesBasket":
                return count(value);
              case "seriesProduct":
                return count(value);
              case "seriesTimer":
                return second(value);
              case "seriesRate":
                return percent(value);
              case "seriesPrice":
                return price(value);
              }
            },
            style: {
              fontSize: "16px",
            },
          },
        },

        xaxis: {
          axisTicks: {
            show: false,
          },

          axisBorder: {
            show: false,
          },

          crosshairs: {
            show: false,
          },

          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },

          floating: true,

          categories: [],
          type: "datetime",
          labels: {
            show: true,
            trim: false,
            datetimeUTC: true,
            format: "dd.MM.yy",
            offsetY: 5,

            style: {
              fontSize: "16px",
            },
          },
        },
      },
    };
  },

  computed: {
    showAnalytics() {
      const { data } = this;
      const { result } = data;
      return result;
    },
  },

  components: {
    chart: VueApexCharts,
    "mini-chart": MiniChart,
  },

  methods: {
    price,
    second,
    percent,
    count,
    setActivePanel(panel) {
      this.activePanel = panel;
      this.series = this[panel];
    },

    setClass(panel) {
      return panel === this.activePanel;
    },

    async actionRead() {
      this.isLoaded = false;

      await AnalyticsModel.findMe(
        this.limit,
        () => {},
        (data) => {
          this.data = data;
          this.panel = data.panel;
          this.chartOptions.xaxis.categories = data.panel.labels;

          //Návštěvnost
          this.seriesVisit[0].data = data.dataset.old.page_view;
          this.seriesVisit[1].data = data.dataset.pobo.page_view;

          // Přidání do košíku
          this.seriesBasket[0].data = data.dataset.old.basket_counter;
          this.seriesBasket[1].data = data.dataset.pobo.basket_counter;

          // Konverzní poměr
          this.seriesRate[0].data = data.dataset.old.conversion_rate;
          this.seriesRate[1].data = data.dataset.pobo.conversion_rate;

          //Hodnota stránek
          this.seriesPrice[0].data = data.dataset.old.price;
          this.seriesPrice[1].data = data.dataset.pobo.price;

          this.seriesScroll[0].data = data.dataset.old.scroll;
          this.seriesScroll[1].data = data.dataset.pobo.scroll;

          // Doba na produktech
          this.seriesTimer[0].data = data.dataset.old.session_timer;
          this.seriesTimer[1].data = data.dataset.pobo.session_timer;

          // todo nastavit jako parametr
          this.series = this.seriesVisit;

          this.seriesProduct[0].data = data.dataset.old.product;
          this.seriesProduct[1].data = data.dataset.pobo.product;
        }
      );

      this.isLoaded = true;
      const { user } = this;
      this.form = user;
    },
  },
};
</script>

<style scoped>
.analytics-card {
  border: 1px solid gold;
}
</style>