<template>
  <div class="shadow-sm p-3 bg-white mb-4 rounded">
    <b-row align-v="center">
      <b-col cols="9">
        <b-form-radio-group
          v-model="useAspectRatio"
          inlist
          class="d-flex"
        >
          <b-form-radio
            :value="true"
            :disabled="aspectRatio === null"
            class="w-50"
          >
            <strong>{{ $t("Use the best aspect ratio") }}

              <template v-if="aspectRatio">
                <span
                  class="d-inline-block yellow-info-box"
                >
                  {{ $t("we recommend") }}
                </span>
              </template>
              <template v-else>
                ({{ $t("unavailable") }})
              </template>
            </strong>

            <small class="d-block text-muted lh-16 mt-1">
              <template v-if="aspectRatio">
                {{
                  $t(
                    "To make the cropped image look best in the widget, the photo is cropped according to its aspect ratio"
                  )
                }}.
              </template>
              <template v-else>
                {{
                  $t(
                    "We are working on setting the ideal crop ratio for this widget. Please try to come later"
                  )
                }}.
              </template>
            </small>
          </b-form-radio>
          <b-form-radio
            :value="false"
            class="w-50"
          >
            <strong>{{
              $t("Use aspect ratio cropping")
            }}</strong>
            <small class="d-block text-muted">
              {{
                $t(
                  "The image will be cropped in any way and arbitrarily, regardless of the aspect ratio of the widget"
                )
              }}
            </small>
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
      <b-col cols="3">
        <b-btn
          block
          data-cy="image-copper-crop-button"
          @click="actionCreate"
        >
          {{ $t("widgetTree.modal.crop.save") }}
          <i class="ico ico-crop ml-2" />
        </b-btn>
      </b-col>
    </b-row>
  </div>
  <b-row
    v-if="false"
    class="pb-3"
  >
    <b-col cols="6">
      <b-btn @click="actionCreate">
        {{ $t("widgetTree.modal.crop.save") }}
      </b-btn>
    </b-col>

    <b-col
      class="text-right"
      cols="6"
    >
      <b-btn-group>
        <b-btn
          :class="[
            cropType === 'square'
              ? 'btn-secondary'
              : 'btn-light',
          ]"
          size="sm"
          @click="cropType = 'square'"
        >
          {{ $t("widgetTree.modal.crop.filter.square") }}
        </b-btn>
        <button
          :class="[
            cropType === 'circle'
              ? 'btn-secondary'
              : 'btn-light',
          ]"
          :disabled="true"
          class="btn btn-sm"
          type="button"
          @click="cropType = 'circle'"
        >
          {{ $t("widgetTree.modal.crop.filter.circle") }}
        </button>
      </b-btn-group>
    </b-col>
  </b-row>
  <div class="shadow-sm p-2 bg-white content-editor__image-crop">
    <template v-if="isCropImageType('square')">
      <cropper
        v-if="image"
        ref="cropper"
        :src="image.src"
        :stencil-props="cropperConfig"
        background-class="bg-white"
        class="cropper"
      />
    </template>
    <template v-if="isCropImageType('circle')">
      <cropper
        v-if="image"
        ref="cropper"
        :src="image.src"
        :stencil-component="stencil"
        background-class="bg-white"
        class="cropper"
      />
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { Cropper } from "vue-advanced-cropper";
import ImageCircle from "@/share/image-circle.vue";

export default {
  name: "image-cropper",

  props: {
    productId: {
      type: Number,
      require: true,
    },

    aspectRatio: {
      validator: (prop) => typeof prop === "number" || prop === null,
      require: true,
      default: null,
    },

    imageId: {
      type: Number,
      require: true,
    },

    options: {
      type: Object,
      required: true,
    },
  },

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      image: Object,
      canvas: null,
      stencil: ImageCircle,
      cropType: "square",
      useAspectRatio: this.useAspectRatio === null,
    };
  },

  computed: {
    cropperConfig() {
      return {
        aspectRatio: this.useAspectRatio ? this.aspectRatio : null,
        transitions: false,
      };
    },
  },

  components: {
    cropper: Cropper,
  },

  methods: {
    isCropImageType(cropType){
      return this.cropType === cropType;
    },

    async actionCreate(){
      const { canvas } = this.$refs.cropper.getResult();
      const { options, imageId, productId } = this;
      const { api, content } = options;
      const { crop } = api;

      const temp = crop.replace("[id]", productId);
      const url = temp.replace("[picture]", imageId);

      if (canvas) {
        const form = new FormData();
        canvas.toBlob((blob) => {
          form.append("content_id", productId);
          form.append("content", content);
          form.append("imageData", blob);
          axios
            .post(url, form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(({ data }) => {
              this.$emit("crop-image", data);
            });
        }, "image/jpeg");
      }
    },

    async actionRead(){
      const { productId, imageId, options } = this;
      const { api } = options;
      const { crop } = api;
      const temp = crop.replace("[id]", productId);
      const url = temp.replace("[picture]", imageId);

      await axios.get(url).then(({ data }) => {
        this.image = data;
      });
    },

    selectIcon(image){
      this.$emit("select-icon", image);
    },
  },
};
</script>
