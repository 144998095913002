<template>
  <div :class="['grid-layout__filter-item grid-layout__filter-item--dropdown', classTrigger]">
    <span class="filter-trigger">
      <i class="ico ico-filter-category" /> {{ $t("sort.category.header") }}
    </span>
    <div
      class="position-absolute px-3 pt-3 filter-box grid-layout__filter-box"
    >
      <div class="position-relative">
        <b-form-group>
          <grid-search
            @input="updateQuery"
            @submit="getData"
          />
        </b-form-group>

        <div class="grid-layout__filter-list">
          <template v-if="isLoaded">
            <template v-if="categories.length > 0">
              <b-form-group>
                <b-form-checkbox-group
                  v-model="selected"
                  stacked
                  @change="$emit('input', selected)"
                >
                  <b-form-checkbox
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                    class="grid-layout__filter-checkbox"
                  >
                    {{ category.name }}
                    <small class="text-muted ml-2">
                      ({{ $t("sort.product", category.product.count) }})
                    </small>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </template>
            <template v-else>
              <div class="text-center text-muted fs-13 lh-1 mb-4">
                {{ $t("sort.category.empty") }}
              </div>
            </template>
          </template>
          <template v-else>
            <div
              v-for="n in 20"
              :key="n"
              class="mb-2"
            >
              <b-skeleton-img
                height="24px"
                no-aspect
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryModel from "@/model/CategoryModel.js";
import GridSearch from "@/share/grid-search.vue";

export default {
  name: "FilterCategory",

  props: {
    classTrigger: {
      type: String,
      default: null,
      required: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },


  mounted() {
    this.getData();
  },
  
  data() {
    return {
      categories: [],
      selected: [],
      isLoaded: false,
      params: {
        offset: 1,
        limit: 20,
        total: 0,
        query: "",
        filter: 1,
      },
    };
  },

  watch: {
    value(newVal) {
      this.selected = newVal;
    },
  },
  
  components: {
    "grid-search": GridSearch,
  },

  methods: {
    updateQuery(query) {
      this.params.query = query;
    },

    async getData() {
      const { params } = this;
      const result = await CategoryModel.findAllCategory(
        params,
        () => {
          this.isLoaded = false;
        },
        () => {
          this.isLoaded = true;
        }
      );

      if(!result.category) {
        return;
      }

      const { category, total } = result;
      this.categories = category;
      this.params.total = total;
    },
  },
};
</script>