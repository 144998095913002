<template>
  <div
    class="menu-select-item"
  >
    <div
      class="menu-select-item__selected d-flex"
      :class="{'menu-select-item__selected-open': isOpen}"
      @click="toggleDropdown"
    >
      <div class="menu-select-item__left w-75">
        <p class="m-0 fs-16">
          {{ eshop.humanUrl }}
        </p>
      </div>
      <div
        v-if="filteredEshops.length > 0"
        class="menu-select-item__right w-25 d-flex align-items-center justify-content-end"
      >
        +{{ filteredEshops.length }} <i class="bi bi-cart2 d-flex" />
      </div>
    </div>
    <div
      v-if="isOpen"
      class="menu-select-item__dropdown"
    >
      <template v-if="filteredEshops.length > 0">
        <div
          v-for="(shop, index) in filteredEshops"
          :key="index"
          class="menu-select-item__dropdown-item"
          :class="{ 'menu-select-item__disabled' : shop.is_disable === 1 }"
          @click.prevent="selectShop(shop)"
        >
          <div
            v-if="eshop.platform === 'shopify'"
            :style="{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              background: `linear-gradient(${generateColor(shop.humanUrl)}, #ffffff)`
            }"
          />

          <div
            v-else
            class="menu-select-item__ico"
          >
            <img :src="'https://' + shop.humanUrl + '/favicon.ico'">
          </div>

          <div class="d-flex flex-column">
            <p class="m-0 fs-16 d-flex">
              {{ shop.humanUrl }}
            </p>
            <small
              v-if="shop.is_disable === 1"
              class="fs-9"
            > {{ $t('E-shop has deactivated Pobo Page Builder') }} </small>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="menu-select-item__dropdown-item menu-select-item__dropdown-item-add"  
          @click.prevent="addUser"
        >
          <b-icon
            icon="plus-circle-fill"
            size="20"
          />
    
          <div class="d-flex flex-column">
            <p class="m-0 fs-16 d-flex">
              {{ $t('switch.box.button') }}
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import eshopData from "@/mixin/eshopData";
export default {
  name: "switch-eshop",

  mixins: [eshopData],

  mounted() {

  },

  data() {
    return {
      selectedShop: null,
      isOpen: false,
    }
  },

  computed: {
    filteredEshops() {
      return this.availableEshop.filter(availableEshopItem => availableEshopItem.url !== this.eshop.url);
    },
  },


  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    addUser(){
      this.$router.push({ name: 'connect-user' });
    },
    selectShop(shop) {
      if(shop.is_disable === 0){
        this.$emit('switch-eshop', shop.id)
      }
    },
    generateColor(humanUrl) {
      const hash = this.hashCode(humanUrl);
      return '#' + this.padWithZeros(hash.substring(0, 6));
    },
    hashCode(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash = hash & hash;
      }
      const hexHash = hash.toString(16).substring(0, 6);
      return hexHash.length < 6 ? this.padWithZeros(hexHash) : hexHash;
    },
    padWithZeros(str) {
      const cleanedStr = str.replace(/[^0-9a-fA-F]/g, '');
      const zerosToAdd = 6 - cleanedStr.length;
      return '0'.repeat(zerosToAdd > 0 ? zerosToAdd : 0) + cleanedStr;
    }
  },
}
</script>
