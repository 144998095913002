import axios from "axios";

export default class AdminModel {
  static async findDemonstration(
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/admin/demonstration/grid")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch demonstration [findDemonstration()]", error);
        return error;
      });
  }

  static async findUsers(
    params,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    const query = {
      limit: params.limit,
      offset: params.offset,
      query: params.query,
      filter: params.filter,
    };

    return await axios
      .get("/api/v2/admin/user/grid", { params: query })
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error find user [findUsers()]", error);
        return error;
      });
  }


  static async findUser(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/admin/user/detail/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch user [findUser]", error);
        return error;
      });
  }

  static async findInvoice(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/admin/invoices")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch admin [findInvoice()]", error);
        return error;
      });
  }

  static async findProduct(
    params,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    const query = {
      limit: params.limit,
      offset: params.offset,
      query: params.query,
      filter: params.filter,
    };

    return await axios
      .get("/api/v2/admin/product/grid/" + params.userid, { params: query })
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch product [findProduct()]", error);
        return error;
      });
  }

  static async createDemonstration(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/admin/demonstration/detail", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn(
          "Error create demonstration [createDemonstration()]",
          error
        );
        return error;
      });
  }

  static async loginUser(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/admin/user/login", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create user login [loginUser()]", error);
        return error;
      });
  }

  static async addCredit(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/admin/eshop/credit", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn(
          "Error add credit in to eshop [addCredit()]",
          error
        );
        return error;
      });
  }

  static async updateUser(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .put("/api/v2/admin/user/detail", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error admin update [updateUser()]", error);
        return error;
      });
  }
}
