<template>
  <div>
    <!-- -->
  </div>
</template>

<script>
export default {
  name: "switch-template",
}
</script>
