<template>
  <div class="container modal-designer-max-width">
    <template v-if="isActiveStep(1)">
      <h2 class="text-center mb-3 mt-3">
        {{ $t("Introducing Designer 2.0.") }}
      </h2>

      <h5 class="text-center text-muted mb-4 font-weight-light">
        {{ $t("Your assistant in styling product pages.") }}
      </h5>

      <ul class="list-group list-group-flush mb-4 pt-3">
        <li
          class="list-group-item d-flex align-items-center p-2"
          :class="{'bg-success-designer' : isGeneric}"
        >
          <b-icon
            v-if="isGeneric"
            icon="check-circle"
            variant="success"
            class="mr-2"
          />
          <b-icon
            v-else
            icon="exclamation-circle"
            variant="success"
            class="mr-2"
          />
          {{ $t("Have generic styling of labels enabled:") }} &nbsp;
          <strong v-if="isGeneric">{{ $t("COMPLETED") }}</strong>
          <strong v-else>{{ $t("NOT COMPLETED") }}</strong>
        </li>
      </ul>

      <tier-control
        :allow-tariff="['profi', 'premium', 'saas']"
        minimum-tariff="profi"
        position="left"
      >
        <b-form-group class="text-center pt-3">
          <b-button
            block
            type="submit"
            variant="secondary"
            size="lg"
            :disabled="!isGeneric"
            @click.prevent="startDesigner"
          >
            {{ $t("Launch Designer") }}
          </b-button>
        </b-form-group>
      </tier-control>

      <p class="text-center text-muted mb-4 fs-12">
        {{ $t("Designer is currently being rolled out gradually and is in BETA testing. It will be available to you in the coming weeks.") }}
      </p>
    </template>

    <template v-if="isActiveStep(2)">
      <div class="d-flex justify-content-center align-items-center mt-3 mb-3 flex-column">
        <div class="loading-spinner" />

        <h2 class="text-center mt-3 mb-3 font-weight-bolder">
          {{ $t("Processing Design settings") }}
        </h2>

        <h5 class="text-center text-muted mb-4 font-weight-light">
          {{ $t("Please wait, this will only take a moment.") }}
        </h5>
      </div>
    </template>
    <template v-if="isActiveStep(3)">
      <div class="mt-3 mb-3">
        <i
          class="ico ico-checked-xl m-auto d-block"
        />
        <h2 class="text-center mb-3">
          {{ $t("The design has been modified and assigned to this product") }}
        </h2>

        <h5 class="text-center text-muted mb-4 font-weight-light">
          {{ $t("To publish the changes, please export the description.") }}
        </h5>

        <h2 class="text-left mt-5 mb-3 font-weight-bolder fs-20">
          {{ $t("How to use this Design for other products") }}
        </h2>

        <p class="text-left text-muted">
          {{ $t("If you want to use the Design created in the Designer for other products, just go to the Product Details -> Design Settings and select the option Design generate from Live Designer.") }}
        </p>

        <img
          src="https://pobo-cdn.b-cdn.net/static/designer-gif-_1_-_online-video-cutter.com_.gif"
          alt="designer-gif"
          class="w-100"
        >
      </div>
    </template>

    <template v-if="isActiveStep(4)">
      <div class="mt-3 mb-3">
        <h2 class="text-center mt-3 mb-3 font-weight-bolder fs-25">
          {{ $t("Saving unfortunately failed") }}
        </h2>

        <p class="text-left text-muted">
          {{ $t("The data could not be saved, please try again later.") }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import EshopData from "@/mixin/eshopData";
import TierControl from "@/share/tier-control.vue";
export default {
  name: 'DesignerV2',

  mixins: [EshopData],

  props:{
    mode:{
      required: true,
      type: String
    },
    step:{
      required: true,
      default: 1,
      type: Number,
    }
  },

  computed:{
    isGeneric(){
      return this.mode === 'generic';
    },
  },
  components: {TierControl},

  methods:{
    startDesigner(){
      this.$emit('startDesigner');
    },

    isActiveStep(number){
      return this.step === number;
    }
  }

}
</script>


<style scoped>
  .modal-designer-max-width{
    max-width: 600px;
  }

  .bg-success-designer{
    background: rgba(0, 255, 0, 0.25);
  }

  .loading-spinner {
    border: 5px solid #1c1c1c;
    border-top: 5px solid #649b0d;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spinLoading 2s linear infinite;
  }

  @keyframes spinLoading {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>
