<template>
  <b-form @submit.prevent="saveForm">
    <b-form-group :label="$t('widgetTree.modal.link.text.label')">
      <b-form-input
        v-model="formData.text"
        :placeholder="$t('widgetTree.modal.link.text.placeholder')"
        type="text"
      />
    </b-form-group>

    <b-form-group :label="$t('widgetTree.modal.link.url.label')">
      <b-form-input
        v-model="formData.url"
        :placeholder="$t('widgetTree.modal.link.url.placeholder')"
        type="url"
      />
    </b-form-group>

    <b-form-group>
      <b-btn
        block
        type="submit"
        variant="secondary"
      >
        {{ $t("widgetTree.modal.link.save") }}
      </b-btn>
    </b-form-group>
  </b-form>
</template>
<script>
export default {
  name: "ModalLink",

  props: {
    item: {
      type: Object,
      required: true,
    },

    valueLang: {
      type: String,
      required: true,
      default: "default",
    },
  },

  data() {
    const { item, valueLang } = this;
    const { extension, value } = item;

    const { text } = extension[valueLang];
    const url = value[valueLang];

    return {
      formData: {
        text: this.$t(text),
        url,
      },
    };
  },

  methods: {
    saveForm() {
      const { formData } = this;
      this.$emit("handle-save", formData);
    },
  },
};
</script>
