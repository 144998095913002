<template>
  <div>
    <table class="table table-sm">
      <tbody>
        <template v-if="company">
          <tr>
            <td
              class="text-right font-weight-bold border-0"
              style="width: 200px"
            >
              {{ $t("invoice.detail.accountNumber") }}
            </td>
            <td class="border-0">
              {{ company.bank[invoice.currency].number }}/{{
                company.bank[invoice.currency].code
              }}
            </td>
          </tr>

          <tr>
            <td class="text-right font-weight-bold">
              {{ $t("invoice.detail.variableSymbol") }}:
            </td>
            <td>{{ invoice.order_id }}</td>
          </tr>

          <tr>
            <td class="text-right font-weight-bold">
              {{ $t("invoice.detail.totalPriceWithVat") }}:
            </td>
            <td>
              {{
                ((invoice.vat / 100) * invoice.price + invoice.price).toFixed(0)
              }}
              {{ $t("tariff.rawCurrency." + invoice.currency) }}
            </td>
          </tr>

          <tr>
            <td class="text-right font-weight-bold">
              {{ $t("invoice.detail.vat") }}:
            </td>
            <td>{{ invoice.vat.toFixed(0) }}%</td>
          </tr>

          <tr>
            <td class="text-right font-weight-bold">
              {{ $t("invoice.detail.state") }}:
            </td>
            <td>
              <i
                :class="[
                  invoice.state === 'paid' ? 'ico-success' : 'ico-alert',
                ]"
              />
              {{
                invoice.state === "paid"
                  ? $t("invoice.detail.status.paid")
                  : $t("invoice.detail.status.unPaid")
              }}
            </td>
          </tr>
        </template>
        <template v-else>
          <tr
            v-for="n in 4"
            :key="n"
          >
            <td colspan="2">
              <b-skeleton-img
                height="20px"
                no-aspect
                width="100%"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <a
      :href="invoice.pdf"
      class="btn btn-secondary btn-block mt-4 text-uppercase"
      target="_blank"
    >
      {{
        invoice.state === "paid"
          ? $t("invoice.detail.download.invoice")
          : $t("invoice.detail.download.proforma")
      }}
    </a>
  </div>
</template>

<script>
import InfoModel from "@/model/InfoModel.js";

export default {
  name: "invoice-detail",
  displayName: "invoice-detail",

  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    InfoModel.findInfo(
      () => {},
      (data) => {
        this.data = data;
        this.company = data;
      }
    );
  },

  data() {
    return {
      company: null,
    };
  },
};
</script>

<style scoped></style>