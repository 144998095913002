import axios from "axios";

export default class AuthModel {
  static async login(
    username,
    password,
    poboEshopId,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    try {
      const response = await axios.post(
        "/api/v2/user/token/",
        {
          username,
          password,
        }
      );

      const { data } = response;
      endCallback(data);
      return data;
    } catch (error) {
      console.warn("Error during login [login()]", error);
      endCallback(error);
      return error;
    }
  }

  static async resetPassword(email, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    try {
      const response = await axios.post("/api/v2/user/reset-password", { email });
      const { data } = response;
      endCallback(data);
      return data;
    } catch (error) {
      console.warn("Error during password reset [resetPassword()]", error);
      endCallback(error);
      return error.response ? error.response.data : error;
    }
  }

  static async changePassword(data, token) {
    try {
      const response = await axios.post(`/api/v2/user/change-password/${token}`, data);
      return response.data;
    } catch (error) {
      console.warn("Error during password change [changePassword()]", error);
      return error.response ? error.response.data : error;
    }
  }

}
