<template>
  <transition name="fade">
    <div
      v-if="isLoaded"
      class="bg-white p-4"
    >
      <template v-if="product.old_description">
        <div class="text-center modal-old-description__recovery mb-4">
          <b-btn
            class="my-4 rounded"
            @click="recoveryOldDescription"
          >
            {{ $t("widgetTree.recoveryOldDescription") }}
          </b-btn>
        </div>

        <div
          class="modal-old-description"
        >
          <div v-html="product.old_description" />
        </div>
      </template>
      <template v-else>
        <div class="text-center">
          {{ $t("widgetTree.oldDescriptionNotFound") }}
        </div>
      </template>
    </div>
  </transition>
</template>

<script>
import ProductModel from "@/model/ProductModel";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },


  async mounted() {
    const { id } = this;
    await ProductModel.findMe(
      id,
      () => {},
      (data) => {
        this.product = data;
        this.isLoaded = true;
      });
  },


  data() {
    return {
      product: {},
      isLoaded: false,
    };
  },

  methods: {
    recoveryOldDescription() {
      ProductModel.recoveryOldDescription(this.product.id, () => {}, () => {
        this.$emit("recovery-old-description");
      })
      
    }
  },
}
</script>