<template>
  <b-form @submit.prevent="actionUpdate">
    <div
      v-for="(item, index) in form.notification"
      :key="index"
    >
      <layout-setting>
        <template #header>
          {{ $t(item.title) }}
        </template>
        <template #content>
          <p class="text-sm mb-3">
            {{ $t(item.content) }}
          </p>


          <tier-control
            :allow-tariff="['profi', 'premium', 'saas']"
            minimum-tariff="profi"
          >
            <b-form-radio-group
              v-model="form.notification[index].mode"
              :options="[
                { item: 'wysiwyg', name: 'Wysiwyg' },
                { item: 'html', name: 'HTML' },
              ]"
              class="mb-3"
              disabled-field="notEnabled"
              text-field="name"
              value-field="item"
            />

            <template v-if="item.mode === 'html'">
              <div class="border">
                <monaco
                  :value="form.notification[index].value"
                  language="html"
                  height="400px"
                  @change="form.notification[index].value = $event"
                />
              </div>
            </template>
            <template v-if="form.notification[index].mode === 'wysiwyg'">
              <froala
                v-model:value="form.notification[index].value"
                :config="config"
                style="width: 100%; height: 300px"
              />
            </template>

            <b-form-checkbox
              v-model="form.notification[index].active"
              class="mt-4"
            >
              {{ $t("notifications.active") }}
            </b-form-checkbox>

            <b-form-group class="mt-4">
              <b-button
                type="submit"
              >
                {{ $t("notifications.save") }} <i class="ico ico-arrow-right" />
              </b-button>
            </b-form-group>
          </tier-control>
        </template>
      </layout-setting>
    </div>
  </b-form>
</template>

<script>
import Monaco from "@/components/share/monaco.vue";
import editorConfig from "@/js/config/froala.js";
import eshopData from "@/mixin/eshopData";
import TierControl from "@/share/tier-control.vue";
import { useEshopStore } from "@/store/EshopStore";
import cloneDeep from "lodash.clonedeep";


export default {
  name: "notification-setting",

  mixins: [eshopData],

  async mounted() {
    const { eshop } = this;
    this.form = cloneDeep(eshop);
  },

  data() {
    return {
      form: {
        notification: [],
      },
      errors: {},
      config: {
        ...editorConfig,
        placeholderText: "",
      },
    };
  },

  components: {
    TierControl,
    monaco: Monaco,
  },

  methods: {
    async actionUpdate() {
      const { form } = this;
      await useEshopStore().updateEshop({
        data: form
      });
    },
  },
};
</script>
