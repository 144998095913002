export const loadTemplateCss = (css) => {
  // todo pridat refresh v pripade, ze se updatne asset
  const style = document.createElement("style");
  style.innerHTML = css;
  style.id = "template-css";
  document.head.appendChild(style);
};

export const loadStyle = (url) => {
  const link = document.createElement('link');
  link.href = url;
  link.type = 'text/css';
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};