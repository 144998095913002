import axios from "axios";

export default class WidgetFavouriteModel {
  static async findAll(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/widget/favourite")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch favourite [findAll()]", error);
        return error;
      });
  }

  static async delete(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    const { id } = data;

    return await axios
      .delete("/api/v2/widget/favourite", {
        data: {
          id,
        },
      })
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error delete favourite widget [delete()]", error);
        return error;
      });
  }

  static async create(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .post("/api/v2/widget/favourite", data)
      .then((data) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create widget favourite [create()]", error);
        return error;
      });
  }
}
