<template>
  <div
    class="card text-center mb-3"
    style="width: 16rem;"
  >
    <div class="card-body">
      <h5 class="card-title fs-18">
        {{ name }}
      </h5>
      <b-img
        :src="imageUrl"
        class="card-img-top mb-3"
        alt="fotka/logo"
        @click="redirectToWebsite"
      />
      <p class="card-text h-25 d-flex align-items-center">
        {{ $t(description) }}
      </p>
      <b-button
        variant="secondary"
        :disabled="disabled || !active"
        @click="invite"
      >
        Pozvat
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnerSingle",
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    website: {
      type: String,
      required: true
    },
    disabled:{
      type: Boolean,
      required: true,
    },
    active:{
      type: Boolean,
      required: true,
    }
  },
  methods: {
    redirectToWebsite() {
      window.open(this.website, '_blank');
    },
    invite() {
      this.$emit('invite');
    }
  }
};
</script>

<style scoped>
.card-img-top {
  cursor: pointer;
}
</style>
