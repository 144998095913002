import axios from "axios";

export default class OnboardModel {
  static async findAll(
    params,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    const query = {
      limit: params.limit,
      offset: params.offset,
      query: params.query,
      filter: params.filter,
    };

    return await axios
      .post("/api/v2/admin/onboard/grid", {...query })
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch onboard [findAll()]", error);
        return error;
      });
  }

  static async exportToEcoMail(
    params,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    return await axios
      .post("/api/v2/admin/onboard/detail", params)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error post onboard [exportToEcoMail()]", error);
        return error;
      });
  }
}
