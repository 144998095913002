<template>
  <b-alert
    :show="isLocked"
    class="mb-3"
    variant="danger"
  >
    {{ $t("translation.modal.empty.prefix") }} „
    <router-link
      :to="{ name: 'credit' }"
      style="text-decoration: underline"
    >
      {{ $t("translation.modal.empty.button") }}
    </router-link>
    “, {{ $t("translation.modal.empty.postfix") }}
  </b-alert>

  <div class="px-3">
    <b-row class="shadow-sm bg-white rounded">
      <b-col cols="8">
        <b-row
          class="text-left"
          no-gutters
        >
          <b-col cols="6">
            <div class="pt-3">
              <h6 class="p-0 m-0 fs-18 font-weight-bold">
                {{ $t(`inflection.enum.${type}`, countProduct) }}
              </h6>
              <small class="text-muted d-block lh-19 fs-14 mt-2">
                {{ $t(`inflection.summary.lang`, countLang) }}.
              </small>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="pt-3">
              <h6 class="p-0 m-0 fs-18 font-weight-bold">
                {{
                  $t(
                    "inflection.enum.credit",
                    countProduct * countLang * price.translate
                  )
                }}
              </h6>
              <small class="text-muted d-block lh-19 fs-14 mt-2">
                {{
                  $t(
                    "inflection.summary.credit",
                    countProduct * countLang * price.translate
                  )
                }}.
              </small>
            </div>
          </b-col>
        </b-row>
        <small class="text-muted d-block mt-4 mb-2">
          ({{ $t("translation.modal.charge") }}
          {{ $t("sort.credit", price.translate) }}.)
        </small>
      </b-col>
      <b-col cols="4">
        <b-row
          class="text-left"
          no-gutters
        >
          <b-col cols="12">
            <div class="px-2 py-3">
              <h6 class="p-0 m-0 fs-18 font-weight-bold">
                {{ $t("sort.credit", summary.reaming) }}
              </h6>

              <small class="text-muted d-block lh-19 fs-14 mt-2">
                {{ $t("translation.modal.reaming") }}
              </small>

              <router-link
                :to="{ name: 'credit' }"
                class="btn btn-secondary fs-14 btn-sm btn-block mt-3"
              >
                {{ $t("translation.modal.buy") }}
                <i class="ico ico-basket-small ml-2" />
              </router-link>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
  <span
    v-if="type.type === 'product'"
    class="mb-4 mt-3 text-sm p-3 rounded d-block information-box"
  >
    <i class="ico ico-warning" /> {{ $t("translation.modal.text") }}
  </span>
</template>

<script>
import { useEshopStore } from "@/store/EshopStore.js";
import { useCreditStore } from "@/store/CreditStore.js";
import { mapWritableState } from "pinia";

export default {
  name: "CreditCharge",

  props: {
    type: {
      type: String,
      default: "product",
    },

    countProduct: {
      type: Number,
      default: 0,
      required: true,
    },

    isLocked: {
      type: Boolean,
      default: false,
      required: true,
    },

    countLang: {
      type: Number,
      default: 0,
      required: true,
    },
  },

  computed: {
    ...mapWritableState(useCreditStore, {
      history: "history",
      summary: "summary",
      price: "price",
      edited: "edited",
    }),

    ...mapWritableState(useEshopStore, {
      eshop: "detail",
    }),
  },
};
</script>
