<template>
  <transition name="fade">
    <template v-if="isLoaded">
      <template v-if="list.length > 0">
        <draggable
          v-model="list"
          :group="{ name: 'people', pull: 'clone', put: true }"
          :sort="false"
          @end="$emit('move-end', false)"
          @start="$emit('move-start', true)"
        >
          <template #item="{ element }">
            <div>
              <span class="font-weight-bolder d-block mb-1 mb-2 fs-16 mt-2">
                {{ element.name }}
              </span>
              <div
                :class="[
                  element.html_preview
                    ? 'content-editor__component-preview'
                    : 'content-editor__component-item',
                ]"
                class="position-relative"
              >
                <template v-if="element.html_preview">
                  <div
                    :style="getZoom(element.zoom)"
                    class="content-editor__preview-inner"
                    v-html="element.html_preview"
                  />
                </template>
                <template v-else>
                  <div
                    v-if="element.ico"
                    class="content-editor__component-ico"
                    v-html="element.ico"
                  />
                  <div class="content-editor__component-name">
                    {{ element.name }}
                  </div>
                </template>

                <div class="content-editor__component-delete">
                  <a
                    href="#"
                    data-cy="widget-favourite-delete-button"
                    @click.prevent="handleDelete(element.widget_id)"
                  >
                    <i class="ico ico-widget-delete" />
                  </a>
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </template>
      <template v-else>
        <div class="d-block pb-2 text-center">
          <div class="px-1 py-4">
            {{ $t("editor.favourite.info") }}
          </div>

          <i class="ico ico-favourite-red-big" />
        </div>
      </template>
    </template>
  </transition>
</template>

<script>
import Draggable from "vuedraggable";
import WidgetFavouriteModel from "@/model/WidgetFavouriteModel.js";

export default {
  name: "WidgetFavourite",

  mounted() {
    this.actionRead();
  },

  data() {
    return {
      list: [],
      isLoaded: false,
      isConfirmOpen: false,
    };
  },

  components: {
    draggable: Draggable,
  },

  methods: {
    getZoom(zoom) {
      return `zoom: ${ zoom }%`;
    },

    async handleDelete(id) {
      if (confirm("Opravdu smazat?") == true) {
        let data = {
          id,
        };

        await WidgetFavouriteModel.delete(
          data,
          () => {},
          (data) => {
            this.list = data;
          }
        );
      }
    },
    async actionRead(){
      this.isLoaded = false;

      await WidgetFavouriteModel.findAll(
        () => {},
        (data) => {
          this.list = data;
          this.isLoaded = true;
        }
      );
    },
  },
};
</script>

<style scoped></style>