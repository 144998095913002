<template>
  <layout-setting>
    <template #header>
      {{ $t("REST API settings") }}
    </template>
    <template #content>
      <p
        class="mb-4 text-sm p-3 rounded information-box"
      >
        <i class="ico ico-warning" />
        {{
          $t(`The REST API is currently under development.
        If you are interested in accessing the API, please contact us at`)
        }}
        <a
          class="text-danger"
          href="mailto:podpora@pobo.cz"
        >podpora@pobo.cz</a>
      </p>

      <b-form>
        <b-form-group :label="$t('API key')">
          <b-form-input
            readonly
            type="text"
            value="********************************************************************"
          />
        </b-form-group>

        <b-form-group>
          <b-btn disabled="disabled">
            {{ $t("Regenerate API key") }}
          </b-btn>
        </b-form-group>
      </b-form>
    </template>
  </layout-setting>
</template>

<script>
export default {
  name: "SettingApi",
  displayName: "SettingApi",
};
</script>
