<template>
  <i
    :class="iconClass"
    :style="iconSize"
  />
</template>

<script>
import { computed } from "vue";

export default {
  name: "b-icon",

  props: {
    icon: {
      type: String,
      required: true,
    },

    size: {
      type: Number,
      default: 25,
    },
  },

  setup(props) {
    const iconClass = computed(() => `bi bi-${props.icon}`);
    const iconSize = computed(() => `font-size: ${props.size}px;`);

    return {
      iconClass,
      iconSize,
    };
  },
};
</script>