import wNumb from "wnumb";
import days from 'dayjs';
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import advanced from "dayjs/plugin/advancedFormat"

days.locale("cs");

days.extend(timezone)
days.extend(utc)
days.extend(advanced)
days().tz('Europe/Paris')

export function price(value) {
  const format = wNumb({
    decimals: 0,
    thousand: " ",
    suffix: " Kč",
  });

  return format.to(value);
}

export function percent(value) {
  const format = wNumb({
    decimals: 2,
    suffix: "%",
  });

  return format.to(value);
}

export function count(value) {
  const format = wNumb({
    decimals: 0,
    thousand: " ",
    suffix: "x",
  });

  return format.to(value);
}

export function second(value) {
  const format = wNumb({
    decimals: 0,
    thousand: " ",
    suffix: "s",
  });

  return format.to(value);
}

export function date(value) {
  return days(value).format("DD.MM");
}

export function when(value) {
  return days(value).format("DD.MM.YYYY");
}

export function datetime(value) {
  return days(value).format("DD.MM.YYYY HH:mm:ss");
}

export function currency(price) {
  return days(price)
    .add(5, "minutes")
    .format("DD.MM.YYYY hh:mm");
}

export function truncate(value, from, count) {
  if (value.length <= count) {
    return value;
  }

  return value.substring(from, count) + "...";
}

export function endCron(value) {
  return days(value)
    .add(5, "minutes")
    .format("DD.MM.YYYY hh:mm");
}

export function ago(datetime) {
  return days(datetime).fromNow();
}

export function product(count) {
  let result;

  switch (count) {
  case 1:
    result = "produkt";
    break;
  case 2:
  case 3:
  case 4:
    result = "produkty";
    break;

  default:
    result = "produktů";
    break;
  }
  return `${count} ${result}`;
}

export function widget(count) {
  let result;

  switch (count) {
  case 1:
    result = "widget";
    break;
  case 2:
  case 3:
  case 4:
    result = "widgety";
    break;

  default:
    result = "widgetů";
    break;
  }
  return `${count} ${result}`;
}

export default {
  price,
  percent,
  count,
  second,
  date,
  when,
  datetime,
  currency,
  truncate,
  endCron,
  ago,
  product,
  widget,
};
