<template>
  <div
    ref="area"
    :class="['tier-wrapper__container', classPosition]"
    class="w-100"
  >
    <div
      v-if="isVisible && !hasAccess"
      :class="['tier-wrapper__info', classInfo]"
      style="z-index: 10"
    >
      <div :class="['tier-wrapper__inner', classInner]">
        <a
          class="tier-close-btn"
          @click="closeBanner"
        >
          ×
        </a>

        <h5 class="fs-15 p-0 mt-1 mb-1">
          {{ $t('Available from tariff') }} {{ minimumTariff.toUpperCase() }}
        </h5>
        <small>
          {{ $t('To switch on, go to the tariff') }}
          <router-link
            :to="{ name: 'change-tariff' }"
          >
            {{ minimumTariff.toUpperCase() }}.
          </router-link>
        </small>

        <div class="text-center mt-4">
          <b-img
            rounded="circle"
            class="bg-white"
            style="width: 80px; height: 80px;"
            src="https://pobo-cdn.b-cdn.net/static/michal-stikar-team-2.png"
            fluid
          />

          <h5 class="font-weight-bolder fs-15 mt-3">
            {{ $t('Do you have questions?') }}
          </h5>

          <a
            :href="contactUrl"
            target="_blank"
            class="btn btn-sm btn-secondary"
          >
            {{ $t('Write to us') }}
          </a>
        </div>
      </div>
    </div>
    <slot ref="slot" />
  </div>
</template>

<script>
import eshopData from "@/mixin/eshopData.js";

export default {
  name: "TierControl",

  mixins: [eshopData],

  props: {
    allowTariff: {
      type: Array,
      required: true,
    },

    minimumTariff: {
      type: String,
      required: true,
    },

    position: {
      type: String,
      required: false,
      default: "bottom"
    }
  },

  async mounted() {
    await this.disableInputsIfNoAccess();
  },

  data() {
    return {
      isVisible: true,
    }
  },


  computed: {
    hasAccess() {
      const { eshop, allowTariff } = this;
      const { tariff } = eshop;
      return allowTariff.includes(tariff);
    },

    classPosition() {
      const { position } = this
      return `tier-wrapper__container--${position}`
    },

    classInfo() {
      const { position } = this
      return `tier-wrapper__info--${position}`
    },

    classInner() {
      const { position } = this
      return `tier-wrapper__inner--${position}`
    },

    contactUrl() {
      return window.location.origin + '/contact'
    }
  },

  watch: {
    hasAccess(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.disableInputsIfNoAccess();
      }
    }
  },

  methods:{
    async disableInputsIfNoAccess(){
      const {hasAccess} = this
      const disableFormsInput = ['button', 'checkbox', 'form', 'radio', 'select', 'textarea', 'input'];

      if (!hasAccess) {
        Array.from(disableFormsInput).forEach((input) => {
          Array.from(this.$refs.area.getElementsByTagName(input)).forEach((el) => {
            el.disabled = true
            el.readOnly = true
          })
        })
      }else{
        Array.from(disableFormsInput).forEach((input) => {
          Array.from(this.$refs.area.getElementsByTagName(input)).forEach((el) => {
            el.disabled = false
            el.readOnly = false
          })
        })
      }
    },

    closeBanner(){
      this.isVisible = false;
    }
  },
};
</script>

<style scoped>
  .tier-close-btn{
    position: absolute;
    top: 2px;
    right: 2px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    text-decoration: none !important;
  }

</style>
