import axios from "axios";

export default class InfoModel {
  static async findInfo(
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/company")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch info [findInfo()]", error);
        errorCallback(error);
        return error;
      });
  }
}
