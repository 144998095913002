import debounceScroll from "@/libary/debounceScroll";

export default {
  mounted () {
    if(this.$refs.tag && Object.values(this.$refs.tag.classList).includes('pb-carousel-container')) {

      const carousel = this.$refs.tag;
      const left = document.createElement('div')
      const container = carousel.getElementsByClassName('pb-carousel-inner')[0]

      function setLeft () {
        container.scrollLeft = container.scrollLeft - carousel.offsetWidth
      }

      function setRight () {
        container.scrollLeft = container.scrollLeft + carousel.offsetWidth
      }

      left.innerHTML = '<a class="pb-carousel-inner__trigger-left" href="#" id="left"></a>'
      left
        .addEventListener('click', (e) => {
          e.preventDefault()
          setLeft()
        })

      carousel.appendChild(left)

      const right = document.createElement('div')
      right.innerHTML = '<a class="pb-carousel-inner__trigger-right" href="#" id="right"></a>'

      right
        .addEventListener('click', (e) => {
          e.preventDefault()
          setRight()
        })

      carousel.appendChild(right)

      const onScroll = debounceScroll(() => {
        const { scrollLeft, scrollWidth, clientWidth } = container

        if (scrollLeft >= 0 && scrollLeft < 20) {
          left.style.visibility = 'hidden'
          left.style.opacity = '0%'
        } else {
          left.style.visibility = 'visible'
          left.style.opacity = '100%'
        }

        if ((scrollWidth - clientWidth - 20) < scrollLeft) {
          right.style.visibility = 'hidden'
          right.style.opacity = '0%'
        } else {
          right.style.visibility = 'visible'
          right.style.opacity = '100%'
        }
      }, 10)

      container.addEventListener('scroll', () => onScroll(), {
        capture: true,
        passive: true
      }
      )
    }
  }
}