import axios from "axios";

export default class TemplateModel {
  static async findMe(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/asset/grid")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch template [findMe()]", error);
        return error;
      });
  }

  static async findAll(
    params,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/blog/grid?query=" + params)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch template [findAll()]", error);
        return error;
      });
  }

  static async detail(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/asset/detail/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch detail template [detail()]", error);
        return error;
      });
  }

  static async create(
    data,
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/asset/detail/", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create asset template [create()]", error);
        errorCallback(error);
        return error;
      });
  }

  static async delete(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .delete("/api/v2/asset/detail/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error delete template [delete()]", error);
        return error;
      });
  }

  static async update(
    id,
    data,
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .put(`/api/v2/asset/detail/${id}`, data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error template update [update()]", error);
        errorCallback(error);
        return error;
      });
  }

  static async findHtml(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/blog/html/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch detail blog [findHtml()]", error);
        return error;
      });
  }

  static async findNotification(
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/notification/blog")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch detail blog [findNotification()]", error);
        return error;
      });
  }
}
