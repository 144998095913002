<template>
  <div class="border-danger">
    <div
      ref="monaco"
      :style="{ height: height, border: isEnableDark ? '1px solid #ffffff10' : '1px solid #00000030'}"
    />
  </div>
</template>

<script>
import * as monaco from "monaco-editor";
import eshopData from "@/mixin/eshopData";

export default {
  name: "monaco",

  mixins: [eshopData],

  props: {
    value: {
      // string or object
      type: [String],
      default: "",
    },

    language: {
      type: String,
      default: "html",
    },

    height: {
      type: String,
      default: "500px",
    },
  },


  mounted() {
    setTimeout(() => {
      const { language, value } = this;
      const editor =  monaco.editor.create(this.$refs.monaco, {
        value,
        language,
      });


      if(this.isEnableDark){
        monaco.editor.defineTheme('dark', {
          base: 'vs-dark',
          inherit: true,
          colors: this.theme.colors,
          rules: this.theme.rules,
        });

        monaco.editor.setTheme('dark');
      }

      editor.onDidChangeModelContent((event) => {
        const value = editor.getValue();

        if (this.value !== value) {
          this.$emit("change", value, event);
        }
      });
    }, 759);
  },

  data() {
    return {
      isLoaded: false,
      theme:{
        colors: {
          "activityBar.background":"#333e69",
          "activityBar.border":"#00000060",
          "activityBar.foreground":"#8f93a2",
          "activityBarBadge.background":"#80cbc4",
          "activityBarBadge.foreground":"#000000",
          "badge.background":"#00000030",
          "badge.foreground":"#464b5d",
          "breadcrumb.activeSelectionForeground":"#80cbc4",
          "breadcrumb.background":"#333333",
          "breadcrumb.focusForeground":"#8f93a2",
          "breadcrumb.foreground":"#4b526d",
          "breadcrumbPicker.background":"#090b10",
          "button.background":"#717cb450",
          "debugToolBar.background":"#333333",
          "diffEditor.insertedTextBackground":"#c3e88d15",
          "diffEditor.removedTextBackground":"#ff537020",
          "dropdown.background":"#333333",
          "dropdown.border":"#ffffff10",
          "editor.background":"#333333",
          "editor.findMatchBackground":"#000000",
          "editor.findMatchBorder":"#80cbc4",
          "editor.findMatchHighlightBackground":"#00000050",
          "editor.findMatchHighlightBorder":"#ffffff50",
          "editor.foreground":"#8f93a2",
          "editor.lineHighlightBackground":"#00000050",
          "editor.selectionBackground":"#717cb450",
          "editor.selectionHighlightBackground":"#ffcc0020",
          "editorBracketMatch.background":"#333333",
          "editorBracketMatch.border":"#ffcc0050",
          "editorCursor.foreground":"#ffcc00",
          "editorError.foreground":"#ff537070",
          "editorGroup.border":"#00000030",
          "editorGroupHeader.tabsBackground":"#333333",
          "editorGutter.addedBackground":"#c3e88d60",
          "editorGutter.deletedBackground":"#ff537060",
          "editorGutter.modifiedBackground":"#82aaff60",
          "editorHoverWidget.background":"#333333",
          "editorHoverWidget.border":"#ffffff10",
          "editorIndentGuide.activeBackground":"#3b3f51",
          "editorIndentGuide.background":"#3b3f5170",
          "editorInfo.foreground":"#82aaff70",
          "editorLineNumber.activeForeground":"#4b526d",
          "editorLineNumber.foreground":"#3b3f5180",
          "editorLink.activeForeground":"#8f93a2",
          "editorMarkerNavigation.background":"#8f93a205",
          "editorOverviewRuler.border":"#333333",
          "editorOverviewRuler.errorForeground":"#ff537040",
          "editorOverviewRuler.findMatchForeground":"#80cbc4",
          "editorOverviewRuler.infoForeground":"#82aaff40",
          "editorOverviewRuler.warningForeground":"#ffcb6b40",
          "editorRuler.foreground":"#3b3f51",
          "editorSuggestWidget.background":"#333333",
          "editorSuggestWidget.border":"#ffffff10",
          "editorSuggestWidget.foreground":"#8f93a2",
          "editorSuggestWidget.highlightForeground":"#80cbc4",
          "editorSuggestWidget.selectedBackground":"#00000050",
          "editorWarning.foreground":"#ffcb6b70",
          "editorWhitespace.foreground":"#8f93a240",
          "editorWidget.background":"#090b10",
          "editorWidget.border":"#ff0000",
          "editorWidget.resizeBorder":"#80cbc4",
          "extensionButton.prominentBackground":"#c3e88d90",
          "extensionButton.prominentHoverBackground":"#c3e88d",
          "focusBorder":"#ffffff00",
          "gitDecoration.conflictingResourceForeground":"#ffcb6b90",
          "gitDecoration.deletedResourceForeground":"#ff537090",
          "gitDecoration.ignoredResourceForeground":"#4b526d90",
          "gitDecoration.modifiedResourceForeground":"#82aaff90",
          "gitDecoration.untrackedResourceForeground":"#c3e88d90",
          "input.background":"#1a1c25",
          "input.border":"#ffffff10",
          "input.foreground":"#eeffff",
          "input.placeholderForeground":"#8f93a260",
          "inputOption.activeBackground":"#8f93a230",
          "inputOption.activeBorder":"#8f93a230",
          "inputValidation.errorBorder":"#ff537050",
          "inputValidation.infoBorder":"#82aaff50",
          "inputValidation.warningBorder":"#ffcb6b50",
          "list.activeSelectionBackground":"#090b10",
          "list.activeSelectionForeground":"#80cbc4",
          "list.focusBackground":"#8f93a220",
          "list.focusForeground":"#8f93a2",
          "list.highlightForeground":"#80cbc4",
          "list.hoverBackground":"#090b10",
          "list.hoverForeground":"#ffffff",
          "list.inactiveSelectionBackground":"#00000030",
          "list.inactiveSelectionForeground":"#80cbc4",
          "listFilterWidget.background":"#00000030",
          "listFilterWidget.noMatchesOutline":"#00000030",
          "listFilterWidget.outline":"#00000030",
          "menu.background":"#333333",
          "menu.foreground":"#8f93a2",
          "menu.selectionBackground":"#00000050",
          "menu.selectionBorder":"#00000030",
          "menu.selectionForeground":"#80cbc4",
          "menu.separatorBackground":"#8f93a2",
          "menubar.selectionBackground":"#00000030",
          "menubar.selectionBorder":"#00000030",
          "menubar.selectionForeground":"#80cbc4",
          "notificationLink.foreground":"#80cbc4",
          "notifications.background":"#333333",
          "notifications.foreground":"#8f93a2",
          "panel.background":"#090b10",
          "panel.border":"#00000060",
          "panelTitle.activeBorder":"#80cbc4",
          "panelTitle.activeForeground":"#ffffff",
          "panelTitle.inactiveForeground":"#8f93a2",
          "peekView.border":"#00000030",
          "peekViewEditor.background":"#8f93a205",
          "peekViewEditor.matchHighlightBackground":"#717cb450",
          "peekViewEditorGutter.background":"#8f93a205",
          "peekViewResult.background":"#8f93a205",
          "peekViewResult.matchHighlightBackground":"#717cb450",
          "peekViewResult.selectionBackground":"#4b526d70",
          "peekViewTitle.background":"#8f93a205",
          "peekViewTitleDescription.foreground":"#8f93a260",
          "pickerGroup.foreground":"#80cbc4",
          "progressBar.background":"#80cbc4",
          "scrollbar.shadow":"#33333300",
          "scrollbarSlider.activeBackground":"#80cbc4",
          "scrollbarSlider.background":"#8f93a220",
          "scrollbarSlider.hoverBackground":"#8f93a210",
          "selection.background":"#80cbc4",
          "settings.checkboxBackground":"#090b10",
          "settings.checkboxForeground":"#8f93a2",
          "settings.dropdownBackground":"#090b10",
          "settings.dropdownForeground":"#8f93a2",
          "settings.headerForeground":"#80cbc4",
          "settings.modifiedItemIndicator":"#80cbc4",
          "settings.numberInputBackground":"#090b10",
          "settings.numberInputForeground":"#8f93a2",
          "settings.textInputBackground":"#090b10",
          "settings.textInputForeground":"#8f93a2",
          "sideBar.background":"#090b10",
          "sideBar.border":"#00000060",
          "sideBar.foreground":"#4b526d",
          "sideBarSectionHeader.background":"#090b10",
          "sideBarSectionHeader.border":"#00000060",
          "sideBarTitle.foreground":"#8f93a2",
          "statusBar.background":"#090b10",
          "statusBar.border":"#00000060",
          "statusBar.debuggingBackground":"#c792ea",
          "statusBar.debuggingForeground":"#ffffff",
          "statusBar.foreground":"#4b526d",
          "statusBar.noFolderBackground":"#333333",
          "statusBarItem.hoverBackground":"#464b5d20",
          "statusBarItem.remoteBackground":"#80cbc4",
          "statusBarItem.remoteForeground":"#000000",
          "tab.activeBorder":"#80cbc4",
          "tab.activeForeground":"#ffffff",
          "tab.activeModifiedBorder":"#4b526d",
          "tab.border":"#333333",
          "tab.inactiveBackground":"#333333",
          "tab.inactiveForeground":"#4b526d",
          "tab.unfocusedActiveBorder":"#464b5d",
          "tab.unfocusedActiveForeground":"#8f93a2",
          "terminal.ansiBlack":"#000000",
          "terminal.ansiBlue":"#82aaff",
          "terminal.ansiBrightBlack":"#464b5d",
          "terminal.ansiBrightBlue":"#82aaff",
          "terminal.ansiBrightCyan":"#89ddff",
          "terminal.ansiBrightGreen":"#c3e88d",
          "terminal.ansiBrightMagenta":"#c792ea",
          "terminal.ansiBrightRed":"#ff5370",
          "terminal.ansiBrightWhite":"#ffffff",
          "terminal.ansiBrightYellow":"#ffcb6b",
          "terminal.ansiCyan":"#89ddff",
          "terminal.ansiGreen":"#c3e88d",
          "terminal.ansiMagenta":"#c792ea",
          "terminal.ansiRed":"#ff5370",
          "terminal.ansiWhite":"#ffffff",
          "terminal.ansiYellow":"#ffcb6b",
          "terminalCursor.background":"#000000",
          "terminalCursor.foreground":"#ffcb6b",
          "textLink.activeForeground":"#8f93a2",
          "textLink.foreground":"#80cbc4",
          "titleBar.activeBackground":"#090b10",
          "titleBar.activeForeground":"#8f93a2",
          "titleBar.border":"#00000060",
          "titleBar.inactiveBackground":"#090b10",
          "titleBar.inactiveForeground":"#4b526d",
          "tree.indentGuidesStroke":"#3b3f51",
          "widget.shadow":"#00000030"
        },
        rules:{

        },
      }
    }
  }
}
</script>
