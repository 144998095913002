<template>
  <b-overlay
    :show="!isLoaded"
    blur="1px"
    rounded="lg"
    variant="transparent"
  >
    <b-form @submit.prevent="actionSave">
      <b-row>
        <b-col cols="8">
          <b-form-group
            id="name"
            v-model="formData.name"
            :class="errors.name ? 'is-invalid' : ''"
            :label="$t('asset.form.name')"
          >
            <b-form-input
              id="name"
              v-model="formData.name"
              autofocus
            />
            <div
              v-if="errors"
              class="mt-1"
            >
              <small
                v-for="(error, index) in errors.name"
                :key="index"
                class="text-danger d-block"
                v-html="$t(error)"
              />
            </div>
          </b-form-group>
          <b-form-group :label="$t('asset.form.value')">
            <div class="shadow-sm">
              <monaco
                :value="formData.value"
                language="scss"
                height="calc(100vh - 210px)"
                @change="formData.value = $event"
                @on-error="onError"
              />
            </div>

            <div
              v-if="errors"
              class="mt-1"
            >
              <small
                v-for="(error, index) in errors.value"
                :key="index"
                class="text-danger d-block"
                v-html="$t(error)"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <h5 class="">
            {{ $t("asset.help.header") }}
          </h5>
          <small class="text-muted d-block">
            {{ $t("asset.help.all") }}
          </small>
          <div class="bg-white border border-1 p-1 mt-2">
            <code>#pobo-all-content {}</code>
          </div>

          <small class="text-muted d-block mt-4 mb-2">
            {{ $t("asset.help.standard") }}
          </small>
          <div class="bg-white border border-1 p-1">
            <code>#pobo-standard-widget {}</code>
          </div>

          <small class="text-muted d-block mt-4 mb-2">
            {{ $t("asset.help.share") }}
          </small>
          <div class="bg-white border border-1 p-1">
            <code>#pobo-share-widget {}</code>
          </div>

          <b-alert
            :show="!isValidCode"
            class="mt-4"
            variant="warning"
          >
            {{ $t("asset.help.invalidCode") }}
          </b-alert>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import TemplateModel from "@/model/TemplateModel.js";
import Monaco from "@/components/share/monaco.vue";
import { showMessage } from "@/plugins/flashMessage";

export default {
  name: "ModalAsset",

  props: {
    selectedAssetId: {
      validator: (prop) => typeof prop === "number" || prop === null,
      require: true,
    },
  },

  async mounted() {
    const { selectedAssetId } = this;

    if (selectedAssetId) {
      await TemplateModel.detail(
        selectedAssetId,
        () => {},
        (data) => {
          this.formData = data;
        }
      );
    }

    this.isLoaded = true;
  },

  data() {
    return {
      errors: [],
      isValidCode: true,

      formData: {
        id: null,
        name: null,
        value: null,
        type: "scss",
      },

      storeDates: [],
      isLoaded: false,
    };
  },

  components: {
    "monaco": Monaco,
  },

  methods: {
    onError(error) {
      const isValid = error.length === 0;
      this.isValidCode = isValid;
      this.$emit("is-valid", isValid);
    },

    async actionSave() {
      const { formData } = this;
      const { id } = formData;
      this.isLoaded = false;
      if (id) {
        await this.actionUpdate();
      } else {
        await this.actionCreate();
      }
      this.isLoaded = true;
    },

    async actionCreate() {
      const { formData } = this;
      delete formData.id;
      await TemplateModel.create(
        formData,
        () => {},
        (data) => {
          this.errors = [];
          this.storeDates.push(new Date());
          this.formData = data;
          showMessage("flash.asset.create");
        },
        ({ response }) => {
          const { data } = response;
          const { errors } = data;
          this.errors = errors;
        }
      );
    },

    async actionUpdate() {
      let { formData } = this;
      const id = formData.id;

      formData = { ...formData };

      const keysToRemove = ['created_at', 'updated_at', 'id'];
      keysToRemove.forEach(key => delete formData[key]);

      await TemplateModel.update(id, formData,
        () => {},
        (data) => {
          this.errors = [];
          this.storeDates.push(new Date());
          this.formData = data;
          showMessage("flash.asset.update");
        },
        ({ response }) => {
          const { data } = response;
          const { errors } = data;
          this.errors = errors;
        }
      );

    },
  },
};
</script>
