import axios from "axios";

export default class CategoryModel {
  static async findAllCategory(
    params,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    const query = {
      limit: params.limit,
      offset: params.offset,
      query: params.query,
      filter: params.filter,
    };

    startCallback();

    return await axios
      .post("/api/v2/category/grid", query)
      .then(({ data }) => {
        endCallback();
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch all categories [findAllCategory()]", error);
        return error;
      });
  }

  static async findMe(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/category/detail/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch detail category [findMe()]", error);
        return error;
      });
  }
}
