<template>
  <div class="intro">
    <div class="intro__container">
      <transition name="welcome">
        <div v-if="reaming < limit - 1.0">
          <div
            class="intro__logo"
          >
            <img
              class="intro__logo-img"
              src="/static/logo-pobo.png"
            >
          </div>

          <b-progress
            :value="reaming"
            animated
            class="mt-4"
            height="6px"
            :max="limit - 1.0"
            variant="danger"
          />

          <div class="intro__header">
            {{ $t("state.loading") }}
          </div>

          <small class="intro__version">
            <span class="intro__version-name">Rest API:</span>&nbsp;&nbsp;{{ version.api }}
          </small>


          <small class="intro__version">
            <span class="intro__version-name">Client:</span> {{ version.client }}
          </small>


          <small class="intro__version">
            <span class="intro__version-name">Asset:</span>&nbsp;&nbsp;{{ version.asset }}
          </small>

          <small class="intro__version">
            <span class="intro__version-name">AI:</span>&nbsp;&nbsp;{{ version.ai }}
          </small>

          <small class="intro__version">
            <span class="intro__version-name">B2B API:</span>&nbsp;&nbsp;{{ version.b2b }}
          </small>
        </div>
      </transition>
    </div>



    <iframe
      src="https://www.youtube.com/embed/LHmiiRlwdBE?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=LHmiiRlwdBE&modestbranding=1"
      class="intro__video"
    />
  </div>
</template>

<script>
import pcg from "@/../package.json";
export default  {
  name: "Intro",
  props: {
    reaming: {
      type: Number,
      default: 0,
      required: true,
    },

    limit: {
      type: Number,
      default: 0,
      required: true,
    },
  },

  computed: {
    version() {
      return pcg.releaseList;
    },

    environment() {
      return pcg.environment;
    },

    url() {
      return `https://www.youtube.com/embed/9YPiLU8BIp4
      ?controls=0
      &autoplay=1
      &mute=1&
      playsinline=1
      &loop=1
      &playlist=V3vL9IzvXxI
      &modestbranding=1`;
    }
  }
}
</script>

<style lang="scss">
.intro {
  background: white;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.50);
    z-index: 5;
  }

  &__container {
    max-width: 500px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    padding: 50px 80px;
    border-radius: 20px;
  }


  &__logo-img {
    max-width: 100%;
    height: auto;
  }

  &__header {
    font-size: 17px;
    line-height: 19px;
    font-weight: 500;
    color: #FFF;
    margin: 20px 0 40px 0;
  }

  &__version {
    font-size: 15px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    padding: 2px 0;
    display: flex;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }

  &__version-name {
    font-weight: 500;
    width: 140px;
    text-align: right;
    padding-right: 10px;
  }

  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    pointer-events: none;
    zoom: 120%;
  }

  .progress {
    background: #bdbdbd;
  }
}
</style>