import { defineStore } from "pinia/dist/pinia";
import GeneratorModel from "@/model/GeneratorModel.js";

export const DEFAULT_STORE = {
  list: [],
  locked: [],
  reaming: 0,
};

export const useGeneratorStore = defineStore("GeneratorStore", {
  state: () => {
    return DEFAULT_STORE;
  },

  actions: {
    async check() {
      await GeneratorModel.findAll(
        () => {},
        (data) => {
          for (const key in data) {
            this[key] = data[key];
          }
        }
      );
    },
  },
});
