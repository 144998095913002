<template>
  <div class="product-autocomplete">
    <div class="product-autocomplete__input">
      <template v-if="user">
        <span
          class="form-control"
          @click="user = null"
        >
          {{ user.email }}
        </span>
      </template>
      <form
        v-else
        class="input-group position-relative"
        @submit.prevent="actionRead"
      >
        <input
          id="id"
          v-model="params.query"
          type="text"
          class="form-control"
          placeholder="Vyberte uživatele"
          autocomplete="off"
          @click="handleOpen(true)"
        >
        <div class="input-group-append">
          <button
            class="btn btn-secondary"
            type="button"
            :disabled="isLoaded"
          >
            {{ isLoaded ? $t("search.load") : "Vyhledat" }}
          </button>
        </div>
      </form>
    </div>
    <div
      v-if="users && isOpen && !user"
      class="product-autocomplete__container"
    >
      <div
        v-for="user in users"
        :key="user.id"
        class="product-autocomplete__products"
      >
        <div class="product-autocomplete__title">
          <a
            :href="user.url"
            target="_blank"
          >
            {{ user.email }}
            <small class="text-black-50">({{ user.url }})</small>
            <i class="ico ico-external" />
          </a>
        </div>

        <div class="product-autocomplete__button">
          <a
            class="btn btn-secondary btn-sm btn-block text-white"
            @click="handleSelect(user)"
          >
            {{ $t("productAutoComplete.choose") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdminModel from "@/model/AdminModel.js";

export default {
  name: "user-autocomplete",

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      users: [],
      params: {
        query: "",
        offset: 1,
        limit: 10,
        filter: 2,
      },
      total: 0,
      user: null,
      isOpen: false,
      isLoaded: false,
    };
  },

  methods: {
    handleSelect(user) {
      this.user = user;
      this.$emit("select-user", user);
    },

    handleOpen(state) {
      this.isOpen = state;
    },

    async actionRead() {
      this.isLoaded = true;
      const { params } = this;

      await AdminModel.findUsers(
        params,
        () => {},
        (data) => {
          this.users = data.users;
        }
      );

      this.isLoaded = false;
    },
  },
};
</script>

<style scoped></style>
