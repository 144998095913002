import hotkeys from "hotkeys-js";

export default {
  mounted() {
    this.keyboardShortcuts();
  },

  unmounted() {
    hotkeys.unbind();
  },

  methods: {
    keyboardShortcuts(){
      const self = this;
      hotkeys('cmd+a, ctrl+a', function(event, handler){
        event.preventDefault();
        self.selectAllProduct();
      });

      hotkeys('cmd+d, ctrl+d', function(event, handler){
        event.preventDefault();
        if(self.selected.length > 0){
          self.deleteSelected();
        }
      });

      hotkeys('cmd+s, ctrl+s', function(event, handler){
        event.preventDefault();
        if(self.selected.length > 0){
          self.submitProduct();
        }
      });
    },
  }

}
