<template>
  <div>
    <layout-setting col="12">
      <template #header>
        {{ $t("Credits") }}
      </template>
      <template #content>
        <p
          class="mb-4 text-sm p-3 rounded information-box"
        >
          <i class="ico ico-warning" />
          {{ $t("Purchase of credits can be ordered by e-mail") }}
          <a
            class="text-secondary"
            href="mailto:podpora@pobo.cz"
          >podpora@pobo.cz</a>.
          {{
            $t(
              `Please write the URL of your e-shop in the subject line and the number of credits you wish to purchase with billing information. Thank you.`
            )
          }}
        </p>

        <div class="px-4 py-2 rounded border shadow-sm">
          <div class="row text-left">
            <div class="col-4">
              <div class="px-1 py-3">
                <h6 class="p-0 m-0 fs-18 font-weight-bold">
                  {{ $t("Number of credits remaining") }}
                </h6>
                <span class="text-muted d-block mt-1 mb-3">
                  {{ $t("on your account") }}
                </span>

                <div class="row align-items-center">
                  <div class="col-6 fs-18 font-weight-bolder">
                    {{ $t("sort.credit", summary.reaming) }}
                  </div>
                  <div class="col-6">
                    <small class="text-muted d-block lh-14 fs-12" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="px-1 py-3">
                <h6 class="p-0 m-0 fs-18 font-weight-bold">
                  {{ $t("Credits purchased") }}
                </h6>
                <span class="text-muted d-block mt-1 mb-3">
                  {{ $t("for the whole period") }}
                </span>

                <div class="row align-items-center">
                  <div class="col-6 fs-18 font-weight-bolder">
                    {{ $t("sort.credit", summary.add) }}
                  </div>
                  <div class="col-6">
                    <small class="text-muted d-block lh-14 fs-12" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4">
              <div class="px-1 py-3">
                <h6 class="p-0 m-0 fs-18 font-weight-bold">
                  {{ $t("Credits deducted") }}
                </h6>
                <span class="text-muted d-block mt-1 mb-3">
                  {{ $t("for the whole period") }}
                </span>

                <div class="row align-items-center">
                  <div class="col-6 fs-18 font-weight-bolder">
                    {{ $t("sort.credit", summary.remove) }}
                  </div>
                  <div class="col-6">
                    <small class="text-muted d-block lh-14 fs-12" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h5 class="mt-4 mb-3">
          {{ $t("History of credit movements") }}
        </h5>
        <table
          v-if="history.length > 0"
          class="table table-striped"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("Created at") }}</th>
              <th>{{ $t("Count credits") }}</th>
              <th>{{ $t("Action") }}</th>
              <th>{{ $t("Note") }}</th>
              <th>{{ $t("Link") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in history"
              :key="row.id"
            >
              <td>
                {{ row.id }}
              </td>
              <td>
                {{ datetime(row.created_at) }}
              </td>
              <td>
                {{ row.count }}
              </td>
              <td>
                <span
                  :class="[
                    row.action === 'add' ? 'text-success' : 'text-danger',
                  ]"
                >
                  {{ row.action === "add" ? "Add credit" : "Remove credit" }}
                </span>
              </td>
              <td>
                {{ row.note ? row.note : "-" }}
              </td>
              <td>
                <a
                  :href="row.module.url"
                  target="_blank"
                >
                  {{ row.module.title }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <template v-else>
          <b-alert
            :show="true"
            class="mb-3"
            variant="danger"
          >
            {{ $t("No data") }}
          </b-alert>
        </template>
      </template>
    </layout-setting>
  </div>
</template>

<script>
import { datetime } from "@/filter/vue";
import { mapWritableState } from "pinia";
import { useCreditStore } from "@/store/CreditStore";

export default {
  name: "Credit",

  computed: {
    ...mapWritableState(useCreditStore, {
      history: "history",
      summary: "summary",
      price: "price",
    }),
  },

  methods: { datetime },
};
</script>
