<template>
  <b-form
    class="pb-1"
    @submit.prevent="$emit('action-update')"
  >
    <h6>
      {{ $t('Product design') }}
    </h6>

    <p>{{ $t('In this section you can set a separate product appearance.') }}</p>
    <div
      class="bg-white p-4 shadow-sm rounded"
      style="overflow-y: auto; height: calc(100vh - 300px)"
    >
      <b-form-group>
        <b-form-radio
          v-for="(design, index) in data"
          :key="index"
          v-model="currentDesign"
          class="mb-2 border-bottom"
          :disabled="eshopTariff === 'start'"
          :value="design.id"
          data-cy="product-detail-change-design"
          @change="changeStatus"
        >
          {{ design.name }}

          <small class="d-block text-black-50">
            {{ design.info }}
          </small>
        </b-form-radio>
      </b-form-group>
    </div>
    <b-form-group>
      <tier-control
        :allow-tariff="['profi', 'premium', 'saas']"
        minimum-tariff="profi"
        position="bottom"
      >
        <b-btn
          variant="secondary"
          class="mt-4"
          type="submit"
          data-cy="product-detail-change-design-submit-btn"
          block
        >
          {{ $t("Save") }}
        </b-btn>
      </tier-control>
    </b-form-group>
  </b-form>
</template>

<script>
import DesignModel from "@/model/front/DesignModel";
import TierControl from "@/share/tier-control.vue";
import eshopData from "@/mixin/eshopData";

export default {
  name: "ModalChangeDesign",

  mixins: [eshopData],

  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },

  async mounted() {
    await DesignModel.findAll(() => {
    }, (data) => {
      this.data = [{
        id: null,
        name: "The default look",
        info: "The default look to use if no other look is selected"
      }, ...data.custom];
    });

    this.currentDesign = this.product.design_id;
  },

  data() {
    return {
      currentDesign: null,
      data: []
    };
  },
  components: { TierControl },

  methods: {
    changeStatus() {
      const {  currentDesign } = this;
      this.$emit("change-design", currentDesign);
    },
  }
};

</script>

