import axios from "axios";

export default class LockedModel {
  static async fetchLock(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/is-lock")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch export [fetchLock()]", error);
        return error;
      });
  }
}
