<template>
  <tag
    v-if="!enclose.includes(item.tag)"
    :tag="item.tag"
    :class="item.class"
  >
    {{ contentResolver() }}

    <template v-if="!enclose.includes(item.tag) && widget.children">
      <widget-tree
        v-for="(child, childIndex) in widget.children"
        :id="id"
        :key="child.id + childIndex"
        :lang="lang"
        :item="child"
      />
    </template>
  </tag>

  <div
    v-if="item.tag === 'p'"
    v-html="contentResolver()"
  />

  <div
    v-if="item.tag === 'youtube'"
    class="embed-responsive embed-responsive-16by9"
  >
    <iframe
      class="embed-responsive-item"
      :src="contentResolver()"
      allowfullscreen
    />
  </div>

  <img
    v-if="item.tag === 'img'"
    :src="contentResolver()"
    :class="item.class"
  >

  <div
    v-if="item.tag === 'youtube'"
    class="embed-responsive embed-responsive-16by9"
  >
    <iframe
      class="embed-responsive-item"
      :src="contentResolver()"
      allowfullscreen
    />
  </div>

  <div
    v-if="item.tag === 'ul'"
    :class="[
      item.is_editable == 1 ? 'content-editor__editable' : '',
      item.class,
    ]"
  >
    <div class="form-group">
      <textarea
        v-model="item.value[lang]"
        class="form-control"
        rows="3"
      >{{ contentResolver() }}</textarea>
    </div>
  </div>
</template>

<script>
import Tag from "@/components/helper/tag.vue";
export default {

  name: "widget-tree",
  props: {
    item: {
      type: Object,
      required: true,
    },

    id: {
      type: Number,
      required: true,
    },

    lang: {
      type: String,
      required: true,
    },
  },

  data(){
    return {
      widget: this.item,
      enclose: ["img", "li", "ul", "p", "youtube"],
    };
  },

  components: {
    tag: Tag,
  },

  methods: {
    contentResolver(){
      const { item, lang } = this;
      return item.default_value[lang];
    },

    paste($event){
      setTimeout(function () {
        $event.target.innerText = $event.target.innerText;
      }, 10);
    },
  },
};
</script>
