<template>
  <div>
    <template v-if="!confirmExportType">
      <h2 class="my-4 p-0">
        {{ $t("guide.export.heading") }}
      </h2>

      <span
        class="d-inline-block mb-4"
        style="background: #ffe72e; padding: 4px 8px"
      >
        <strong>{{ $t("guide.export.importantStrong") }}</strong>
        {{ $t("guide.export.important") }}
      </span>

      <p class="mb-2">
        {{ $t("guide.export.descOne") }}
        <strong>{{ $t("guide.export.descOneStrong") }}</strong>
        {{ $t("guide.export.descTwo") }}
      </p>

      <p class="mb-4">
        {{ $t("guide.export.descThree") }}
        <strong>{{ $t("guide.export.descThreeStrong") }}</strong>
        {{ $t("guide.export.descFourth") }}
      </p>

      <h2 class="h3 mb-4">
        {{ $t("guide.export.setheading") }}
      </h2>

      <div class="bg-white shadow-sm p-3 rounded">
        <setting-form
          @handle-close="handleConfirmTypeExport"
        />
      </div>
    </template>

    <template v-else>
      <h2 class="my-4 p-0">
        {{ $t("guide.export.after.heading") }}
      </h2>

      <p class="mb-4">
        {{ $t("guide.export.after.descOne") }}
        <strong>{{ $t("guide.export.after.descOneStrong") }}</strong>
        {{ $t("guide.export.after.descTwo") }}
      </p>
      <template v-if="enableRequest">
        <button
          class="btn btn-secondary"
          @click="actionCreate"
        >
          {{ $t("guide.export.after.button") }}<i class="ico ico-arrow-right" />
        </button>
      </template>
      <template v-else>
        <template v-if="lastRequest">
          <span class="d-block text-muted pb-2">
            <b-spinner
              class="mb-1 mr-1"
              small
            />
            {{ $t("guide.export.after.export") }}
          </span>

          <b-progress
            :max="100"
            :value="lastRequest.export_progress"
            animateht="15px"
            striped="striped"
            variant="secondary"
          />

          <small class="d-block w-100 text-muted mt-1">
            {{ $t("guide.done") }}
            <strong>{{ lastRequest.export_progress }}% / 100%</strong>
          </small>
        </template>
        <template v-else>
          <b-skeleton-img
            height="70px"
            no-aspect
            width="100%"
          />
        </template>
      </template>
    </template>
  </div>
</template>

<script>
/** import SettingForm from "../setting/part/setting-form";  Chybí ... */
import SettingForm from "@/components/setting/part/setting-form.vue";
import ExportModel from "@/model/ExportModel.js";
import eshopData from "@/mixin/eshopData";
export default {
  name: "export",

  mixins: [eshopData],

  mounted() {
    this.actionRead();

    setInterval(() => {
      this.actionRead();
    }, 5000);
  },

  data() {
    return {
      submitRequest: false,
      confirmExportType: false,

      cron: {
        stats: [],
        enable_request: true,
        list: [],
      },
    };
  },

  computed: {
    enableRequest() {
      const { cron } = this;
      return cron.enable_request;
    },

    lastRequest() {
      const { cron } = this;
      const { list } = cron;
      return list ? list[0] : null;
    },
  },

  components: {
    "setting-form": SettingForm,
  },

  methods: {
    handleConfirmTypeExport() {
      this.confirmExportType = true;
    },

    async actionCreate() {
      await ExportModel.create(
        () => {},
        (data) => {
          this.submitRequest = true;
          this.cron = data;
        }
      );
    },

    async actionRead() {
      await ExportModel.findAll(
        () => {},
        (data) => {
          const { list } = data;
          const { submitRequest } = this;

          this.cron = data;

          if (list.length > 0 && submitRequest) {
            if (list[0].is_complete) {
              this.$emit("update-step", 5);
            }
          }
        }
      );
    },
  },
};
</script>
