<template>
  <div
    class="generative-ai-button__dropdown"
    @click.prevent="toggleDropdown"
  >
    <b-icon
      v-if="!showDropdown"
      icon="caret-down-fill"
      class="fs-10 p-0 m-0"
    />
    <b-icon
      v-else
      icon="caret-up-fill"
      class="fs-10 p-0 m-0"
    />
    <div
      v-if="showDropdown"
      class="generative-ai-button__dropdown-wrap"
    >
      <template v-if="filteredContentList.length > 0">
        <div
          v-for="item in filteredContentList"
          :key="item.id"
          class="generative-ai-button__dropdown-item"
          href="#"
          @click.prevent="handleItemClick(item)"
        >
          {{ item.title }}
        </div>
      </template>
      <div
        v-else
        class="dropdown-empty"
      >
        <div
          v-if="isLoading"
          class="loading-message text-center p-3 fs-12"
        >
          {{ $t("product.search.load") }}
        </div>
        <div
          v-else
          class="empty-message text-center p-3 fs-12"
        >
          {{ $t('For now, you dont have any custom AI prompts. Are you interested? Contact us') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eshopData from "@/mixin/eshopData";
import GenerativeModel from "@/model/GenerativeModel";

export default {
  mixins: [eshopData],

  data() {
    return {
      contentList: [],
      isLoading: false,
      showDropdown: false,
    };
  },

  computed: {
    filteredContentList() {
      return this.contentList.filter(item => item.id > 2);
    }
  },

  watch:{
    showDropdown(newVal) {
      if (newVal) {
        this.fetchData();
        document.addEventListener("mousedown", this.handleClickOutside);
      } else {
        document.removeEventListener("mousedown", this.handleClickOutside);
      }
    }
  },

  beforeUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  },

  methods: {
    async fetchData() {
      if (this.contentList.length === 0) {
        this.isLoading = true;
        await GenerativeModel.getContent(
          () => {},
          (data) => {
            this.contentList = data;
            this.isLoading = false;
          }
        );
      }
    },

    toggleDropdown(){
      this.showDropdown = !this.showDropdown;
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },

    handleItemClick(item) {
      const {lang} = this;
      this.$emit("generate-ai-text", {
        html: "<span></span>",
        templateId: item.id,
        lang: lang,
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.generative-ai-button {
  &__dropdown {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 6px 10px;
    border-radius: 0 25px 25px 0;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 13px;
    border: none;
    color: white;
    background: linear-gradient(45deg, #e10073, #ca09ff);
    z-index: 999;
    margin: 0;
    outline: none;
    box-shadow: none;
  }

  &__dropdown-wrap {
    color: black;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    left: 0;
    bottom: 100%;
    background: linear-gradient(135deg, #f9e7f1, #e6d4f5);
    width: 100%;
    border-radius: 8px;
    padding: 5px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
  }

  &__dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #333;
    transition: background 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.71);
    }
  }

  .dropdown-empty,
  .loading-message,
  .empty-message {
    padding: 12px;
    text-align: center;
    color: #666;
    font-size: 12px;
    font-style: italic;
  }
}
</style>
