<template>
  <help-box
    author="sara"
    type="theme"
    classes="mt-4"
  >
    <h2 class="mb-4">
      {{ $t("How to switch to dark mode?") }}
    </h2>
    <p class="fs-16 lh-26">
      {{ $t("Dark mode reduces glare and eye fatigue, especially when working in the dark.") }}
      {{ $t("It improves concentration, reduces eye strain and extends battery life thanks to lower light consumption on the display.") }}
    </p>
  </help-box>

  <b-form @submit.prevent="actionUpdate">
    <h5 class="mb-3">
      {{ $t("Setting the appearance of the editor") }}
    </h5>

    <div class="bg-white p-4 mb-5 shadow-sm rounded">
      <p class="text-sm mb-3">
        {{ $t("Switch the editor to dark and write labels more comfortably.") }}
        {{ $t('Dark Mode protects your eyes, creates a comfortable working environment and saves power for your device.') }}
      </p>
      <b-form-group
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio
          v-model="form.enable_dark"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          :value="false"
        >
          {{ $t('Light theme') }}
        </b-form-radio>
        <b-form-radio
          v-model="form.enable_dark"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          :value="true"
        >
          {{ $t('Dark theme') }}
        </b-form-radio>
      </b-form-group>

      <b-form-group>
        <b-btn
          class="mt-3"
          type="submit"
          variant="secondary"
        >
          {{ $t("Save") }} <i class="ico ico-arrow-right" />
        </b-btn>
      </b-form-group>
    </div>
  </b-form>
</template>

<script>
import lang from "@/js/config/lang.js";
import HelpBox from "@/share/help-box.vue";
import eshopData from "@/mixin/eshopData";
import cloneDeep from "lodash.clonedeep";
import { useEshopStore } from "@/store/EshopStore";
export default {
  name: "SettingTheme",
  displayName: "SettingTheme",

  mixins: [eshopData],

  async mounted() {
    const { eshop } = this;
    this.form = cloneDeep(eshop);
  },

  data() {
    return {
      allowLang: [],
      lang,
      form: {},
    };
  },

  components: {
    HelpBox,
  },

  methods: {
    async actionUpdate() {
      const { form } = this;
      await useEshopStore().updateEshop({
        data: form
      });
      location.reload();
    },
  },
};
</script>
