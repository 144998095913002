<template>
  <b-form @submit.prevent="handleSubmit">
    <b-form-group label="Název kategorie">
      <b-form-input
        v-model="form.name"
        type="text"
        class="form-control"
        :class="errors.name ? 'is-invalid' : ''"
        placeholder="Např. „Obrázky a galerie“"
      />
      <div
        v-if="errors"
        class="mt-1"
      >
        <small
          v-for="(error, index) in errors.name"
          :key="index"
          class="text-danger d-block"
          v-html="error"
        />
      </div>
    </b-form-group>

    <b-form-group
      class="form-group"
      label="Třída ikony"
    >
      <b-form-input
        v-model="form.ico"
        type="text"
        class="form-control"
        :class="errors.ico ? 'is-invalid' : ''"
        placeholder="Např. „ico-wc-image“"
      />
      <div
        v-if="errors"
        class="mt-1"
      >
        <small
          v-for="(error, index) in errors.ico"
          :key="index"
          class="text-danger d-block"
          v-html="error"
        />
      </div>
    </b-form-group>

    <b-form-group class="form-group">
      <div class="form-check">
        <input
          id="public"
          v-model="form.public"
          class="form-check-input"
          type="checkbox"
        >
        <label
          class="form-check-label"
          for="public"
        >
          Viditelná kategorie
        </label>
      </div>

      <div
        v-if="errors"
        class="mt-1"
      >
        <small
          v-for="(error, index) in errors.public"
          :key="index"
          class="text-danger d-block"
          v-html="error"
        />
      </div>
    </b-form-group>

    <b-form-group class="form-group">
      <div class="form-check">
        <input
          id="allow_reference"
          v-model="form.allow_reference"
          class="form-check-input"
          type="checkbox"
        >
        <label
          class="form-check-label"
          for="allow_reference"
        >
          Zobrazit jako referenci na webu
        </label>
      </div>

      <div
        v-if="errors"
        class="mt-1"
      >
        <small
          v-for="(error, index) in errors.allow_reference"
          :key="index"
          class="text-danger d-block"
          v-html="error"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <b-btn
        variant="secondary"
        type="submit"
      >
        Odeslat
      </b-btn>
    </b-form-group>
  </b-form>
</template>

<script>
import WidgetCategoryModel from "@/model/WidgetCategoryModel.js";

export default {
  name: "widget-category",

  props: {
    id: {
      type: Number,
      required: false,
    },
  },

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      form: {
        id: null,
        name: null,
        ico: null,
        public: false,
        allow_reference: false,
      },

      errors: {},
      isLoaded: false,
    };
  },

  methods: {
    async actionRead() {
      const { id } = this;

      if (id) {
        await WidgetCategoryModel.findMe(
          id,
          () => {},
          (data) => {
            this.form = data;
          }
        );
      }

      this.isLoaded = true;
    },

    async handleSubmit() {
      const { form, id } = this;

      if (id) {
        await WidgetCategoryModel.saveMe(
          form,
          () => {},
          (data) => {
            const { errors } = data;
            if (errors) {
              this.errors = errors;
            } else {
              this.$emit("update");
            }
          }
        );
      } else {
        await WidgetCategoryModel.create(
          form,
          () => {},
          (data) => {
            const { errors } = data;
            if (errors) {
              this.errors = errors;
            } else {
              this.$emit("update");
            }
          }
        );
      }
    },
  },
};
</script>
