<template>
  <a
    :class="active ? 'active' : ''"
    class="analytics-card__link d-block shadow-sm rounded position-relative"
    href="#"
    @click.prevent="$emit('select-panel', type)"
  >
    <div class="analytics-card">
      <div class="analytics-card__content">
        <span class="analytics-card__header">
          <slot name="header" />
        </span>

        <span class="analytics-card__info">
          <slot name="info" />
        </span>

        <b-row
          class="analytics-card__result"
          no-gutters
        >
          <b-col cols="6">
            <span class="text-secondary analytics-card__result-top">
              <slot name="with" />
            </span>
            <span class="analytics-card__result-bottom">
              {{ $t("analytics.eshop.with") }}
            </span>
          </b-col>
          <b-col
            class="text-right"
            cols="6"
          >
            <span class="text-danger analytics-card__result-top">
              <slot name="without" />
            </span>
            <span class="analytics-card__result-bottom">
              {{ $t("analytics.eshop.without") }}
            </span>
          </b-col>
        </b-row>
      </div>
    </div>
    <chart
      :options="chartOptions"
      :series="series"
      height="25"
    />
  </a>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "MiniChart",

  props: {
    series: {
      type: Array,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    active: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      data: null,
      panel: Object,

      chartOptions: {
        colors: ["#649b0d", "#649b0d"],

        chart: {
          height: 600,
          type: "area",
          sparkline: {
            enabled: true,
          },

          xaxis: {
            lines: {
              show: false,
            },
          },

          grid: {
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },

          animations: {
            enabled: true,
          },

          toolbar: {
            show: false,
          },
        },

        legend: {
          show: false,
        },

        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        fill: {
          opacity: 1,
        },

        axisTicks: {
          show: false,
        },
        floating: true,

        axisBorder: {
          show: false,
        },
      },
    };
  },

  components: {
    chart: VueApexCharts,
  },
};
</script>