<template>
  <div class="browser-editor">
    <div class="browser-editor__container">
      <div class="browser-editor__header">
        <div
          class="browser-editor__tab"
        >
          <div class="browser-editor__tab-entity-img">
            <b-img
              :src="entity.image_preview ? entity.image_preview : 'https://pobo-cdn.b-cdn.net/static/no-image-icon.png'"
              alt=""
              width="40px"
              fluid
            />
          </div>
          <a
            class="browser-editor__tab-entity-name"
            :href="entity.url"
          >
            {{ entity.name }} <i class="ico ico-external" />
          </a>
        </div>

        <div class="browser-editor__tools">
          <slot name="tools" />

          <ul
            class="browser-editor__tool"
          >
            <li
              class="browser-editor__tool-li browser-editor__tool-dropdown-li"
            >
              <a
                href="#"
                class="browser-editor__tool-dropdown-link d-flex approve browser-editor__tool-link"
                @click.prevent="toggleDropdownOpen"
              >
                {{ $t('Events') }}
                <i
                  :class="{'rotate-180': toolDropdownOpen}"
                  class="ico ico-arrow-bold-down ml-1"
                />
              </a>

              <ul
                v-if="toolDropdownOpen"
                class="browser-editor__tool-dropdown"
              >
                <li
                  v-for="(link, index) in toolLinks"
                  :key="index"
                  class="browser-editor__tool-li w-100"
                >
                  <a
                    href="#"
                    class="w-100 align-items-center d-flex"
                    :class="[link.event === 'approve' ? 'browser-editor__tab--approve' : '', 'browser-editor__tool-link']"
                    @click.prevent="openToolModal(link.event, entity)"
                  >
                    <i
                      v-if="link.ico"
                      :class="'ico ico-' + link.ico"
                      class="mr-2"
                    />
                    {{ $t(link.translate) }}
                    <br>
                    <strong
                      v-if="link.event === 'designer'"
                      class="d-inline ml-2 text-yellow fs-9"
                    >
                      {{ $t("translate.news") }}
                    </strong>

                  </a>
                </li>
              </ul>
            </li>

            <li
              v-for="(link, index) in mainLinks"
              :key="index"
              class="browser-editor__tool-li"
            >
              <a
                v-if="isAllowPlatform(link.allowPlatform)"
                href="#"
                :class="[link.event === 'approve' ? 'browser-editor__tab--approve' : '', 'browser-editor__tool-link']"
                @click.prevent="openToolModal(link.event, entity)"
              >
                {{ $t(link.translate) }}
              </a>
            </li>
          </ul>
        </div>

        <ul class="browser-editor__action">
          <li class="browser-editor__action-li">
            <a
              href="#"
              class="browser-editor__action-link browser-editor__action-link--minimize"
              @click.prevent="$emit('action-minimize')"
            > <i class="ico ico-minimize-window" /> </a>
          </li>

          <li class="browser-editor__action-li">
            <a
              href="#"
              class="browser-editor__action-link browser-editor__action-link--save"
              @click.prevent="actionUpdate(true)"
            > <i class="ico ico-save-window" /></a>
          </li>

          <li class="browser-editor__action-li">
            <a
              href="#"
              class="browser-editor__action-link browser-editor__action-link--close"
              @click.prevent="$emit('action-close', entity)"
            > <i class="ico ico-cancel-window" /></a>
          </li>
        </ul>
      </div>

      <div class="browser-editor__content">
        <div class="browser-editor__live">
          <div class="browser-editor__live-editor">
            <transition name="fade">
              <div v-if="isLoaded && !activePreviewMode">
                <div
                  id="pobo-all-content"
                  :style="userStyleEditor"
                  class="content-editor__placeholder--browser"
                  @scroll="onScroll"
                >
                  <help-box
                    author="sara"
                    type="how"
                    classes="mt-4 ml-4 mr-5"
                  >
                    <h2
                      class="mb-4"
                    >
                      {{ $t("How to use the Pobo Page Builder editor?") }}
                    </h2>
                    <p class="fs-16 lh-26">
                      {{ $t("Pobo Page Builder is a drag-and-drop editor for creating product and article descriptions, offering more than 50 widgets and the ability to manage multiple e-stores.")
                      }}
                      {{ $t("It allows you to easily set up labels without programming knowledge and includes tools for evaluating their success.")
                      }}
                    </p>
                  </help-box>
                  <div
                    v-for="item in tabs"
                    :key="item.id"
                  >
                    <div v-show="item.id === activeId">
                      <widget-area
                        :id="item.id"
                        ref="area"
                        :value-lang="valueLang"
                        :options="options"
                      />

                      <template v-if="item.share && item.share.length > 0">
                        <div class="content-editor__placeholder-inner">
                          <div>
                            <h4 class="font-weight-light p-0 mb-4 mt-0">
                              {{ $t("product.share.fill.text") }}
                            </h4>
                            <template
                              v-for="share in entity.share"
                              :key="share.id"
                            >
                              <template v-if="share.widget">
                                <span class="my-2 d-block p-0 text-muted font-weight-normal">
                                  {{ share.name }}
                                </span>
                                <div
                                  v-for="widget in share.widget"
                                  :key="widget.id"
                                  class="bg-white p-4 border"
                                >
                                  <div v-html="widget.html" />
                                </div>
                              </template>
                            </template>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div
                          class="text-center mb-2"
                        >
                          <h4 class="font-weight-light p-0 mb-3 mt-0">
                            {{ $t("product.share.empty.text") }}
                          </h4>

                          <router-link
                            :to="{ name: 'share' }"
                            class="btn btn-secondary mb-5"
                          >
                            {{ $t("product.share.empty.try") }}
                          </router-link>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            

            <transition name="fade">
              <div v-if="activePreviewMode && isLoaded">
                <h3 class="mt-2">
                  {{ $t("Virtual preview") }}
                </h3>

                <p>
                  {{ $t("Virtual preview allows you to check the appearance on the e-shop and additionally edit the content") }}.
                </p>

                <div
                  v-if="isShoptet && configureMessage"
                  class="px-5 py-4 rounded-pill rounded d-flex align-items-center mt-2 mb-4 bg-white shadow-sm border"
                >
                  <span>
                    <b-icon
                      icon="paint-bucket"
                      size="40"
                    />
                  </span>
                  <div class="fs-15 lh-20 d-block pl-4">
                    <h5 class="font-weight-bold">
                      Získejte náhled na desktop a mobile u produktů na vašem e-shopu
                    </h5>
                    Náhled popisku na PC a mobilu bude obsahovat grafiku ukázkového popisku, díky tomu
                    si lépe představíte, jak bude popisek vypadat na vašem e-shopu.
                  </div>

                  <div class="d-flex align-items-center ml-aut pl-4">
                    <b-button
                      variant="secondary"
                      class="text-nowrap"
                      @click="openThemeModal"
                    >
                      {{ $t("Jak získat náhled") }}
                    </b-button>
                  </div>
                </div>

                <div class="d-flex align-items-center justify-content-center">
                  <div class="position-relative m-auto browser-preview__desktop-container">
                    <div class="position-absolute browser-preview__desktop-inner">
                      <div
                        class="position-relative h-100 browser-preview__desktop-img"
                      >
                        <transition name="fade">
                          <div
                            v-if="reamingLoading > 0"
                            class="browser-preview__loading"
                          >
                            <div class="browser-preview__loading-inner">
                              <b-spinner
                                size="md"
                                variant="white"
                              />
                            </div>
                          </div>
                          <div v-else>
                            <b-img
                              fluid
                              :src="editorConfig.image.desktop.top"
                            />
                            <div
                              :style="editorConfig.style.desktop"
                              class="browser-preview__desktop-editor"
                            >
                              <div
                                id="pobo-all-content"
                                :style="userStyleEditor"
                                class="content-editor__placeholder--browser overflow-auto h-auto pr-5 pb-4 pt-0"
                                @scroll="onScroll"
                              >
                                <div
                                  v-for="item in tabs"
                                  :key="item.id"
                                >
                                  <div v-show="item.id === activeId">
                                    <widget-preview
                                      :id="item.id"
                                      :value-lang="valueLang"
                                      :options="options"
                                    />

                                    <template v-if="item.share && item.share.length > 0">
                                      <div class="content-editor__placeholder-inner">
                                        <div>
                                          <h4 class="font-weight-light p-0 mb-4 mt-0">
                                            {{ $t("product.share.fill.text") }}
                                          </h4>
                                          <template
                                            v-for="share in entity.share"
                                            :key="share.id"
                                          >
                                            <template v-if="share.widget">
                                              <span class="my-2 d-block p-0 text-muted font-weight-normal">
                                                {{ share.name }}
                                              </span>
                                              <div
                                                v-for="widget in share.widget"
                                                :key="widget.id"
                                                class="bg-white p-4 border"
                                              >
                                                <div v-html="widget.html" />
                                              </div>
                                            </template>
                                          </template>
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <b-img
                              fluid
                              :src="editorConfig.image.desktop.bottom"
                            />
                          </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>

          <div class="browser-editor__live-widget">
            <transition name="fade">
              <div
                v-if="activePreviewMode"
                class="browser-preview__mobile-wrapper"
              >
                <div class="browser-preview__mobile-container">
                  <div class="browser-preview__mobile-inner">
                    <div class="browser-preview__mobile-view">
                      <transition name="fade">
                        <div
                          v-if="reamingLoading > 0"
                          class="browser-preview__loading"
                        >
                          <div class="browser-preview__loading-inner">
                            <b-spinner
                              size="md"
                              variant="white"
                            />
                          </div>
                        </div>
                      </transition>
                      <div
                        class="browser-preview__mobile-iframe-container"
                      >
                        <iframe
                          class="browser-preview__mobile-iframe"
                          :src="'/app/iframe/product/[id]'.replace('[id]', activeId.toString())"
                          width="100%"
                          height="100%"
                          frameborder="0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <widget-list
              ref="widget"
              use-browser-editor
              :value-lang="valueLang"
              @move-start="moveWidget(true)"
              @move-end="moveWidget(false)"
              @update-lang="updateLang"
            >
              <template #view>
                <!--
                <ul class="browser-editor__switch">
                  <li class="browser-editor__switch-li">
                    <a
                      href="#"
                      class="browser-editor__switch-link"
                      :class="[!activePreviewMode ? 'active' : '', 'browser-editor__switch-link']"
                      @click.prevent="enablePreviewMode(false)"
                    > <i class="ico ico-switch-edit" /> {{ $t("Basic editing") }}</a>
                  </li>

                  <li class="browser-editor__switch-li">
                    <a
                      href="#"
                      :class="[activePreviewMode ? 'active' : '', 'browser-editor__switch-link']"
                      @click.prevent="enablePreviewMode(true)"
                    > <i class="ico ico-switch-preview" /> {{ $t("Virtual content preview") }} </a>
                  </li>
                </ul>
                -->
              </template>
            </widget-list>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="isTranslateModalOpen"
      :title="$t('translate.modal.form.targetLang')"
      hide-footer
      size="handler"
    >
      <modal-tool-translate
        :options="options"
        :active-loading-modal="activeLoadingModal"
        @action-translate="actionTranslate"
      />
    </b-modal>

    <!-- Create modal, in modal is component 'modal-theme-help', v model is 'isThemeModalOpen' -->
    <b-modal
      v-model="isThemeModalOpen"
      :title="$t('Vlastní náhled e-shopu na počítači a mobilu')"
      hide-footer
      size="md"
      content-class="modal-panel"
    >
      <modal-theme-help />
    </b-modal>
  </div>
</template>

<script>
import WidgetList from "@/share/widgets.vue";
import axios from "axios";
import ShareModel from "@/model/ShareModel.js";
import { useCreditStore } from "@/store/CreditStore.js";
import { showMessage } from "@/plugins/flashMessage";
import HelpBox from "@/share/help-box.vue";
import eshopData from "@/mixin/eshopData";
import WidgetArea from "@/share/widget-area.vue";
import WidgetPreview from "@/share/widget-preview.vue";
import ModalToolTranslate from "@/share/modal-tool-translate.vue";
import ModalThemeHelp from "@/share/modal-theme-help.vue";

export default {
  name: "editor-browser",

  mixins: [eshopData],

  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => []
    },

    activeId: {
      type: Number,
      required: true
    },

    isOpen: {
      type: Boolean,
      required: true
    },

    options: {
      type: Object,
      required: true
    },

    windowBrowserTab: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  async mounted() {
    this.isLoaded = true;
  },

  data() {
    return {
      isLoaded: false,
      widget: [],
      reamingLoading: 0,
      isMovedWidget: false,
      activeLoadingModal: false,
      isTranslateModalOpen: false,
      isThemeModalOpen: false,
      activePreviewMode: false,
      valueLang: "default",
      positionScrollEditor: 0,
      toolDropdownOpen: false,
    };
  },

  computed: {
    entity() {
      return this.tabs.find((item) => item.id === this.activeId) || {};
    },

    mainLinks() {
      return this.extendWindowBrowserTab.filter(link => link.type === 'main');
    },
    toolLinks() {
      return this.extendWindowBrowserTab.filter(link => link.type === 'tool');
    },

    id() {
      return this.tabs.find((item) => item.id === this.activeId).map((item) => item.id);
    },

    configureMessage() {
      const { isShoptet, editorConfig } = this
      const { active } = editorConfig

      return isShoptet && !active
    },

    userStyleEditor() {
      const { eshop } = this;
      const { editor_width } = eshop;
      return `max-width: ${editor_width}px`;
    },

    watchDog() {
      return `${this.activeId}|${this.activePreviewMode}`;
    },

    extendWindowBrowserTab() {
      const { windowBrowserTab } = this;

      return [
        ... windowBrowserTab,
        {
          event: "translate",
          translate: "Translate content",
          type: "tool",
          ico: "tool-translate"
        },

      ];
    },
  },

  watch: {
    watchDog: {
      handler: function (val) {
        if (val) {
          this.reamingLoading = 2;
          this.timer = setInterval(() => {
            this.reamingLoading--;
            if (this.reamingLoading === 0) {
              clearInterval(this.timer);
            }
          }, 1000);
        } else {
          clearInterval(this.timer);
        }
      },
    },

    activePreviewMode: {
      handler: function (val) {
        // toggle body class 'modal-preview'
        if (val) {
          document.body.classList.add("editor-mode-live");
        } else {
          document.body.classList.remove("editor-mode-live");
        }
      }
    },

    isOpen: {
      handler: function (val) {
        if (val) {
          this.valueLang = "default";
        }
      }
    }
  },

  components: {
    "modal-tool-translate": ModalToolTranslate,
    "widget-preview": WidgetPreview,
    HelpBox,
    "widget-list": WidgetList,
    "widget-area": WidgetArea,
    "modal-theme-help": ModalThemeHelp
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    toggleDropdownOpen(){
      this.toolDropdownOpen = !this.toolDropdownOpen;
      if (this.toolDropdownOpen) {
        document.addEventListener('click', this.handleClickOutside);
      } else {
        document.removeEventListener('click', this.handleClickOutside);
      }
    },

    isAllowPlatform(allowPlatform){
      if(allowPlatform){
        const { eshop } = this;
        const { platform } = eshop;
        return allowPlatform.includes(platform);
      }else{
        return true;
      }
    },

    handleClickOutside(event) {
      if (!this.$el.querySelector('.browser-editor__tool-dropdown-li').contains(event.target)) {
        this.toolDropdownOpen = false;
        document.removeEventListener('click', this.handleClickOutside);
      }
    },

    openThemeModal() {
      this.isThemeModalOpen = !this.isThemeModalOpen;
    },

    openToolModal(link, entity) {
      // if is translate, open local translate modal
      if (link === "translate") {
        this.toggleTranslateModal();
        return;
      }

      this.$emit('open-tool-modal', link, entity)
    },

    toggleTranslateModal() {
      this.isTranslateModalOpen = !this.isTranslateModalOpen;
    },

    async actionTranslate(target_lang = []) {
      const { options, activeId } = this;
      const { api } = options;
      const { translate } = api;

      this.activeLoadingModal = true;
      await this.updateLang('default');
      await axios.post(translate.replace("[id]", activeId), {
        id: activeId,
        target_lang
      });

      this.isTranslateModalOpen = false;
      await this.actionRead();

      await useCreditStore().findHistory();
      this.activeLoadingModal = false;
      showMessage("flash.translated");
    },

    onScroll($event) {
      this.positionScrollEditor = $event.target.scrollTop;
    },

    async updateLang(lang) {
      this.isLoaded = false;
      this.valueLang = lang;
      await this.actionUpdate(false, true)
      this.isLoaded = true;
    },


    async enablePreviewMode(active) {
      this.isLoaded = false
      await this.actionUpdate(true, true);

      this.activePreviewMode = active;
      this.isLoaded = true
    },

    async actionRead() {
      const { tabs, activeId } = this;
      const index = tabs.findIndex((item) => item.id === activeId);

      if (index === undefined) return

      if (!this.activePreviewMode) {
        await this.$refs.area[index].actionRead()
      }
    },

    async actionUpdate(
      showFlashMessage = false,
      refreshData = false
    ) {
      const { tabs, activeId } = this;
      const index = tabs.findIndex((item) => item.id === activeId);

      if (index === undefined) return

      if (!this.activePreviewMode) {
        await this.$refs.area[index].actionUpdate(
          showFlashMessage,
          refreshData,
        )
      } else {
        /*
        todo nachystano na inline editaci
        await this.$refs.preview[index].actionUpdate(
          showFlashMessage,
          refreshData,
        )

         */
      }
    },

    moveWidget(state) {
      this.isMovedWidget = state;
    }
  }
};
</script>

<style scoped>
.rotate-180{
  transform: rotate(180deg);
}

.text-yellow{
  color: #9b8e06;
}
</style>