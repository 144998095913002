<template>
  <div>
    <h2 class="my-4 p-0">
      {{ $t("guide.final.title") }}
    </h2>

    <p class="mb-4">
      <a
        :href="product.url"
        class="text-secondary border-bottom font-weight-bolder"
      >
        {{ product.name }}
      </a>
      {{ $t("guide.final.description") }}
      <strong>{{ $t("guide.final.strongdesc") }}</strong>.
    </p>

    <button
      class="btn btn-secondary"
      @click="handleClose"
    >
      {{ $t("guide.final.button") }} <i class="ico ico-arrow-right" />
    </button>
  </div>
</template>

<script>
export default {
  name: "final",

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    handleClose() {
      this.$emit("handle-close");
    },
  },
};
</script>
