import { configureCompat, createApp } from "@vue/compat";
import "@/plugins/axios";
import App from "./App.vue";
import { routes } from "./router";
import { createPinia } from "pinia";
import { createRouter, createWebHistory } from "vue-router";
import { BootstrapVue } from "bootstrap-vue";
import VueFroala from "vue-froala-wysiwyg";
import VTooltip from "v-tooltip";
import Cookies from "js-cookie";
import i18n from "@/plugins/i18n";
import BIcon from "@/share/b-icon.vue";
import axios from "axios";
import 'vue3-toastify/dist/index.css';
import * as Sentry from "@sentry/browser";
import pcg from "@/../package.json";
import { createGtm } from '@gtm-support/vue-gtm';
import 'core-js/features/array/flat-map';

// todo odstranit po dokonceni moigrace
if (import.meta.env.DEV) {
  console.info('Dev mode enable, set eshopId to 352');
  Cookies.set("eshopId", 352); // 103 (shopify) or 352 (shoptet)
}


configureCompat({
  MODE: 2,
  WATCH_ARRAY: true,
  INSTANCE_EVENT_EMITTER: true,
  // COMPILER_V_IF_V_FOR_PRECEDENCE: true,
  GLOBAL_EXTEND: true,
  COMPILER_NATIVE_TEMPLATE: false,
  INSTANCE_LISTENERS: true,
  OPTIONS_DATA_MERGE: true,
  ATTR_FALSE_VALUE: true,
  COMPONENT_FUNCTIONAL: true,
  CUSTOM_DIR: false,
  // COMPONENT_V_MODEL: false,
});

const pinia = createPinia();

const app = createApp(App);

const router = createRouter({
  mode: "history",
  history: createWebHistory("/"),
  routes,
});

router.afterEach(async (to, from) => {
  document.title = to.meta.title || "Pobo Page Builder";

  const token = Cookies.get("access_token");

  if (!token && to.name !== 'login' && to.name !== 'auth-reset-password') {
    await router.push({ name: 'login' });
  }
  else if (token && to.name === 'login') {
    await router.push({ name: 'product' });
  }

  // todo docasne reseni, ktere obchazi auth + JWT
  if (to.name !== 'check') {
    try {
      // const { data } = await axios.get("/api/v2/eshop/is-auth");
      // const { isLoggedIn, role } = data;

      /*
      if (!isLoggedIn) {
        location.href = "/app/login";
      }

      // Redirect for admin
      if (to.meta.protected === true && !role.includes("admin")) {
        return { name: 'homepage' }
      }
      */
    } catch (error) {
      console.log('Request took longer than 5 seconds, refreshing...');
    //  location.reload();
    }
  }
});

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://931f6a90c6275c9bf3343fdf7c24072a@o4505739655512064.ingest.sentry.io/4505739656822784",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    release: "pobo@" + pcg.releaseList.client,
    environment:  import.meta.env.MODE,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      // @see https://github.com/vitejs/vite/discussions/13925
      "Unable to preload CSS for",
      // Network error - client is offline
      "Load failed",
      "r is not a function"
    ]
  });
}

app.use(
  createGtm({
    id: import.meta.env.VITE_GTM_CODE,
    enabled: false,
    vueRouter: router,
    debug: true
  })
)

app.use(VueFroala);
app.use(BootstrapVue);
app.use(router);
app.use(pinia);
app.use(i18n);
app.use(VTooltip);


app.component("b-icon", BIcon);
app.component("layout-grid", () => import("@/share/layout-grid.vue"));
app.component("confirm-delete", () => import("@/share/confirm-delete.vue"));
app.component("layout-single-export", () => import("@/share/layout-single-export.vue"));

app.component("layout-extend-export", () => import("@/share/modal-extend-export.vue"));

app.component("layout-extend-export", () => import("@/share/modal-extend-export.vue"));
app.component("layout-edit-button", () => import("@/share/layout-edit-button.vue"));
app.component("help-box", () => import("@/share/help-box.vue"));

app.component("tier-wrapper", () => import("@/share/tier-wrapper.vue"));

app.component("layout-setting", () => import("@/components/setting/part/layout-setting.vue"));

app.component("select-lang-vertical", () => import("@/share/select-lang-vertical.vue"))

app.component("link-cutter", () => import("@/share/link-cutter.vue"))


// silent warning
app.config.warnHandler = function (msg, vm, trace) {
  return null;
};

app.mount("#app");
