import axios from "axios";

export default class TagModel {
  static async update(id, data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .put("/api/v2/widget/tag/grid/" + id, data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error tag [update()]", error);
        return error;
      });
  }

  static async create(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .post("/api/v2/widget/tag/grid", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create tag [create()]", error);
        return error;
      });
  }

  static async delete(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .delete("/api/v2/widget/tag/grid/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error delete tag [delete()]", error);
        return error;
      });
  }

  static async findMe(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/widget/tag/grid")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch tag [findMe]", error);
        return error;
      });
  }
}
