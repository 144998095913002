<template>
  <help-box
    author="sara"
    type="collaborators"
    classes="mt-4"
  >
    <h2 class="mb-4">
      {{ $t("How to add collaborators") }}
    </h2>
    <p class="fs-16 lh-26">
      {{ $t("Pobo Page Builder makes it easy to add collaborators, such as agency accounts, copywriters and staff, to your project.") }}
      {{ $t("Just enter their email and the system will automatically link their accounts.") }}
      {{ $t("This feature also allows linking with e-shops, so if an email has a connection with another e-shop, an automatic connection is made between them.") }}
    </p>
  </help-box>

  <layout-setting>
    <template #header>
      {{ $t("connect.user.menu") }}
    </template>

    <template #content>
      <p class="text-sm mb-1">
        {{ $t("connect.user.page.info") }}
      </p>
    </template>
  </layout-setting>
  <layout-setting :full-size="true">
    <template #header>
      <b-row>
        <b-col cols="12">
          {{ $t("connect.user.page.header") }}
        </b-col>
        <b-col
          class="mt-3"
          cols="12"
        >
          <b-button
            size="sm"
            variant="secondary"
            @click="openModal"
          >
            {{ $t("connect.user.page.connect") }}
          </b-button>
        </b-col>
      </b-row>
    </template>

    <template #content>
      <list-connect-user
        :connections="connections"
        @delete="actionDelete"
      />
    </template>
  </layout-setting>

  <b-modal
    v-model="modalOpen"
    :title="$t('connect.modal.title')"
    hide-footer
    size="handler"
  >
    <tier-control
      :allow-tariff="['profi', 'premium', 'saas']"
      minimum-tariff="profi"
    >
      <modal-connect-user @handle-close="closeModal" />
    </tier-control>
  </b-modal>
</template>

<script>
import ModalConnectUser from "@/components/setting/part/modal-connect-user.vue";
import ConnectUser from "@/model/ConnectUser.js";
import TierControl from "@/share/tier-control.vue";
import HelpBox from "@/share/help-box.vue";
import ListConnectUser from "@/components/setting/part/list-connect-user.vue";

export default {
  name: "ConnectUser",
  displayName: "ConnectUser",

  mounted() {
    this.actionRead();
  },

  data() {
    return {
      modalOpen: false,
      connections: [],
    };
  },

  components: {
    ListConnectUser,
    HelpBox,
    "modal-connect-user": ModalConnectUser,
    "tier-control": TierControl,
  },

  methods: {
    openModal() {
      this.modalOpen = true;
    },

    closeModal(data) {
      this.connections = data;
      this.modalOpen = false;
    },

    async actionDelete(id) {
      await ConnectUser.deleteConnection(
        id,
        () => {},
        (data) => {
          this.connections = data;
        }
      );
    },

    async actionRead() {
      await ConnectUser.findAllConnection(
        () => {},
        (data) => {
          this.connections = data;
        }
      );
    },
  },
};
</script>