export default {
  key: "SDB17hD8D7D6D4G3F3F3c1JWSDBCQJ1ZGDa1F1c1JXDAAOZWJhB3D3C10A5C3B4A4F3G3D3==",
  zIndex: 500,
  tableCellMultipleStyles: true,
  tableDefaultWidth: null,
  pasteImage: false,
  imageUpload: false,
  pasteDeniedTags: ['img'],
  imageInsertButtons: [],
  charCounterCount: true,
  colorsStep: 6,
  colorsText: [
    '#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
    '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000',
    '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF',
    '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', 'REMOVE',
    '#c25614', '#622715'
  ],

  toolbarButtons: [
    "paragraphFormat",
    "bold",
    "italic",
    "insertLink",
    "insert",
    "align",
    "clearFormatting",
    "insertTable",
    "formatOL",
    "formatUL",
    "textColor"
  ],
  htmlRemoveTags: ["script", "style", "base", "id"],
  tableEditButtons: [
    "tableRows",
    "tableColumns",
    "tableCells",
    "tableCellVerticalAlign",
    "tableRemove",
    "tableCellBackground",
    "tableHeader"
  ],
  tableInsertHelper: true,
  tableResizer: false,
  pasteDeniedAttrs: [
    "style",
    "script",
    "class",
    "id",
    "data",
    "width",
    "height",
    "background",
    "background-color"
  ],
  toolbarSticky: false,
  dragInline: true,
  tableInsertButtons: ["tableBack"],
  quickInsertTags: [""],

  paragraphStyles: {
    "fr-text-gray": "Gray",
    "fr-text-bordered": "Bordered",
    "fr-text-spaced": "Spaced",
    "fr-text-uppercase": "Uppercase"
  },

  linkInsertButtons: []
};
