import { mapWritableState } from "pinia/dist/pinia";
import { useStatsInfoStore } from "@/store/StatsInfoStore";
import { useEshopStore } from "@/store/EshopStore";

export default {
  inject: ['pageType'],

  computed: {
    ...mapWritableState(useStatsInfoStore, {
      product: "product",
      category: "category",
      blog: "blog",
    }),

    ...mapWritableState(useEshopStore, {
      eshop: "detail",
    }),

    expireTariff() {
      const { eshop, pageType } = this;
      const { platform } = eshop;
      const isShoptet = platform === 'shoptet';

      if(!pageType) return false;

      const { tariff } = eshop;

      const limits = {
        start: { 'product': isShoptet ? 100 : 10, 'blog': 10, 'category': 10 },
        profi: { 'product': isShoptet ? Infinity : 100, 'blog': isShoptet ? Infinity : 10, 'category': isShoptet ? Infinity :  10 },
        premium: { 'product': Infinity, 'blog': Infinity, 'category': Infinity }
      };

      return this[pageType].edited > limits[tariff][pageType]
    },
  },
};
