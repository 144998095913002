<template>
  <div class="user-block bg-body">
    <div class="container">
      <div class="row">
        <div class="col-8 m-auto vh-100 d-flex align-items-center">
          <div class="bg-white p-5 shadow-sm rounded-sm w-100">
            <h2 class="mb-2 text-center">
              {{ $t("Please update your addon") }}
            </h2>

            <p class="text-center mb-2">
              <mark> {{ $t("Follow these steps:") }} </mark>
            </p>

            <ol class="mt-4 fs-19">
              <li>{{ $t("Log in to the Shoptet administration") }}</li>
              <li>{{ $t("In the Add-ons section, confirm the Pobo Page Builder rights extension") }}</li>
              <li>{{ $t("Return to Pobo Page Builder and refresh the page (press F5)") }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExtensionRights",
};
</script>

<style scoped></style>
