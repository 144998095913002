<template>
  <div class="content-editor__languages-flags">
    <div
      v-for="(flag, index) in lang"
      :key="index"
    >
      <a
        :class="selectedLang === flag ? 'active' : ''"
        class="content-editor__languages-flag"
        href="#"
        @click.prevent="handleSelectLang(flag)"
      >
        <b-img-lazy
          :src="`https://pobo-cdn.b-cdn.net/flag/${flag}.svg`"
          class="content-editor__languages-flag-img"
        /></a>
    </div>
  </div>
</template>

<script>
import lang from "@/js/config/lang.js";

export default {
  name: "SwitchLang",

  data() {
    return {
      selectedLang: "default",
      lang,
    };
  },

  methods: {
    handleSelectLang(flag) {
      this.selectedLang = flag;
      this.$emit("select-lang", flag);
    },
  },
};
</script>