import axios from "axios";

export default class GeneratorModel {
  static async create(
    data,
    startCallback = () => {},
    endCallback = () => {},
  ) {
    startCallback();

    return await axios
      .post("/api/v2/cron/generator", data)
      .then((data) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn(
          "An error occurred when creating AI content [createAiContent()]",
          error
        );
        return error;
      });
  }

  static async findAll(
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/cron/generator")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn(
          "An error occurred when creating AI content [findAll()]",
          error
        );
        errorCallback(error);
        return error;
      });
  }
}
