import axios from "axios";
import Nanobar from "nanobar";
import Cookies from "js-cookie";

let nanoBar = new Nanobar({
  target: document.getElementById("js-progress-bar"),
});

axios.interceptors.response.use(response => {
  if (response.config.url.includes("/v2/")) {
    return { data: response.data.result || response.data };
  } else {
    return response;
  }
}, async (error) => {
  const ignoreRedirects = ['/api/v2/user/token/', '/api/v2/user/reset-password'];

  if (error.response.status === 401) {
    Cookies.remove("access_token");

    if (!ignoreRedirects.includes(error.config.url)) {
      window.location.href = '/';
    }
    console.warn('Error -  failed fetch endpoint: ', error.config.url);
  }
  return Promise.reject(error);
});
axios.interceptors.request.use(
  (config) => {
    const bar = document.getElementById("js-progress-bar");

    config.baseURL = import.meta.env.VITE_API_BASE_URL;

    config.headers["Referer"] = window.location.origin;

    if (bar && bar.getElementsByClassName("bar").length <= 1) {
      nanoBar.go(100);
    }

    if (config.url.startsWith("/api/v2/")) {
      const token = Cookies.get("access_token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      } else {
        console.warn("Token nebyl nalezen v cookies.");
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
)
