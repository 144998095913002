<template>
  <div
    class="confirm-delete"
    v-bind="$attrs"
  >
    <b-btn
      :disabled="disable"
      size="sm"
      class="w-100"
      variant="danger"
      data-cy="button-delete-item"
      @click="isOpen = true"
    >
      <slot name="trigger" />
    </b-btn>

    <div
      v-if="isOpen"
      class="confirm-delete__message"
    >
      <div class="confirm-delete__inner">
        <span class="confirm-delete__header">
          {{ $t("confirmDelete.header") }}</span>
        <b-container>
          <div class="d-flex">
            <div class="w-50 pr-1">
              <b-btn
                block
                size="sm"
                variant="danger"
                data-cy="confirm-delete-button"
                @click="$emit('delete', id)"
              >
                {{ $t("confirmDelete.delete") }}
              </b-btn>
            </div>
            <div class="w-50 pl-1">
              <b-btn
                block
                size="sm"
                variant="secondary"
                @click="isOpen = false"
              >
                {{ $t("confirmDelete.abort") }}
              </b-btn>
            </div>
          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmDelete",
  props: {
    id: {
      type: Number,
      require: true,
    },

    disable: {
      type: Boolean,
      require: false,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
