<template>
  <div class="text-center pt-4">
    <template v-if="activeExport">
      <b-spinner size="30" />
      <h4 class="mt-4 mb-0 pb-4">
        {{ $t("template.modalLoadExport.part1") }}
      </h4>
      <p class="text-center">
        {{ $t("template.modalLoadExport.part2") }}
      </p>
      <b-progress
        :max="limit"
        :value="reaming"
        animated
        class="mt-4"
        height="10px"
        variant="secondary"
      />
      <small class="d-block text-center text-muted mt-2">
        {{ $t("template.modalLoadExport.part3") }}
        {{ limit + 1 - Math.ceil(reaming) }}
        {{ $t("template.modalLoadExport.part4") }}
      </small>
    </template>
    <template v-else>
      <b-icon
        icon="arrow-up-circle"
        size="40"
      />
      <p class="text-center">
        {{ $t("Kliknutím nahrajete obsah do e-shopu.") }}
      </p>
    </template>
  </div>

  <b-btn
    block
    variant="secondary"
    @click="runExport"
  >
    {{ $t("Export content to the e-shop") }}
  </b-btn>
</template>

<script>
import ExportModel from "@/model/ExportModel.js";
const LIMIT = 10;

export default {
  name: "ModalExtendExport",

  props: {
    content: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      require: true,
      default: "product", // product, category, blog
    },
  },


  data() {
    return {
      url: null,
      reaming: 0,
      activeExport: false
    };
  },

  computed: {
    limit() {
      return LIMIT;
    },
  },

  methods: {
    async runExport() {
      const { content, limit, type } = this;
      const { id } = content;

      setInterval(async () => {
        if (this.reaming < limit) {
          this.reaming = this.reaming + 0.1;
        }
      }, 100);

      const request = {
        type: type,
        data: {
          id: id,
        },
      };

      await ExportModel.uploadMe(
        request,
        () => {},
        (data) => {
          const { result, url } = data;

          if (result) {
            const opened = url + "#pobo-all-content";

            let openedWindow = window.open('', opened);

            if (openedWindow && !openedWindow.closed) {
              openedWindow.close();
            }

            openedWindow = window.open(opened, opened);

            this.$emit("close");
          }
        }
      );
    }
  }
};
</script>

<style scoped></style>
