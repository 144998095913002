<template>
  <div class="text-center pt-4">
    <template v-if="!cron.is_complete">
      <b-spinner
        label="Small Spinner"
        small
        style="width: 40px; height: 40px"
      />

      <h4 class="mt-4 mb-0 pb-4">
        {{ $t("template.modalLoad.part1") }}
      </h4>

      <p class="text-center">
        {{ $t("template.modalLoad.part2") }}
      </p>

      <div class="px-2">
        <b-progress
          :max="60"
          :value="reaming"
          class="mb-3"
          variant="secondary"
        />
        <small class="d-block text-center text-muted mt-1">
          {{ $t("template.modalLoad.part3") }} {{ reaming }}s
        </small>
      </div>
    </template>
    <template v-else>
      <h4 class="mt-4 mb-0 pb-4">
        {{ $t("template.modalLoad.part4") }}
      </h4>

      <p class="text-center">
        {{ $t("template.modalLoad.part5") }}
      </p>

      <div class="row mt-4">
        <div class="col-5">
          <button
            class="btn btn-danger btn-block"
            @click="$emit('close')"
          >
            {{ $t("template.modalLoad.part6") }}
          </button>
        </div>
        <div class="col-7">
          <button
            class="btn btn-secondary btn-block"
            @click="handleOpenDebug"
          >
            {{ $t("template.modalLoad.part7") }}
            <i class="ico ico-arrow-right" />
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ProjectorModel from "@/model/ProjectorModel.js";

export default {
  name: "form-adaptation",

  props: {
    template: {
      type: Object,
      required: true,
    },
  },

  async mounted() {
    await this.actionCreate();

    const { cron } = this;
    const { hash } = cron;

    setInterval(async () => {
      if (!hash) {
        await this.actionRead();
      }
    }, 10000);

    setInterval(() => {
      if (this.reaming > 0) this.reaming = this.reaming - 1;
    }, 1000);
  },

  data() {
    return {
      isOpenDebug: false,
      cron: Object,
      reaming: 60,
    };
  },

  methods: {
    handleOpenDebug() {
      const { cron } = this;
      const { product } = cron;
      const { debug } = product;
      this.isOpenDebug = true;

      window.open(debug);

      window.addEventListener("message", (event) => {
        const { data } = event;

        if (typeof data === "object") {
          const { hash } = data;

          if (hash) {
            this.$emit("save", data);
          }
        }
      });
    },

    async actionRead() {
      await ProjectorModel.findAll(
        () => {},
        (data) => {
          this.cron = data;
        }
      );
    },

    async actionCreate() {
      const { template } = this;

      const { id } = template;

      await ProjectorModel.create(
        { template_id: id },
        () => {},
        (data) => {
          this.cron = data;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
