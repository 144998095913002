<template>
  <layout-setting>
    <template #header>
      {{ $t("First steps with Pobo Page Builder") }}
    </template>

    <template #content>
      <b-row>
        <b-col cols="7">
          <div class="pr-5">
            <h5 class="mb-4">
              1. {{ $t("Create a test product, collection and blog article") }}
            </h5>

            <p class="fs-16 lh-26 font-weight-light">
              {{ $t(`In the first step, we'll create a test product, a collection, and a blog post to test the look and feel of the content.`) }}
            </p>

            <p class="fs-16 lh-26 font-weight-light">
              {{ $t(`Please watch a short video in which Sara will guide you through the whole process.`) }}
            </p>


            <b-button-group class="mt-4">
              <b-button
                variant="secondary"
                target="_blank"
                @click="openProduct"
              >
                {{ $t("Create product") }}
              </b-button>
              <b-button
                variant="secondary"
                target="_blank"
                @click="openCollection"
              >
                {{ $t("Create collection") }}
              </b-button>
              <b-button
                variant="secondary"
                target="_blank"
                @click="openArticle"
              >
                {{ $t("Create blog post") }}
              </b-button>
            </b-button-group>
          </div>
        </b-col>

        <b-col cols="5">
          <b-embed
            type="iframe"
            aspect="16by9"
            class="routed"
            src="https://www.youtube.com/embed/xhYnsphVgrY"
            allowfullscreen
          />
        </b-col>
      </b-row>
    </template>
  </layout-setting>

  <layout-setting>
    <template #content>
      <b-row>
        <b-col cols="7">
          <div class="pr-5">
            <h5 class="mb-4">
              2. {{ $t("Edit your labels in Pobo Page Builder") }}
            </h5>

            <p class="fs-16 lh-26 font-weight-light">
              {{ $t('In the second step, please edit the descriptions of the created product, collection and article in Pobo Page Builder.') }}
            </p>

            <p class="fs-16 lh-26 font-weight-light">
              {{ $t('Finally, we upload the labels back to the e-shop and move on to the last step.') }}
            </p>
          </div>
        </b-col>

        <b-col cols="5">
          <b-embed
            type="iframe"
            aspect="16by9"
            class="routed"
            src="https://www.youtube.com/embed/_bRAqCfcpok"
            allowfullscreen
          />
        </b-col>
      </b-row>
    </template>
  </layout-setting>

  <layout-setting>
    <template #content>
      <b-row>
        <b-col cols="7">
          <div class="pr-5">
            <h5 class="mb-4">
              3. {{ $t("Add visual add-on blocks to your e-shop template") }}
            </h5>

            <p class="fs-16 lh-26 font-weight-light">
              {{ $t('In the last step, we insert the Pobo Page Builder blocks into the e-shop template to display the product, collection or article descriptions correctly.') }}
            </p>

            <p class="fs-16 lh-26 font-weight-light">
              {{ $t('Please watch the video in which Sara shows you how to insert the blocks into the template.') }}
            </p>

            <b-button
              variant="secondary"
              target="_blank"
              @click="openTheme"
            >
              {{ $t("Open e-shop template settings") }}
            </b-button>
          </div>
        </b-col>

        <b-col cols="5">
          <b-embed
            type="iframe"
            aspect="16by9"
            class="routed"
            src="https://www.youtube.com/embed/sWrD_v0jElo"
            allowfullscreen
          />
        </b-col>
      </b-row>
    </template>
  </layout-setting>
</template>
<script>
import eshopData from "@/mixin/eshopData";
export default {
  name: "installation-guide",

  mixins: [eshopData],

  computed: {
    humanUrl() {
      const { eshopUrl } = this;
      const url = new URL(eshopUrl);
      return url.host.split('.').slice(0, -2).join('.')
    }
  },

  methods: {
    openProduct() {
      const { humanUrl } = this;
      window.open(`https://admin.shopify.com/store/${humanUrl}/products/new`, "_blank")
    },

    openCollection() {
      const { humanUrl } = this;
      window.open(`https://admin.shopify.com/store/${humanUrl}/collections/new`, "_blank")
    },

    openArticle() {
      const { humanUrl } = this;
      window.open(`https://admin.shopify.com/store/${humanUrl}/articles/new`, "_blank")
    },

    openTheme() {
      const { humanUrl } = this;
      window.open(`https://admin.shopify.com/store/${humanUrl}/themes`, "_blank")
    }
  }
}
</script>