import { defineStore } from "pinia";
import EshopModel from "@/model/EshopModel";
import { loadTemplateCss, loadStyle } from "@/function/template";
import { flashMessageShowSuccess, flashMessageShowWait } from "@/function/flashMessage";
const light = document.getElementById('light-theme');
const dark = document.getElementById('dark-theme');
import customConfig from '../../customConfig.json'

export const useEshopStore = defineStore("EshopStore", {
  state: () => {
    return {
      form: {
        template_id: 0,
        export_mode: 1,
        lang: "cs",
        ai_lang: "cs",
        editor_width: 980,
        editor_lang: "cs",
        confirm_guide: 1,
        allow_lang: 1,
        enable_marketplace: 0,
        enable_dark: 0,
        enable_fullscreen: 0,
        notification: null,
        lang_list: [],
        design_id: null,
        config: {},
        template_mode: "standalone",
      },

      detail: {
        id: null,
        url: "",
        humanUrl: "",
        role: [],
        email: "",
        platform: "shoptet",
        region: "cz",
        force_disable: 0,
        export_mode: 2,
        lang: "cs",
        ai_lang: "cs",
        enable_analytics: 1,
        template_id: null,
        design_id: null,
        editor_width: 1000,
        editor_lang: "cs",
        is_premium: 0,
        is_disable: 0,
        allow_lang: 1,
        tariff: "start",
        lang_list: [],
        confirm_guide: 1,
        notification: [],
        enable_marketplace: 0,
        enable_fullscreen: false,
        enable_dark: 0,
        config: {},
        template_css: "",
        available_eshop: [],
        template_mode: "standalone",
        expire_tariff: false,
      },
    };
  },

  getters: {
    getEshop: (state) => () => {
      return state.detail;
    },
  },

  actions: {
    async fetchEshop() {
      await EshopModel.findMe(
        () => {},
        (data) => {
          this.detail = data;
          for (const key in data) {
            if (this.form.hasOwnProperty(key)) {
              this.form[key] = data[key];
            }
          }

          if(data.template_mode === 'generic') {
            console.log('%c Inject generic CSS template', 'background: #222; color: #bada55')
            loadStyle('https://image.pobo.cz/assets/generic.css')
          } else {
            console.log('%c Inject generic CSS template', 'background: #222; color: #bada55')
            loadStyle('https://image.pobo.cz/assets/editor.css')
          }

          loadTemplateCss(data.template_css);
        }
      );
    },

    async updateEshop(params = {}) {
      const options = {
        data: params.data || {},
        flash: params.flash || {
          title: "Well done!",
          text: "Eshop has been updated.",
          confirmButtonText: "Done!",
        },
      };


      return await EshopModel.saveMe(
        options.data,
        () => {},
        (data) => {
          flashMessageShowSuccess({
            title: options.flash.title,
            text: options.flash.text,
            confirmButtonText: options.flash.confirmButtonText,
          });
          this.detail = data;

          for (const key in data) {
            if (this.form.hasOwnProperty(key)) {
              // this.form[key] = data[key];
            }
          }
        },
        (error) => {
          return error;
        }
      );
    },
  },
});
