<template>
  <div class="editor-modal__header-menu">
    <ul class="content-editor__menu">
      <li>
        <button
          :disabled="!isValidCode"
          class="content-editor__menu-btn content-editor__menu-btn--save"
          data-cy="product-detail-save"
          @click="$emit('handle-save')"
        >
          {{ $t("product.panel.save") }}
        </button>
      </li>

      <li>
        <button
          class="content-editor__menu-btn content-editor__menu-btn--close"
          data-cy="product-detail-close"
          @click="$emit('handle-close')"
        >
          {{ $t("product.panel.close") }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ModalMenu",

  props: {
    isValidCode: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      active: "editor",
    };
  },
};
</script>
