<template>
  <b-form @submit.prevent="submitForm">
    <b-form-group
      label="E-mail"
      label-for="login-email"
    >
      <b-form-input
        id="login-email"
        v-model="email"
        maxlength="65"
        name="email"
        type="email"
        autocomplete="email"
      />
    </b-form-group>
    <b-form-group
      :label="$t('Password')"
      label-for="login-password"
    >
      <b-form-input
        id="login-password"
        v-model="password"
        maxlength="65"
        name="password"
        type="password"
      />
    </b-form-group>
    <b-button
      block
      type="submit"
      :disabled="disabled"
      variant="secondary"
    >
      {{ $t('Login me') }}
    </b-button>
  </b-form>
</template>

<script>

export default {
  name: "LoginForm",

  props:{
    disabled:{
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async submitForm() {
      const {email, password } = this;

      this.$emit('submit', {email, password})
    },
  },
};
</script>
