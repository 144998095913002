<template>
  <b-form @submit.prevent="actionUpdate">
    <p class="text-sm mb-3">
      {{ $t("customize.info.editor_width") }}
    </p>

    <b-form-input
      v-model="formData.editor_width"
      class=" mt-4"
      max="1200"
      min="756"
      type="range"
    />


    <div class="d-block text-black mb-2">
      {{ $t("customize.form.editor_width") }} -
      <strong class="text-dark">{{ formData.editor_width }}px</strong>
    </div>

    <div class="mb-3 pb-3">
      <b-button
        type="submit"
        variant="secondary"
        class="mt-1 mb-1"
      >
        {{ $t("Save editor width") }}
      </b-button>
    </div>

    <editor-skeleton :form-data="formData">
      <template #calculator />
    </editor-skeleton>
  </b-form>
</template>
<script>
import eshopStore from "@/mixin/eshopStore.js";
import EditorSkeleton from "@/components/setting/part/editor-skeleton.vue";

export default {
  name: "Customize",
  displayName: "Customize",

  mixins: [eshopStore],

  components: {
    "editor-skeleton": EditorSkeleton,
  }
};
</script>