<template>
  <div>
    <b-card title="Widgety a komponenty">
      <b-btn-group>
        <b-btn
          variant="secondary"
          @click="isOpenCreate = true"
        >
          Vytvořit widget
        </b-btn>
        <b-btn
          variant="secondary"
          @click="isOpenCategory = true"
        >
          Vytvořit kategorii
        </b-btn>
      </b-btn-group>
    </b-card>

    <b-modal
      v-model="isOpenCategory"
      size="handler"
      title="Vytvoření kategorie widgetu"
      hide-footer
    >
      <widget-category
        :id="id"
        @update="handleUpdate"
      />
    </b-modal>

    <b-modal
      v-model="isOpenConnectWidget"
      size="md"
      title="Propojení widgetu s e-shopem"
      hide-footer
      no-fade
    >
      <widget-connect
        :widget="selectWidget"
        @update="handleUpdate"
      />
    </b-modal>

    <b-modal
      v-model="isOpenElement"
      size="fullscreen"
      content-class="editor-fullscreen-content"
      title="Správa elementů ve widgetu"
      hide-footer
      no-fade
    >
      <widget-element
        :id="id"
        @update="handleUpdate"
      />
    </b-modal>

    <b-modal
      v-model="isOpenCreate"
      size="ai"
      title="Vytvoření / úprava widgetu"
      hide-footer
      no-fade
    >
      <widget-detail
        :id="id"
        @handle-close="handleRefresh"
      />
    </b-modal>

    <b-modal
      v-model="isOpenUpload"
      size="lg"
      title="Nahrání HTML widgetu"
      hide-footer
      no-fade
    >
      <widget-upload
        :id="id"
        @handle-close="handleRefresh"
      />
    </b-modal>

    <h5 class="mt-5 mb-4">
      Seznam widgetů
    </h5>
    <table
      v-if="list.length > 0"
      class="table border"
    >
      <tbody class="bg-white">
        <tr class="font-weight-bold">
          <td>Informace o widgetu</td>
          <td>Název widgetu</td>
          <td
            colspan="4"
            class="text-right"
          >
            <div class="custom-control custom-switch">
              <input
                id="showAllWidget"
                v-model="enableMove"
                type="checkbox"
                class="custom-control-input"
              >
              <label
                class="custom-control-label"
                for="showAllWidget"
              >Povolit přesun kategorií</label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <draggable
      v-model="list"
      :group="{ name: 'people'}"
      :animation="500"
      :ghost-class="'ghost'"
      :disabled="false"
      :handle="'.btn-move'"
      tag="div"
      @change="actionUpdate()"
    >
      <template #item="{ element }">
        <div
          class="mb-4 bg-white shadow-sm mb-2 rounded"
        >
          <table
            :key="element.id"
            class="table"
          >
            <thead class="bg-white">
              <tr>
                <td colspan="2">
                  <i
                    :class="['ico', element.ico]"
                    style="margin: 0 2px -4px 0px"
                  />
                  <strong>{{ element.name }}</strong>
                  <small class="text-muted d-block">
                    (pořadí: {{ element.position }})
                  </small>
                </td>
                <td class="text-right">
                  <button
                    v-if="!enableMove"
                    class="btn btn-secondary btn-sm"
                    @click.prevent="
                      handleEditCategory(category)
                    "
                  >
                    <i class="ico ico ico-edit" /> Upravit
                    kategorii
                  </button>

                  <button
                    v-if="enableMove"
                    class="btn btn-secondary btn-sm btn-move"
                  >
                    <i class="ico ico-component-drag" />
                  </button>
                </td>
              </tr>
            </thead>

            <tbody>
              <template v-if="!enableMove">
                <tr
                  v-for="item in element.widget"
                  :key="item.id"
                  :class="[
                    !item.public
                      ? 'table-danger'
                      : 'table-secondary',
                  ]"
                >
                  <td>
                    <b-row align-v="center">
                      <b-col cols="1">
                        #{{ item.id }}
                      </b-col>
                      <b-col cols="2">
                        <b-form-group
                          id="position"
                          label="Pořadí"
                          size="sm"
                        >
                          <!-- todo v-model poradi -->
                          <b-form-input
                            id="position"
                            type="number"
                            :value="
                              item.position
                                ? item.position
                                : 0
                            "
                            required
                            size="sm"
                            @blur="
                              actionUpdateWidgetPosition(
                                item,
                                $event
                              )
                            "
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="3">
                        <div class="bg-white p-2 shadow-sm dark-color-text">
                          <template
                            v-if="item.html_preview"
                          >
                            <div
                              style="
                                                            zoom: 20%;
                                                            height: 400px;
                                                        "
                              class="overflow-auto"
                              v-html="
                                item.html_preview
                              "
                            />
                          </template>
                          <template v-else>
                            <small
                              class="text-center pt-3 text-muted w-100 d-block"
                              style="height: 80px"
                            >
                              Náhledový HTML kód není
                              k dispozici
                            </small>
                          </template>
                        </div>
                      </b-col>
                      <b-col cols="5">
                        <strong>{{ item.name }}</strong>

                        <template
                          v-if="item.users.length > 0"
                        >
                          <small
                            v-for="user in item.users"
                            :key="user.id"
                            class="text-black-50 pt-1"
                          >
                            {{ user.email }} ({{
                              user.url
                            }}) |
                          </small>
                        </template>
                        <template v-else>
                          <small
                            class="d-block text-black-50 pt-1"
                          >
                            Widget je veřejně přístupný
                            všem
                          </small>
                        </template>
                        <p>
                          <small
                            class="text-black-50"
                            style="cursor: pointer"
                            @click.prevent="
                              openConnect(item)
                            "
                          >
                            <i
                              class="ico ico-connect"
                            />
                            Propojení
                          </small>
                        </p>
                      </b-col>
                    </b-row>
                  </td>
                  <td style="width: 250px">
                    <small class="d-block text-muted">
                      <i
                        :class="[
                          'ico',
                          item.enable_typography_css
                            ? 'ico-success'
                            : 'ico-cancel',
                          'mr-1',
                        ]"
                      />
                      {{
                        item.enable_typography_css
                          ? "Povoleno stylovvání typografie"
                          : "Stylování typografie vypnuto"
                      }}
                    </small>
                    <small class="d-block text-muted mt-1">
                      <i
                        :class="[
                          'ico',
                          item.public
                            ? 'ico-success'
                            : 'ico-cancel',
                          'mr-1',
                        ]"
                      />
                      {{
                        item.public
                          ? "Widget je veřejný"
                          : "Widget není veřejný"
                      }}
                    </small>

                    <small class="d-block text-muted mt-1">
                      <i
                        :class="[
                          'ico',
                          item.enable_gallery
                            ? 'ico-success'
                            : 'ico-cancel',
                          'mr-1',
                        ]"
                      />
                      {{
                        item.enable_gallery
                          ? "Povolena galerie"
                          : "Vypnutá galerie"
                      }}
                    </small>
                  </td>
                  <td class="text-right">
                    <b-btn-group
                      size="sm"
                      vertical
                    >
                      <b-btn
                        v-if="false"
                        variant="secondary"
                        @click.prevent="
                          handleCopyWidget(item)
                        "
                      >
                        Kopírovat
                      </b-btn>
                      <b-btn
                        variant="secondary"
                        size="sm"
                        @click.prevent="
                          handleUploadWidget(item)
                        "
                      >
                        Nahrát widget
                      </b-btn>

                      <b-btn
                        size="sm"
                        variant="secondary"
                        @click.prevent="
                          handleEditElement(item)
                        "
                      >
                        Elementy
                      </b-btn>

                      <b-btn
                        size="sm"
                        variant="secondary"
                        @click.prevent="
                          handleEditWidget(item)
                        "
                      >
                        Upravit
                      </b-btn>
                    </b-btn-group>
                    <small
                      v-if="item.is_deleted"
                      class="d-block text-danger pt-1"
                    >
                      Widget je označený jako smazaný
                    </small>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td
                    colspan="7"
                    class="text-muted text-center small"
                  >
                    V kategorii se nenachází žádné widgety
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import WidgetElement from "@/components/component/part/widget-element.vue";
import WidgetDetail from "@/components/component/part/widget-detail.vue";
import WidgetCategory from "@/components/component/part/widget-category.vue";
import WidgetUpload from "@/components/component/part/widget-upload.vue";
import Draggable from "vuedraggable";
import WidgetModel from "@/model/WidgetModel.js";
import WidgetCategoryModel from "@/model/WidgetCategoryModel.js";
import WidgetConnect from "@/components/component/part/widget-connect.vue";

export default {

  mounted() {
    this.actionRead();
  },

  data(){
    return {
      inputHTML: ``,
      isOpenCategory: false,
      isOpenConnectWidget: false,
      isOpenDetail: false,
      isOpenCreate: false,
      isOpenElement: false,
      isOpenUpload: false,
      list: [],
      id: null,
      widgetElement: [],
      enableMove: false,
      selectWidget: null,
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 500,
        group: "people",
        ghostClass: "ghost",
        disabled: false,
        handle: ".btn-move",
      };
    },
  },
  components: {
    "widget-element": WidgetElement,
    "widget-detail": WidgetDetail,
    "widget-category": WidgetCategory,
    "widget-upload": WidgetUpload,
    "widget-connect": WidgetConnect,
    draggable: Draggable,
  },

  methods: {
    async actionUpdateWidgetPosition(item, $event) {
      const test = $event.target.value;
      const data = item;

      data["position"] = test;

      await WidgetModel.saveMe(
        data.id,
        data,
        () => {},
        () => {}
      );

      await this.actionRead();
    },

    async openConnect(item) {
      this.selectWidget = item;
      if (item != null) {
        this.isOpenConnectWidget = true;
      }
    },
    async actionUpdate() {
      const { list } = this;

      let position = [];

      Array.from(list).forEach(({ id }, index) => {
        position.push({
          id: id,
          position: index,
        });
      });

      await WidgetCategoryModel.saveCategoryList(
        { category: position },
        () => {},
        (data) => {
          this.list = data;
        }
      );
    },

    async handleRefresh(data) {
      await this.handleUpdate();
    },

    async handleUpdate(){
      this.isOpenCategory = false;
      this.isOpenDetail = false;
      this.isOpenCreate = false;
      this.isOpenElement = false;
      this.isOpenUpload = false;
      this.id = null;

      await this.actionRead();
    },

    async actionRead(){
      await WidgetModel.findAll(
        () => {},
        (data) => {
          this.list = data;
        }
      );
    },

    async handleUploadWidget(widget) {
      this.id = widget.id;
      this.isOpenUpload = true;
    },

    async handleEditElement(widget) {
      this.id = widget.id;
      this.isOpenElement = true;
    },

    async handleEditWidget(widget) {
      this.id = widget.id;
      this.isOpenCreate = true;
    },

    async handleEditCategory(category) {
      this.id = category.id;
      this.isOpenCategory = true;
    },

    async handleCopyWidget(widget) {
      await WidgetModel.copyMe(
        widget.id,
        () => {},
        () => {
          this.actionRead();
        }
      );
    },
  },
};
</script>
