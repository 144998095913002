<script>
export default {
  name: "Tag",
  functional: true,

  props: {
    tag: {
      type: Object,
      required: true
    }
  },

  render(h, context) {
    return h(context.props.tag, context.data, context.children);
  },
};
</script>
