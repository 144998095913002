<template>
  <form
    class="editor-bg"
    @submit.prevent="actionUpdate"
  >
    <b-form-group :label="$t('share.page.form.title')">
      <b-form-input
        v-model="model.name"
        :placeholder="$t('share.page.form.placeholder')"
      />
    </b-form-group>

    <b-btn
      :disabled="!model.name"
      block
      type="submit"
      variant="secondary"
    >
      {{ $t("share.page.form.save") }}
    </b-btn>
  </form>
</template>

<script>
import ShareModel from "@/model/ShareModel.js";
import { showMessage } from "@/plugins/flashMessage";

export default {
  name: "ModalEdit",

  props: {
    share: {
      type: Object,
      required: true,
    },
  },

  data() {
    const { share } = this;
    return {
      model: {
        name: share.name,
      },
    };
  },

  methods: {
    async actionUpdate() {
      const { model, share } = this;

      await ShareModel.save(
        share.id,
        {
          name: model.name,
        },
        () => {},
        () => {
          this.model.name = model.name;
          showMessage("flash.share.name");
          this.$emit('update');
        }
      );
    },
  },
};
</script>

<style scoped></style>
