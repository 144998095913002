import hotkeys from "hotkeys-js";

export default {
  mounted() {
    this.keyboardShortcuts();
  },

  unmounted() {
    hotkeys.unbind();
  },

  methods:{
    keyboardShortcuts(){
      const self = this;

      hotkeys('esc', function(event){
        event.preventDefault();
        self.$emit('close-modal');
      });

      hotkeys('cmd+s, ctrl+s', function(event){
        event.preventDefault();
        self.confirmModal(true);
      });

    },
  },
}
