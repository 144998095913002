import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-secondary",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

export const flashMessageShowSuccess = async (options) => {
  await swalWithBootstrapButtons.fire({
    title: options.title,
    text: options.text,
    showCancelButton: false,
    icon: "success",
    // autoclose after 3s
    timer: 3000,
  });
};

export const flashMessageShowWait = async (options) => {
  await swalWithBootstrapButtons.fire({
    title: options.title,
    text: options.text,
    showCancelButton: false,
    icon: "loading",
    timer: 4000,
  });
};

// todo make error message
