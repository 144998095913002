import { defineStore } from 'pinia';
import LockedModel from "@/model/LockedModel";

export const useLockedStore = defineStore('lockedStore', {
  state: () => ({
    isLocked: false
  }),

  actions: {
    async check() {
      await LockedModel.fetchLock().then((data) => {
        this.isLocked = false; // data.result;
      })
    },
  },
});
