<template>
  <div>
    <h2 class="my-4 p-0">
      {{ $t("guide.designer.heading") }}
    </h2>

    <p class="mt-4">
      {{ $t("guide.designer.descOne") }}
      <strong>{{ $t("guide.designer.descOneStrong") }}</strong>
      {{ $t("guide.designer.descTwo") }}
      <strong>{{ $t("guide.designer.descTwoStrong") }}</strong>
    </p>

    <p class="mt-4">
      <strong>{{ $t("guide.designer.descThreeStrong") }}</strong>
      {{ $t("guide.designer.descThree") }}
    </p>
    <template v-if="!isSubmit">
      <button
        class="btn btn-secondary"
        @click="actionCreate"
      >
        {{ $t("guide.designer.button") }} <i class="ico ico-arrow-right" />
      </button>
    </template>

    <template v-else>
      <b-modal
        v-model="isSubmit"
        :title="$t('guide.designer.bmodal')"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        no-fade
        size="xs"
      >
        <form-projector
          :template="template"
          @close="handleClose"
          @save="actionUpdate"
        />
      </b-modal>
    </template>
  </div>
</template>

<script>
import FormProjector from "@/components/account/form-projector.vue";
import AdaptationModel from "@/model/AdaptationModel.js";
import ProjectorModel from "@/model/ProjectorModel.js";

export default {
  name: "designer",

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      template: null,
      isSubmit: false,
    };
  },

  components: {
    "form-projector": FormProjector,
  },

  methods: {
    handleClose() {
      this.isSubmit = false;
    },

    async actionCreate() {
      const { product } = this;
      const { id } = product;

      await AdaptationModel.create(
        {
          name: "Vzhled vytvořený přes průvodce nastavení vzhledu",
          product_id: id,
        },
        () => {},
        (data) => {
          this.isSubmit = true;
          this.template = data;
        }
      );
    },

    async actionUpdate(data) {
      await ProjectorModel.saveMe(
        data,
        () => {},
        () => {
          this.$emit("update-step", 6);
        }
      );
    },
  },
};
</script>
