<template>
  <div
    v-if="loaded && allowAi"
    class="multiple-copy-ai d-flex"
  >
    <div class="w-25 multiple-copy-ai__select">
      <small class="fs-13"> {{ $t("Select a product:") }} </small>
      <div
        v-for="(product, index) in products"
        :key="index"
        :class="{'multiple-copy-ai__product--active' : isSelectedProduct(product)}"
        class="multiple-copy-ai__product mt-2 mb-2 p-2 d-flex align-items-center"
        :disabled="!product.is_loaded"
        @click.prevent="selectedProduct = product"
      >
        <div
          v-if="product.is_loaded"
          class=" d-flex justify-content-center align-items-center"
        >
          <i
            class="ico ico-checked  m-0"
          />
        </div>
        <div
          v-else
          class="multiple-copy-ai__product-ico-time d-flex justify-content-center align-items-center"
        >
          <i
            class="ico ico-time m-0"
          />
        </div>
        <div class="multiple-copy-ai__product-name">
          <h2 class="fs-13 mb-0">
            {{ shortName(product.name, 28) }}
          </h2>
          <p
            v-if="product.is_loaded && !product.formData.short_description || !product.formData.parameters"
            class="m-0 p-0 fs-10 text-danger"
          >
            {{ $t("Fill in the information") }}
          </p>
        </div>
      </div>
    </div>

    <b-form
      v-if="selectedProduct"
      class="w-75 pl-3 multiple-copy-ai__setting"
      @submit.prevent="actionCreateAll()"
    >
      <b-form-group
        id="name"
        :label="$t('product.name')"
        size="sm"
      >
        <b-form-input
          id="name"
          v-model="selectedProduct.formData.name"
          required
          size="sm"
          type="text"
        />

        <small
          class="text-danger d-block"
        />
      </b-form-group>

      <b-row>
        <b-col cols="6">
          <b-form-group
            id="content"
            :label="$t('product.shortDescription')"
            size="sm"
          >
            <b-form-textarea
              id="content"
              v-model="selectedProduct.formData.short_description"
              rows="3"
              size="sm"
            />

            <small
              class="text-danger d-block"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            id="parameters"
            :label="$t('product.parameters')"
            size="sm"
          >
            <b-form-textarea
              id="parameters"
              v-model="selectedProduct.formData.parameters"
              rows="3"
              size="sm"
            />

            <small
              class="text-danger d-block"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group
        :description="$t('translation.optional.widget.help')"
      >
        <b-form-checkbox
          v-model="selectedProduct.formData.remove_exist_widget"
          class="mb-0"
        >
          {{ $t("translation.optional.widget.info") }}
        </b-form-checkbox>
      </b-form-group>

      <h2 class="fs-20 text-center mt-4 mb-4 pb-2 pt-2">
        {{ $t("Select widget layout") }}
      </h2>

      <template v-if="selectedProduct">
        <div id="preview-widget-container">
          <b-row>
            <b-col
              v-for="(detail, index) in selectedProduct.widget"
              :key="index"
              cols="6"
            >
              <span
                class="font-weight-bolder fs-17 lh-22 d-block mb-4 pr-5"
              >
                {{ $t("translation.variant." + index) }}
              </span>
            </b-col>
          </b-row>
          <b-row
            class="bg-white rounded-lg shadow-sm"
            no-gutters
          >
            <b-col
              v-for="(detail, index) in selectedProduct.widget"
              :key="index"
              cols="6"
            >
              <a
                :class="[
                  selectedProduct.layout === detail.id
                    ? 'active'
                    : '',
                  'template-trigger p-4 rounded d-block',
                ]"
                @click.prevent="selectedProduct.layout = detail.id"
              >
                <div
                  v-for="widget in detail.list"
                  :key="widget.id"
                >
                  <div
                    class="preview-widget pb-4"
                    v-html="widget.html_preview"
                  />
                </div>
              </a>
            </b-col>
          </b-row>
        </div>
      </template>
      <b-form-group class="mt-4 multiple-copy-ai__submit mb-0">
        <b-btn
          block
          variant="secondary"
          :disabled="!allProductsLoaded || !allProductsValid"
          type="submit"
        >
          {{ $t("Create a bulk label via AI") }}
        </b-btn>
        <small class="text-center fs-11 d-block mt-2">
          <b-icon
            icon="stopwatch"
            class="mr-1"
            size="10"
          />
          {{ $t("Estimated duration") }} {{ products.length * 19 }}s. {{ $t("You will be charged") }} {{ credit }} {{ $t('credits.five') }}.</small>
      </b-form-group>
    </b-form>
  </div>

  <template v-if="!allowAi">
    <warning-low-credits
      :price="5"
      image-type="michal"
      :show="!allowAi"
    />

    <div class="row text-center align-items-baseline">
      <div class="col-4">
        <b-skeleton-img
          height="300px"
          no-aspect
          width="100%"
        />
      </div>
      <div class="col-8">
        <b-skeleton-img
          height="300px"
          no-aspect
          width="100%"
        />
      </div>
    </div>
  </template>
</template>

<script>
import ProductModel from "@/model/ProductModel";
import GeneratorModel from "@/model/GeneratorModel";
import {showMessage} from "@/plugins/flashMessage";
import {useGeneratorStore} from "@/store/GeneratorStore";
import WarningLowCredits from "@/share/warning-low-credits.vue";
import { useCreditStore } from "@/store/CreditStore";

export default{
  name: "multiple-copy-ai",

  props: {
    products: {
      type: Array,
      required: true,
    },
    allowAi: {
      type: Boolean,
      required: true,
    },
    credit:{
      type: Number,
      required: true,
    }
  },

  async mounted(){
    await useCreditStore().findHistory();


    if(this.allowAi){
      await this.dataPreparation();

      for (let index = 0; index < this.products.length; index++) {
        await this.fetchProducts(this.products[index]);
        if (index === 0) {
          this.selectedProduct = this.products[0];
          this.loaded = true;
        }
      }

      this.allProductsLoaded = true;
    }
  },

  data() {
    return {
      widget: [],
      layout: '',
      allProductsLoaded: false,
      selectedProduct: null,
      loaded: false,
    }
  },

  computed: {
    allProductsValid(){
      for (let product of this.products) {
        if (!product.formData.short_description || !product.formData.parameters.length) {
          return false;
        }
      }
      return true;
    },
  },
  components: {"warning-low-credits" : WarningLowCredits},

  methods:{
    isSelectedProduct(product){
      if(this.selectedProduct && product){
        return this.selectedProduct.id === product.id
      }else{
        return false;
      }
    },
    async dataPreparation(){
      if(this.products.length > 0){
        this.products.forEach(product => {
          product.formData = {
            name: product.name,
            short_description: '',
            remove_exist_widget: true,
            parameters: [],
          };
          product.layout = null;
          product.widget = null;
        });
      }
    },

    async fetchProducts(item){
      await ProductModel.findCurrent(
        item.id,
        () => {},
        (data) => {
          const { product, widget } = data;
          const formData = {
            name: product.name,
            short_description: '',
            remove_exist_widget: true,
            parameters: [],
          };

          formData.name = product.name.trim();
          formData.short_description = product.short_description
            ? product.short_description
              .replace(/<[^>]+>|&nbsp;/g, " ")
              .replace(/\s+/g, " ")
              .trim()
            : '';

          // Fill widget and layout
          item.widget = widget;
          item.layout = widget[0].id;

          const remappingParameter = [];

          if (product.parameters) {
            product.parameters.map(({ name, value }) => {
              const formattedName = (
                name.charAt(0).toUpperCase() + name.slice(1)
              )
                .trim()
                .replace(/:$/, "");
              const formattedValue = value
                .replace(/\s+/g, " ")
                .trim();
              remappingParameter.push(
                `${formattedName}: ${formattedValue}`
              );
            });
          }

          formData.parameters = remappingParameter.length > 0
            ? remappingParameter.join(", ")
            : "";

          item.is_loaded = true;
          item.formData = formData;

          item.widget = widget;
        }
      );
    },

    shortName(text, length) {
      if (text.length <= length) {
        return text;
      } else {
        return text.substring(0, length) + '...';
      }
    },

    async actionCreateAll() {
      if(this.allowAi){
        for (let product of this.products) {
          if (!product.formData.short_description || !product.formData.parameters.length) {
            return false;
          }
        }

        this.$emit('close-modal');
        for (let product of this.products) {
          await this.actionCreate(product.formData, product.layout, product.id);
        }
      }
      return false;
    },

    actionCreate(formData, layout, productId) {
      return new Promise((resolve, reject) => {
        formData["product_id"] = productId;

        GeneratorModel.create(
          { ...formData, ...{ layout } },
          () => {},
          (data) => {
            if (data.response?.status === 422) {
              this.errors = data.response.data.errors;
              reject(new Error("multiple-ai error"));
            } else {
              showMessage("flash.generator.product");
              useGeneratorStore().check();
              resolve();
            }
          }
        );
      });
    }

  },
}
</script>