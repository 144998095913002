<template>
  <layout-setting col="12">
    <template #header>
      {{ $t("invoice.title") }}
    </template>

    <template #content>
      <template v-if="isLoaded">
        <p class="text-md">
          {{ $t("invoice.info") }}
        </p>
      </template>

      <template v-else>
        <h2 class="mt-0 pt-0">
          <b-skeleton-img
            height="38px"
            no-aspect
            width="300px"
          />
        </h2>
        <p class="d-block">
          <b-skeleton-img
            height="24px"
            no-aspect
            width="100%"
          />
        </p>
      </template>

      <table class="table table-hover">
        <thead class="bg-light">
          <tr class="font-weight-bolder">
            <td>{{ $t("invoice.table.orderNumber") }}</td>
            <td>{{ $t("invoice.table.state") }}</td>
            <td>{{ $t("invoice.table.created") }}</td>
            <td>{{ $t("invoice.table.orderTariff") }}</td>
            <td>{{ $t("invoice.table.period") }}</td>
            <td class="text-right">
              {{ $t("invoice.table.priceWitVat") }}
            </td>
            <td>{{ $t("invoice.table.detail") }}</td>
          </tr>
        </thead>
        <tbody>
          <template v-if="isLoaded">
            <template v-if="data.length > 0">
              <tr
                v-for="invoice in data"
                :key="invoice.id"
              >
                <td>{{ invoice.order_id }}</td>
                <td>
                  <i
                    :class="[
                      'ico',
                      invoice.state === 'paid' ? 'ico-success' : 'ico-alert',
                    ]"
                  />
                  {{ getType(invoice.state) }}
                </td>
                <td>{{ when(invoice.created_at) }}</td>
                <td class="text-uppercase">
                  {{ invoice.data.tariff.tariff_paid }}
                </td>
                <td>
                  {{ $t("invoice.table.from") }}
                  {{ when(invoice.data.tariff.from) }}
                  {{ $t("invoice.table.to") }}
                  {{ when(invoice.data.tariff.to) }}
                </td>
                <td class="text-right">
                  {{
                    (
                      (invoice.vat / 100) * invoice.price +
                      invoice.price
                    ).toFixed(0)
                  }}
                  {{ $t("tariff.rawCurrency." + invoice.currency) }}
                </td>
                <td class="text-right">
                  <b-btn
                    block
                    size="sm"
                    variant="secondary"
                    @click="handleOpenInvoice(invoice, true)"
                  >
                    {{ $t("invoice.table.detailOrder") }}
                  </b-btn>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td
                  class="text-black-50 text-center"
                  colspan="7"
                >
                  {{ $t("invoice.table.notFound") }}
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr
              v-for="n in 5"
              :key="n"
            >
              <td colspan="7">
                <b-skeleton-img
                  height="20px"
                  no-aspect
                  width="100%"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <hr class="my-5">

      <template v-if="isLoaded">
        <h3 class="mt-0 pt-0 font-weight-normal">
          {{ $t("invoice.widget.title") }}
        </h3>
        <p>{{ $t("invoice.widget.info") }}</p>
      </template>

      <table class="table table-hover">
        <thead class="bg-light">
          <tr class="font-weight-bolder">
            <td>{{ $t("invoice.widget.number") }}</td>
            <td>{{ $t("invoice.widget.status") }}</td>
            <td>{{ $t("invoice.widget.created") }}</td>
            <td>{{ $t("invoice.widget.name") }}</td>
            <td class="text-right">
              {{ $t("invoice.widget.price") }}
            </td>
            <td>{{ $t("invoice.widget.detail") }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              class="text-black-50 text-center"
              colspan="7"
            >
              {{ $t("invoice.widget.nothing") }}
            </td>
          </tr>
        </tbody>
      </table>

      <b-modal
        v-model="isDetailOpen"
        :title="$t('invoice.detail.title')"
        hide-footer
        class="bg-light"
        no-close-on-backdrop
        no-fade
        size="md"
      >
        <invoice-detail :invoice="selectedInvoice" />
      </b-modal>
    </template>
  </layout-setting>
</template>

<script>
import { when } from "@/filter/vue.js";
import InvoiceDetail from "@/components/setting/part/invoice-detail.vue";
import TariffModel from "@/model/TariffModel.js";

export default {
  name: "invoice-list",

  async mounted() {
    await this.actionRead();
  },

  displayName: "invoice-list",

  data() {
    return {
      data: [],
      isLoaded: false,
      isDetailOpen: false,
      selectedInvoice: {},
    };
  },
  components: {
    "invoice-detail": InvoiceDetail,
  },

  methods: {
    when,
    handleOpenInvoice(invoice, state) {
      this.isDetailOpen = state;
      this.selectedInvoice = invoice;
    },

    getType(type) {
      switch (type) {
      case "paid":
        return "Zaplaceno";
      case "cancel":
        return "Nezaplaceno";
      case "new":
        return "Čeká na platbu";
      }
    },
    async actionRead() {
      await TariffModel.findInvoice(
        () => {},
        (data) => {
          this.data = data;
          this.isLoaded = true;
        }
      );
    },
  },
};
</script>

<style scoped></style>
