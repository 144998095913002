import hotkeys from "hotkeys-js";

export default {
  methods:{
    mounted() {
      this.keyboardShortcuts();
    },

    unmounted() {
      hotkeys.unbind();
    },

    keyboardShortcuts(){
      const self = this;

      if(this.isOpenEditModal){
        hotkeys('esc', function(event, handler){
          event.preventDefault();
          self.openEditModal(false);
        });

        hotkeys('cmd+s, ctrl+s', function(event, handler){
          event.preventDefault();
          self.$refs.client.saveData();
        });

        hotkeys('cmd+u, ctrl+u', function(event, handler){
          event.preventDefault();
          self.$refs.client.openModalEdit(true);
        });

        hotkeys('cmd+p, ctrl+p', function(event, handler){
          event.preventDefault();
          self.$refs.client.changeMode("preview");
        });

        hotkeys('cmd+m, ctrl+m', function(event, handler){
          event.preventDefault();
          self.$refs.client.changeMode("multiple");
        });

        hotkeys('cmd+e, ctrl+e', function(event, handler){
          event.preventDefault();
          self.$refs.client.changeMode("editor");
        });
      }else{
        hotkeys.unbind();
      }

      hotkeys.setScope('default');
    }
  },
}
