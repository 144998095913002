<template>
  <b-form @submit.prevent="submitForm">
    <b-form-group
      :label="$t('E-mail to which the e-shop is registered')"
      label-for="email"
    >
      <b-form-input
        id="email"
        v-model="email"
        maxlength="65"
        name="email"
        type="email"
      />
    </b-form-group>

    <b-form-group>
      <b-button
        block
        type="submit"
        :disabled="disabled"
        variant="secondary"
      >
        {{ $t('Send forgotten password') }} »
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
export default {
  name: "ResetPasswordForm",
  props:{
    disabled:{
      type: Boolean,
      default: false,
    }
  },
  mounted() {},
  data() {
    return {
      email: "",
    };
  },
  methods: {
    submitForm() {
      this.$emit("reset", this.email);
    },
  },
};
</script>
