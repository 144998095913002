<template>
  <div
    v-if="categories.length > 0"
    class="filter-product-list w-100 p-3 rounded-lg"
  >
    <div
      v-for="category in categories"
      :key="category.id"
      class="filter-product-list__item mr-1"
    >
      <div class="filter-product-list__item-name">
        {{ category.name }}
      </div>
      <div
        class="filter-product-list__item-delete pointer-event"
        @click="$emit('remove', category.id)"
      >
        <b-icon
          icon="x"
          class="fs-16 font-weight-bold"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
  .filter-product-list{
    background: rgba(100, 155, 13, 0.27);
    display: flex;
    flex-wrap: wrap;
    &__item{
      padding: 2px 11px;
      border-radius: 16px;
      background: white;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(100, 155, 13, 0.77);
      color: white;
    }

    &__item-delete{
      cursor: pointer;
      &:hover{
        i{
          font-size: 18px;
        }
      }
    }
  }
</style>
