<template>
  <layout-setting>
    <template #header>
      {{ $t("Our partners") }}
    </template>

    <template #content>
      <p class="text-sm mb-1">
        {{ $t("Don't you have an appointment yet? Whether you are looking for a graphic designer, coder or marketing agency. Here at Pobo, you can choose from proven partners who can help you improve your online business.") }}
      </p>

      <div
        class="w-25 m-auto"
        style="min-width: 200px"
      >
        <b-btn
          block
          variant="secondary"
          size="sm"
          class="mt-3 pt-2 pb-2 fs-15 d"
          @click="goToPartnersPage"
        >
          Prohlédnout Partnery
        </b-btn>
      </div>
    </template>
  </layout-setting>
  <layout-setting>
    <template #header>
      {{ $t("Invite collaborators") }}
    </template>

    <template #content>
      <div class="d-flex justify-content-center align-items-center partners-detail">
        <b-button
          :style="{ opacity: canScrollLeft ? 1 : 0.2, pointerEvents: canScrollLeft ? 'auto' : 'none' }"
          variant="link"
          class="partners-detail__btn"
          @click="scrollLeft"
        >
          <b-icon
            variant="secondary"
            icon="arrow-left-circle-fill"
            size="30"
          />
        </b-button>
        <div
          ref="partnersContainer"
          class="partners-wrapper d-flex overflow-auto partners-container"
          @scroll="checkScroll"
        >
          <div class="d-flex">
            <partner-single
              v-for="(partner, index) in partners"
              :key="index"
              :image-url="partner.imageUrl"
              :name="partner.name"
              :description="partner.description"
              :website="partner.website"
              :disabled="isEmailAdded(partner.email)"
              :active="partner.active"
              @invite="handleInvite(partner.email)"
            />
          </div>
        </div>
        <b-button
          :style="{ opacity: canScrollRight ? 1 : 0.2, pointerEvents: canScrollRight ? 'auto' : 'none' }"
          variant="link"
          class="partners-detail__btn"
          @click="scrollRight"
        >
          <b-icon
            icon="arrow-right-circle-fill"
            size="30"
          />
        </b-button>
      </div>
    </template>
  </layout-setting>

  <layout-setting>
    <template #header>
      {{ $t("connect.user.page.header") }}
    </template>
    <template #content>
      <list-connect-user
        :connections="filteredConnections"
        @delete="actionDelete"
      />
    </template>
  </layout-setting>
</template>

<script>
import PartnerSingle from "@/components/setting/part/partner-single.vue";
import ConnectUser from "@/model/ConnectUser";
import ListConnectUser from "@/components/setting/part/list-connect-user.vue";

export default {
  name: "PartnersDetail",

  async mounted(){
    await this.actionRead();
    this.$nextTick(() => {
      this.checkScroll();
    });
  },
  data() {
    return {
      connections: [],
      filteredConnections: [],
      form: {
        email: "",
      },
      partners: [
        {
          imageUrl: "https://pobo-cdn.b-cdn.net/partners/shoptak.png",
          name: "Tomáš Hlad / Shoptak.cz",
          description: "Focuses on design and coding from small tweaks to complete e-commerce development.",
          website: "https://www.tomashlad.eu/",
          email: "podpora@shoptak.cz",
          active: true,
        },
        {
          imageUrl: "https://pobo-cdn.b-cdn.net/partners/mime.png",
          name: "mime digital",
          description: "A team of more than 50 professionals. We will take care of your project from the first idea to worldwide expansion.",
          website: "https://mimedigital.cz/",
          email: "obchod@mimedigital.cz",
          active: true,
        },
        {
          imageUrl: "https://pobo-cdn.b-cdn.net/partners/fv.png",
          name: "FV STUDIO",
          description: "Specializes in web design, development and UX of websites & e-shops.",
          website: "https://fv-studio.cz/",
          email: "support@fv-studio.cz",
          active: true,
        },
        {
          imageUrl: "https://pobo-cdn.b-cdn.net/partners/partners.png",
          name: "Coming soon",
          description: "We are preparing this partner for you",
          website: "",
          email: "",
          active: false,
        },
      ],
      canScrollLeft: false,
      canScrollRight: false,
    };
  },
  watch: {
    partners() {
      this.$nextTick(() => this.checkScroll());
    }
  },
  components: {
    ListConnectUser,
    PartnerSingle
  },

  methods: {
    handleInvite(email) {
      this.form.email = email;
      ConnectUser.createConnection(
        this.form,
        () => {},
        (data) => {
          this.actionRead()
        },
        ({ errors }) => {
          this.errors = errors;
        }
      );
    },

    filterConnections() {
      const partnerEmails = this.partners.map(partner => partner.email);
      this.filteredConnections = this.connections.filter(connection =>
        partnerEmails.includes(connection.user.email)
      );
    },

    isEmailAdded(email){
      return this.filteredConnections.some(connection => connection.user.email === email);
    },

    goToPartnersPage(){
      window.open("http://pobo.cz/static/partners", '_blank');
    },
    scrollLeft() {
      const container = this.$refs.partnersContainer;
      if (!container) return;

      const newPosition = container.scrollLeft - container.clientWidth;
      container.scrollTo({
        left: newPosition,
        behavior: 'smooth'
      });
      this.$nextTick(() => this.checkScroll());
    },
    scrollRight() {
      const container = this.$refs.partnersContainer;
      if (!container) return;

      const newPosition = container.scrollLeft + container.clientWidth;
      container.scrollTo({
        left: newPosition,
        behavior: 'smooth'
      });
      this.$nextTick(() => this.checkScroll());
    },

    async actionDelete(id) {
      await ConnectUser.deleteConnection(
        id,
        () => {},
        (data) => {
          this.actionRead()
        }
      );
    },

    async actionRead() {
      await ConnectUser.findAllConnection(
        () => {},
        (data) => {
          this.connections = data;
          this.filterConnections();
        }
      );
    },
    checkScroll() {
      const container = this.$refs.partnersContainer;
      if (!container) return;

      this.canScrollLeft = container.scrollLeft > 0;
      this.canScrollRight = container.scrollLeft + container.clientWidth < container.scrollWidth;
    },
  }
};
</script>

<style lang="scss" scoped>
.partners-detail{
  &__container{
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__btn{
    &:active{
      box-shadow: unset !important;
      border: none;
      background: transparent;
    }
    i{
      color: #649b0d;
    }
  }
}
.partners-container{
  &::-webkit-scrollbar {
    display: none;
  }
}
.arrow-button {
  font-size: 24px;
  color: #000;
  text-decoration: none;
}

.arrow-button:hover {
  color: #000;
}
</style>
