import axios from "axios";

export default class ExportModel {
  static async formatHTML(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();
    return await axios
      .post("/api/v2/generative/format", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch generative [formatHTML()]", error);
        return error;
      });
  }

  static async getContent(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/content/list")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch generative content [getContent()]", error);
        return error;
      });
  }
}