<template>
  <layout-setting anchor="export">
    <template #header>
      {{ $t("invoice.setting.title") }}
    </template>
    <template #content>
      <p class="text-sm mb-3">
        {{ $t("invoice.setting.content") }}
      </p>

      <setting-form />
    </template>
  </layout-setting>
</template>

<script>;
import SettingForm from "@/components/setting/part/setting-form.vue";

export default {
  name: "SettingExport",

  components: {
    "setting-form" : SettingForm,
  },
};
</script>
