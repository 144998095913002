<template>
  <div class="form-group m-0">
    <template v-if="data.length > 0">
      <select
        class="form-control"
        @change="handleChange($event)"
      >
        <option>{{ $t("tariff.step.two.select.info") }}</option>
        <option
          v-for="(item, index) in data"
          :key="item.id"
          :value="index"
        >
          {{ item.name }}
        </option>
      </select>
    </template>
    <template v-else>
      <a
        class="btn btn-secondary btn-block"
        href="#"
        @click.prevent="handleOpen"
      >
        {{ $t("tariff.step.two.select.create") }}
      </a>

      <b-modal
        v-model="isBillingModalOpen"
        hide-footer
        no-fade
        size="md"
        title="Vytvořit nový fakturační údaj"
      >
        <billing-form @handle-close="handleClose" />
      </b-modal>
    </template>
  </div>
</template>

<script>
import BillingForm from "@/components/setting/part/billing-form.vue";
import BillingModel from "@/model/BillingModel.js";

export default {
  name: "select-billing",
  displayName: "select-billing",

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      data: [],
      isBillingModalOpen: false,
    };
  },

  components: {
    "billing-form": BillingForm,
  },

  methods: {
    handleOpen() {
      this.isBillingModalOpen = true;
    },

    handleClose(data) {
      if (data) {
        this.data = data;
        this.$emit("handle-change", data[0]);
      }
    },

    handleChange($event) {
      const { value } = $event.target;
      const { data } = this;
      this.$emit("handle-change", data[value]);
    },

    async actionRead() {
      await BillingModel.findAll(
        () => {},
        (data) => {
          this.data = data;
        }
      );
    },
  },
};
</script>