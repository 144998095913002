import { mapWritableState } from "pinia";
import { useEshopStore } from "@/store/EshopStore";
import { useCreditStore } from "@/store/CreditStore";
import defaultConfig from "@/mixin/defaultConfig.json";

export default {
  computed: {
    ...mapWritableState(useEshopStore, {
      eshop: "detail",
    }),

    ...mapWritableState(useCreditStore, {
      summary: "summary",
      edited: "edited",
      price: "price",
    }),

    availableEshop() {
      const { eshop } = this;
      const { available_eshop } = eshop;
      return available_eshop;
    },

    isConfigureLang() {
      const { eshop } = this;
      const { allow_lang } = eshop;
      return allow_lang === true;
    },

    isEnableDark() {
      const { eshop } = this;
      const { enable_dark } = eshop;
      return enable_dark;
    },


    aiLang() {
      const { eshop } = this;
      const { ai_lang } = eshop;
      return ai_lang;
    },

    eshopConfig() {
      const { eshop } = this;
      const { config } = eshop;
      return config ? JSON.parse(config) : {};
    },

    editorLang(){
      const {eshop} = this;
      const {editor_lang } = eshop;
      return editor_lang;
    },

    editorConfig() {
      const { eshopConfig } = this;
      const { editor } = eshopConfig;

      if (editor && editor.active) {
        console.log('%c Allow custom editor config', 'background: #222; color: #bada55')
        return editor;
      } else {
        console.log('%c Use default editor config', 'background: #222; color: #bada55')
        return defaultConfig.editor;
      }
    },

    eshopUrl() {
      const { eshop } = this;
      const { url } = eshop;
      return url;
    },

    eshopTariff() {
      const { eshop } = this;
      const { tariff } = eshop;
      return tariff;
    },

    isShopify() {
      const { eshop } = this;
      const { platform } = eshop;
      return platform === "shopify";
    },

    isShoptet() {
      const { eshop } = this;
      const { platform } = eshop;
      return platform === "shoptet";
    },

    isB2B() {
      const { eshop } = this;
      const { platform } = eshop;
      return platform === "b2b";
    },

    showShoptetWebhookNotification() {
      return false;
      const { eshop } = this;
      const { platform, webhook_id, role } = eshop;
      return platform === "shoptet" && webhook_id === null && !role.includes('admin');
    },

    isAdmin() {
      const { eshop } = this;
      const { role } = eshop;
      return role.includes('admin');
    },

    designId() {
      const { eshop } = this;
      const { design_id } = eshop;
      return design_id;
    },

    isPositiveCreditAccountStatus() {
      const { summary } = this;
      const { reaming } = summary;

      return reaming < 0;
    },

    allowGenerateContent() {
      const { summary, price } = this;
      const { reaming } = summary;
      const { generate } = price;
      return reaming > generate;
    },

    allowFormatContent() {
      const { summary, price } = this;
      const { reaming } = summary;
      const { format } = price;
      return reaming > format;
    },

    showExpireTariff() {
      const { eshop } = this;
      const { expire_tariff } = eshop;
      return expire_tariff;
    },
  },
};
