const loading = document.createElement('div');
loading.id = 'hero-loading';
loading.innerHTML = `
  <div class="loading">
    <div class="loading__spinner">
      <!-- Temp -->
    </div>
  </div>
`;
document.body.appendChild(loading);


const style = document.createElement('style');
style.innerHTML = `
  #hero-loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .loading__spinner {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 4px solid #ccc;
    border-top-color: #ff0000;
    animation: spin 1s infinite linear;
  }
  .loading__spinner__inner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid #ccc;
    border-top-color: #000;
    animation: spin 1s infinite linear;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg)
    }
  }`;

document.head.appendChild(style);