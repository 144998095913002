<template>
  <div>
    <b-row>
      <b-col cols="7">
        <b-btn-group
          v-model="open"
          class="mb-4"
        >
          <b-btn
            :variant="setActiveVariant('detail')"
            size="sm"
            @click="openBlock('detail')"
          >
            Informace o e-shopu
          </b-btn>

          <b-btn
            :variant="setActiveVariant('credit')"
            size="sm"
            @click="openBlock('credit')"
          >
            Kredity
          </b-btn>

          <b-btn
            :variant="setActiveVariant('note')"
            size="sm"
            @click="openBlock('note')"
          >
            Poznámka
          </b-btn>

          <b-btn
            :variant="setActiveVariant('stats')"
            size="sm"
            @click="openBlock('stats')"
          >
            Informace o produktech
          </b-btn>
        </b-btn-group>
      </b-col>
      <b-col cols="5">
        <b-form>
          <b-row>
            <b-col cols="8">
              <b-form-select
                v-model="selectUserId"
                :options="data.users"
                size="sm"
                value-field="id"
                text-field="email"
              />
            </b-col>
            <b-col cols="4">
              <b-btn
                size="sm"
                variant="secondary"
                block
                @click="actionCreate"
              >
                Přihlásit se
              </b-btn>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-if="isOpenBlock('detail')">
      <b-row v-if="data">
        <b-col cols="6">
          <h5 class="mb-3">
            Informace o e-eshopu
          </h5>
          <div class="p-4 bg-white shadow-sm">
            <b-form @submit.prevent="actionUpdate">
              <div
                v-if="success"
                class="alert alert-success"
              >
                Nastavení e-shopu bylo uloženo
              </div>

              <table class="table">
                <tbody>
                  <tr>
                    <td
                      class="font-weight-bolder text-right"
                    >
                      ID eshopu:
                    </td>
                    <td>{{ data.id }}</td>
                  </tr>

                  <tr>
                    <td
                      class="font-weight-bolder text-right"
                    >
                      Telefon:
                    </td>
                    <td>
                      {{
                        data.phone
                          ? data.phone
                          : "Není známo"
                      }}
                    </td>
                  </tr>

                  <tr>
                    <td
                      class="font-weight-bolder text-right"
                    >
                      Mobilní telefon:
                    </td>
                    <td>
                      {{
                        data.mobile_phone
                          ? data.mobile_phone
                          : "Není známo"
                      }}
                    </td>
                  </tr>

                  <tr>
                    <td
                      class="font-weight-bolder text-right"
                    >
                      Kontaktní osoba:
                    </td>
                    <td>
                      {{
                        data.contact_person
                          ? data.contact_person
                          : "Není známo"
                      }}
                    </td>
                  </tr>

                  <tr>
                    <td
                      class="font-weight-bolder text-right"
                    >
                      URL:
                    </td>
                    <td>
                      <a
                        :href="data.url"
                        target="_blank"
                        class="text-danger"
                      >
                        {{ data.url }}
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td
                      class="font-weight-bolder text-right"
                    >
                      Aktivita:
                    </td>
                    <td>
                      <span
                        :class="[
                          data.is_disable
                            ? 'bg-danger'
                            : 'bg-secondary',
                          'd-inline-block px-2 py-1 text-white',
                        ]"
                      >
                        {{
                          data.is_disable
                            ? "Eshop je deaktivovaný"
                            : "Eshop je aktivní"
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="custom-control custom-switch">
                <input
                  id="force_disable"
                  v-model="data.force_disable"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="force_disable"
                >Zobrazit modální alert okno o nutnosti
                  odinstalování doplňku
                </label>
              </div>

              <div class="custom-control custom-switch">
                <input
                  id="is_premium"
                  v-model="data.is_premium"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="is_premium"
                >
                  Aktivovat PREMIUM účet
                </label>
              </div>

              <b-form-group class="pt-4">
                <b-btn
                  variant="secondary"
                  type="submit"
                  block
                >
                  Uložit nastavení e-shopu
                  <i class="ico ico-arrow-right" />
                </b-btn>
              </b-form-group>
            </b-form>
          </div>
        </b-col>

        <b-col cols="6">
          <h5 class="mb-3">
            Historie přihlášení
          </h5>
          <div class="p-4 bg-white shadow-sm">
            <div style="overflow-y: auto; height: 445px">
              <template v-if="data.history.length > 0">
                <b-row
                  v-for="item in data.history"
                  :key="item.id"
                  class="py-2 border-bottom"
                >
                  <b-col cols="4">
                    {{
                      datetime(item.created_at)
                    }}
                  </b-col>
                  <b-col cols="8">
                    {{
                      item.type === 1
                        ? "Přes formulář"
                        : "Přihlášení přes e-shop"
                    }}
                  </b-col>
                </b-row>
              </template>
              <template v-else>
                <b-row>
                  <b-col cols="12">
                    <small
                      class="text-muted text-center d-block"
                    >
                      Uživatel se zatím nepřihlásil.
                    </small>
                  </b-col>
                </b-row>
              </template>
            </div>
          </div>
        </b-col>
      </b-row>
    </template>

    <template v-if="isOpenBlock('credit')">
      <b-form
        class="bg-white shadow-sm p-4 border rounded"
        @submit.prevent="insertCredit"
      >
        <p>
          Počet kreditů, které má klient na účtu:
          <strong>{{ data.credit.summary.reaming }}</strong>
        </p>

        <b-form-group label="Počet kreditů, které se přičtou">
          <b-input
            v-model="creditForm.count_credit"
            type="number"
            min="1"
          />
        </b-form-group>

        <b-form-group>
          <b-button type="submit">
            Přičíst kredity
          </b-button>
        </b-form-group>
      </b-form>
    </template>

    <template v-if="isOpenBlock('note')">
      <b-form @submit.prevent="actionUpdate">
        <b-form-group label="Interní poznámka">
          <b-form-textarea
            v-model="data.note"
            placeholder="Např. Uživatel má problém s přihlášením nebo s platbou."
            rows="10"
          />
        </b-form-group>

        <b-form-group>
          <b-btn
            variant="secondary"
            type="submit"
            block
          >
            Uložit poznámku <i class="ico ico-arrow-right" />
          </b-btn>
        </b-form-group>
      </b-form>
    </template>

    <template v-if="isOpenBlock('stats')">
      <h5 class="mb-3">
        Informace o produktech
      </h5>
      <b-row>
        <b-col cols="3">
          <div class="p-4 bg-white shadow-sm text-center">
            <h6 class="p-0 mt-0 mb-4">
              Počet produktů
            </h6>

            <h4 class="m-0 p-0">
              {{ data.stats.product.total }}x
            </h4>
          </div>
        </b-col>

        <b-col cols="3">
          <div class="p-4 bg-white shadow-sm text-center">
            <h6 class="p-0 mt-0 mb-4">
              Upravené produkty
            </h6>

            <h4 class="m-0 p-0">
              {{ data.stats.product.edited }}x
            </h4>
          </div>
        </b-col>

        <b-col cols="3">
          <div class="p-4 bg-white shadow-sm text-center">
            <h6 class="p-0 mt-0 mb-4">
              Počet článků v blogu
            </h6>

            <h4 class="m-0 p-0">
              {{ data.stats.blog.total }}x
            </h4>
          </div>
        </b-col>

        <b-col cols="3">
          <div class="p-4 bg-white shadow-sm text-center">
            <h6 class="p-0 mt-0 mb-4">
              Počet kategorií
            </h6>

            <h4 class="m-0 p-0">
              {{ data.stats.category.total }}x
            </h4>
          </div>
        </b-col>
      </b-row>

      <h5 class="mb-3 mt-5">
        Exporty, importy a požadavky na umělou inteligenci
      </h5>

      <b-row>
        <b-col cols="4">
          <div class="p-4 bg-white shadow-sm text-center">
            <h6 class="p-0 mt-0 mb-4">
              Počet požadavků na export
            </h6>

            <h4 class="m-0 p-0">
              {{ data.stats.export.total }}x
            </h4>
          </div>
        </b-col>

        <b-col cols="4">
          <div class="p-4 bg-white shadow-sm text-center">
            <h6 class="p-0 mt-0 mb-4">
              Počet požadavků na import
            </h6>

            <h4 class="m-0 p-0">
              {{ data.stats.import.total }}x
            </h4>
          </div>
        </b-col>

        <b-col cols="4">
          <div class="p-4 bg-white shadow-sm text-center">
            <h6 class="p-0 mt-0 mb-4">
              Počet požadavků na umělou inteligenci
            </h6>

            <h4 class="m-0 p-0">
              {{ data.stats.copy.total }}x
            </h4>
          </div>
        </b-col>
      </b-row>

      <h5 class="mb-3 mt-5">
        Počet widgetů
      </h5>
      <b-row>
        <b-col cols="3">
          <div class="p-4 bg-white shadow-sm text-center">
            <h6 class="p-0 mt-0 mb-4">
              Produkty
            </h6>

            <h4 class="m-0 p-0">
              {{ data.stats.widget.product }}x
            </h4>
          </div>
        </b-col>

        <b-col cols="3">
          <div class="p-4 bg-white shadow-sm text-center">
            <h6 class="p-0 mt-0 mb-4">
              Blog
            </h6>

            <h4 class="m-0 p-0">
              {{ data.stats.widget.blog }}x
            </h4>
          </div>
        </b-col>

        <b-col cols="3">
          <div class="p-4 bg-white shadow-sm text-center">
            <h6 class="p-0 mt-0 mb-4">
              Sdílené popisky
            </h6>

            <h4 class="m-0 p-0">
              {{ data.stats.widget.share }}x
            </h4>
          </div>
        </b-col>

        <b-col cols="3">
          <div class="p-4 bg-white shadow-sm text-center">
            <h6 class="p-0 mt-0 mb-4">
              Kategorie
            </h6>

            <h4 class="m-0 p-0">
              {{ data.stats.widget.category }}x
            </h4>
          </div>
        </b-col>

        <b-col cols="3">
          <div class="p-4 mt-4 bg-white shadow-sm text-center">
            <h6 class="p-0 mt-0 mb-4">
              Oblíbené
            </h6>

            <h4 class="m-0 p-0">
              {{ data.stats.widget.favourite }}x
            </h4>
          </div>
        </b-col>
      </b-row>
    </template>

    <template v-if="isOpenBlock('users')" />
  </div>
</template>

<script>
import { datetime, when } from "@/filter/vue";
import AdminModel from "@/model/AdminModel.js";
import Cookies from "js-cookie";
import { showMessage } from "@/plugins/flashMessage";

export default {
  props: {
    userId: {
      type: Number,
      require: true,
    },
  },

  mounted(){
    this.actionRead();
  },

  data(){
    return {
      selectUserId: null,
      data: {
        note: null,
        history: [],
        users: [],
        credit: {
          summary: {
            reaming: 0,
          },
        },
      },
      success: false,
      open: "detail",
      creditForm: {
        count_credit: null,
      },
    };
  },

  methods: {
    datetime, when,
    openBlock(state) {
      this.open = state;
    },

    isOpenBlock(state) {
      return this.open === state;
    },

    setActiveVariant(state) {
      return this.open === state ? "secondary" : "light";
    },

    async insertCredit(){
      const { creditForm, userId } = this;
      const { count_credit } = creditForm;
      await AdminModel.addCredit(
        {
          eshop_id: userId,
          count_credit: parseInt(count_credit),
        },
        () => {},
        (data) => {
          this.creditForm.count_credit = null;

          showMessage("Kredity byly přičteny");
        }
      );
    },

    async actionUpdate(){
      const { data } = this;

      await AdminModel.updateUser(
        data,
        () => {},
        (data) => {
          this.success = true;
        }
      );
    },

    async actionCreate(){
      const { selectUserId, userId } = this;

      await AdminModel.loginUser(
        { user_id: selectUserId },
        () => {},
        () => {
          Cookies.set("eshopId", userId);
          location.href = "/app";
        }
      );
    },

    async actionRead(){
      const { userId } = this;
      await AdminModel.findUser(
        userId,
        () => {},
        (data) => {
          const { users } = data;
          this.selectUserId = users[0].id;
          this.data = data;
        }
      );
    },
  },
};
</script>
