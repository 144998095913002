import axios from "axios";

export default class TariffModel {
  static async findInvoice(
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/invoice")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch tariff [findInvoice()]", error);
        errorCallback(error);
        return error;
      });
  }

  static async readCompanyInfo(
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/company")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch tariff [readCompanyInfo()]", error);
        errorCallback(error);
        return error;
      });
  }

  static async find(
    currency = "usd",
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/eshop/tariff", { currency })
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch tariff [find()]", error);
        errorCallback(error);
        return error;
      });
  }

  static async create(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .post("/api/transaction", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create tariff [create()]", error);
        return error;
      });
  }
}
