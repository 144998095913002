<template>
  <b-row v-if="icon">
    <b-col cols="12">
      <div class="content-editor__image-scroll">
        <b-row>
          <b-col
            v-for="(img, index) in icon.icon"
            :key="index"
            cols="4"
          >
            <div class="content-editor__image-container shadow-sm rounded mb-4">
              <div class="content-editor__image p-1">
                <a
                  href="#"
                  class="w-100 h-100"
                  @click.prevent="selectIcon(img)"
                >
                  <b-img-lazy
                    :src="img"
                    class="content-editor__img-svg w-100"
                  />
                </a>
              </div>
              <div class="pt-2">
                <b-btn
                  block
                  size="sm"
                  variant="secondary"
                  @click="selectIcon(img)"
                >
                  {{ $t("widgetTree.modal.image.selectIcon") }}
                </b-btn>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ImageIcon",

  props: {
    icon: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      menu: [],
    };
  },

  methods: {
    selectIcon(image) {
      this.$emit("select-icon", image);
    },
  },
};
</script>