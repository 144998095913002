<template>
  <div
    v-if="show"
    class="bg-white rounded-lg shadow-sm p-5 mb-4 w-100"
    :style="{
      background: `url(${photoUrl}) right bottom / contain no-repeat`,
      border: '2px solid #fb1900'
    }"
  >
    <h4 class="mt-0 mb-3">
      {{ $t("Unfortunately you don't have enough credits to edit the captions using AI") }}
    </h4>

    <p class="mb-0 fs-16 lh-28 d-block">
      {{ $t("Please go to the section") }} „<router-link
        :to="{ name: 'credit' }"
        style="text-decoration: underline"
        class="text-secondary"
      >
        {{ $t("translation.modal.empty.button") }}
      </router-link>“, {{ $t("translation.modal.empty.postfix") }}<br>
      {{ $t('If you have any questions, please contact us, we will be happy to help you.') }}
    </p>

    <small class="mb-0 mt-2 text-muted d-block">
      {{ $t('The price for formatting the caption using ChatGPT is') }} {{ price }} {{ setCreditLang }}
    </small>
  </div>
</template>

<script>

export default {
  name: "WarningLowCredits",
  props: {
    show: {
      required: true,
      type: Boolean
    },
    imageType:{
      required: true,
      type: String,
    },
    price:{
      required: true,
      type: Number,
    }
  },

  mounted(){
    this.setImage();
  },

  data() {
    return {
      photoUrl: 'https://pobo-cdn.b-cdn.net/our-team-photos/without-bg/6.png',
      creditLang: 'credits.one',
    }
  },

  computed:{
    setCreditLang(){
      let key;
      if (this.price === 1) {
        key = "credits.one";
      } else if (this.price > 1 && this.price < 5) {
        key = "credits.two";
      } else {
        key = "credits.five";
      }
      return this.$t(key);
    },
  },

  methods:{
    setImage(){
      const {imageType} = this;
      if(imageType === 'michal'){
        this.photoUrl = 'https://pobo-cdn.b-cdn.net/our-team-photos/without-bg/6.png';
      }else if(imageType === 'tomas'){
        this.photoUrl = 'https://pobo-cdn.b-cdn.net/our-team-photos/without-bg/5.png';
      }else if(imageType === 'vojta'){
        this.photoUrl = 'https://pobo-cdn.b-cdn.net/our-team-photos/without-bg/4.png';
      }else if(imageType === 'sara'){
        this.photoUrl = 'https://pobo-cdn.b-cdn.net/our-team-photos/without-bg/2.png';
      }else{
        this.photoUrl = 'https://pobo-cdn.b-cdn.net/our-team-photos/without-bg/6.png';
      }
    },
  },
}

</script>