<template>
  <form @submit.prevent="actionCreate">
    <div class="form-group">
      <div class="row">
        <div class="col-6">
          HTML nakódovaného widgetu
        </div>
        <div class="col-6 text-right">
          <div class="custom-control custom-switch">
            <input
              id="showJson"
              v-model="showJson"
              type="checkbox"
              class="custom-control-input"
              @change="handleParse"
            >
            <label
              class="custom-control-label"
              for="showJson"
            >Zobrazit JSON</label>
          </div>
        </div>
      </div>
      <template v-if="showJson">
        <pre
          style="height: calc(100vh - 250px)"
          class="form-control disabled"
        >
          {{ json }}
        </pre>
      </template>
      <template v-else>
        <monaco
          :value="inputHTML"
          language="html"
          height="calc(100vh - 250px)"
          @change="inputHTML = $event"
        />
        <div class="d-none">
          <div
            id="htmlProject"
            ref="generateHTML"
            v-html="inputHTML"
          />
        </div>
      </template>
    </div>

    <div
      v-if="showJson"
      class="form-group text-right"
    >
      <button
        type="submit"
        class="btn btn-secondary"
        :disabled="!inputHTML"
      >
        Nahrát widget
      </button>
    </div>
  </form>
</template>

<script>
import treeFlatter from 'tree-flatter';
import { jsonFromHTML } from "jsonfromhtml";
import lang from "@/js/config/lang.js";
import WidgetModel from "@/model/WidgetModel.js";
import monaco from "@/components/share/monaco.vue";

export default {
  name: "widget-upload",

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data(){
    return {
      inputHTML: null,
      error: null,
      json: null,
      showJson: false,
      lang,
      cmOptions: {
        tabSize: 2,
        indentUnit: 2,
        mode: "text/html",
        theme: "default",
        connect: "align",
        lineNumbers: true,
        lineWrapping: true,
        matchBrackets: true,
        fontSize: 55,
        line: true,
        placeholder: "Sem vložte HTML",
        extraKeys: {
          Ctrl: "autocomplete",
        },
      },
    };
  },

  components: {
    monaco,
  },

  methods: {
    handleParse() {
      /*

      <div>
        <ul class="list">
          <li>Prvni</li>
          <li>Druha</li>
        </ul>

        <div>
        </div>
      </div>

       */
      try {
        Array.from([
          "aside",
          "h1",
          "h2",
          "h3",
          "span",
          "img",
          "ul",
          "li",
          "iframe",
        ]).forEach((tag) => {
          Array.from(
            document.getElementById("htmlProject").getElementsByTagName(tag)
          ).forEach((el) => {
            if (el.tagName !== "UL") {
              el.setAttribute(
                "default_value",
                el.src || el.innerHTML.replace("\t", "").trim()
              );
            }

            if (el.tagName === "ASIDE") {
              el.innerHTML = "";
            }
          });
        });

        const { generateHTML } = this.$refs;
        const htmlToJson = jsonFromHTML(generateHTML);
        const flat = treeFlatter(htmlToJson, "children");

        let output = [];

        Array.from(flat).forEach(({ nodeName, parent, id, attrs }) => {
          const cssClass = attrs && attrs.class ? attrs.class : null;
          const default_value =
            attrs && attrs.default_value ? attrs.default_value : null;
          const tag = nodeName;

          if (tag) {
            let mappingLang = {};

            Array.from(lang).forEach((flag) => {
              Object.assign(mappingLang, { [flag]: default_value });
            });

            output.push({
              tag: tag === "ASIDE" ? "p" : tag.toLowerCase(),
              child_id: id,
              parent_id: parent ? parent : 0,
              cssClass: cssClass,
              attributes: cssClass,
              default_value: mappingLang,
            });
          }

          this.json = output;
        });
      } catch (error) {
        this.error = error;
      }
    },
    async actionCreate(){
      const { json, id } = this;

      await WidgetModel.uploadWidget(
        {
          widget: json,
          widget_id: id,
        },
        () => {},
        (data) => {
          this.$emit("handle-close", data);
        }
      );
    },
  },
};
</script>
