<template>
  <layout-grid :has-background="false">
    <template #title>
      {{ $t("Manage your designs") }}
    </template>

    <template
      #create
    >
      <div class="position-relative">
        <tier-control
          :allow-tariff="['profi', 'premium', 'saas']"
          minimum-tariff="profi"
          position="bottom"
        >
          <b-btn
            class="my-4"
            variant="secondary"
            @click="toggleFormModal"
          >
            {{ $t("Create custom design") }}
            <b-icon
              icon="chevron-double-right"
              size="12"
            />
          </b-btn>
        </tier-control>
      </div>
    </template>

    <template #welcome>
      <p>
        {{ $t("Here you can create your individual label design using CSS code") }}.
        {{ $t("These designs can be used freely for any product to create a variety of designs") }}.
        {{ $t("When designing the design, we recommend using") }}
        <a
          href="../../../static/vector-doc"
          class="link text-secondary"
        >{{ $t("vector design") }}</a>, {{ $t("that have long-term technical support") }}.
      </p>

      <b-alert
        show
        variant="info"
        class="fs-15 lh-22"
      >
        <strong class="font-weight-bold">{{ $t("Warning") }}:</strong>
        {{ $t("The design editing feature is designed for advanced CSS practitioners") }}.
        {{ $t("Use of unverified code may cause problems or damage to the site for which we are not responsible") }}.
        {{ $t("We do not provide technical support for modifications outside our or approved codes") }}.
      </b-alert>
    </template>

    <template #content>
      <h4 class="mt-4 mb-3 p-0 font-weight-bold">
        {{ $t("Templates of the looks you have created") }}
      </h4>

      <div class="shadow-sm bg-white rounded p-2">
        <div class="design-table--head">
          <div>{{ $t("ID") }}</div>
          <div>{{ $t("Design name") }}</div>
          <div>{{ $t("Info") }}</div>
        </div>


        <div
          v-for="item in data.custom"
          :key="item.id"
          class="design-table"
        >
          <div>{{ item.id }}</div>
          <div>{{ item.name }}</div>
          <div class="text-muted small">
            {{ item.info ? item.info : $t('Without additional info') }}
          </div>
          <div>
            <b-button
              variant="secondary"
              size="sm"
              block
              @click="handleEdit(item.id)"
            >
              <b-icon
                icon="pencil"
                size="12"
                class="mr-1"
              />
              {{ $t("Edit design") }}
            </b-button>
          </div>
          <div>
            <confirm-delete
              :id="item.id"
              @delete="actionDelete"
            >
              <template #trigger>
                <b-icon
                  icon="trash"
                  size="12"
                  class="mr-1"
                />
                {{ $t("Move to trash") }}
              </template>
            </confirm-delete>
          </div>
        </div>
      </div>
    </template>
  </layout-grid>

  <modal-code
    v-if="isOpenCodeModal"
    :design-id="selectedDesignId"
    @handle-close="closeModal"
    @action-update="actionUpdate"
  />

  <b-modal
    v-model="isOpenFormModal"
    size="md"
    hide-footer
    :title="$t('Create or edit design')"
  >
    <design-form
      :item="design"
      @handle-close="refreshData"
    />
  </b-modal>
</template>

<script>
import eshopData from "@/mixin/eshopData";
import DesignModel from "@/model/front/DesignModel.js";
import TierControl from "@/share/tier-control.vue";

export default {
  name: "DesignCustom",

  mixins: [eshopData],

  async mounted() {
    await this.fetchData();
  },

  data() {
    return {
      isOpenFormModal: false,
      isOpenCodeModal: false,
      selectedDesignId: null,
      design: {},
      data: {
        public: [],
        custom: []
      }
    };
  },

  components: {
    TierControl,
    "design-form": () => import("@/components/design/design-form.vue"),
    "modal-code": () => import("@/components/design/modal-code.vue")
  },

  methods: {
    async fetchData(){
      await DesignModel.findAll(() => {
      }, (data) => {
        this.data = data;
      });
    },

    async closeModal(){
      this.toggleCodeModal();
      await this.fetchData();
    },

    toggleFormModal() {
      this.isOpenFormModal = !this.isOpenFormModal;
    },

    toggleCodeModal() {
      this.isOpenCodeModal = !this.isOpenCodeModal;
    },

    refreshData(data = {}) {
      this.data = data;
      this.isOpenFormModal = false;
    },

    handleEdit(designId) {
      this.selectedDesignId = designId;
      this.isOpenCodeModal = true;
    },

    async actionDelete(id) {
      const item = this.data.custom.find((item) => item.id === id);

      //item.delete = true;

      await DesignModel.delete(
        item.id,
        () => {
        },
        (data) => {
          this.data.custom = data.custom;
        }
      );
    },

    actionUpdate() {
      console.log("update");
    }
  }
};
</script>
