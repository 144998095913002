import { createI18n } from "vue-i18n";

import en from "./../../src/translation/en.json";
import cs from "./../../src/translation/cs.json";
import sk from "./../../src/translation/sk.json";
import hu from "./../../src/translation/hu.json";

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  legacy: false,
  warnHtmlMessage: false,
  globalInjection: true,
  runtimeOnly: false,
  pluralizationRules: {
    cs: (choice) => {
      if (choice === 0) {
        return 0;
      }

      if (choice === 1) {
        return 1;
      }

      if (choice >= 2 && choice <= 4) {
        return 2;
      }

      return 3;
    },
  },
  messages: {
    en,
    cs,
    sk,
    hu,
  },
});
export default i18n;
