<template>
  <tag
    v-if="!enclose.includes(item.tag)"
    ref="tag"
    :class="[
      item.is_editable === 1 ? 'content-editor__editable' : '',
      item.class,
    ]"
    :contenteditable="item.is_editable === 1"
    :tag="item.tag"
    @blur="actionUpdate($event, 'innerText')"
    @paste="paste($event)"
  >
    {{ contentResolver() }}

    <template v-for="(child, childIndex) in widget.children">
      <template v-if="widget.children">
        <widget-tree
          :id="id"
          :key="`${child.id}${id}${childIndex}`"
          :extension-editor="extensionEditor"
          :content-id="contentId"
          :active-preview-mode="activePreviewMode"
          :index="childIndex"
          :item="child"
          :options="options"
          :value-lang="valueLang"
          @element-handler="elementHandler"
          @element-root-handler="elementRootHandler"
        />
      </template>
    </template>
  </tag>

  <div
    v-if="isCopyable"
    ref="copyContainer"
    class="content-editor__copy-container"
  >
    <a
      class="content-editor__copy-element"
      href="#"
      @click.prevent="elementHandler('copy')"
    >
      <i class="ico ico-copy-element" />
    </a>

    <a
      class="content-editor__copy-element"
      href="#"
      @click.prevent="elementHandler('remove')"
    >
      <i class="ico ico-delete-element" />
    </a>
  </div>

  <div
    v-if="item.tag === 'p'"
    class="position-relative"
  >
    <b-overlay
      :show="isActiveGenerate"
      rounded="sm"
      variant="white"
      opacity="95%"
      blur="40px"
      z-index="500"
    >
      <template #overlay>
        <div class="mb-3">
          <span class="d-block font-weight-bolder fs-18">
            <b-spinner
              variant="primary"
              small
              class="mr-2"
              label="Generating..."
            />{{ $t("Generating...") }}
          </span>
          <span class="d-block text-muted fs-13 mb-3 mt-1">
            {{ $t("Please wait, we are generating the text for you") }}.
          </span>
        </div>
      </template>

      <froala
        ref="editor"
        v-model:value="wysiwygModel"
        :config="config"
      />

      <div class="generative-ai-button">
        <div class="generative-ai-button">
          <b-btn-group style="z-index: 999;">
            <button
              type="button"
              class="btn btn-primary generative-ai-button__btn"
              @click.prevent="openAiModal(true)"
            >
              <i class="ico ico-ai" /> {{ $t("Edit with AI") }}
            </button>
            <dropdown-ai-item @generate-ai-text="generateAi" />
          </b-btn-group>
        </div>
      </div>
    </b-overlay>
  </div>

  <div
    v-if="showImageImageTrigger"
    class="content-editor__photo-select-share"
    data-cy="open-image-modal-btn"
  >
    <button
      class="btn btn-sm btn-info btn-block"
      @click="openImageModal(true)"
    >
      <i class="ico ico-component-photo" />
      {{ $t("widgetTree.action.selectImage") }}
    </button>
  </div>

  <div
    v-if="item.tag === 'textarea'"
    class="my-2"
  >
    <small class="d-block text-muted pb-1">HTML kód:</small>
    <textarea
      v-model="item.value[valueLang]"
      class="form-control"
      rows="5"
    >{{
      contentResolver()
    }}</textarea>
  </div>

  <div v-if="item.tag === 'a'">
    <b-btn
      block
      size="sm"
      variant="info"
      @click="openLinkModal(true)"
    >
      {{ $t(item.extension[valueLang].text) }}
    </b-btn>
  </div>

  <div
    v-if="item.tag === 'youtube'"
    class="content-editor__photo-select-share"
    style="z-index: 999"
  >
    <b-btn
      block
      size="sm"
      variant="info"
      @click="openYouTubeModal(true)"
    >
      <i class="ico ico-component-photo" />
      {{ $t("widgetTree.action.selectVideo") }}
    </b-btn>
  </div>

  <img
    v-if="item.tag === 'img'"
    ref="image"
    :class="item.class"
    :src="contentResolver()"
    @click="openImageModal(true)"
  >

  <div
    v-if="item.tag === 'youtube'"
    class="embed-responsive embed-responsive-16by9"
  >
    <iframe
      :src="contentResolver()"
      allowfullscreen
      class="embed-responsive-item"
    />
  </div>

  <div
    v-if="item.tag === 'li'"
    :class="[
      item.is_editable == 1 ? 'content-editor__editable' : '',
      item.class,
    ]"
  >
    <div class="form-group">
      <textarea
        v-model="item.value[valueLang]"
        class="form-control"
        rows="3"
      >
          {{ contentResolver() }}
        </textarea>
    </div>
  </div>

  <b-modal
    v-if="item.tag === 'img'"
    v-model="isOpenPhotoModal"
    :title="$t('widgetTree.modal.image.title')"
    hide-footer
    size="ai"
  >
    <modal-image
      :id="id"
      :aspect-ratio="aspectRatio"
      :item="item"
      :options="options"
      :value-lang="valueLang"
      @select-image="selectImage"
      @update-alt="updateAlt"
      @update-link="updateLink"
    />
  </b-modal>

  <b-modal
    v-model="isOpenAiModal"
    :title="$t('Edit with AI')"
    hide-footer
    size="editor-lg"
  >
    <modal-text-ai
      :id="id"
      :config="config"
      :value-lang="valueLang"
      :allow-ai="allowFormatContent"
      :item="item"
      @generate-ai-text="generateAi"
    />
  </b-modal>

  <b-modal
    v-if="item.tag === 'youtube'"
    v-model="isOpenYouTubeModal"
    :title="$t('widgetTree.modal.video.title')"
    hide-footer
    size="lg"
  >
    <div class="input-group w-100">
      <input
        v-model="item.value[valueLang]"
        :placeholder="$t('widgetTree.modal.video.placeholder')"
        class="form-control form-control-sm"
        type="text"
        @blur="sanitizeYouTubeUrl()"
      >
      <div class="input-group-prepend">
        <b-btn
          size="sm"
          variant="secondary"
        >
          <i class="ico ico-search" />
        </b-btn>
      </div>
    </div>
    <div
      class="embed-responsive embed-responsive-16by9 my-2"
    >
      <iframe
        :src="item.value[valueLang]"
        allowfullscreen
        class="embed-responsive-item"
      />
    </div>
    <b-btn
      block
      size="md"
      variant="secondary"
      @click="openYouTubeModal(false)"
    >
      {{ $t("widgetTree.modal.video.save") }}
    </b-btn>
  </b-modal>

  <!-- @deprecated -->
  <b-modal
    v-if="item.tag === 'a'"
    v-model="isOpenLinkModal"
    :title="$t('widgetTree.modal.link.title')"
    hide-footer
    size="handler"
  >
    <modal-link
      :item="item"
      :value-lang="valueLang"
      @handle-save="saveLink"
    />
  </b-modal>
</template>

<script>
import Tag from "@/components/helper/tag.vue";
import ModalImage from "@/share/modal-image.vue";
import ModalLink from "@/share/modal-link.vue";
import editorConfig from "@/js/config/froala.js";
import getVideoId from "get-video-id";
import { showMessage } from "@/plugins/flashMessage";
import carousel from "@/share/mixins/widget-tree/carousel";
import elementCopy from "@/share/mixins/widget-tree/element-copy";
import FroalaEditor from "froala-editor";
import ModalTextAI from "@/share/modal-text-ai.vue";
import GenerativeModel from "@/model/GenerativeModel.js";
import eshopData from "@/mixin/eshopData";
import { useCreditStore } from "@/store/CreditStore.js";
import DropdownAiItem from "@/share/dropdown-ai-item.vue";

const COPYABLE_CLASS = [
  "rc-parameter__box-small-right-parameter",
  "rc-parameter__box-small-left-parameter",
  "rc-parameter__box-big-left-parameter",
  "rc-parameter__box-big-right-parameter",
  "rc-parameter-right__box-parameter",
  "rc-profit__list-item",
  "arsov-text__items-single",
  "arsov-photo-left__items-single",
  "arsov-photo-right__items-single",
  "arsov-effects__left-item",
  "arsov-effects__right-item",
  "arsov-numbers__item",
  "arsov-ingredients__item",
  "pb-carousel-inner__item",
  "pb-carousel-inner__item--carousel-product",
  "pb-carousel-lobey__item",
  "rc-cerna-svicka-buttons__button"
];
export default {
  name: "widget-tree",

  inject: ['pageType'],

  mixins: [carousel, elementCopy, eshopData],

  props: {
    item: {
      required: true,
      type: Object,
      default: () => {
        return {
          extension: {},
        }
      },
    },

    valueLang: {
      required: true,
      type: String,
    },

    options: {
      required: true,
      type: Object,
    },

    contentId: {
      required: true,
      type: Number,
    },

    id: {
      required: true,
      type: Number,
    },

    index: {
      required: true,
      type: Number,
    },

    extensionEditor: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    },

    activePreviewMode: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.showSelectPhoto();

    const buttonLang = this.$t("widgetTree.froala.insertButton");

    FroalaEditor.DefineIcon("insert", {
      SRC: "https://pobo-cdn.b-cdn.net/static/button-svgrepo-com.svg",
      ALT: "Button",
      template: "image",
    });

    FroalaEditor.RegisterCommand("insert", {
      title: this.$root.$t("widgetTree.froala.insertButton"),
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        this.html.insert(
          `<a href='#' class='btn btn-conversion btn-secondary button'>${buttonLang}</a>`
        );
      },
    });
  },

  data() {
    const { extensionEditor } = this
    editorConfig["language"] = this.$i18n.locale;
    editorConfig["placeholderText"] = this.$t("widgetTree.froala.placeholder");
    editorConfig["paragraphFormat"] = {
      N: this.$t("widgetTree.froala.block.p"),
      H2: this.$t("widgetTree.froala.block.h2"),
      H3: this.$t("widgetTree.froala.block.h3"),
      H4: this.$t("widgetTree.froala.block.h4"),
    };

    return {
      copyableClass: COPYABLE_CLASS,
      isOpenPhotoModal: false,
      isOpenAiModal: false,
      isOpenYouTubeModal: false,
      isOpenLinkModal: false,
      isOpenCopyElementPanel: false,
      isActiveGenerate: false,
      youTubeLink: null,
      showSelectImage: false,
      openTypeImage: 1,
      widget: this.item,
      aspectRatio: null,
      enclose: ["img", "li", "p", "youtube", "textarea", "a"],
      files: "",
      open: null,
      config: {
        ...editorConfig,
        ...extensionEditor,
        events: {
          insert: function (table) {
            table.contentEditable = true;
            table.classList.add("pb-standard-table");
          },

          "table.inserted": function (table) {
            table.contentEditable = true;
            table.classList.add("pb-standard-table");
          },

          "paste.afterCleanup": function (html) {
            return html.replace(
              "<table",
              `<table contenteditable="true" class="pb-standard-table"`
            );
          },
        },
      },
    };
  },

  computed: {
    isCopyable() {
      const { copyableClass, item } = this;
      return copyableClass.includes(item.class);
    },

    getTag() {
      const { tag } = this.$refs;
      return tag;
    },

    showImageImageTrigger() {
      const { showSelectImage, activePreviewMode } = this;

      if (showSelectImage) {
        return !activePreviewMode;
      }

      return false;
    },

    wysiwygModel: {
      get() {
        const { valueLang } = this;
        return this.item.value[valueLang];
      },

      set(newVal) {
        const { valueLang } = this;
        this.item.value[valueLang] = newVal;
      },
    },
  },

  components: {
    "dropdown-ai-item": DropdownAiItem,
    tag: Tag,
    "modal-image": ModalImage,
    "modal-link": ModalLink,
    "modal-text-ai": ModalTextAI,
  },

  methods: {
    updateAlt(value) {
      const { valueLang } = this;
      this.item.extension[valueLang].alt = value;

      showMessage("flash.updateAlt.saved");
    },

    async generateAi(data = {}) {
      if (this.allowFormatContent){
        this.isOpenAiModal = false;
        this.isActiveGenerate = true;

        const { valueLang, aiLang, pageType, id } = this;
        const { html, templateId } = data;

        const additionalData = {}

        if (pageType === 'product') {
          additionalData['productId'] = id;
        }

        const sendData = {
          html,
          id: templateId,
          lang: aiLang,
          ...additionalData
        };

        await GenerativeModel.formatHTML(sendData,
          () => {},
          (data) => {
            if(data.result === 'OK') {
              this.item.value[valueLang] = data.data;
              showMessage("flash.generateAi.saved");
            }
          });

        await useCreditStore().findHistory();

        this.isActiveGenerate = false;
      }
    },

    updateLink(value) {
      const { valueLang } = this;
      this.item.extension[valueLang].href = value;

      showMessage("flash.updateLink.saved");
    },

    async showSelectPhoto() {
      const { item } = this;
      const { tag } = item;

      if (tag !== "img") return;

      await this.$nextTick().then(() => {
        const image = this.$refs.image;

        if (image) {
          const { parentElement } = image;
          const { clientWidth } = parentElement;
          this.showSelectImage = clientWidth > 120;
        }
      });
    },

    calculateTagPosition(getTag) {
      this.$nextTick(() => {
        const { offsetLeft, offsetTop } = this.$refs.tag;
        const { clientWidth } = getTag;
        this.$refs.copyContainer.style.top = `${offsetTop}px`;
        this.$refs.copyContainer.style.left = `${
          offsetLeft + clientWidth - 20
        }px`;
      });
    },

    elementHandler(action) {
      const { index, contentId } = this;

      this.$emit("element-root-handler", {
        action,
        index,
        contentId,
        parent: this.$parent.item.class,
      });
    },

    elementRootHandler(data) {
      this.$emit("element-root-handler", data);
    },

    saveLink(formData) {
      const { valueLang } = this;
      const { url, text } = formData;

      this.item.extension[valueLang].text = text;
      this.item.value[valueLang] = url;

      this.openLinkModal(false);
    },

    sanitizeYouTubeUrl() {
      const { item, valueLang } = this;
      const { value } = item;
      const { id } = getVideoId(value[valueLang]);
      this.item.value[valueLang] = `https://www.youtube.com/embed/${id}`;
    },

    openImageSize(id) {
      if (this.open) {
        this.open = null;
      } else {
        this.open = id;
      }
    },

    openImageModal(state) {
      this.aspectRatio =
        this.$refs.image.naturalWidth / this.$refs.image.naturalHeight;
      this.isOpenPhotoModal = state;
    },

    openAiModal(state) {
      this.isOpenAiModal = state;
      //    this.currentAiData = data;
    },

    openYouTubeModal(state) {
      this.isOpenYouTubeModal = state;
    },

    openLinkModal(state) {
      this.isOpenLinkModal = state;
    },

    async selectImage(image) {
      const { valueLang } = this;
      this.item.value[valueLang] = image;
      this.isOpenPhotoModal = false;
    },

    selectTypeImage(type) {
      this.openTypeImage = type;
    },

    actionUpdate($event, target) {
      let value = null;
      const { valueLang } = this;
      switch (target) {
      case "innerText":
        value = $event.target.innerText = $event.target.innerText;
        break;
      case "value":
        value = $event.target.value;
        break;

      case "editor":
        value = this.item.value[valueLang];
        break;
      }

      this.item.value[valueLang] = value;
    },

    contentResolver() {
      const { item, valueLang } = this;
      return item.value[valueLang];
    },

    paste($event) {
      setTimeout(function () {
        $event.target.innerText = $event.target.innerText;
      }, 10);
    },
  },
};
</script>
