<template>
  <div class="pt-4">
    <layout-grid>
      <template #title>
        Onboarding
      </template>

      <template #panel>
        <div style="width: 500px">
          <grid-search
            @input="updateQuery"
            @submit="getData"
          />
        </div>
      </template>

      <template #filter>
        <grid-filter
          :current="params.filter"
          :list="listFilter"
          @change="filterProduct"
        />
      </template>

      <template #pagination>
        <b-pagination
          v-model="params.offset"
          :per-page="params.limit"
          :total-rows="params.total"
          pills
          size="sm"
          @change="handleUsePaginator"
        />
      </template>

      <template #content>
        <table class="table table-striped table-bordered">
          <thead>
            <tr class="font-weight-bold bg-light">
              <td>ID</td>
              <td>URL e-shopu</td>
              <td>E-mail</td>
              <td class="text-right">
                Revenue
              </td>
              <td>První detekce</td>
              <td>Již v Pobo</td>
              <td>Export do EM</td>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in data"
              :key="item.id"
            >
              <td style="width: 50px">
                {{ item.id }}
              </td>
              <td style="width: 320px">
                <span
                  v-for="(url, index) in item.location_on_site"
                  :key="index"
                  class="d-block"
                >
                  <a
                    :href="`https://${url}`"
                    target="_blank"
                  >{{ url }}</a>
                </span>
                <small class="text-muted d-block">
                  {{ item.company || "Vlastník nezjištěn" }}
                </small>
              </td>
              <td style="width: 200px">
                <template v-if="item.emails.length > 0">
                  <span
                    v-for="(email, index) in item.emails"
                    :key="index"
                    class="d-block"
                  >
                    {{ email }}
                  </span>

                  <small class="text-muted d-block">
                    PageRank: {{ item.page_rank }}
                  </small>
                </template>
                <template v-else>
                  <small class="text-muted">Nejsou e-maily</small>
                </template>
              </td>
              <td
                class="text-right"
                style="width: 150px"
              >
                {{ item.sales_revenue || "Nezjištěn" }}
              </td>
              <td
                class="text-right"
                style="width: 130px"
              >
                {{ when(item.first_detected) }}
              </td>
              <td
                :class="[
                  item.in_pobo ? 'bg-primary' : 'bg-danger',
                  'text-center text-white',
                ]"
                style="width: 120px"
              >
                {{ item.in_pobo ? "Je v Pobo" : "Není v Pobo" }}
              </td>

              <td
                class="text-center"
                style="width: 150px; vertical-align: inherit"
              >
                <template v-if="item.emails.length > 0">
                  <template v-if="item.status === 'exported'">
                    <strong class="text-success">Exportováno</strong>
                    <small class="d-block text-muted">{{
                      when(item.updated_at)
                    }}</small>
                  </template>
                  <template v-else>
                    <b-btn
                      variant="secondary"
                      @click="exportToEm(item)"
                    >
                      Export do EM
                    </b-btn>
                  </template>
                </template>
                <template v-else>
                  <small class="text-muted">Nejsou e-maily, nelze exportovat</small>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </layout-grid>
  </div>
</template>

<script>
import OnboardModel from "@/model/OnboardModel.js";
import GridSearch from "@/share/grid-search.vue";
import { date, when } from "@/filter/vue";
import { onboard } from "@/config/filter.json";
import GridFilter from "@/share/grid-filter.vue";

export default {
  name: "Onboard",

  mounted() {
    this.getData();
  },

  data() {
    return {
      data: [],
      isLoaded: false,
      listFilter: onboard,
      params: {
        offset: 1,
        limit: 20,
        query: "some value",
        total: 0,
        filter: 1
      }
    };
  },

  components: {
    "grid-search": GridSearch,
    "grid-filter": GridFilter
  },

  methods: {
    when, date,
    async exportToEm(item) {
      const { params } = this;
      const { emails, id } = item;
      const query = {
        ...params,
        id,
        emails
      };

      const { items, total } = await OnboardModel.exportToEcoMail(query);
      this.data = items;
      this.params.total = total;
    },

    async filterProduct(filter) {
      this.params.filter = filter;
      this.params.offset = 1;
      await this.getData();
    },

    updateQuery(query) {
      this.params.query = query;
    },

    async handleUsePaginator(offset) {
      this.params.offset = offset;
      await this.getData();
    },

    async getData() {
      const { params } = this;
      const result = await OnboardModel.findAll(
        { ...params },
        () => {
          this.isLoaded = false;
        },
        () => {
          this.isLoaded = true;
        }
      );

      const { items, total } = result;
      this.data = items;
      this.params.total = total;
    }
  }
};
</script>
