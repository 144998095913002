<template>
  <b-modal
    v-model="isModalOpen"
    :title="title"
    data-cy="modal-import-container"
    hide-footer
    :no-close-on-esc="noCloseOnEsc"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :hide-header-close="hideHeaderClose"
    size="handler"
    header-class="modal-panel__header"
    body-class="modal-panel__body"
    content-class="modal-panel"
    @close="$emit('close-modal')"
  >
    <div class="modal-panel__video">
      <div class="modal-panel__wrap">
        <p
          class="w-75"
          v-html="header"
        />

        <div class="modal-panel__buttons">
          <div class="modal-panel__buttons-left">
            <slot name="action" />
          </div>
          <div
            v-if="currentHelp"
            class="modal-panel__buttons-right"
          >
            <a
              :href="currentHelp"
              target="_blank"
            >
              <b-btn
                variant="info"
              >
                {{ $t("Help") }}
              </b-btn>
            </a>
          </div>
        </div>
      </div>
      <div class="modal-panel__video-wrapper">
        <iframe
          width="100%"
          height="350"
          src="https://www.youtube.com/embed/qS8eHV7b6zw?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=qS8eHV7b6zw&modestbranding=1"
          frameborder="0"
          allowfullscreen
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      </div>
    </div>

    <div class="modal-panel__content">
      <slot name="content" />
    </div>
  </b-modal>
</template>

<script>
import eshopData from "@/mixin/eshopData";

export default {
  mixins: [eshopData],
  props: {
    title: {
      type: String,
      required: true
    },

    header: {
      type: String,
      required: true
    },

    noCloseOnEsc: {
      type: Boolean,
      default: false,
      required: false
    },

    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
      required: false
    },

    hideHeaderClose: {
      type: Boolean,
      default: false,
      required: false
    },

    help: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isModalOpen: false,
      helpLinks: {
        export: "https://www.pobo.cz/functions/export",
        import: "https://www.pobo.cz/functions/import",
        credit: "https://www.pobo.cz/functions/editor#multijazycnost-popisku-s-automatickymi-preklady-deepl"
      }
    };
  },

  computed: {
    currentHelp() {
      if (this.help === "export") {
        return this.helpLinks.export;
      } else if (this.help === "import") {
        return this.helpLinks.import;
      } else if (this.help === "credit") {
        return this.helpLinks.credit;
      } else {
        return null;
      }
    }
  }
};
</script>
