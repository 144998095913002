<template>
  <div class="grid-layout__filter">
    <a
      v-for="(filter, index) in list"
      :key="index"
      :class="[
        filter.type === current ? 'active' : '',
        'grid-layout__filter-item',
      ]"
      href="#"
      @click.prevent="$emit('change', filter.type)"
    >
      <i :class="[filter.ico, 'ico']" />
      {{ $t(filter.translate) }}
    </a>
    <slot name="additional" />
  </div>
</template>

<script>
export default {
  name: "GridFilter",
  props: {
    list: {
      type: Array,
      require: true,
      default: () => [],
    },
    current: {
      type: Number,
      require: true,
      default: 1,
    },
  },
  emits: { change: null },
};
</script>
