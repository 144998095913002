import { mapWritableState } from "pinia";
import { useGeneratorStore } from "@/store/GeneratorStore.js";

export default {
  computed: {
    ...mapWritableState(useGeneratorStore, {
      list: "list",
      locked: "locked",
      reaming: "reaming",
    }),

    isLockedItemId() {
      const { item, locked } = this;
      const { id } = item;
      return locked.includes(id);
    },
  },
};
