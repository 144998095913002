<template>
  <b-row>
    <b-col cols="12">
      <template v-if="isLoaded">
        <template v-if="widget.length > 1">
          <div id="preview-widget-container">
            <b-row>
              <b-col
                v-for="(detail, index) in widget"
                :key="index"
                cols="6"
              >
                <span
                  class="fs-16 lh-22 d-block mt-2 mb-1 pr-5"
                >
                  {{ $t("translation.variant." + index) }}
                </span>
              </b-col>
            </b-row>
            <b-row
              class="bg-white rounded-lg shadow-sm"
              no-gutters
            >
              <b-col
                v-for="(detail, index) in widget"
                :key="index"
                cols="6"
              >
                <a
                  :class="[
                    layout === detail.id
                      ? 'active'
                      : '',
                    'template-trigger p-4 rounded d-block',
                  ]"
                  @click.prevent="switchLayout(detail.id)"
                >
                  <div
                    v-for="widget in detail.list"
                    :key="widget.id"
                  >
                    <div
                      class="preview-widget pb-4"
                      v-html="widget.html_preview"
                    />
                  </div>
                </a>
              </b-col>
            </b-row>
          </div>
        </template>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import ProductModel from "@/model/ProductModel.js";
import eshopData from "@/mixin/eshopData.js";

export default {
  name: "AiLayout",

  mixins: [eshopData],

  props:{
    layout: {
      type: Number,
      required: true,
    }
  },

  async mounted(){
    await this.fetchData();
  },

  data() {
    return {
      isLoaded: false,
      widget: [],
      productId: null,
      params: {
        offset: 1,
        limit: 1,
        total: 0,
        query: "",
        filter: 1,
      },
    };
  },

  methods:{
    async fetchData(){
      const { params } = this;
      await ProductModel.findAll(
        params,
        () => {},
        (data) => {
          this.productId = data.product[0].id;
          this.getWidgetData();
        }
      );
    },

    async getWidgetData(){
      const { productId } = this;

      await ProductModel.findCurrent(
        productId,
        () => {},
        (data) => {
          const { widget } = data;

          this.widget = widget;

          this.isLoaded = true;
        }
      );
    },

    async switchLayout(id){
      this.$emit('switchLayout', id);
    }
  },

};
</script>
