<template>
  <b-row>
    <b-col
      v-for="(version, key) in ['standard', 'share']"
      :key="key"
      cols="6"
    >
      <template v-if="quality(content[version]).percent !== 0">
        <b-progress
          :max="100"
          :value="quality(content[version]).percent"
          height="16px"
          show-progress
        >
          <b-progress-bar
            :class="quality(content[version]).class"
            :value="quality(content[version]).percent"
          >
            {{ quality(content[version]).percent }} %
          </b-progress-bar>
        </b-progress>
        <small class="text-center mt-2 d-block text-muted lh-16">
          {{ $t("tooltip.widget.widget", content[version]) }}
        </small>
      </template>
      <small
        v-else
        class="text-muted d-block fs-11 text-center"
      >
        {{ $t(`The ${version} is without widgets`) }}
      </small>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "GridItemQualityInline",

  props: {
    content: {
      type: Object,
      require: true,
    },
  },

  methods: {
    quality(percent) {
      switch (percent) {
      case 0:
        return {
          percent: 0,
          class: "bg-danger",
        };
      case 1:
        return {
          percent: 25,
          class: "bg-danger",
        };
      case 2:
        return {
          percent: 50,
          class: "bg-warning",
        };
      case 3:
        return {
          percent: 75,
          class: "bg-secondary",
        };
      case 4:
        return {
          percent: 100,
          class: "bg-secondary",
        };
      default:
        return {
          percent: 100,
          class: "bg-secondary",
        };
      }
    },
  },
};
</script>
