<template>
  <div class="product-autocomplete">
    <div class="product-autocomplete__input">
      <template v-if="selected">
        <span
          class="form-control"
          @click="selected = null"
        >
          {{ selected.name }}
        </span>
      </template>
      <template v-else>
        <form
          class="input-group position-relative"
          @submit.prevent="actionRead"
        >
          <input
            id="id"
            v-model="params.query"
            type="text"
            class="form-control"
            :placeholder="$t('search.placeHolderTwo')"
            autocomplete="off"
            @click="handleOpen(true)"
          >
          <div class="input-group-append">
            <button
              class="btn btn-secondary"
              type="button"
            >
              {{ $t("search.button") }}
            </button>
          </div>
        </form>
      </template>
    </div>
    <div
      v-if="products.product && isOpen && !selected"
      class="product-autocomplete__container"
    >
      <div
        v-for="product in products.product"
        :key="product.id"
        class="product-autocomplete__products"
      >
        <div class="product-autocomplete__title">
          <a
            :href="product.url"
            target="_blank"
          >
            {{ product.name }} <i class="ico ico-external" />
          </a>
        </div>

        <div class="product-autocomplete__button">
          <a
            class="btn btn-secondary btn-sm btn-block text-white"
            @click="handleSelect(product)"
          >
            {{ $t("productAutoComplete.choose") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdminModel from "@/model/AdminModel.js";

export default {
  name: "product-autocomplete",

  props: {
    userId: {
      type: Number,
      required: true,
    },
  },

  async mounted() {
    await this.actionRead();

    this.params.userid = this.userId;
  },

  data() {
    return {
      products: [],
      params: {
        userid: null,
        query: "",
        offset: 1,
        limit: 10,
        filter: 2,
      },
      total: 0,
      selected: null,
      isOpen: false,
    };
  },

  methods: {
    handleSelect(product){
      this.selected = product;
      this.$emit("select-product", product);
    },

    handleOpen(state){
      this.isOpen = state;
    },

    async actionRead() {
      const { userId } = this;

      const { params } = this;

      await AdminModel.findProduct(
        params,
        () => {},
        (data) => {
          this.products = data;
        }
      );
    },
  },
};
</script>

<style scoped></style>
