import axios from "axios";

export default class AdaptationModel {
  static async create(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .post("/api/v2/template/detail", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error adaptation [create()]", error);
        return error;
      });
  }
}
