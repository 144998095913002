import { mapWritableState } from "pinia/dist/pinia";
import { useEshopStore } from "@/store/EshopStore";

export default {
  data() {
    return {
      formData: {},
      errors: {},
    };
  },

  async mounted() {
    this.formData = this.form;
  },

  computed: {
    ...mapWritableState(useEshopStore, {
      form: "form",
    }),
  },

  methods: {
    async actionUpdate() {
      const { formData } = this;
      await useEshopStore()
        .updateEshop({
          data: formData,
          flash: {
            title: this.$t("flash.success"),
            text: this.$t("flash.user.update"),
          },
        })
        .then(() => {
          this.$i18n.locale = formData.lang;
        })
        .catch((error) => {
          this.errors = error;
        });
    },
  },
};
