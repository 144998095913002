import axios from "axios";

export default class IconModel {
  static async findAll(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/image/icon")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch icon [findAll()]", error);
        return error;
      });
  }

  static async findMe(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/image/icon/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch icon [findMe()]", error);
        return error;
      });
  }
}
