import axios from "axios";

export default class WidgetCategoryModel {
  static async findMe(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/widget/category/grid/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch detail widgetCategory  [findMe()]", error);
        return error;
      });
  }

  static async findAll(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/widget/category/grid")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch widgetCategory [findAll()]", error);
        return error;
      });
  }

  static async saveMe(
    data,
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();
    return await axios
      .put("/api/v2/widget/category/grid", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        errorCallback(error);
        console.warn("Error widgetCategory [saveMe()]", error);
        return error;
      });
  }

  static async create(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .post("/api/v2/widget/category/grid", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create widget category [create()]", error);
        return error;
      });
  }

  static async saveCategoryList(
    data,
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();
    return await axios
      .put("/api/v2/widget/category/update", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        errorCallback(error);
        console.warn("Error widgetCategoryList [saveMe()]", error);
        return error;
      });
  }
}
