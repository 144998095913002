<template>
  <div>
    <label>1. Vyberte uživatele</label>
    <user-autocomplete @select-user="selectUser" />
    <hr>
    <template v-if="user">
      <label>2. Vyberte produkt</label>
      <small class="d-block text-dark mb-2">Zobrazuji pouze upravené produkty.</small>
      <product-autocomplete
        :user-id="user.id"
        @select-product="selectProduct"
      />
    </template>

    <button
      class="btn btn-secondary mt-4 btn-block"
      @click="actionCreate"
    >
      Odeslat demonstrativní popisek »
    </button>
  </div>
</template>

<script>
import ProductAutocomplete from "@/components/dashboard/part/product-autocomplete.vue";
import UserAutocomplete from "@/components/dashboard/part/user-autocomplete.vue";
import AdminModel from "@/model/AdminModel.js";

export default {
  name: "create-demonstration",
  data() {
    return {
      user: null,
      product: null,
    };
  },

  components: {
    "product-autocomplete": ProductAutocomplete,
    "user-autocomplete": UserAutocomplete,
  },

  methods: {
    selectUser(user) {
      this.user = user;
    },

    selectProduct(product) {
      this.product = product;
    },

    async actionCreate() {
      const { product, user } = this;

      await AdminModel.createDemonstration(
        { product_id: product.id, user_id: user.id },
        () => {},
        (data) => {
          this.$emit("handle-close", data);
        }
      );
    },
  },
};
</script>
