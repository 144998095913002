<template>
  <div>
    <layout-setting anchor="editor">
      <template #header>
        {{ $t("customize.header") }}
      </template>
      <template #content>
        <customize :auto-open="false" />
      </template>
    </layout-setting>


    <layout-setting
      anchor="erasure"
      :is-danger="true"
      :has-background="false"
    >
      <template #header>
        {{ $t("Data erasure options") }}
      </template>
      <template #content>
        <erasure-setting class="p-4" />
      </template>
    </layout-setting>
  </div>
</template>

<script>
import Customize from "@/components/setting/part/customize.vue";
import ErasureSetting from "@/components/setting/part/erasure-setting.vue";

export default {
  name: "SettingEditor",
  displayName: "editor",

  components: {
    "erasure-setting": ErasureSetting,
    customize: Customize,
  },
};
</script>