<template>
  <div>
    <b-form @submit.prevent="actionCreate">
      <b-form-group
        :description="$t('connect.user.form.description')"
        :label="$t('connect.user.form.email')"
      >
        <b-form-input
          v-model="form.email"
          :class="errors.email ? 'is-invalid' : ''"
          :placeholder="$t('connect.user.form.placeholder')"
          class="form-control"
          type="email"
        />

        <div
          v-if="errors"
          class="mt-1"
        >
          <small
            v-for="(error, index) in errors.email"
            :key="index"
            class="text-danger d-block"
            v-html="$t(error)"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <b-button
          type="submit"
          variant="secondary"
        >
          {{ $t("connect.user.form.submit") }}
        </b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import ConnectUser from "@/model/ConnectUser.js";

export default {
  name: "ModalConnectEshop",
  displayName: "ModalConnectEshop",

  data() {
    return {
      form: {
        email: "",
      },
      errors: {},
    };
  },

  methods: {
    async actionCreate() {
      const { form } = this;

      await ConnectUser.createConnection(
        form,
        () => {},
        (data) => {
          this.$emit("handle-close", data);
        },
        ({ errors }) => {
          this.errors = errors;
        }
      );
    },
  },
};
</script>