<template>
  <div class="mt-4">
    <ul class="nav nav-tabs">
      <li
        v-for="(item, index) in list"
        :key="index"
        class="nav-item"
      >
        <router-link
          :to="{ name: item.path }"
          class="border-0 nav-link"
        >
          {{ item.name }}
        </router-link>
      </li>
    </ul>

    <router-view />
  </div>
</template>

<script>
export default {
  name: "Admin",

  computed: {
    list() {
      return [
        {
          name: "Dashboard",
          path: "dashboard"
        },
        {
          name: "E-shop",
          path: "eshop"
        },
        {
          name: "Demonstration",
          path: "demonstration"
        },
        {
          name: "Invoices",
          path: "invoice"
        },
        {
          name: "Widgets",
          path: "component"
        },
        {
          name: "Onboard",
          path: "onboard"
        },
        {
          name: "Design",
          path: "template"
        }
      ]
    }
  }
};
</script>
