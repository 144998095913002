<template>
  <div class="footer__container mt-5 py-5 border-top bg-dark">
    <div class="container py-5 text-center">
      <div class="m-auto">
        <h5 class="d-block mb-5 text-white">
          {{ $t("footer.team") }}
        </h5>

        <div class="footer__help">
          <div class="footer__help-box">
            <div class="footer__help-img">
              <img
                class="img-circle img-fluid"
                src="https://pobo-cdn.b-cdn.net/static/marketa-zurkova-team.png"
              >
            </div>
            <div class="footer__help-name">
              {{ $t("footer.teams.name.marketa") }}
            </div>

            <div class="footer__help-position">
              {{ $t("footer.teams.position.marketa") }}
            </div>
          </div>

          <div class="footer__help-box">
            <div class="footer__help-img">
              <img
                class="img-circle img-fluid"
                src="https://pobo-cdn.b-cdn.net/static/sara-pietakova.png"
              >
            </div>
            <div class="footer__help-name">
              {{ $t("footer.teams.name.sara") }}
            </div>

            <div class="footer__help-position">
              {{ $t("footer.teams.position.sara") }}
            </div>
          </div>

          <div class="footer__help-box">
            <div class="footer__help-img">
              <img
                class="img-circle img-fluid"
                src="https://pobo-cdn.b-cdn.net/static/michal-stikar-team-2.png"
              >
            </div>
            <div class="footer__help-name">
              {{ $t("footer.teams.name.michal") }}
            </div>

            <div class="footer__help-position">
              {{ $t("footer.teams.position.michal") }}
            </div>
          </div>

          <div class="footer__help-box">
            <div class="footer__help-img">
              <img
                class="img-circle img-fluid"
                src="https://pobo-cdn.b-cdn.net/static/tomas-smetka-cto.png"
              >
            </div>
            <div class="footer__help-name">
              {{ $t("footer.teams.name.tomas") }}
            </div>

            <div class="footer__help-position">
              {{ $t("footer.teams.position.tomas") }}
            </div>
          </div>
          
          <div class="footer__help-box">
            <div class="footer__help-img">
              <img
                class="img-circle img-fluid"
                src="https://pobo-cdn.b-cdn.net/static/vojtech-vanek-team.png"
              >
            </div>
            <div class="footer__help-name">
              {{ $t("footer.teams.name.vojta") }}
            </div>

            <div class="footer__help-position">
              {{ $t("footer.teams.position.vojta") }}
            </div>
          </div>

          <div class="footer__help-box">
            <div class="footer__help-img">
              <img
                class="img-circle img-fluid"
                src="https://pobo-cdn.b-cdn.net/static/mzak-team.png"
              >
            </div>
            <div class="footer__help-name">
              {{ $t("footer.teams.name.marek") }}
            </div>

            <div class="footer__help-position">
              {{ $t("footer.teams.position.marek") }}
            </div>
          </div>
        </div>

        <h5 class="mb-4 p-0 mt-5 pt-5 text-white mr-5">
          {{ $t("footer.contact") }}
        </h5>

        <b-row class="text-white col-12 col-lg-10 m-auto">
          <b-col cols="4">
            <a
              class="text-white text-decoration-none"
              :href="getSupportUrl"
            >
              <div>
                <i class="ico ico-email">&nbsp;</i>
              </div>
              {{ $t("menu.help.form") }}
            </a>
          </b-col>
          <b-col cols="4">
            <div>
              <i class="ico ico-phone">&nbsp;</i>
            </div>
            {{ $t("menu.help.phone") }}
          </b-col>

          <b-col cols="4">
            <router-link
              class="d-block text-white"
              to="/"
            >
              <div>
                <i class="ico ico-setting-footer">&nbsp;</i>
              </div>
              {{ $t("menu.help.account") }}
            </router-link>
          </b-col>
        </b-row>
      </div>

      <small
        class="text-center mb-2 mt-4 d-block"
        style="color: #c2c2c2"
      >
        (c) 2020 - 2023 Page Builder s.r.o., All rights reserved
        <span class="d-inline-block px-2">|</span>
        {{ $t("state.version.app") }}: {{ version }}@{{ environment }}
      </small>
    </div>
  </div>
</template>

<script>
import pcg from "@/../package.json";
import eshopData from "@/mixin/eshopData";
export default {
  name: "Footer-template",

  mixins:[eshopData],

  data() {
    return {
      supportEmail: import.meta.env.VITE_SUPPORT_EMAIL
    }
  },

  computed: {
    version() {
      return pcg.version;
    },

    getSupportUrl(){
      return "https://pobo-support.com/" + this.editorLang + "/?eshop=" + this.eshopUrl + "&tariff=" + this.eshopTariff + "&multilang=" + this.isConfigureLang + "&is-shoptet=" + this.isShoptet;
    },

    environment() {
      return pcg.environment;
    }
  },
};
</script>
