<template>
  <p
    v-if="product.length >= 500"
    class="mb-4 text-sm p-3 rounded fs-15 information-box"
  >
    <i class="ico ico-warning" />
    <strong>{{ $t("Notice") }}:</strong>
    {{
      $t(
        "Product display speed may be slower if you have many products in your shop"
      )
    }}.
  </p>

  <div
    v-if="limitIsMet"
    class="p-3 shadow-sm rounded fs-15 lh-15 mb-4 information-box"
  >
    <b-row align-v="center">
      <b-col cols="10">
        <b-icon
          animation="cylon"
          class="mr-2"
          font-scale="4"
          icon="info-circle"
          size="20"
          variant="danger"
        />
        {{ $t("You have exceeded the product limit for shared labels in your plan. If you want to continue, choose a higher plan.")
        }}
      </b-col>
      <b-col
        class="text-right"
        cols="2"
      >
        <router-link
          :to="{ name: 'change-tariff' }"
          class="btn btn-sm btn-secondary text-white"
          style="text-decoration: none"
        >
          {{ $t("Switch to a paid tariff") }}
          <b-icon
            icon="chevron-double-right"
            size="10"
          />
        </router-link>
      </b-col>
    </b-row>
  </div>

  <b-row>
    <b-col cols="8">
      <grid-filter
        :current="params.filter"
        :list="listFilter"
        class="d-flex align-items-end"
        @change="filterProduct"
      >
        <template #additional>
          <filter-category
            @input="updateCategory"
          />
        </template>
      </grid-filter>
    </b-col>
    <b-col
      class="pr-3"
      cols="4"
    >
      <grid-search
        @input="updateQuery"
        @submit="searchProduct"
      />
    </b-col>
  </b-row>

  <div
    :class="['product-simple-table--head text-left',
             hideCountColumn ? 'product-simple-table--head-count' : '',
    ]"
  >
    <div />
    <div>{{ $t("Product content") }}</div>
    <div>{{ $t("Share content") }}</div>
    <div>{{ $t("product.name") }}</div>
    <div>
      <div class="d-flex float-right">
        <div
          v-if="!isCopyingBetweenShops"
        >
          <b-btn
            size="sm"
            variant="secondary"
            data-cy="product-match-all"
            @click="findDuplicity"
          >
            <template v-if="!wereFoundDuplicity">
              <i class="ico ico-search" />
              {{ $t("Find duplicates") }}
            </template>
            <template v-else>
              {{ $t("Cancel filtering") }}
            </template>
          </b-btn>
        </div>
        <div class="ml-2">
          <confirm-delete
            v-if="selected.length > 0"
            :id="0"
            ref="confirm"
            @delete="deleteSelected"
          >
            <template #trigger>
              <i class="ico ico-trash" /> {{ $t("Remove") }}
              {{ $t("inflection.enum.product", selected.length) }}
            </template>
          </confirm-delete>
        </div>
        <div class="ml-2">
          <tier-control
            minimum-tariff="profi"
            position="right"
            :allow-tariff="['profi', 'premium', 'sass']"
          >
            <b-btn
              size="sm"
              variant="secondary"
              data-cy="product-match-all"
              @click="selectAllProduct"
            >
              <i class="ico ico-control" /> {{ $t("Select all") }}
            </b-btn>
          </tier-control>
        </div>
      </div>
    </div>
  </div>

  <div class="share-content__list">
    <template v-if="isLoaded">
      <div
        v-if="product.length === 0"
        class="text-center text-muted h4 py-5 font-weight-light"
      >
        {{ $t("grid.notFound") }}
      </div>

      <template v-if="product.length > 0">
        <div
          v-for="item in product"
          :key="item.id"
          data-cy="product-match-single"
          :class="[
            'product-simple-table',
            hideCountColumn ? 'product-simple-table--count-hide' : '',
            isMatched(item.id) ? 'checked' : null,
          ]"
          @click="productHandler(item.id)"
        >
          <div>
            <div class="product-simple-table__img">
              <template v-if="item.image">
                <b-img-lazy
                  :src="item.image"
                  class="product-simple-table__image"
                />
              </template>
              <template v-else>
                <b-img-lazy
                  class="product-simple-table__image"
                  src="https://pobo-cdn.b-cdn.net/static/no-image-icon.png"
                />
              </template>
            </div>
          </div>
          <div>
            <grid-item-quality-inline :content="item.content" />
          </div>
          <div
            v-if="!hideCountColumn"
            class="text-right"
          >
            <span class="d-block fs-15">
              {{ count(item.read.end) }}
              <i class="ico ico-filter-basket" />
            </span>

            <span class="d-block fs-15">
              {{ count(item.read.start) }}
              <i class="ico ico-filter-eye" />
            </span>
          </div>
          <div>
            <div class="pl-3 text-left">
              <link-cutter
                :url="item.url"
                :name="item.name"
                :max-width="400"
              />

              <template v-if="item.category.length">
                <small class="text-muted d-block mt-1">
                  <i class="ico ico-folder" />
                  <template v-for="(cat, index) in item.category">
                    {{ cat.name }}
                    <template v-if="index + 1 < item.category.length">,
                    </template>
                  </template>
                </small>
              </template>
            </div>
          </div>
          <div
            class="text-right"
            data-cy="match-single-product-checkbox"
          >
            <i
              :class="[
                'ico',
                isMatched(item.id)
                  ? 'ico-checked-lg'
                  : 'ico-checked-lg-disable',
              ]"
            />
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div
        v-for="n in 25"
        :key="n"
        class="row py-1 border-bottom align-content-center"
      >
        <div class="col-12">
          <b-skeleton-img
            height="31px"
            no-aspect
          />
        </div>
      </div>
    </template>
  </div>

  <b-row>
    <b-col :cols="hideSubmitButton ? 12 : 6">
      <div class="d-flex align-items-center">
        <div>
          <b-pagination
            v-model="params.offset"
            :per-page="params.limit"
            :total-rows="params.total"
            class="m-0"
            pills
            size="sm"
            @change="productPaginator"
          />
        </div>
        <div
          v-if="params.total > 500"
          class="pl-4 font-weight-bolder fs-15"
        >
          <i class="ico ico-warning mr-2" />
          {{ $t("Showing") }}
          {{ $t("inflection.enum.product", product.length) }}
          {{ $t("from") }} {{ params.total }}.
        </div>
      </div>
    </b-col>

    <b-col
      class="text-right"
      :cols="hideSubmitButton ? 0 : 6"
    >
      <b-btn
        v-if="selected.length > 0 && !hideSubmitButton"
        :disabled="limitIsMet"
        class="mt-2"
        variant="secondary"
        data-cy="match-single-product-submit-btn"
        @click="submitProduct"
      >
        {{ $t("Save") }}
        {{ $t("inflection.enum.product", selected.length) }}
        <i class="ico ico-arrow-right" />
      </b-btn>
    </b-col>
  </b-row>
</template>

<script>
import { count } from "@/filter/vue";
import FilterCategory from "@/share/filter-category.vue";
import ProductModel from "@/model/ProductModel.js";
import GridFilter from "@/share/grid-filter.vue";
import { share } from "@/config/filter.json";
import GridSearch from "@/share/grid-search.vue";
import TierControl from "@/share/tier-control.vue";
import { useEshopStore } from "@/store/EshopStore";
import { mapWritableState } from "pinia";
import GridItemQualityInline from "@/share/grid-item-quality-inline.vue";
import eshopData from "@/mixin/eshopData";
import matchProductShortcuts from "@/mixin/shortcuts/matchProductShortcuts";
import LinkCutter from "@/share/link-cutter.vue";

export default {
  name: "match-single-product",

  mixins: [eshopData, matchProductShortcuts],

  props: {
    shareSelected: {
      type: Object,
      require: false
    },
    type: {
      type: String,
      require: true
    },
    hideSubmitButton: {
      type: Boolean,
      default: false,
      require: false
    },
    hideCountColumn: {
      type: Boolean,
      default: false,
      require: false
    },
    selectedProduct: {
      type: [Object, null],
      require: false
    },
    allSelectedProductId: {
      type: Array,
      require: false
    },
    eshopId: {
      type: Number,
      required: false,
      default: null
    }
  },

  async mounted() {
    await this.searchProduct();

    if (this.allSelectedProductId) {
      this.internalAllSelectedProductId = this.allSelectedProductId;
    }

    if (this.shareSelected) {
      this.selected = this.shareSelected;
    }
  },

  data() {
    return {
      data: [],
      selected: [],
      isLoaded: false,
      listFilter: share,
      internalAllSelectedProductId: [],
      wereFoundDuplicity: false,
      params: {
        shareid: "",
        offset: 1,
        limit: 50,
        total: 0,
        query: "",
        filter: 1,
        category: []
      }
    };
  },

  computed: {
    ...mapWritableState(useEshopStore, {
      eshop: "detail"
    }),

    selectedProductId() {
      if (this.selectedProduct) {
        return this.selectedProduct.id;
      }
      return null;
    },

    product() {
      if (this.selectedProductId === null) {
        return this.data;
      }

      return this.data.filter((item) => item.id !== this.selectedProductId);
    },

    limitIsMet() {
      if (this.type === "share") {
        if (this.eshopTariff !== "start" || this.eshop.platform !== "shoptet") {
          return false;
        }
        const uniqueProductCount = new Set(this.internalAllSelectedProductId).size;
        return uniqueProductCount > 100;
      } else {
        return false;
      }
    },

    isCopyingBetweenShops() {
      const { eshop, eshopId } = this;
      return eshop.id !== eshopId;
    }
  },

  watch: {
    eshopId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.changeId();
      }
    }
  },

  components: {
    LinkCutter,
    "filter-category": FilterCategory,
    "grid-filter": GridFilter,
    "grid-search": GridSearch,
    "tier-control": TierControl,
    "grid-item-quality-inline": GridItemQualityInline
  },

  methods: {
    count,
    deleteSelected() {
      this.selected = [];
      this.$refs.confirm.isOpen = false;
    },

    findDuplicity() {
      if (this.selectedProduct) {
        let productName = this.selectedProduct.name;
        let words = productName.split(" ");

        if (words.length > 3) {
          productName = words.slice(0, -1).join(" ");
        }

        if (!this.wereFoundDuplicity) {
          this.updateQuery(productName);
        } else {
          this.updateQuery("");
        }

        this.searchProduct();
        this.wereFoundDuplicity = !this.wereFoundDuplicity;
      }
    },

    updateCategory(category) {
      this.params.category = category;
      this.searchProduct();
    },

    isMatched(id) {
      const { selected } = this;
      return selected.includes(id);
    },

    selectAllProduct() {
      const { product } = this;
      const { eshopTariff } = this;

      if (eshopTariff !== "start") {
        Array.from(product)
          .map(({ id }) => id)
          .forEach((id) => {
            if (this.selected.includes(id)) {
            } else {
              this.selected.push(id);
            }
          });
      }
    },

    updateQuery(query) {
      this.params.query = query;
    },

    productHandler(id) {
      const { selected } = this;

      const index = selected.indexOf(id);
      if (index > -1) {
        selected.splice(index, 1);

        const internalIndex = this.internalAllSelectedProductId.indexOf(id);
        if (internalIndex > -1) {
          this.internalAllSelectedProductId.splice(internalIndex, 1);
        }
      } else {
        selected.push(id);
        this.internalAllSelectedProductId.push(id);
      }

      this.$emit("selected", selected);
    },

    async filterProduct(filter) {
      this.params.filter = filter;
      this.params.offset = 1;
      await this.searchProduct();
    },

    async submitProduct() {
      if (!this.limitIsMet) {
        this.$emit("submit", this.selected);
      }
    },

    async setFilter(type) {
      this.params.filter = type;
      await this.searchProduct();
    },

    async productPaginator(count) {
      this.params.offset = count;
      await this.searchProduct();
    },

    async changeId() {
      this.selected = [];
      await this.searchProduct();
    },

    async searchProduct() {
      this.isLoaded = false;
      const { params } = this;
      await ProductModel.findAll(
        params,
        () => {
        },
        (data) => {
          this.data = data.product;
          this.params.total = data.total;
          this.all = data.all;
        }, this.eshopId
      );
      this.isLoaded = true;
    }
  }
};
</script>
