<template>
  <div class="content-editor__image-scroll">
    <template v-if="isLoaded">
      <template v-if="images.length > 0">
        <b-row>
          <b-col
            v-for="image in images"
            :key="image.id"
            cols="4"
          >
            <div class="content-editor__image-container shadow-sm rounded mb-4">
              <div class="content-editor__image">
                <a
                  href="#"
                  class="content-editor__zoom"
                  @click.prevent="showImage(image.path)"
                >
                  <i
                    class="ico ico-zoom"
                  />
                </a>
                <a
                  class="content-editor__image-link"
                  href="#"
                  data-cy="library-photobank-select-image-btn"
                  :data-text="$t('widgetTree.modal.image.selectImage')"
                  @click.prevent="selectImage(image.path)"
                >
                  <b-img-lazy
                    :src="image.path"
                    class="content-editor__img"
                  />
                </a>
              </div>

              <div class="pt-0">
                <b-button
                  size="sm"
                  class="w-100"
                  type="secondary"
                  @click="selectImage(image.path)"
                >
                  {{ $t("widgetTree.modal.image.selectImage") }}
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <div class="text-center">
          <h3 class="py-4 font-weight-light">
            {{ $t("widgetTree.modal.image.photoBank.download") }}
          </h3>

          <b-btn
            variant="secondary"
            @click="$emit('change-view', 4)"
          >
            {{ $t("widgetTree.modal.image.photoBank.goToPhotoBank") }}
            <i class="ico ico-arrow-right" />
          </b-btn>
        </div>
      </template>
    </template>
    <template v-else>
      <b-row>
        <b-col
          v-for="n in 20"
          :key="n"
          cols="3"
        >
          <div class="content-editor__image-container shadow-sm rounded mb-4">
            <div class="content-editor__image">
              <b-skeleton-img
                height="130px"
                no-aspect
              />
            </div>

            <div class="pt-2">
              <b-skeleton-img
                height="30px"
                no-aspect
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </template>

    <b-modal
      v-model="showDwImage"
      :title="$t('widgetTree.modal.image.preview')"
      hide-footer
      size="ai"
    >
      <div class="content-editor__image-preview">
        <b-img-lazy :src="showImagePath" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import ImageModel from "@/model/ImageModel.js";

export default {
  name: "ImagePhotoDownloaded",

  mounted() {
    this.actionRead();
  },

  data() {
    return {
      images: Array,
      showDwImage: false,
      showImagePath: false,
      isLoaded: false,
    };
  },

  methods: {
    async actionRead() {
      this.isLoaded = false;
      await ImageModel.findAll(
        () => {},
        (data) => {
          this.images = data;
          this.isLoaded = true;
        }
      );
    },

    showImage(image) {
      this.showDwImage = true;
      this.showImagePath = image;
    },

    selectImage(image) {
      this.$emit("select-image", image);
    },
  },
};
</script>