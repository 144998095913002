<template>
  <span class="position-relative">
    <a
      :href="url"
      target="_blank"
      class="link-cutter"
      :style="`max-width: ${maxWidth}px`"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      {{ name }}
      <i class="ico ico-external" />
    </a>
    <span
      v-show="isActive && false"
      class="box"
    >
      adapsdkapdmapdmpa
    </span>
  </span>
</template>
<script>
export default {
  name: "LinkCutter",
  props: {
    name: {
      type: String,
      required: true,
    },

    url: {
      type: String,
      required: true,
    },

    maxWidth: {
      type: Number,
      default: 200,
    }
  },

  data() {
    return {
      hoverTimeoutId: null,
      isActive: false,
    };
  },
  methods: {
    handleMouseEnter() {
      this.hoverTimeoutId = setTimeout(this.hover, 500);
    },
    handleMouseLeave() {
      if (this.hoverTimeoutId) {
        clearTimeout(this.hoverTimeoutId);
        this.isActive = false;
      }
    },
    hover() {
      this.isActive = true;
    }
  }
};
</script>

<style lang="scss">
.link-cutter {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  font-weight: 500;
  position: relative;
  z-index: 10;
  padding: 2px 4px;
  border-radius: 2px;
  margin: 0 0 -8px 0;
  font-size: 15px;

  &:hover {
    text-decoration: underline;
    background-color: rgba(255, 251, 0, 0.40);
  }
}
</style>