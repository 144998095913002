<template>
  <div class="modal-image-dropdown">
    <button
      class="modal-image-dropdown__button fs-14"
      data-cy="modal-image-dropdown"
      @click="handleClick"
    >
      {{ $t("widgetTree.modal.image.dropdown") }}
      <b-icon
        v-if="isOpen"
        icon="arrow-up-square"
        class="ml-2"
        size="13"
      />
      <b-icon
        v-else
        icon="arrow-down-square"
        class="ml-2"
        size="13"
      />
    </button>
    <div
      v-if="isOpen"
      class="modal-image-dropdown__content"
    >
      <slot name="content" />
    </div>
  </div>
</template>
<script>
export default {
  name: "DropdownImageSection",
  props: {
    isOpen: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit('toggle');
    },
  },
}
</script>