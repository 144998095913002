<template>
  <b-btn
    class="pr-3"
    size="sm"
    variant="secondary"
    :disabled="isLocked"
    @click="openModal()"
  >
    <b-spinner
      v-if="enableRequest"
      small
    />
    <i
      v-else
      class="ico ico-control"
    />
    {{
      enableRequest
        ? $t("control.status.running.button")
        : $t("control.status.complete.button")
    }}
  </b-btn>

  <span
    v-if="false"
    class="text-muted text-center mt-2 d-block"
    style="font-size: 13px; line-height: 15px"
  >
    <template v-if="enableRequest">
      {{ $t("control.status.complete.info") }}
    </template>
    <template v-else>
      {{ $t("control.status.running.info") }}
    </template>
  </span>

  <modal-panel
    ref="modalPanel"
    :hide-header-close="enableRequest"
    :no-close-on-backdrop="enableRequest"
    :no-close-on-esc="enableRequest"
    :title="$t('control.modal.enableRequest.title')"
    :header="$t('control.modal.enableRequest.info')"
    @close-modal="$emit('close-modal')"
  >
    <template #action>
      <b-btn
        :disabled="enableRequest"
        variant="secondary"
        @click="actionCreate"
      >
        <i class="ico ico-control" />
        {{ $t("control.modal.enableRequest.submit") }}
      </b-btn>
    </template>
    <template #content>
      <div>
        <div class="row">
          <div
            v-if="enableRequest"
            class="text-center m-auto px-5"
          >
            <div class="text-center font-weight-bolder">
              <b-spinner
                label="Small Spinner"
                small
                style="width: 40px; height: 40px"
              />
            </div>
            <h4 class="mt-4 mb-0 pb-4">
              {{ $t("control.modal.waitingRequest.title") }}
            </h4>

            <p
              class="text-center"
              v-html="$t('control.modal.waitingRequest.info')"
            />
          </div>
        </div>

        <div class="text-center">
          <b-btn-group class="my-4">
            <b-btn
              :variant="setActiveClass('list')"
              @click="openTab('list')"
            >
              {{ $t("control.modal.tab.list") }}
            </b-btn>
            <b-btn
              :variant="setActiveClass('error')"
              @click="openTab('error')"
            >
              {{ $t("control.modal.tab.error") }}
            </b-btn>
          </b-btn-group>
        </div>

        <div
          v-if="isTabActive('list')"
          class="shadow-sm bg-light rounded p-2"
        >
          <table class="table mt-2">
            <thead>
              <tr class="font-weight-bolder">
                <td class="align-middle border-top-0">
                  {{ $t("control.modal.table.start") }}
                </td>
                <td class="align-middle border-top-0">
                  {{ $t("control.modal.table.finish") }}
                </td>
                <td class="align-middle border-top-0">
                  {{ $t("control.modal.table.state") }}
                </td>
              </tr>
            </thead>
            <tbody>
              <template v-if="cron.list.length">
                <tr
                  v-for="item in cron.list"
                  :key="item.id"
                >
                  <td class="align-middle">
                    {{ datetime(item.created_at) }}
                  </td>
                  <td class="align-middle">
                    <span v-if="item.updated_at">
                      {{ datetime(item.updated_at) }}
                    </span>
                    <span v-else>
                      {{ $t("control.modal.table.waitingForEnd") }}
                      <b-spinner
                        label="Small Spinner"
                        small
                      />
                    </span>
                  </td>

                  <td
                    class="align-middle"
                    style="width: 200px"
                  >
                    <b-progress
                      :max="100"
                      :value="item.control_progress"
                      animateht="15px"
                      striped="striped"
                      variant="secondary"
                    />

                    <small class="d-block text-center w-100 text-muted mt-1">
                      {{ $t("control.modal.table.finish") }}:
                      <strong>{{ item.control_progress }}% / 100%</strong>
                    </small>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr class="text-center">
                  <td
                    class="text-center font-weight-normal pt-4 text-muted"
                    colspan="3"
                  >
                    {{ $t("control.modal.notFound") }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <div
          v-if="isTabActive('error')"
          class="shadow-sm bg-light rounded p-2"
        >
          <table class="table mt-2">
            <thead>
              <tr class="bg-light font-weight-bolder">
                <td
                  class="align-middle border-top-0"
                  colspan="2"
                >
                  {{ $t("control.modal.table.productName") }}
                </td>
              </tr>
            </thead>
            <tbody>
              <template v-if="cron.error.length">
                <tr
                  v-for="product in cron.error"
                  :key="product.id"
                >
                  <td class="align-middle">
                    {{ product.name }}

                    <small class="d-block text-danger">
                      {{ $t("control.modal.table.longContent") }}
                    </small>
                  </td>
                  <td class="align-middle text-right">
                    <b-btn
                      size="sm"
                      variant="secondary"
                      @click="$emit('handle-edit', product)"
                    >
                      <i class="ico ico-edit" />
                      {{ $t("control.modal.table.edit") }}
                    </b-btn>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr class="text-center">
                  <td
                    class="text-center font-weight-normal pt-4 text-muted"
                    colspan="3"
                  >
                    {{ $t("control.modal.notFound") }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </modal-panel>
</template>

<script>
import ControlModel from "@/model/ControlModel.js";
import { datetime } from "@/filter/vue";
import ModalPanel from "@/components/share/modal-panel.vue";
import lockedData from "@/mixin/lockedData";

export default {

  mixins: [lockedData],

  async mounted() {
    await this.actionRead();

    await this.checkModal();

    setInterval(async () => {
      await this.checkModal();
    }, 5000);
  },

  data() {
    return {
      running: false,
      modalOpen: false,
      tabOpen: ["list"],
      cron: {
        stats: [],
        enable_request: true,
        list: [],
        error: [],
      },
      count: 0,
    };
  },

  computed: {
    enableRequest: function () {
      const { cron } = this;
      return !cron.enable_request;
    },
  },
  components: {"modal-panel" : ModalPanel},

  methods: {
    datetime,
    async checkModal() {
      if (this.enableRequest) {
        this.openModal();
        await this.actionRead();
      }
    },

    openTab(type) {
      this.tabOpen = type;
    },

    isTabActive(type) {
      return this.tabOpen.includes(type);
    },

    setActiveClass(type) {
      return this.tabOpen.includes(type) ? "secondary" : "light";
    },

    actionHide() {
      this.modalOpen = false;
    },

    async actionCreate() {
      await ControlModel.create(
        () => {},
        (data) => {
          this.cron = data;
        }
      );
    },

    async actionRead() {
      await ControlModel.findAll(
        () => {},
        (data) => {
          this.cron = data;
        }
      );
    },

    openModal() {
      this.$refs.modalPanel.isModalOpen = true;
    },
  },
};
</script>
