import { defineStore } from "pinia";
import CategoryModel from "@/model/CategoryModel";
import BlogModel from "@/model/BlogModel";
import ProductModel from "@/model/ProductModel";
import ShareModel from "@/model/ShareModel";

export const DEFAULT_STORE = {
  globalTabs: {
    category: [],
    blog: [],
    product: [],
    share: [],
  },
  globalActiveIndex: {
    category: null,
    blog: null,
    product: null,
    share: null,
  },
};

export const useTabsStore = defineStore("TabsStore", {
  state: () => {
    return {
      globalTabs: { ...DEFAULT_STORE.globalTabs },
      globalActiveIndex: { ...DEFAULT_STORE.globalActiveIndex },
    };
  },

  actions: {
    async setActiveIndex(type, index) {
      this.globalActiveIndex[type] = index;
    },

    async closeEditor(type, id) {
      const index = this.globalTabs[type].findIndex((item) => item.id === id);

      if (index !== -1) {
        this.globalTabs[type].splice(index, 1);
      }
    },

    async fetchTab(type, data) {
      const { id } = data;

      this.globalActiveIndex[type] = id;

      const index = this.globalTabs[type].findIndex((item) => item.id === data.id);

      if (index === -1) {
        let model;
        switch (type) {
        case "category":
          model = CategoryModel;
          break;
        case "blog":
          model = BlogModel;
          break;
        case "product":
          model = ProductModel;
          break
        case "share":
          model = ShareModel;
          break;
        default:
          throw new Error(`Unknown tab type: ${type}`);
        }

        await model.findMe(id).then((data) => {
          this.globalTabs[type].push(data);
        });
      }
    },
  },

  getters: {
	  getTabData: (state) => (type) =>{
		  return state.globalTabs[type].find(tab => tab.id === state.globalActiveIndex[type]);
	  },

	  getGlobalActiveIndex: (state) => (type) => {
		  return state.globalActiveIndex[type];
	  },

	  getGlobalTabs: (state) => (type) => {
		  return state.globalTabs[type];
	  },
  },
});