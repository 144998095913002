<template>
  <layout-grid :has-background="false">
    <template #title>
      {{ $t("Pre-prepared label layouts") }}
    </template>

    <template #welcome>
      <p>
        {{ $t('On this page, you can set the appearance of descriptions across products, categories and blog articles') }}.
        {{ $t('A quality template can increase the clarity and readability of content, which can lead to better understanding and faster decision making for customers') }}.
        {{ $t('In addition, a uniform and attractive label design helps build trust and reinforces the overall brand impression in the eyes of the customer') }}.
      </p>
    </template>

    <template #content>
      <h4 class="mt-4 mb-3 p-0 font-weight-bold">
        {{ $t('List of appearance templates') }}
      </h4>

      <b-row
        class="mb-2"
      >
        <b-col
          v-for="item in data.public"
          :key="item.id"
          cols="4"
        >
          <design-preview-public
            :design="item"
            @select-design="selectDesign"
            @try-preview="tryPreviewOpen"
            @open-preview="openPreview"
          />
        </b-col>
      </b-row>
    </template>
  </layout-grid>

  <b-modal
    v-model="isOpenPreviewModal"
    size="lg"
    content-class="editor-modal"
    hide-footer
    :title="opened.name"
  >
    <div class="text-center">
      <b-img-lazy
        v-if="opened.image_preview"
        class="shadow-lg rounded w-100 rounded"
        :src="opened.image_preview + '?width=900'"
      />
    </div>
  </b-modal>


  <b-modal
    v-model="isOpenTryPreviewModal"
    size="lg"
    content-class="editor-modal"
    hide-footer
    :title="$t('Template preview')"
  >
    <try-design :design="design" />
  </b-modal>
</template>

<script>
import eshopData from "@/mixin/eshopData";
import EshopModel from "@/model/EshopModel";
import DesignModel from "@/model/front/DesignModel";
import { useEshopStore } from "@/store/EshopStore";
import { showMessage } from "@/plugins/flashMessage";
import DesignPreviewPublic from "@/components/design/design-preview-public.vue";
import TryDesign from "@/components/design/try-design.vue";

export default  {
  name: "DesignPublic",

  mixins: [eshopData],

  async mounted() {
    const { eshop } = this;
    const { design_id } = eshop;
    this.selected = design_id
    this.data = await DesignModel.findAll()
  },

  data() {
    return {
      selected: null,
      opened: {},
      isOpenPreviewModal: false,
      isOpenTryPreviewModal: false,
      productUrl: '',
      design: null,
      data: {
        public: [],
        custom: [],
      }
    }
  },

  components: {
    "design-preview-public": DesignPreviewPublic,
    "try-design": TryDesign,
  },

  methods: {
    async selectDesign(design_id) {
      const { eshop } = this
      await EshopModel.saveMe({
        ...eshop,
        ...{ design_id },
      }).then(async () => {
        await useEshopStore().fetchEshop()
        showMessage("Design has been changed");
      })
    },

    openPreview(design) {
      this.opened = design;
      this.isOpenPreviewModal = true;
    },

    tryPreviewOpen(design) {
      this.design = design;
      this.isOpenTryPreviewModal = true;
    }
  }
}
</script>

<style lang="scss">
.design-preview {
  height: 350px;
  overflow-y: auto;
}
</style>
