<template>
  <div>
    <form-template
      @update-template="updateTemplate"
    />
  </div>
</template>

<script>
import FormTemplate from "@/components/account/form-template.vue";
import EshopModel from "@/model/EshopModel.js";

export default {
  mounted() {
    this.actionRead();
  },

  data() {
    return {
      eshop: {},
    };
  },
  components: {
    "form-template": FormTemplate,
  },

  metaInfo() {
    return { title: this.$t("meta.designer") };
  },

  methods: {
    updateTemplate(template) {
      this.eshop.template_id = template.id;
      this.actionUpdate();
    },

    async actionRead() {
      this.eshop = await EshopModel.findMe();
    },

    async actionUpdate() {
      const { eshop } = this;
      await EshopModel.saveMe(
        {
          template_id: eshop.template_id,
        },
        () => {},
        (data) => {}
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
