<template>
  <b-row v-if="allowAi">
    <b-col cols="6">
      <h5 class="mb-1">
        {{ $t("The label in the widget") }}
      </h5>
      <small class="text-muted d-block mb-2">
        {{ $t("Before you edit the text using AI, you can edit it (changes are not saved to the widget)") }}.
      </small>

      <div class="bg-white shadow-sm rounded">
        <transition name="fade">
          <b-skeleton-wrapper v-if="!showFroala">
            <b-skeleton
              type="text"
              animation="glow"
              width="100%"
              height="200px"
              class="b-skeleton-setting"
            />
          </b-skeleton-wrapper>
          <froala
            v-else
            v-model:value="editorModel"
            :config="remappingConfig"
          />
        </transition>
      </div>
    </b-col>
    <b-col cols="6">
      <h5 class="mb-3">
        {{ $t("Content creation options") }}
      </h5>
      <b-form
        class="bg-white p-4 shadow-sm rounded modal-text-ai-scroll"
        @submit.prevent="actionCreate"
      >
        <b-skeleton-wrapper v-if="!contentListFiltered.length">
          <b-skeleton
            v-for="n in 3"
            :key="n"
            type="text"
            animation="glow"
            height="20px"
            width="100%"
            class="mb-3 b-skeleton-setting"
          />
        </b-skeleton-wrapper>
        <b-form-group v-else>
          <b-form-radio
            v-for="item in contentListFiltered"
            :key="item.id"
            v-model="templateId"
            :value="item.id"
            class="mb-3"
            :disabled="Boolean(item.is_disabled)"
          >
            <span class="font-weight-bolder">
              {{ item.title }}
            </span>
            <small
              class="d-block text-black-50 pt-1"
              v-html="item.description"
            />
          </b-form-radio>
        </b-form-group>

        <b-form-group>
          <b-btn
            class="w-100 mt-1"
            type="submit"
            :disabled="!allowAi"
          >
            {{ $t('Enhance text') }} <i class="ico ico-arrow-right" />
          </b-btn>
          <small
            v-if="isShoptet"
            class="text-muted d-block text-center mt-3"
          >
            {{ $t("1 credit will be deducted by sending. To charge credits, please visit us") }}
            <router-link
              :to="{ name: 'credit' }"
              class="link text-secondary"
              target="_blank"
            >
              {{ $t("this page") }}
            </router-link>.
          </small>
        </b-form-group>
      </b-form>
    </b-col>
  </b-row>

  <template v-else>
    <warning-low-credits
      :price="price.format"
      image-type="michal"
      :show="!allowAi"
    />
    <div class="row text-center align-items-baseline">
      <div class="col-6">
        <b-skeleton-img
          height="200px"
          no-aspect
          width="100%"
          animation="glow"
          class="b-skeleton-setting"
        />
      </div>
      <div class="col-6">
        <b-skeleton-img
          height="200px"
          no-aspect
          width="100%"
          animation="glow"
          class="b-skeleton-setting"
        />
      </div>
    </div>
  </template>
</template>


<script>
import eshopData from "@/mixin/eshopData";
import WarningLowCredits from "@/share/warning-low-credits.vue";
import { useCreditStore } from "@/store/CreditStore";
import GenerativeModel from "@/model/GenerativeModel";

export default {
  name: "ModalTextAi",

  inject: ['pageType'],

  mixins: [eshopData],

  props: {
    config: {
      required: true,
      type: Object,
      default: () => {
        return {};
      }
    },

    valueLang: {
      required: true,
      type: Object
    },

    allowAi:{
      required: true,
      type: Boolean
    },

    item: {
      required: true,
      type: Object
    }
  },

  async mounted() {
    await useCreditStore().findHistory();
    await this.fetchData();
    this.editorModel = this.item.value[this.valueLang];

    setTimeout(() => {
      this.showFroala = true;
    }, 500);
  },

  data() {
    return {
      templateId: 0,
      showFroala: false,
      editorModel: "",
      contentList: [],
    };
  },

  computed: {
    remappingConfig() {
      const { config } = this;

      return { ...config, ...{
        toolbarSticky: true,
        charCounterCount: false
      }}
    },

    contentListFiltered() {
      const { pageType, contentList } = this

      if (pageType !== 'product') {
        return contentList.filter(item => item.eshop_id === null);
      }
      return contentList;
    }
  },
  components: {"warning-low-credits": WarningLowCredits},

  methods: {
    actionCreate() {
      const { editorModel, lang, templateId} = this;

      this.$emit("generate-ai-text", {
        html: editorModel,
        templateId,
        lang
      });
    },

    async fetchData(){
      await GenerativeModel.getContent(
        () => {},
        (data) => {
          this.contentList = data;
          this.templateId = data[0].id;
        }
      );
    }
  }
};
</script>

<style scoped>
  .b-skeleton-setting{
    background-color: #e0e0e0;
    border-radius: 8px;
  }

  .modal-text-ai-scroll{
    overflow-y: scroll;
    max-height: 390px;
  }
</style>
