
<template>
  <div class="px-4 pb-4">
    <p class="fs-17">
      Jaký je postup pro získání náhledů popisků vašeho e-shopu  na počítači a mobilu?
    </p>
    <div class="mt-4">
      <ul class="list-check">
        <li
          v-for="(item, index) in benefit"
          :key="index"
        >
          {{ item }}
        </li>
      </ul>

      <p class="fs-15 lh-22">
        Pro přidání šablony nám napište na e-mail <a
          href="mailto:vojta@pobo.cz"
          class="text-secondary"
        >vojta@pobo.cz</a>,
        v předmětu uveďte "<strong>Pobo - šablona</strong>",
        do e-mailu napište URL vašeho e-shopu a my se vám ozveme s dalšími informacemi.
      </p>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      benefit: [
        "Zašlete nám URL vašeho produktu",
        "Zkontrolujeme nastavení vzhledu a eventuelně doporučíme menší opravy",
        "Nafotíme náhledy na počítači a mobilu, poté je nahrajeme do editoru k vašemu e-shopu",
        "Napozicujeme popisky a náhledy na správná místa",
        "Náhledy jsou k dispozici v tarifu PROFI a PREMIUM",
      ],
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>