import axios from "axios";

export default class DesignModel {
  static async findAll(
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/admin/design/grid")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch design [findAll()]", error);
        return error;
      });
  }

  static async create(
    data = {},
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/admin/design/detail", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch design [create()]", error);
        return error;
      });
  }

  static async update(
    id,
    data = {},
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .put("/api/v2/admin/design/detail/" + id, data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch design [update()]", error);
        return error;
      });
  }

  static async connectMe(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/admin/design/connect", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch design [connectMe()]", error);
        return error;
      });
  }

  static async findConnections(
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/admin/design/connect")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch design [findConnections()]", error);
        return error;
      });
  }
}