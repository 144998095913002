<template>
  <div v-if="eshop">
    <div class="grid-account">
      <div class="grid-account__menu">
        <router-link
          v-for="(item, index) in filterNavigation"
          :key="index"
          :to="{ name: item.name }"
          class="grid-account__menu-link"
        >
          <b-icon
            :icon="item.icon"
            class="mr-3"
            size="16"
          />
          <span class="position-relative">{{ $t(item.lang.header) }}
            <b-badge
              v-if="item.minimumTariff"
              class="grid-account__menu-badge font-weight-normal fs-10"
              variant="secondary"
            >
              {{ item.minimumTariff.toUpperCase() }}
            </b-badge>
          </span>
        </router-link>
      </div>
      <div class="grid-account__content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import EshopModel from "@/model/EshopModel.js";
import InfoModel from "@/model/InfoModel.js";
import navigation from "@/components/setting/navigation.json";

export default {
  name: "setting",
  displayName: "setting",

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      eshop: null,
      company: null,
      navigation,
    };
  },

  computed: {
    filterNavigation() {
      const { navigation, eshop } = this;
      const { platform, role } = eshop;

      return navigation.filter(
        ({ visible, allowPlatform }) =>
          (visible === true || role.includes("admin")) &&
          allowPlatform.includes(platform)
      );
    },
  },

  metaInfo() {
    return { title: this.$t("meta.account") };
  },

  methods: {
    setActiveClass(component) {
      const { $route } = this;
      const { name } = $route;

      return [name === component ? "btn-secondary" : "btn-light", "btn"];
    },

    async actionRead() {
      this.eshop = await EshopModel.findMe();

      await InfoModel.findInfo(
        () => {},
        (data) => {
          this.company = data;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
