<template>
  <div v-if="isLoaded">
    <b-img
      fluid
      :src="editorConfig.image.mobile.top"
    />
    <div id="pobo-all-content">
      <div id="pobo-standard-widget">
        <div v-if="widget && widget.length > 0">
          <div
            v-for="item in widget"
            :key="item.id"
            :class="[item.enable_typography_css ? 'widget-typography' : 'widget-projector', 'widget-container']"
          >
            <div v-html="item.html" />
          </div>
        </div>
      </div>

      <div id="pobo-share-widget">
        <template
          v-for="share in share"
          :key="share.id"
        >
          <div
            v-for="widget in share.widget"
            :key="widget.id"
            class="widget-container"
            v-html="widget.html"
          />
        </template>
      </div>
    </div>
    <b-img
      fluid
      :src="editorConfig.image.mobile.bottom"
    />
  </div>
</template>
<script>
import axios from "axios";
import eshopData from "@/mixin/eshopData";
import { useEshopStore } from "@/store/EshopStore";
import ProductModel from "@/model/ProductModel";

export default {
  name: "Product",

  mixins: [eshopData],

  async mounted() {
    const { id } = this.$route.params

    const loadingElement = document.getElementById("hero-loading");
    if (loadingElement) {
      loadingElement.remove();
    }

    if (!id) {
      console.warn('No id provided')
      return;
    }

    await useEshopStore().fetchEshop();

    await axios.get("/api/v2/content/product/[id]/widget".replace("[id]", id)).then(({ data }) => {
      this.widget = data;
      this.$emit("action-update", data);
    });

    await ProductModel.findMe(id).then((data) => {
      this.share = data.share;
    });

    this.isLoaded = true;
  },

  data() {
    return {
      widget: [],
      share: [],
      isLoaded: false
    }
  },
}
</script>

<style lang="scss">
body {
  * {
    box-sizing: border-box;
  }
}
</style>