<template>
  <b-form @submit.prevent="actionUpdate">
    <p class="text-sm mb-3">
      {{ $t("Here you have the option to delete the data in Pobo Page Builder. Keep in mind that deleting data is irreversible") }}.
    </p>
    <b-form-group>
      <b-form-checkbox
        v-model="formData.analytics"
        :value="1"
        class="mb-2"
      >
        {{ $t("Deleting analytical data") }}
      </b-form-checkbox>
    </b-form-group>

    <b-form-group class="mt-4">
      <b-button
        type="submit"
        variant="danger"
        :disabled="!formData.analytics"
      >
        {{ $t("Permanently delete") }}
        <i class="ico ico-arrow-right" />
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { ref } from 'vue';
import EshopModel from "@/model/EshopModel";

export default {
  name: "ErasureSetting",
  displayName: "LangSetting",

  setup() {
    const formData = ref({
      analytics: false,
    });

    const actionUpdate = async () => {
      await EshopModel.erasureAnalyticsData();
    };

    return {
      formData,
      actionUpdate
    };
  },
};
</script>
