<template>
  <b-btn
    class="pr-3"
    size="sm"
    variant="secondary"
    :disabled="isLocked"
    @click="openModal()"
  >
    <b-spinner
      v-if="enableRequest"
      small
    />
    <i
      v-else
      class="ico ico-export"
    />
    {{
      enableRequest
        ? $t("export.status.running.button")
        : $t("export.status.complete.button")
    }}
  </b-btn>

  <modal-panel
    ref="modalPanel"
    :no-close-on-esc="enableRequest"
    :no-close-on-backdrop="enableRequest"
    :hide-header-close="enableRequest"
    :title="$t('export.modal.enableRequest.title')"
    help="export"
    :header="$t('export.modal.enableRequest.subtitle') + ' ' + $t('Attention! If you choose bulk export, all changes and data in Pobo Page Builder will be uploaded to Shopify. Due to the amount of data, the export may take longer as all data is uploaded.')"
    @close-modal="$emit('close-modal')"
  >
    <template #action>
      <b-btn
        :disabled="enableRequest || countReaming > 0"
        data-cy-="modal-export-create"
        variant="secondary"
        @click="actionCreate"
      >
        <i class="ico ico-export" />
        {{ $t("export.modal.request") }}
      </b-btn>

      <router-link
        :to="{ name: 'setting-export' }"
        class="btn btn-secondary"
      >
        <i class="ico ico-setting" />
        {{ $t("export.modal.setting") }}
      </router-link>
    </template>
    <template #content>
      <div>
        <div
          v-if="!enableRequest"
          class="text-center"
        >
          <p
            v-html="$t('export.modal.enableRequest.content')"
          />

          <div
            v-if="countReaming > 0"
            class="py-3 px-4 text-center my-4"
            style="
                                background: rgb(255, 231, 154);
                                margin: -1px -16px;
                                border: 1px solid rgb(245, 209, 92);
                                color: rgb(88, 76, 17);
                            "
          >
            <span class="d-block fs-17">
              <b-spinner
                small
                style="width: 15px; height: 15px"
              />
              <span class="font-weight-bolder">
                {{
                  $t("export.modal.waitingRequest.title")
                }}
                ({{
                  $t(
                    "export.modal.waitingRequest.success"
                  )
                }}
                {{
                  $t(
                    "inflection.enum.product",
                    cron.reaming
                  )
                }})
              </span>
            </span>
            <small class="text-muted d-block pt-2">
              {{
                $t(
                  "export.modal.waitingRequest.waitForSync"
                )
              }}
            </small>
          </div>
        </div>
        <div
          v-else
          class="text-center"
          data-cy="modal-export-indicator-disable"
        >
          <div>
            <b-spinner size="large" />
          </div>
          <h3 class="mt-4 fs-22">
            {{ $t("export.modal.waitingRequest.waitMinute") }}
          </h3>

          <p
            v-html="$t('export.modal.waitingRequest.content')"
          />
        </div>

        <div
          v-if="cron.list.length > 0"
          class="shadow-sm bg-white rounded p-2 mt-4"
        >
          <div
            class="export-table export-table--finish font-weight-bold"
          >
            <div>{{ $t("export.modal.table.start") }}</div>
            <div>{{ $t("export.modal.table.finish") }}</div>
          </div>

          <div
            v-for="item in cron.list"
            :key="item.id"
            :class="[
              item.updated_at
                ? 'export-table--finish'
                : 'export-table--running',
              'export-table',
            ]"
          >
            <div>{{ datetime(item.created_at) }}</div>

            <div>
              <span v-if="item.updated_at">
                {{ datetime(item.updated_at) }}
              </span>
              <span v-else>
                {{ $t("export.modal.table.waitForEnd") }}
                <b-spinner
                  label="Small Spinner"
                  small
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal-panel>
</template>

<script>
import { when, datetime } from "@/filter/vue.js";
import ExportModel from "@/model/ExportModel.js";
import eshopData from "@/mixin/eshopData.js";
import ModalPanel from "@/components/share/modal-panel.vue";
import lockedData from "@/mixin/lockedData";

export default {
  name: "ModalExport",

  mixins: [eshopData, lockedData],

  async mounted() {
    await this.actionRead();

    await this.checkModal();

    setInterval(async () => {
      await this.checkModal();
    }, 5000);
  },

  data() {
    return {
      running: false,
      is_silent: true,
      cron: {
        stats: [],
        enable_request: true,
        list: [],
        reaming: 0
      },
      count: 0
    };
  },

  computed: {
    enableRequest() {
      const { cron } = this;
      return !cron.enable_request;
    },

    countReaming() {
      const { cron } = this;
      return cron.reaming;
    },

    getSize() {
      const { expireTariff } = this;
      return expireTariff ? "md" : "handler";
    }
  },
  components: { "modal-panel": ModalPanel },

  methods: {
    when, datetime,
    async checkModal() {
      if (this.enableRequest) {
        this.openModal();
        await this.actionRead();
      }

      if (this.countReaming > 0 && this.isOpen) {
        await this.actionRead();
      }
    },

    async actionCreate() {
      await ExportModel.create(
        () => {
        },
        (data) => {
          this.cron = data;
        }
      );
    },

    async actionRead() {
      this.cron = await ExportModel.findAll();
    },

    openModal() {
      this.$refs.modalPanel.isModalOpen = true;
    }
  }
};
</script>
