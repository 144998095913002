<template>
  <template v-if="content.standard > 0">
    <div
      v-if="quality.percent !== 0"
      v-tooltip.top-center="$t('tooltip.seoPercentInfo')"
      class="progress fs-12"
    >
      <div
        :class="[quality.class, 'progress-bar']"
        :style="{
          width: `${quality.percent === 0 ? 100 : quality.percent}%`,
        }"
        role="progressbar"
      >
        {{ quality.percent }} %
      </div>
    </div>
    <small class="text-center mt-2 d-block text-muted lh-16">
      {{ $t("tooltip.widget.widget", content.standard ) }}
    </small>

    <small
      v-if="content.share"
      class="text-center d-block text-muted lh-16 fs-12"
    >
      {{ $t("tooltip.widget.share", content.share) }}
    </small>
  </template>
</template>

<script>
export default {
  name: "GridItemQuality",

  props: {
    content: {
      type: Object,
      require: true,
    },
  },

  computed: {
    quality() {
      const { content } = this;
      switch (content.standard) {
      case 0:
        return {
          percent: 0,
          class: "bg-danger",
        };
      case 1:
        return {
          percent: 25,
          class: "bg-danger",
        };
      case 2:
        return {
          percent: 50,
          class: "bg-warning",
        };
      case 3:
        return {
          percent: 75,
          class: "bg-secondary",
        };
      case 4:
        return {
          percent: 100,
          class: "bg-secondary",
        };
      default:
        return {
          percent: 100,
          class: "bg-secondary",
        };
      }
    },
  },
};
</script>
