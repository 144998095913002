<template>
  <div class="menu__dropdown">
    <b-icon
      icon="bell"
      size="25"
    />

    <div class="menu__dropdown-box trigger menu__dropdown-mess">
      <div class="menu__notification-content">
        <a
          v-for="item in blog"
          :key="item.id"
          :href="'/blog/' + item.id + '-' + item.slug"
          class="d-flex position-relative"
          target="_blank"
        >
          <div v-html="item.prefix" />

          <div class="menu__notification-date text-muted">
            {{ when(item.created_at) }}
          </div>
        </a>
      </div>

      <div class="menu__dropdown-menu--showall">
        <a href="/blog"> {{ $t("notification.info") }} </a>
      </div>
    </div>
  </div>
</template>

<script>
import BlogModel from "@/model/BlogModel.js";
import { when } from "@/filter/vue";

export default {
  name: "Bell",

  async mounted() {
    await BlogModel.findNotification(
      () => {},
      (data) => {
        this.blog = data.slice(0, 3);
      }
    );
  },

  data() {
    return {
      blog: [],
      dateFil: "",
      sample: `
  <div class="widget-projector">
  <div class="rc-image-half-right">
  <div class="rc-image-half-right__text">
  <h2>Slovenštinu a angličtinu podporuje AI, nastavení editoru a nový widget</h2>
  <p>Do Pobo Page Builder jsme přidali nové funke.</p>
  </div>
  <div class="rc-image-half-right__image">
  <img src="https://image.pobo.cz/images/hk4722pmfv9q/8xy7v33wq3qei5saj3wq-md.png" class="lazyLoad rc-image-half-right__img" alt="" loading="lazy">
  </div>
   </div>
    </div>
`,
    };
  },
  methods: { when },
};
</script>