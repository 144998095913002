<template>
  <div>
    <template v-if="currentStep === 9">
      <div class="col-8 m-auto mt-2">
        <h2 class="my-4 p-0">
          {{ $t("guide.modal.finishInstall.header") }} 👌
        </h2>
        <p
          class="mb-4"
          v-html="$t('guide.modal.finishInstall.info')"
        />
        <b-btn
          class="btn btn-secondary"
          @click="$emit('update-step', 2)"
        >
          {{ $t("guide.import.button") }} <i class="ico ico-arrow-right" />
        </b-btn>
      </div>
    </template>
    <template v-else>
      <div class="px-4">
        <template
          v-for="(item, index) in currentData"
          :key="index"
        >
          <b-row align-v="center">
            <b-col cols="6">
              <h2 class="my-4 p-0">
                Step {{ item.step }}/8
              </h2>
            </b-col>
            <b-col
              class="text-right"
              cols="6"
            >
              <a
                :href="eshopUrl"
                class="btn btn-secondary"
                target="_blank"
              >
                Open your e-shop <i class="ico ico-external-white" />
              </a>
            </b-col>
          </b-row>

          <p
            class="mb-4"
            v-html="item.text.en"
          />

          <b-row class="mb-2">
            <b-col cols="4">
              <template v-if="currentStep !== 1">
                <b-btn
                  class="px-3"
                  size="sm"
                  variant="secondary"
                  @click="setStep(currentStep - 1)"
                >
                  « Previous step
                </b-btn>
              </template>
            </b-col>

            <b-col
              class="text-center"
              cols="4"
            />

            <b-col
              class="text-right"
              cols="4"
            >
              <b-btn
                class="px-3"
                size="sm"
                variant="secondary"
                @click="setStep(currentStep + 1)"
              >
                Next step »
              </b-btn>
            </b-col>
          </b-row>

          <div class="bg-white p-2 shadow-sm rounded">
            <a
              :href="item.image"
              target="_blank"
            >
              <b-img-lazy
                :key="item.step"
                :src="item.image"
                class="rounded"
                fluid
              />
            </a>

            <a
              :href="item.image"
              class="d-block text-center mt-2"
              target="_blank"
            >
              Open image in new tab (new window)
            </a>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import install from "@/components/guide/install.json";
import eshopData from "@/mixin/eshopData";
export default {
  name: "Install",

  mixins: [eshopData],

  data() {
    return {
      step: 1,
    };
  },

  computed: {
    currentData() {
      const { step } = this;
      return install["shopify"].filter((item) => item.step === step);
    },

    currentStep() {
      const { step } = this;
      return step;
    },

    eshopUrl() {
      const { eshop } = this;
      const { url } = eshop;
      return `${url}/admin`;
    },
  },

  methods: {
    setStep(step) {
      this.step = step;
    },
  },
};
</script>
