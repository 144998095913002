<template>
  <form
    class="bg-white p-2"
    @submit.prevent="handleSubmit"
  >
    <template v-if="step === 1">
      <p
        class="p-3 warning-box-info"
      >
        {{ $t('invoice.billing.reverseCharge.info') }}
      </p>

      <div class="d-flex mt-4">
        <b-form-group>
          <label for="country-group">{{ $t('invoice.billing.reverseCharge.country') }}</label>
          <b-form-radio-group
            v-model="country"
            name="country"
            :options="countryOptions"
            inline
          />
        </b-form-group>

        <b-form-group class="ml-5">
          <label for="vat-group">{{ $t('invoice.billing.reverseCharge.vat') }}</label>
          <b-form-radio-group
            v-model="vatPayer"
            name="vatPayer"
            :options="vatOptions"
            inline
          />
        </b-form-group>
      </div>

      <b-button
        variant="secondary"
        class="mt-4 w-100"
        :disabled="!isNextStepPossible"
        @click="nextStep(2)"
      >
        {{ $t('invoice.billing.reverseCharge.confirm') }}
      </b-button>

      <p
        v-if="showDetailMessage"
        class="fs-10 fr-text-gray text-center pt-3"
      >
        {{ $t('invoice.billing.reverseCharge.message-part') }}
        <strong
          v-if="vatPayer"
          class="font-weight-bold"
        >
          {{ $t('invoice.billing.reverseCharge.message-true') }}
        </strong>
        <strong
          v-else
          class="font-weight-bold"
        >
          {{ $t('invoice.billing.reverseCharge.message-false') }}
        </strong>
        {{ $t('invoice.billing.reverseCharge.to-mood') }}
        <strong class="font-weight-bold"> "Reverse Charge".
        </strong>
        {{ $t('invoice.billing.reverseCharge.message-end') }}
      </p>
    </template>
    <template v-if="step === 2">
      <div class="form-group">
        <label for="name">
          {{ $t("invoice.billing.form.name.input") }}
          <span class="text-danger">*</span>
        </label>
        <input
          id="name"
          v-model="form.name"
          :class="errors.name ? 'is-invalid' : ''"
          :placeholder="$t('invoice.billing.form.name.placeholder')"
          class="form-control"
          type="text"
        >

        <div
          v-if="errors"
          class="mt-1"
        >
          <small
            v-for="(error, index) in errors.name"
            :key="index"
            class="text-danger d-block"
            v-html="$t(error)"
          />
        </div>
      </div>

      <div class="form-group">
        <label for="street">
          {{ $t("invoice.billing.form.street.input") }}
          <span class="text-danger">*</span>
        </label>
        <input
          id="street"
          v-model="form.street"
          :class="errors.street ? 'is-invalid' : ''"
          :placeholder="$t('invoice.billing.form.street.placeholder')"
          class="form-control"
          type="text"
        >

        <div
          v-if="errors"
          class="mt-1"
        >
          <small
            v-for="(error, index) in errors.street"
            :key="index"
            class="text-danger d-block"
            v-html="$t(error)"
          />
        </div>
      </div>

      <div class="form-group">
        <label for="street">
          {{ $t("invoice.billing.form.city.input") }}
          <span class="text-danger">*</span>
        </label>
        <input
          id="city"
          v-model="form.city"
          :class="errors.street ? 'is-invalid' : ''"
          :placeholder="$t('invoice.billing.form.city.placeholder')"
          class="form-control"
          type="text"
        >

        <div
          v-if="errors"
          class="mt-1"
        >
          <small
            v-for="(error, index) in errors.city"
            :key="index"
            class="text-danger d-block"
            v-html="$t(error)"
          />
        </div>
      </div>

      <div class="form-group">
        <label for="zip">
          {{ $t("invoice.billing.form.zip.input") }}
          <span class="text-danger">*</span>
        </label>
        <input
          id="zip"
          v-model="form.zip"
          :class="errors.zip ? 'is-invalid' : ''"
          :placeholder="$t('invoice.billing.form.zip.placeholder')"
          class="form-control"
          type="text"
        >

        <div
          v-if="errors"
          class="mt-1"
        >
          <small
            v-for="(error, index) in errors.zip"
            :key="index"
            class="text-danger d-block"
            v-html="$t(error)"
          />
        </div>
      </div>

      <div class="form-group">
        <label for="ic">{{ $t("invoice.billing.form.ic.input") }}</label>
        <input
          id="ic"
          v-model="form.ic"
          :class="errors.ic ? 'is-invalid' : ''"
          :placeholder="$t('invoice.billing.form.ic.placeholder')"
          class="form-control"
          type="text"
        >

        <div
          v-if="errors"
          class="mt-1"
        >
          <small
            v-for="(error, index) in errors.ic"
            :key="index"
            class="text-danger d-block"
            v-html="$t(error)"
          />
        </div>
      </div>

      <div class="form-group">
        <label for="dic">
          {{ $t("invoice.billing.form.dic.input") }}
        </label>
        <input
          id="dic"
          v-model="form.dic"
          :class="errors.dic ? 'is-invalid' : ''"
          :placeholder="$t('invoice.billing.form.dic.placeholder')"
          class="form-control"
          type="text"
          @keyup="watchDic"
        >

        <div
          v-if="errors"
          class="mt-1"
        >
          <small
            v-for="(error, index) in errors.dic"
            :key="index"
            class="text-danger d-block"
            v-html="$t(error)"
          />
        </div>
      </div>
      
      <div
        v-show="!billingCreate && form.dic"
        class="form-group"
      >
        <b-form-checkbox
          v-model="form.use_reverse_charge"
          :value="true"
        >
          <span class="d-block">
            {{ $t("invoice.billing.reverseCharge.use") }}
          </span>
          <span class="d-block text-black-50 mt-2">
            {{ $t("invoice.billing.reverseCharge.checkbox") }}
          </span>
        </b-form-checkbox>
      </div>

      <div class="form-group mt-4">
        <button
          class="btn btn-secondary btn-block"
          type="submit"
        >
          {{ $t("invoice.billing.form.save") }}
        </button>
      </div>
    </template>
  </form>
</template>

<script>
import BillingModel from "@/model/BillingModel.js";

export default {
  name: "billing-form",
  displayName: "billing-form",
  props: {
    billing: {
      validator: (prop) => typeof prop === "object" || prop === null,
      required: false,
      default: null,
    },

    billingCreate: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  mounted() {
    const { billing, billingCreate } = this;

    if(!billingCreate){
      this.step = 2;
    }
    if (billing) {
      this.form = billing;
      this.form.use_reverse_charge = Boolean(this.form.use_reverse_charge);
    }
  },

  data() {
    return {
      form: {
        name: null,
        street: null,
        city: null,
        zip: null,
        ic: null,
        dic: null,
        use_reverse_charge: false,
      },
      countryOptions: [
        { text: 'CZ', value: 'CZ' },
        { text: 'EU', value: 'EU' }
      ],
      vatOptions: [
        { text: 'Ano', value: true },
        { text: 'Ne', value: false }
      ],
      country: null,
      vatPayer: null,
      step: 1,
      errors: {},
    };
  },

  computed:{
    isNextStepPossible(){
      return !!(this.country !== null && this.vatPayer !== null);
    },
    showDetailMessage(){
      return this.country === 'EU' && this.vatPayer !== null;
    }
  },

  methods: {
    watchDic() {
      const { form } = this;
      const { dic } = form;

      if (!dic) {
        this.form.use_reverse_charge = false;
      }
    },

    nextStep(step) {
      if(this.country === 'EU' && this.vatPayer === true){
        this.form.use_reverse_charge = true;
      }else{
        this.form.use_reverse_charge = false;
      }
      this.step = step;
    },

    async handleSubmit() {
      await this.actionCreate();
    },

    async actionCreate() {
      let {form } = this;
      form.zip = String(form.zip);

      if (!form.dic || form.dic.trim() === "") {
        delete form.dic;
      }

      console.log(form);


      await BillingModel.create(
        form,
        () => {},
        (data) => {
          const { errors } = data;

          if (errors) {
            this.errors = errors;
          } else {
            this.$emit("handle-close", data);
          }
        }
      );
    },
  },
};
</script>
