import axios from "axios";

export default class ProductModel {
  static async findMe(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/product/detail/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch detail product [findMe()]", error);
        return error;
      });
  }

  static async findCurrent(
    id,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/product/current/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn(
          "An error occurred when fetching current product data [findCurrent()]",
          error
        );
        return error;
      });
  }

  static async getStatistics(
    id,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/product/analytics/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error get product statistics [getStatistics()]", error);
        return error;
      });
  }

  static async findAll(
    params = {},
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    const query = {
      limit: params.limit || 10,
      offset: params.offset || 0,
      query: params.query || "",
      filter: params.filter || [],
      category: params.category || [],
    };

    return await axios
      .post("/api/v2/product/grid", query)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch product [findAll()]", error);
        return error;
      });
  }

  static async update(id, data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .put("/api/v2/product/detail/" + id, data)
      .then(({ data }) => {
        endCallback();
        return data;
      })
      .catch((error) => {
        console.warn("Error product detail update [update()]", error);
        return error;
      });
  }


  static async findMeAnalytics(
    id,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/product/analytics/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn(
          "Error fetch detail product analytics [findMeAnalytics()]",
          error
        );
        return error;
      });
  }

  static async favourite(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/content/favorite", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create favorite [favorite()]", error);
        return error;
      });
  }

  static async findAdmin(
    params,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    const query = {
      limit: params.limit,
      offset: params.offset,
      query: params.query,
      filter: params.filter,
      userId: params.userId,
    };

    return await axios
      .get("/api/admin-product", { params: query })
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch product [findAdmin()]", error);
        return error;
      });
  }

  static async createCopy(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    const { eshopId } = data;

    const axiosInstance = eshopId
      ? axios.create({
        headers: {
          "pobo-eshop-id": eshopId,
        },
      })
      : axios;

    return await axiosInstance
      .post("/api/v2/widget/copy", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error copy widget [createCopy()]", error);
        return error;
      });
  }

  static async recoveryOldDescription(
    product_id,
    startCallback = () => {},
    endCallback = () => {},
  ) {
    startCallback();

    return await axios
      .post('/api/v1/product/recovery', {
        product_id
      })
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error recovery widget [recoveryOldDescription()]", error);
        return error;
      });
  }
}
