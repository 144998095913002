<template>
  <div>
    <template v-if="eshop.force_disable">
      <force-disable />
    </template>
    <template v-else-if="isDisable">
      <disable-account />
    </template>
    <template v-else-if="showShoptetWebhookNotification">
      <extension-disable />
    </template>
    <template v-else>
      <div
        v-if="showInformationLayer"
        class="information-layer"
      >
        <div class="content__container">
          <b-row
            align-h="between"
            align-v="center"
          >
            <b-col
              v-if="showInformationLayer"
              cols="9"
              v-html="showInformationLayer"
            />
            <b-col
              class="text-right"
              cols="3"
            >
              <b-button
                size="sm"
                variant="secondary"
                @click="disableNotification('header')"
              >
                {{ $t("menu.close") }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>

      <menu-template />

      <div class="content">
        <div class="content__container mt-4">
          <template v-if="merchantLoaded">
            <router-view />
          </template>
        </div>
      </div>

      <footer-template />
    </template>

    <transition name="fade">
      <div
        v-if="reaming < limit"
        class="footer__overlay"
      >
        <intro
          :reaming="reaming"
          :limit="limit"
        />
      </div>
    </transition>

    <template v-if="showInformationModal">
      <b-modal
        v-model="isInformationModalOpen"
        hide-footer
        hide-header
        size="md"
        @hide="disableNotification('modal')"
      >
        <div>
          <div v-html="showInformationModal" />
        </div>
        <b-btn
          block
          size="sm"
          variant="secondary"
          @click="disableNotification('modal')"
        >
          {{ $t("menu.close") }}
        </b-btn>
      </b-modal>
    </template>

    <wrapper-template v-if="false" />
  </div>
</template>

<script>
import Intro from "@/layout/part/intro.vue";
import ForceDisable from "@/layout/part/force-disable.vue";
import MenuTemplate from "@/layout/part/menu-template.vue";
import FooterTemplate from "@/layout/part/footer-template.vue";
import WrapperTemplate from "@/layout/layer/wrapper.vue";
import DisableAccount from "@/layout/part/disable-account.vue";
import EshopModel from "@/model/EshopModel.js";
import NotificationModel from "@/model/NotificationModel.js";
import navigation from "@/components/setting/navigation.json";
import { useEshopStore } from "@/store/EshopStore.js";
import { useCreditStore } from "@/store/CreditStore.js";
import eshopData from "@/mixin/eshopData.js";
import { useGeneratorStore } from "@/store/GeneratorStore";
import { mapWritableState } from "pinia";
import * as Sentry from "@sentry/browser";
import { useStatsInfoStore } from "@/store/StatsInfoStore";
import Smartlook from 'smartlook-client'
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/third_party/embedly.min";
import "froala-editor/js/third_party/font_awesome.min";
import "froala-editor/js/third_party/image_tui.min";
import ExtensionDisable from "@/layout/part/extension-disable.vue";
import { useLockedStore } from "@/store/lockedStore";

const LIMIT = import.meta.env.PROD ? 7 : 0;

export default {
  name: "Merchant",
  mixins: [eshopData],

  async mounted() {
    await useEshopStore().fetchEshop().then(() => {
      // todo premapovat na bool v api
      if (this.eshop.enable_dark === 1 || this.eshop.enable_dark === true) {
        import('@/scss/theme/dark.scss');
      } else {
        import('@/scss/theme/light.scss');
      }
      this.merchantLoaded = true;
    })
    await useCreditStore().findHistory();

    if (import.meta.env.PROD) {
      Sentry.setUser({ email: this.user.email || "unknown" });
    }
    // First check
    await useGeneratorStore().check();
    await useStatsInfoStore().fetch();
    await useLockedStore().check()


    // Check generator every 5s
    setInterval(async () => {
      console.info("%c Refresh generator and lock data", "color: green;");
      await useGeneratorStore().check();
      await useLockedStore().check()
    }, 5000);

    this.$i18n.locale = this.eshop.lang;

    const { eshop } = this;
    const { confirm_guide } = eshop;

    if (confirm_guide) {
      await NotificationModel.findAll(
        () => {},
        (data) => {
          this.notification = data;
        }
      );
    }

    await EshopModel.sync().then()

    window.addEventListener("offline", () => {
      this.state = "offline";
    });

    window.addEventListener("online", () => {
      this.state = "loading";

      setTimeout(() => {
        this.state = "online";
      }, 2500);
    });

    setInterval(() => {
      if (this.reaming < LIMIT) {
        this.reaming = this.reaming + 0.1;
      }
    }, 100);

    const loadingElement = document.getElementById("hero-loading");
    if (loadingElement) {
      loadingElement.remove();
    }
  },

  data() {
    return {
      state: "online",
      overlay: true,
      openSwitch: false,
      reaming: 0,
      navigation,
      merchantLoaded: false,
      user: {
        role: [],
      },
      notification: {
        list: [],
        status: {
          header: false,
          modal: false,
        },
      },
      isInformationModalOpen: true,
    };
  },

  computed: {
    eshop() {
      return useEshopStore().getEshop();
    },

    supportMenu() {
      return [
        {
          href: "mailto:podpora@pobo.cz",
          icon: "life-preserver",
          lang: "menu.help.email",
        },
        {
          href: "/blog",
          icon: "newspaper",
          lang: "menu.help.blog",
        },
        {
          href: "/functions/todo",
          icon: "check-all",
          lang: "menu.help.todo",
        },

        {
          href: "/faq",
          icon: "question-circle",
          lang: "menu.help.faq",
        },
      ];
    },


    bgState() {
      switch (this.state) {
      case "loading":
        return "bg-secondary";
      case "offline":
        return "bg-danger";
      default:
        return "bg-danger";
      }
    },

    filterNavigation() {
      const { navigation, eshop } = this;
      const { platform } = eshop;

      return navigation.filter(
        ({ visible, allowPlatform }) =>
          visible === true && allowPlatform.includes(platform)
      );
    },

    limit() {
      return LIMIT;
    },

    translateState() {
      switch (this.state) {
      case "loading":
        return "state.online";
      case "offline":
        return "state.offline";
      default:
        return "bg-light";
      }
    },

    ...mapWritableState(useGeneratorStore, {
      reamingCronGenerator: "reaming",
    }),

    ...mapWritableState(useEshopStore, {
      user: "detail",
    }),

    showState() {
      return this.state === "loading" || this.state === "offline";
    },

    plainUrl() {
      return this.eshop.url ? this.eshop.url.replace(/^https?:\/\//, "") : null;
    },

    isDisable() {
      return this.eshop.is_disable === 1;
    },

    showInformationLayer() {
      const { notification } = this;
      const { list, status } = notification;
      const { header } = status;
      const info = list.find((item) => item.type === "header");

      if (!info) return false;
      return info.active && header ? info.value : false;
    },

    showInformationModal() {
      const { notification } = this;
      const { list, status } = notification;
      const { modal } = status;
      const info = list.find((item) => item.type === "modal");

      if (!info) return false;
      return info.active && modal ? info.value : false;
    },
  },

  components: {
    "extension-disable": ExtensionDisable,
    "force-disable": ForceDisable,
    "footer-template": FooterTemplate,
    "disable-account": DisableAccount,
    //    "bell-template": BellTemplate,
    "wrapper-template": WrapperTemplate,
    "menu-template": MenuTemplate,
    "intro": Intro,
  },

  methods: {
    async disableNotification(type) {
      this.isInformationModalOpen = false;
      this.notification.status[type] = false;
      await NotificationModel.updateNotification(this.notification.status);
    },
  },
};
</script>
