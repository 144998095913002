import hotkeys from "hotkeys-js";

export default {
  mounted() {
    this.keyboardShortcuts();
  },

  unmounted() {
    hotkeys.unbind();
  },

  methods: {
    keyboardShortcuts(){
      const self = this;

      if(this.isModalOpen){
        hotkeys('esc', function(event, handler){
          event.preventDefault();
          self.openModal(false);
        });

        hotkeys('cmd+s, ctrl+s', function(event, handler){
          event.preventDefault();
          self.$refs.editor.actionSave();
        });
      }else{
        hotkeys.unbind();
      }
    },
  }
}
