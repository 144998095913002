<template>
  <layout-grid>
    <template #title>
      {{ $t("asset.page.header") }}
    </template>

    <template #welcome>
      <b-row
        align-content="center"
        class="mb-4 mt-4"
      >
        <b-col cols="7">
          <p>{{ $t("asset.page.content") }}</p>
          <p v-html="$t('asset.page.hiring')" />
        </b-col>
        <b-col cols="5">
          <div
            class="bg-white p-3 rounded"
            style="border: 1px dashed #d5d8db"
          >
            <h5>{{ $t("asset.list.header") }}</h5>
            <ul class="list-check fs-14 mb-0">
              <li>{{ $t("asset.list.part1") }}</li>
              <li>{{ $t("asset.list.part2") }}</li>
              <li>
                {{ $t("asset.list.part3") }}
                <a
                  :href="`../template`"
                  class="text-danger"
                  target="_blank"
                >Pobo Page Designer</a>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </template>

    <template
      v-if="list.length > 0"
      #create
    >
      <b-btn
        class="my-4"
        variant="secondary"
        @click="openModal(true)"
      >
        {{ $t("asset.page.create") }} <i class="ico ico-arrow-right" />
      </b-btn>
    </template>

    <template #content>
      <template v-if="isLoaded">
        <template v-if="list.length > 0">
          <div class="asset-table--head">
            <div>{{ $t("asset.table.created") }}</div>
            <div>{{ $t("asset.table.updated") }}</div>
            <div>{{ $t("asset.table.name") }}</div>
          </div>

          <div
            v-for="item in list"
            :key="item.id"
            class="asset-table"
          >
            <div class="small text-muted">
              {{ datetime(item.created_at) }}
            </div>
            <div class="small text-muted">
              {{ datetime(item.updated_at) }}
            </div>
            <div>{{ item.name }}</div>
            <div class="text-right">
              <b-btn
                class="mr-3 pr-3"
                size="sm"
                @click="handleEdit(item.id)"
              >
                <i class="ico ico-edit" /> {{ $t("asset.table.edit") }}
              </b-btn>

              <confirm-delete
                :id="item.id"
                class="d-inline-block"
                @delete="actionDelete"
              >
                <template #trigger>
                  <i class="ico ico-component-delete" />
                  {{ $t("asset.table.delete") }}
                </template>
              </confirm-delete>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-center pt-5 pb-2">
            <h3>{{ $t("asset.table.empty") }}</h3>
            <p>{{ $t("asset.table.emptyMessage") }}</p>
            <b-btn
              class="my-4"
              @click="openModal(true)"
            >
              {{ $t("asset.table.create") }}
              <i class="ico ico-arrow-right" />
            </b-btn>
          </div>
        </template>
      </template>
      <template v-else>
        <div
          v-for="n in 10"
          :key="n"
          class="asset-table"
        >
          <div
            v-for="m in 4"
            :key="m"
          >
            <b-skeleton-img
              height="45px"
              no-aspect
            />
          </div>
        </div>
      </template>
    </template>
  </layout-grid>

  <div
    v-if="isModalOpen"
    class="modal-editor"
  >
    <div class="editor-modal__header">
      <div class="editor-modal__header-tab">
        <!-- empoty -->
      </div>
      <modal-menu
        :is-valid="isValidCode"
        @handle-save="$refs.editor.actionSave()"
        @handle-close="openModal(false)"
      />
    </div>
    <div class="p-4">
      <modal-asset
        ref="editor"
        :selected-asset-id="selectedAssetId"
        @is-valid="isValid"
      />
    </div>
  </div>
</template>

<script>
import ModalAsset from "@/components/asset/modal-asset.vue";
import ModalMenu from "@/components/asset/modal-menu.vue";
import { datetime } from "@/filter/vue";
import TemplateModel from "@/model/TemplateModel.js";
import hotkeys from "hotkeys-js";
import assetShortcuts from "@/mixin/shortcuts/assetShortcuts";

export default {
  name: "Asset",

  mixins:[assetShortcuts],

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      isModalOpen: false,
      selectedAssetId: null,
      list: [],
      isLoaded: false,
      isValidCode: true,
    };
  },

  watch: {
    isModalOpen() {
      this.keyboardShortcuts();
    },
  },

  components: {
    "modal-asset": ModalAsset,
    "modal-menu": ModalMenu,
  },

  metaInfo() {
    return { title: this.$t("meta.asset") };
  },

  methods: {
    datetime,

    isValid(isValid) {
      this.isValidCode = isValid;
    },

    async openModal(state) {
      this.isModalOpen = state;
      this.selectedAssetId = null;

      if (!state) await this.actionRead();
    },

    handleEdit(assetId) {
      this.selectedAssetId = assetId;
      this.isModalOpen = true;
    },

    refresh(list) {
      this.list = list;
    },

    async actionRead() {
      this.isLoaded = false;
      await TemplateModel.findMe(
        () => {},
        (data) => {
          this.list = data;
          this.isLoaded = true;
        }
      );
    },

    async actionDelete(id) {
      this.isLoaded = false;

      await TemplateModel.delete(
        id,
        () => {},
        (data) => {
          this.list = data;
          this.isLoaded = true;
        }
      );
    },
  },
};
</script>
