import axios from "axios";

export default class NotificationModel {
  static async findAll(
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/notification/marketing")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch eshop [findAll()]", error);
        errorCallback(error);
        return error;
      });
  }

  static async updateNotification(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();
    return await axios
      .put("/api/v2/eshop/notification/marketing", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error notification model [updateNotification()]", error);
        return error;
      });
  }

  static async runAllNotification(
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();
    return await axios
      .post("/api/v2/admin/user/notification")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error notification model [runAllNotification()]", error);
        return error;
      });
  }
}
