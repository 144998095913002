<template>
  <div class="user-block bg-body">
    <div class="container">
      <div class="row">
        <div class="col-8 m-auto vh-100 d-flex align-items-center">
          <div class="bg-white p-5 shadow-sm rounded-sm w-100">
            <h2 class="mb-4">
              Aktualizujte si prosím doplněk
            </h2>
            <p class="text-md mb-2">
              Od <strong>1. března 2022</strong> jsme přešli na nový
              vzhled editoru, platební systém přes Shoptet a upravili naše tarify. Pro
              aktivaci doplňku Pobo Page Builder je nutné
              doplněk odinstalovat a znova nainstalovat.
            </p>


            <h3 class="mb-4 mt-3 pt-2">
              Jak aktivovat doplněk?
            </h3>

            <p
              class="text-md mb-4"
              style="line-height: 28px"
            >
              Náš nástroj si aktivujete tak,
              <span class="yellow-info-box">že se přihlásíte do svého e-shopu, doplněk Pobo Page Builder
                odinstalujete a opětovně nainstalujete (nebojte, o uložené
                popisky nepřijdete)</span>. Následně se Vám editor zpřístupní.
            </p>


            <p class="text-md mb-4">
              <strong> Pokud potřebujete poradit, <a :href="getSupportUrl"> kontaktujte nás prostřednictvím tohoto odkazu. </a> </strong>
            </p>

            <a
              :href="`${eshop.url}/admin/spravce-doplnku/#content-in`"
              class="btn btn-secondary mt-4 btn-block btn-lg"
              target="_blank"
            >
              Přejít do Shoptet a znova nainstalovat doplněk
              <i class="ico ico-arrow-right" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eshopData from "@/mixin/eshopData";
export default {
  name: "ForceDisable",

  mixins: [eshopData],

  computed:{
    getSupportUrl(){
      return "https://pobo-support.com/" + this.editorLang + "/?eshop=" + this.eshopUrl + "&tariff=" + this.eshopTariff + "&multilang=" + this.isConfigureLang + "&is-shoptet=" + this.isShoptet;
    },
  }
};
</script>

<style scoped></style>
