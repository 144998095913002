import axios from "axios";

export default class AnalyticsModel {
  static async findAll(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/analytics")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch analytics [findAll()]", error);
        return error;
      });
  }

  static async findMe(limit, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/analytics/" + limit)
      .then(({ data }) => {
        const mark =  {
          "result": true,
          "panel": {
            "labels": [
              "2023-09-26T00:00:00+00:00",
              "2023-09-25T00:00:00+00:00",
              "2023-09-24T00:00:00+00:00",
              "2023-09-23T00:00:00+00:00",
              "2023-09-22T00:00:00+00:00",
              "2023-09-21T00:00:00+00:00",
              "2023-09-20T00:00:00+00:00",
              "2023-09-19T00:00:00+00:00",
              "2023-09-18T00:00:00+00:00",
              "2023-09-17T00:00:00+00:00",
              "2023-09-16T00:00:00+00:00",
              "2023-09-15T00:00:00+00:00",
              "2023-09-14T00:00:00+00:00",
              "2023-09-13T00:00:00+00:00",
              "2023-09-12T00:00:00+00:00",
              "2023-09-11T00:00:00+00:00",
              "2023-09-10T00:00:00+00:00",
              "2023-09-09T00:00:00+00:00",
              "2023-09-08T00:00:00+00:00",
              "2023-09-07T00:00:00+00:00",
              "2023-09-06T00:00:00+00:00",
              "2023-09-05T00:00:00+00:00",
              "2023-09-04T00:00:00+00:00",
              "2023-09-03T00:00:00+00:00",
              "2023-09-02T00:00:00+00:00",
              "2023-09-01T00:00:00+00:00",
              "2023-08-31T00:00:00+00:00",
              "2023-08-30T00:00:00+00:00",
              "2023-08-29T00:00:00+00:00",
              "2023-08-28T00:00:00+00:00"
            ],
            "pobo": {
              "page_view": 48,
              "basket_counter": 0,
              "session_timer": 0,
              "price": 15935.823333333334,
              "conversion_rate": 0,
              "scroll": 8.6,
              "product": 36
            },
            "old": {
              "page_view": 0,
              "basket_counter": 0,
              "session_timer": 0,
              "price": 0,
              "conversion_rate": 0,
              "scroll": 0,
              "product": 82
            }
          },
          "dataset": {
            "pobo": {
              "page_view": [
                2,
                0,
                0,
                0,
                0,
                3,
                0,
                1,
                2,
                2,
                2,
                0,
                0,
                2,
                0,
                0,
                0,
                1,
                2,
                0,
                2,
                0,
                0,
                0,
                0,
                0,
                2,
                9,
                16,
                2
              ],
              "basket_counter": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "session_timer": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "price": [
                45000,
                0,
                0,
                0,
                0,
                27500,
                0,
                45000,
                40000,
                45000,
                40000,
                0,
                0,
                45000,
                0,
                0,
                0,
                45000,
                5107.5,
                0,
                45000,
                0,
                0,
                0,
                0,
                0,
                10000,
                15777.7,
                24689.5,
                45000
              ],
              "conversion_rate": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "scroll": [
                6,
                0,
                0,
                0,
                0,
                22,
                0,
                10,
                45,
                15,
                7,
                0,
                0,
                44,
                0,
                0,
                0,
                22,
                1,
                0,
                1,
                0,
                0,
                0,
                0,
                0,
                45,
                14,
                25,
                1
              ],
              "product": [
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                36,
                35
              ]
            },
            "old": {
              "page_view": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "basket_counter": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "session_timer": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "price": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "conversion_rate": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "scroll": [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ],
              "product": [
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82,
                82
              ]
            }
          }
        };
        endCallback(data);
      })
      .catch((error) => {
        console.warn("Error fetch analytics [findMe]", error);
        return error;
      });
  }
}
