<template>
  <div class="user-block bg-body">
    <div class="container">
      <div class="row">
        <div class="col-8 m-auto vh-100 d-flex align-items-center">
          <div class="bg-white p-5 shadow-sm rounded-sm w-100">
            <h2 class="mb-4">
              {{ $t("footer.disable.header") }}
            </h2>

            <p class="text-md mb-5">
              {{ $t("footer.disable.block") }}
            </p>

            <b-btn-group class="w-100">
              <b-btn
                variant="secondary"
                class="w-90 pt-2 pb-2"
                :href="$t('footer.disable.url')"
                target="_blank"
              >
                {{ $t("footer.disable.install") }}
                <i class="ico ico-arrow-right" />
              </b-btn>
              <b-btn
                variant="info"
                class="w-10 pt-2 pb-2"
                @click="handleLogout"
              >
                {{ $t("menu.help.logout") }}
              </b-btn>
            </b-btn-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EshopModel from "@/model/EshopModel";
import Cookies from "js-cookie";

export default {
  name: "DisableAccount",

  methods:{
    async handleLogout() {
      await EshopModel.logout().then(() => {
        window.location.href = "/";
        Cookies.remove("access_token");
      })
    },
  }
};
</script>

<style scoped></style>
