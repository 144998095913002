<template>
  <div class="content-editor">
    <div
      v-if="!allowMode(['multiple'])"
      class="content-editor__product"
    >
      <slot name="sidebar" />
    </div>
    <div
      id="pobo-all-content"
      ref="editor"
      :style="userStyleEditor"
      class="content-editor__placeholder"
      @scroll="onScroll"
    >
      <help-box
        author="sara"
        type="how"
        classes="mt-4 ml-4 mr-5"
      >
        <h2
          class="
          mb-4"
        >
          {{ $t("How to use the Pobo Page Builder editor?") }}
        </h2>
        <p class="fs-16 lh-26">
          {{ $t("Pobo Page Builder is a drag-and-drop editor for creating product and article descriptions, offering more than 50 widgets and the ability to manage multiple e-stores.") }}
          {{ $t("It allows you to easily set up labels without programming knowledge and includes tools for evaluating their success.") }}
        </p>
      </help-box>

      <div
        v-if="isLoaded"
        id="pobo-standard-widget"
        class="position-relative"
      >
        <article
          v-if="widget.length === 0"
          class="content-editor__insert"
        >
          <h2
            class="text-black-50 text-center font-weight-normal"
          >
            {{ $t("editor.guide.part1") }}<br>
            {{ $t("editor.guide.part2") }}
          </h2>

          <i class="ico ico-editor-drag-drop" />
        </article>

        <div
          v-if="widget.length > 0"
          :class="actionClass"
        >
          <div class="d-flex">
            <div class="pl-4">
              <b-form-radio-group
                v-model="activePreviewMode"
                value-field="value"
                text-field="text"
                size="sm"
                :options="mappingTool"
                @change="togglePreviewMode"
              />
            </div>
            <div>
              <b-icon
                v-tooltip.top-center="'The feature is in test mode, please excuse any errors.'"
                icon="info-circle"
                size="17"
              />
            </div>
          </div>
        </div>

        <div :class="previewEditorClass">
          <template v-if="allowMode(['preview', 'multiple'])">
            <div :class="previewWidgetClass">
              <div
                v-for="item in widget"
                :key="item.id"
                :class="[item.enable_typography_css ? 'widget-typography' : '', 'widget-container']"
              >
                <div v-html="item.html" />
              </div>
            </div>
          </template>

          <template v-if="allowMode(['editor', 'multiple'])">
            <draggable
              v-bind="dragOptions"
              v-model="widget"
              :component-data="{ name: 'fade' }"
              class="content-editor__placeholder-inner"
              item-key="id"
              @change="actionCreate($event)"
              @end="moveWidget(false)"
              @start="moveWidget(true)"
            >
              <template #item="{ element }">
                <div class="drag-class">
                  <div
                    :class="[
                      element.enable_typography_css ? 'widget-typography' : '',
                      element.is_original
                        ? 'content-editor__widget-original'
                        : '',
                      isMovedActive ? 'content-editor__widget-lock' : '',
                      allowMode(['editor']) ? 'content-editor__widget' : 'content-editor__widget-multiple',
                      'widget-container',
                    ]"
                  >
                    <widget-panel
                      :widget="element"
                      @handle-delete="actionDelete"
                      @create-favourite="$refs.widget.setActiveBlock(3)"
                    />

                    <div :class="isMovedActive ? 'content-editor__inner' : ''">
                      <template v-if="element.is_original">
                        <div v-html="element.html" />
                      </template>
                      <template v-else>
                        <template
                          v-if="element.children && element.children.length > 0"
                        >
                          <!-- eslint-disable -->
                        <widget-tree
                          v-for="(tree, index) in element.children"
                          :id="id"
                          :key="element.key"
                          :class="tree.class"
                          :content-id="element.id"
                          :extension-editor="extensionModeEditor"
                          :index="index"
                          :item="tree"
                          :options="options"
                          :value-lang="valueLang"
                          :aspect-ratio="element.aspect_ratio"
                          @element-root-handler="elementHandler"
                        />
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </draggable>

            <slot name="placeholder" />
        </template>
        </div>
      </div>

      <div
        v-else
        class="content-editor__placeholder-inner"
      >
        <div class="bg-white border p-3">
          <div
            v-for="n in 15"
            :key="n"
            class="mb-3"
          >
            <b-skeleton-img
              height="180px"
              no-aspect
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content-editor__component" v-if="!allowMode(['multiple'])">
      <widget-list
        ref="widget"
        :value-lang="valueLang"
        @move-start="moveWidget(true)"
        @move-end="moveWidget(false)"
        @update-lang="updateLang"
        @open-translate="toggleTranslateModal"
      />
    </div>
  </div>

  <b-modal
    v-model="isTranslateModalOpen"
    :title="$t('translate.modal.form.targetLang')"
    hide-footer
    size="handler"
  >
    <modal-translate-product
      :options="options"
      :show-translate-loading="showTranslateLoading"
      @action-translate="actionTranslate"
    />
  </b-modal>
</template>

<script>
import WidgetTree from "@/share/widget-tree.vue";
import Draggable from "vuedraggable";
import WidgetPanel from "@/share/widget-panel.vue";
import ModalTranslateProduct from "@/share/modal-translate-product.vue";
import WidgetList from "@/share/widgets.vue";
import axios from "axios";
import ShareModel from "@/model/ShareModel.js";
import WidgetModel from "@/model/WidgetModel.js";
import { useCreditStore } from "@/store/CreditStore.js";
import { showMessage } from "@/plugins/flashMessage";
import HelpBox from "@/share/help-box.vue";
import eshopData from "@/mixin/eshopData";

export default {
  name: "editor",

  mixins: [eshopData],

  props: {
    id: {
      type: Number,
      required: false,
    },

    options: {
      type: Object,
      required: true,
    },
  },

  async mounted(){
    await this.actionRead();
    await this.readComponent();
  },

  data(){
    return {
      isLoaded: false,
      showTranslateLoading: false,
      widget: [],
      isMovedWidget: false,
      isTranslateModalOpen: false,
      activeAutoSave: false,
      activePreviewMode: 'editor',
      widgetType: 1,
      valueLang: "default",
      positionScrollEditor: 0,
      mappingTool: [
        { text: this.$t('Content editing'), value: 'editor' },
        { text: this.$t('Preview mode'), value: 'preview' },
        { text: this.$t('Edit & preview'), value: 'multiple' },
      ]
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 500,
        group: "people",
        ghostClass: "ghost",
        disabled: false,
        handle: ".btn-secondary",
      };
    },

    extensionModeEditor() {
      return this.allowMode(['multiple']) ? {
        toolbarInline: true,
        charCounterCount: false
      } : {}
    },

    isMovedActive(){
      return this.isMovedWidget;
    },

    userStyleEditor() {
      const { eshop } = this;
      const { editor_width } = eshop;
      return this.allowMode(['multiple']) ? 'width: 100%' :  `max-width: ${editor_width}px`;
    },

    previewWidgetClass() {
      return [this.allowMode(['multiple']) ?
        'content-editor__preview-content-multiple' :
        'content-editor__preview-content', 'shadow-sm'
      ]
    },

    previewEditorClass() {
      return [this.allowMode(['multiple']) ? 'content-editor__multiple' : '']
    },

    actionClass() {
      return ['content-editor__action', this.allowMode(['multiple']) ? 'content-editor__action--center' : 'content-editor__action--left']
    },
  },

  watch: {
    activePreviewMode: {
      handler: function (val) {
        document.body.classList.remove('editor-mode-preview', 'editor-mode-editor', 'editor-mode-multiple');
        document.body.classList.add(`editor-mode-${val}`);
      },
      immediate: true,
    }
  },

  components: {
    HelpBox,
    "widget-tree": WidgetTree,
    "widget-panel": WidgetPanel,
    "widget-list": WidgetList,
    "modal-translate-product": ModalTranslateProduct,
    draggable: Draggable,
  },

  methods: {
    allowMode(allow = []) {
      const { activePreviewMode } = this
      return allow.includes(activePreviewMode)
    },

    toggleTranslateModal() {
      this.isTranslateModalOpen = !this.isTranslateModalOpen;
    },

    async togglePreviewMode(state = true) {
      await this.actionUpdate(false, true).then(() => {
        this.activePreviewMode = state
      })
    },

    async actionTranslate(target_lang) {
      this.showTranslateLoading = true;
      const { options, id } = this;
      const { api } = options;
      const { translate } = api;

      await this.actionUpdate();
      await axios.post(translate.replace("[id]", id), {
        id,
        target_lang,
      });

      await this.actionRead();
      this.updateLang('default');
      this.showTranslateLoading = false;
      this.toggleTranslateModal();

      await useCreditStore().findHistory();

      showMessage("flash.translated");

    },

    onScroll($event){
      this.positionScrollEditor = $event.target.scrollTop;
    },

    updateLang(lang){
      this.valueLang = lang;
      this.isLoaded = false;
      setTimeout(() => {
        this.isLoaded = true;
      }, 200);
    },

    async readComponent(type) {
      await ShareModel.findComponent(
        type,
        () => {},
        (data) => {
          this.widgetType = type;
          this.component = data;
        }
      );
    },

    async actionRead(){
      const { options, id } = this;
      const { api } = options;
      const { widget } = api;

      await axios.get(widget.replace("[id]", id)).then(({ data }) => {
        this.widget = data;
        this.$emit("widget-data", this.widget);
        this.isLoaded = true;
        this.$emit("action-update", data);
      });
    },

    async elementHandler(data){
      await this.actionUpdate();

      const { options, id } = this;
      const { api } = options;

      await WidgetModel.copyElement(
        { ...data, ...{ id } },
        api.copy.replace("[id]", id),
        () => {},
        (data) => {
          this.widget = data;
        }
      );
    },

    async actionCreate({ added, moved }){
      const data = added || moved;
      const { options, widget, id } = this;
      const { api } = options;
      const { newIndex, element } = data;

      await this.actionUpdate();

      if (added) {
        let position = [];

        Array.from(widget).forEach(({ id }, index) => {
          position.push({
            id: id,
            position: index,
          });
        });

        await axios
          .post(api.widget.replace("[id]", id), {
            widget_id: element.id,
            position: newIndex,
            added: added !== undefined,
            json: element.children,
            ord: position,
          })
          .then(({ data }) => {
            this.widget = data;

            this.$emit("action-update", data);
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
    },

    async actionDelete(widget_id) {
      const { options, id } = this;
      const { api } = options;

      await this.actionUpdate();

      const url = api.widget.replace("[id]", id) + `/${widget_id}`;

      await axios
        .delete(url)
        .then(({ data }) => {
          this.widget = data;
          showMessage("flash.delete");
          this.$emit("action-update", data);
        });
    },

    async actionUpdate(
      showFlashMessage = false,
      refreshData = false
    ){
      const { options, id, widget } = this;
      const { api } = options;
      const { content } = options;

      await axios
        .put(api.widget.replace("[id]", id), { widget })
        .then(({ data }) => {
          if (refreshData) {
            this.widget = data;
            this.$emit("widget-data", this.widget);
          }
          if (showFlashMessage) {
            showMessage(`flash.content.${content}`);
          }
        });
    },

    moveWidget(state){
      this.isMovedWidget = state;
    },
  },
};
</script>
