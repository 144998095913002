import Account from "@/components/design/page/Account.vue";
import ConnectUser from "@/components/setting/page/connect-user.vue";
import Analytics from "@/page/Analytics.vue";
import Asset from "@/components/design/page/Asset.vue";
import Blog from "@/page/Blog.vue";
import Category from "@/page/Category.vue";
import Product from "@/page/Product.vue";
import ProductIframe from "@/page/Iframe/Product.vue";
import Share from "@/page/Share.vue";
import Setting from "@/components/setting/app.vue";
import BillingList from "@/components/setting/page/billing-list.vue";
import ChangeTariff from "@/components/setting/page/change-tariff.vue";
import CreditSetting from "@/components/setting/page/credit.vue";
import Editor from "@/components/setting/page/editor.vue";
import SettingTheme from "@/components/setting/page/setting-theme.vue";
import InvoiceList from "@/components/setting/page/invoice-list.vue";
import MarketplaceSetting from "@/components/setting/page/marketplace-setting.vue";
import MultiLang from "@/components/setting/page/multi-lang.vue";
import NotificationSetting from "@/components/setting/page/notification-setting.vue";
import InstallationGuide from "@/components/setting/page/installation-guide.vue";
import SettingAPI from "@/components/setting/page/setting-api.vue";
import Admin from "@/components/admin/app.vue";
import Dashboard from "@/components/admin/part/dashboard.vue";
import Demonstration from "@/components/admin/part/demonstration.vue";
import Eshop from "@/components/admin/part/eshop.vue";
import Template from "@/components/admin/template/index.vue";
import DesignApp from "@/components/design/app.vue";
import DesignPublic from "@/components/design/page/public.vue";
import CustomDesign from "@/components/design/page/custom.vue";
import Invoice from "@/components/admin/part/invoice.vue";
import Onboard from "@/components/admin/part/onboard.vue";
import Component from "@/components/component/app.vue";
import Login from "@/page/Login.vue";
import Check from "@/page/Check.vue";
import Designer from "@/components/guide/designer.vue";
import EditorLang from "@/components/setting/page/editor-lang.vue";
import SettingExport from "@/components/setting/page/setting-export.vue";
import Merchant from "@/layout/Merchant.vue";
import SettingTemplate from "@/components/setting/page/setting-template.vue";
import AbTest from "@/page/static/ab-test.vue";
import AiGenerator from "@/components/setting/page/ai-generator.vue";
import PartnersDetail from "@/components/setting/page/partners-detail.vue";
import Auth from "@/layout/Auth.vue";
import ResetPassword from "@/components/forms/ResetPassword.vue";
export const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/check",
    name: "check",
    component: Check,
  },
  {
    path: "/iframe/product/:id",
    component: ProductIframe,
  },
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "reset-password/:token",
        name: "auth-reset-password",
        component: ResetPassword,
      },
    ],
  },
  {
    path: "/static",
    name: "static",
    component: Merchant,
    redirect: "/static/ab-test",
    children: [
      {
        path: "ab-test",
        name: "static-ab-test",
        component: AbTest,
        meta: {
          title: "Category list",
        },
      }
    ]
  },
  {
    path: "/merchant",
    component: Merchant,
    redirect: "/merchant/product",
    children: [
      {
        path: "product",
        name: "product",
        component: Product,
        meta: {
          title: "Products list",
        },
      },
      {
        path: "category",
        name: "category",
        component: Category,
        meta: {
          title: "Category list",
        },
      },
      {
        path: "blog",
        name: "blog",
        meta: {
          title: "Blog posts list",
        },
        component: Blog,
      },
      {
        path: "share",
        name: "share",
        meta: {
          title: "Sdílené popisky pro hromadné úpravy"
        },
        component: Share,
      },
      {
        path: "asset",
        name: "asset",
        redirect: "/merchant/design/asset",
      },
      {
        path: "account",
        name: "account",
        redirect: "/merchant/design/designer",
        meta: {},
      },
      {
        path: "analytics",
        name: "analytics",
        component: Analytics,
        meta: { title: "Statistiky vašich popisků" },
      },
      {
        path: "designer",
        name: "designer",
        meta: { title: "Interaktivní průvodce nastavení vzhledu" },
        component: Designer,
      },
      {
        path: "design",
        name: "design",
        meta: { title: "Template design" },
        component: DesignApp,
        redirect: "/merchant/design/public",
        children: [
          {
            path: "public",
            name: "design-public",
            component: DesignPublic,
            meta: {},
          },
          {
            path: "custom",
            name: "design-custom",
            component: CustomDesign,
            meta: {},
          },
          {
            path: "template",
            name: "setting-template",
            component: SettingTemplate,
            meta: {},
          },
          {
            path: "designer",
            name: "design-designer",
            component: Account,
            meta: {},
          },
          {
            path: "asset",
            name: "design-asset",
            component: Asset,
            meta: {},
          },
        ]
      },
      {
        path: "setting",
        name: "setting",
        meta: { title: "Nastavení aplikace Pobo Page Builder" },
        component: Setting,
        children: [
          {
            path: "theme",
            name: "setting-theme",
            component: SettingTheme,
            meta: {},
          },
          {
            path: "editor",
            name: "setting-editor",
            component: Editor,
            meta: {},
          },
          {
            path: "multi-lang",
            name: "setting-multi-lang",
            component: MultiLang,
            meta: {},
          },
          {
            path: "ai-generator",
            name: "setting-ai-generator",
            component: AiGenerator,
            meta: {},
          },
          {
            path: "invoice",
            name: "setting-invoice-list",
            component: InvoiceList,
            meta: {},
          },
          {
            path: "billing-list",
            name: "setting-billing-list",
            component: BillingList,
            meta: {},
          },

          {
            path: "api",
            name: "setting-api",
            component: SettingAPI,
            meta: {},
          },
          {
            path: "connect-user",
            name: "connect-user",
            component: ConnectUser,
            meta: {},
          },
          {
            path: "partners-detail",
            name: "partners-detail",
            component: PartnersDetail,
            meta: {},
          },
          {
            path: "change-tariff",
            name: "change-tariff",
            component: ChangeTariff,
          },
          {
            path: "marketplace-setting",
            name: "marketplace-setting",
            component: MarketplaceSetting,
            meta: {},
          },

          {
            path: "credit",
            name: "credit",
            component: CreditSetting,
            meta: {},
          },
          {
            path: "notification-setting",
            name: "notification-setting",
            component: NotificationSetting,
            meta: {},
          },

          {
            path: "installation-guide",
            name: "installation-guide",
            component: InstallationGuide,
            meta: {},
          },

          {
            path: "editor-lang",
            name: "editor-lang",
            component: EditorLang,
            meta: {},
          },
          {
            path: "setting-export",
            name: "setting-export",
            component: SettingExport,
            meta: {},
          },
        ],
      },
      {
        path: "/admin",
        name: "admin",
        component: Admin,
        children: [
          {
            path: "dashboard",
            name: "dashboard",
            component: Dashboard,
            meta: {
              protected: true,
            },
          },
          {
            path: "eshop",
            name: "eshop",
            component: Eshop,
            meta: {
              protected: true,
            },
          },
          {
            path: "demonstration",
            name: "demonstration",
            component: Demonstration,
            meta: {
              protected: true,
            },
          },
          {
            path: "onboard",
            name: "onboard",
            component: Onboard,
            meta: {
              protected: true,
            },
          },
          {
            path: "invoice",
            name: "invoice",
            component: Invoice,
            meta: {
              protected: true,
            },
          },
          {
            path: "component",
            name: "component",
            component: Component,
            meta: {
              protected: true,
            },
          },
          {
            path: "template",
            name: "template",
            component: Template,
            meta: {
              protected: true,
            },
          },
        ],
      },
    ],
  },
];
