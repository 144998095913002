<template>
  <div class="w-100">
    <form
      class="col-12 col-md-12 bg-white"
      @submit.prevent="actionUpdate"
    >
      <b-form-group
        id="name"
        :label="$t('blog.modal.title')"
        description="Název článku musí obsahovat alespoň 5 znaků"
      >
        <b-form-input
          id="name"
          v-model="model.name"
          required
          type="text"
        />
      </b-form-group>
      <template v-if="user.role.includes('admin')">
        <b-form-group
          id="slug"
          :label="$t('blog.modal.slug')"
          description="URL adresa za lomítkem"
        >
          <b-form-input
            id="slug"
            v-model="model.slug"
            required
            type="text"
          />
        </b-form-group>

        <b-form-group
          id="position"
          :label="$t('blog.modal.position')"
          description="Pořadí (vyšší číslo znamená vyšší pozici)"
        >
          <b-form-input
            id="position"
            v-model="model.position"
            required
            type="number"
          />
        </b-form-group>

        <b-form-group
          id="category"
          :label="$t('blog.modal.category')"
          label-for="category"
        >
          <b-form-select
            id="category"
            v-model="model.category"
            :options="category"
            type="text"
          />
        </b-form-group>
      </template>

      <b-button
        :disabled="model.name.length < 5"
        block
        type="secondary"
      >
        {{ $t("blog.modal.save") }}
      </b-button>
    </form>
  </div>
</template>

<script>
import EshopModel from "@/model/EshopModel.js";
import BlogModel from "@/model/BlogModel.js";
import { showMessage } from "@/plugins/flashMessage";
export default {
  name: "modal-edit",
  props: {
    blog: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      require: true,
    },
  },

  async mounted() {
    this.user = await EshopModel.findMe();
  },

  data() {
    const { blog } = this;

    return {
      category: [
        { value: null, text: "Článek zobrazený na webu" },
        // { value: "help", text: "Nápověda" },
        { value: "function", text: "Funkce" },
        { value: "partners", text: "Partneři" },
        { value: "faq", text: "FAQ" },
        { value: "interview", text: "Interview" },
        { value: "company", text: "Pro firmy" },
        { value: "agency", text: "Pro agentury" },
        { value: "abtest", text: "A/B testy" },
        { value: "aboutus", text: "O nás" },
        { value: "premium", text: "Premium" },
        { value: "reference", text: "Reference" },
        { value: "static", text: "Statické stránky" },
      ],
      model: blog,
      user: {
        role: [],
      },
    };
  },

  methods: {
    async actionUpdate() {
      const { model, options } = this;
      model.position = Number(model.position);
      const { content } = options;
      await BlogModel.update(
        model.id,
        {
          name: model.name,
          position: model.position,
          slug: model.slug,
          category: model.category
        },
        () => {},
        () => {
          showMessage(`flash.module.${content}`);
          this.$emit('update')
        }
      );
    },
  },
};
</script>
