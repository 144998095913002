<template>
  <div class="text-center col-9 m-auto">
    <div class="ab-test__image-container d-flex justify-content-center align-items-center mb-4">
      <div class="ab-test__image-wrapper text-center">
        <img
          src="https://pobo-cdn.b-cdn.net/static/before-ab-test.png"
          alt="pobo-ab-test"
          class="ab-test__image shadow-sm"
          style="max-width: 100%;"
        >
      </div>

      <div class="ab-test__arrow-wrapper">
        <span class="ab-test__arrow-icon">➔</span>
      </div>

      <div class="ab-test__image-wrapper">
        <img
          src="https://pobo-cdn.b-cdn.net/static/after-ab-test%20(1).webp"
          alt="pobo-ab-test"
          class="ab-test__image shadow-sm"
          style="max-width: 100%; max-height: 1580px"
        >
      </div>
    </div>
    
    <h1 class="font-weight-bold d-flex align-items-center justify-content-center fs-35 mt-5">
      📈 Chcete zjistit, co skutečně funguje na Vašem e-shopu?
    </h1>
    <p class="fs-18 mb-5 mt-3">
      Víte, co Vaše zákazníky opravdu zajímá? Jaké popisky je zaujmou a co naopak odradí? Zjistěte to s nástrojem Pobo Page Builder a otestujte různé verze stránek i popisků pomocí jednoduchého A/B testování! Zjistěte například, jaké výsledky přináší popisky vygenerované umělou inteligencí, a nechte data pracovat za Vás.
    </p>

    <div class="bg-light py-5 px-5 rounded shadow-sm">
      <h2 class="font-weight-bold">
        Proč se do toho pustit?
      </h2>
      <ul class="text-left list-check mt-4">
        <li>
          Optimalizujte e-shop podle toho, co skutečně přináší výsledky
        </li>
        <li>
          Zvyšte konverze a zlepšete uživatelský zážitek
        </li>
        <li>
          Snadno zjistíte, které prvky Vaši zákazníci opravdu ocení
        </li>
      </ul>
    </div>

    <div
      class="mt-4 w-100 bg-light py-5 rounded shadow-sm"
    >
      <h2 class="font-weight-bold mt-4 mb-5">
        Máte zájem o nastavení A/B testu? Registrujte se zde:
      </h2>

      <iframe
        class="w-100 edit-content"
        style="height: 800px; overflow: hidden"
        src="https://pobo-support.com/cs?isIframe=true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AbTest',
};
</script>

<style lang="scss" scoped>
.ab-test {
  &__image-container {
    display: flex;
    align-items: center;
    max-width: 599px;
    gap: 15px;
    margin: 0 auto;
  }

  &__image-wrapper {
    position: relative;
    text-align: center;
  }

  &__variant-label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  &__image {
    max-width: 350px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: 1px solid #ddd;
  }

  &__arrow-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow-icon {
    font-size: 20px;
    color: #ff0000;
  }
}

.edit-content{
  border: none !important;
}

.edit-content .pobo-support-form__head{
  display: none !important;
}
</style>
