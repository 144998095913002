import axios from "axios";

export default class ShareModel {
  static async save(id, params, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .put("/api/v2/share/detail/" + id , params)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error Share [save()]", error);
        return error;
      });
  }

  static async create(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .post("/api/v2/share/detail", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create share [create()]", error);
        return error;
      });
  }

  static async matchProduct(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/share/product", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create match product [matchProduct()]", error);
        return error;
      });
  }

  static async findMe(
    shareId,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/share/detail/" + shareId)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch share products [findMe()]", error);
        return error;
      });
  }

  static async findProducts(
    shareId,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/share/product/" + shareId)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch share products [findProducts()]", error);
        return error;
      });
  }

  static async findAll(
    params,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/share/grid?query=" + params)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch share grid [findMe()]", error);
        return error;
      });
  }

  static async delete(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .delete("/api/v2/share/detail/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error delete share [delete()]", error);
        return error;
      });
  }

  static async update(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .put("/api/v2/share/grid", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error share update [update()]", error);
        return error;
      });
  }

  static async component(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .put("/api/v2/share/grid", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error share update [component()]", error);
        return error;
      });
  }

  static async findComponent(
    data,
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/widget/component/grid/" + data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch ShareModel [findComponent()]", error);
        errorCallback(error);
        return error;
      });
  }
}
