<template>
  <layout-setting col="12">
    <template #header>
      {{ $t("invoice.billing.title") }}
    </template>
    <template #content>
      <template v-if="isLoaded">
        <p class="text-md">
          {{ $t("invoice.billing.info") }}
        </p>
      </template>

      <template v-else>
        <h2 class="mt-0 pt-0">
          <b-skeleton-img
            height="38px"
            no-aspect
            width="300px"
          />
        </h2>
        <p class="d-block">
          <b-skeleton-img
            height="24px"
            no-aspect
            width="100%"
          />
        </p>
      </template>

      <button
        class="btn btn-secondary mb-4"
        @click="handleCreate"
      >
        {{ $t("invoice.billing.create") }}
      </button>

      <table class="table table-hover">
        <thead class="bg-white font-weight-bold">
          <tr>
            <td>{{ $t("invoice.billing.table.name") }}</td>
            <td>{{ $t("invoice.billing.table.ic") }}</td>
            <td>{{ $t("invoice.billing.table.dic") }}</td>
            <td colspan="2">
              {{ $t("invoice.billing.table.info") }}
            </td>
          </tr>
        </thead>
        <tbody>
          <template v-if="isLoaded">
            <template v-if="data.length > 0">
              <tr
                v-for="item in data"
                :key="item.id"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.ic ? item.ic : "-" }}</td>
                <td>{{ item.dic ? item.dic : "-" }}</td>
                <td>{{ item.city }}, {{ item.street }}, {{ item.zip }}</td>
                <td class="text-right">
                  <button
                    class="btn btn-secondary btn-sm"
                    @click="handleEdit(item)"
                  >
                    {{ $t("invoice.billing.table.edit") }}
                  </button>
                </td>
              </tr>
            </template>

            <template v-else>
              <tr>
                <td
                  class="text-black-50 text-center"
                  colspan="7"
                >
                  {{ $t("invoice.billing.table.notFound") }}
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr
              v-for="n in 5"
              :key="n"
            >
              <td colspan="5">
                <b-skeleton-img
                  height="20px"
                  no-aspect
                  width="100%"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <b-modal
        v-model="isBillingModalOpen"
        :title="$t('invoice.billing.form.modal')"
        hide-footer
        no-close-on-backdrop
        no-fade
        size="md"
        @close="handleClose"
      >
        <billing-form
          :billing="selectBilling"
          :billing-create="billingCreate"
          @handle-close="handleCloseForm"
        />
      </b-modal>
    </template>
  </layout-setting>
</template>

<script>
import BillingForm from "@/components/setting/part/billing-form.vue";
import BillingModel from "@/model/BillingModel.js";

export default {
  name: "BillingList",
  displayName: "BillingList",

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      data: Array,
      isLoaded: false,
      isBillingModalOpen: false,
      selectBilling: null,
      billingCreate: false,
    };
  },

  components: {
    "billing-form": BillingForm,
  },

  methods: {
    handleCreate() {
      this.billingCreate = true;
      this.isBillingModalOpen = true;
    },

    handleEdit(billing) {
      this.billingCreate = false;
      this.isBillingModalOpen = true;
      this.selectBilling = billing;
    },

    handleClose() {
      this.selectBilling = null;
    },

    handleCloseForm(data) {
      this.data = data;
      this.selectBilling = null;
      this.isBillingModalOpen = false;
    },

    async actionRead() {
      await BillingModel.findAll(
        () => {},
        (data) => {
          this.data = data;
          this.isLoaded = true;
        }
      );
    },
  },
};
</script>