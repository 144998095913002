import axios from "axios";

export default class ConnectUser {
  static async createConnection(
    data,
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/eshop/connect/user", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch(({ response }) => {
        const { data } = response;
        errorCallback(data);
        console.warn("Error ConnectUser [createConnection()]", data);
        return data;
      });
  }

  static async findAllConnection(
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/connect/user")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch(({ response }) => {
        const { data } = response;
        errorCallback(data);
        console.warn("Error ConnectUser [findAllConnection()]", data);
        return data;
      });
  }

  static async deleteConnection(
    id,
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .delete("/api/v2/eshop/connect/user/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch(({ response }) => {
        const { data } = response;
        errorCallback(data);
        console.warn("Error ConnectUser [deleteConnection()]", data);
        return data;
      });
  }
}
