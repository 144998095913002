<template>
  <div>
    <layout-grid>
      <template #title>
        {{ $t("grid.header") }}
      </template>

      <template #panel>
        <b-btn-group class="shadow-sm">
          <modal-import @close-modal="fetchProduct(1, true)" />

          <modal-export @close-modal="fetchProduct(1, true)" />

          <modal-translate-all @close-modal="fetchProduct(1, true)" />

          <modal-control
            @handle-edit="openEditor"
            @close-modal="fetchProduct"
          />

          <guide-modal
            @close="fetchProduct"
          />
        </b-btn-group>
      </template>

      <template #welcome>
        <help-box
          author="sara"
          type="use"
        >
          <h2 class="mb-4">
            {{ $t("How to use the Pobo Page Builder editor?") }}
          </h2>
          <p class="fs-16 lh-30 font-weight-light">
            {{ $t("Introducing Pobo Page Builder: Drag & drop editor for e-shops.") }}
            {{ $t("Learn how to create attractive product descriptions with advanced formatting, no HTML/CSS needed.") }}
            {{ $t("Discover how to enhance the appeal and conversion rate of your e-shop.") }}
          </p>
        </help-box>
      </template>

      <template #filter-list>
        <filter-product-list
          :categories="selectedCategoryObjects"
          @remove="removeCategory"
        />
      </template>

      <template #pagination>
        <b-pagination
          v-model="params.offset"
          :per-page="params.limit"
          :total-rows="params.total"
          pills
          size="sm"
          @change="handleUsePaginator"
        />
      </template>

      <template #filter>
        <grid-filter
          :current="params.filter"
          :list="listFilter"
          @change="filterProduct"
        >
          <template #additional>
            <filter-category
              v-model="selectedCategories"
              @input="updateCategory"
            />
          </template>
        </grid-filter>
      </template>

      <template #content>
        <div class="product-table--head">
          <div class="text-right">
            {{ $t("grid.analytics") }}
          </div>
          <div>{{ $t("grid.listProduct") }}</div>

          <div>
            <grid-search
              @input="updateQuery"
              @submit="searchProduct"
            />
          </div>
          <b-dropdown
            :variant="checkedProducts.length === 0 ? 'light' : 'secondary'"
            size="sm"
            class="text-left"
          >
            <template #button-content>
              <b-icon
                icon="tools"
                size="12"
              />  {{ $t('Events') }}
            </template>

            <b-dropdown-item
              @click="selectAll"
            >
              <!--
              {{ $t("Deselect all ") }}
              -->
              {{ $t("Select all products") }}
            </b-dropdown-item>

            <b-dropdown-item
              :disabled="checkedProducts.length <= 0"
              @click="changeStatus"
            >
              {{ $t("Change product status") }}
            </b-dropdown-item>

            <b-dropdown-item
              :disabled="checkedProducts.length <= 0"
              @click.prevent="openToolModal('multiple-ai', false)"
            >
              {{ $t("Create via ChatGPT") }}
              <span class="fs-10 mb-0 d-block">
                {{ $t("Creates a label via AI for all tagged products") }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <template v-if="all > 0 && products.length > 0">
          <div
            v-for="item in products"
            :key="item.id"
          >
            <grid-row
              :id="item.id"
              ref="gridRows"
              :category="item.category"
              :content="item.content"
              :image="item.image"
              :is-deleted="item.is_delete"
              :is-favourite="item.is_favourite"
              :is-visible="item.is_visible"
              :item="item"
              :name="item.name"
              :read="item.read"
              :url="item.url"
              :status="item.status"
              :design="item.design"
              data-cy="grid-table"
              grid-class="product-table"
              type="product"
              @open-generator="openGenerator"
              @update="fetchProduct"
              @open-editor="openEditor"
            >
              <template #checkbox>
                <input
                  v-model="checkedProducts"
                  :checked="checkedProducts.includes(item.id)"
                  :value="item"
                  type="checkbox"
                  class="select-grid-item__checkbox"
                >
              </template>
            </grid-row>
          </div>
        </template>
        <template v-else>
          <div class="text-center col-4 m-auto py-4">
            <h2>{{ $t("welcome.title") }}</h2>
            <p>{{ $t("welcome.content") }}</p>
            <modal-import />
          </div>
        </template>
      </template>
    </layout-grid>



    <browser-panel
      :primary-modal-open="primaryModalOpen"
      type="product"
      @set-active-panel="setActivePanel"
    />


    <Transition name="slide-up">
      <editor-browser
        v-show="primaryModalOpen"
        ref="editorBrowser"
        :tabs="tabs.getGlobalTabs('product')"
        :active-id="tabs.getGlobalActiveIndex('product')"
        :options="options.product"
        :is-open="primaryModalOpen"
        type="product"
        :window-browser-tab="windowBrowserTab"
        @open-tool-modal="openToolModal"
        @action-close="closeEditor"
        @change-tab="changeProductTab"
        @action-minimize="minimizeEditor"
      />
    </Transition>

    <b-modal
      v-model="secondaryModalOpen"
      :size="getSize"
      :title="getTitle"
      hide-footer
      :hide-header="getHideHeader"
    >
      <div
        v-if="tabOpen.includes('legacy') && product.old_description"
        class="bg-white p-4 pt-1 editor-bg"
      >
        <div v-html="product.old_description" />
      </div>

      <div v-if="tabOpen.includes('detail')">
        <modal-edit
          :id="product.id"
          :options="options.product"
          @update="approveProduct"
        />
      </div>

      <div v-if="tabOpen.includes('ai')">
        <modal-copy-ai
          :product-id="product.id"
          @create="closeEditor"
        />
      </div>

      <template v-if="tabOpen.includes('multiple-ai')">
        <multiple-copy-ai
          :allow-ai="hasEnoughCredit"
          :products="checkedProducts"
          :credit="countCredit"
          @close-modal="closeSecondaryModal"
        />
      </template>

      <div v-if="tabOpen.includes('copy')">
        <Copy
          :product="product"
          @close-modal="closeEditor"
        />
      </div>

      <div v-if="tabOpen.includes('old')">
        <modal-old-description
          :id="product.id"
          @recovery-old-description="recoveryOldDescription"
        />
      </div>


      <div v-if="tabOpen.includes('export')">
        <modal-extend-export
          :content="product"
          @close="closeSecondaryModal"
        />
      </div>

      <div v-if="tabOpen.includes('approve')">
        <modal-approve-product
          :product="product"
          @approve-product="approveProduct"
        />
      </div>

      <div v-if="tabOpen.includes('designer')">
        <modal-designer-start
          :mode="eshop.template_mode"
          :step="activeStepForDesigner"
          @start-designer="startDesigner"
        />
      </div>
    </b-modal>

    <b-modal
      v-model="modalStatus"
      hide-footer
      size="md"
      :no-close-on-backdrop="isRunningMultipleChangeStatus"
      :no-close-on-esc="isRunningMultipleChangeStatus"
      :hide-header-close="isRunningMultipleChangeStatus"
      :title="$t('Bulk change of states for products')"
      @hide="resetChecked"
    >
      <b-overlay :show="isRunningMultipleChangeStatus">
        <modal-change-status
          :products="checkedProducts"
          @change-status="updateProductsState"
        >
          <template #bottom>
            <b-form-group>
              <slot name="bottom" />
              <b-btn
                block
                variant="secondary"
                size="sm"
                class="mt-3 w-100 fs-15"
                type="submit"
              >
                {{ $t('Apply changes') }}
              </b-btn>
            </b-form-group>
          </template>
        </modal-change-status>
      </b-overlay>
    </b-modal>

    <loading-overlay
      v-if="showLoading"
      :text="$t('loading-overlay.product')"
    />
  </div>
</template>

<script>
import ModalCopyAI from "@/components/product/modal-copy-ai.vue";
import ModalDesignerStart from "@/share/modal-designer-start.vue";
import ModalImport from "@/components/product/modal-import.vue";
import ModalOldDescription from "@/components/product/modal-old-description.vue";
import ModalExport from "@/components/product/modal-export.vue";
import ModalTranslateAll from "@/components/product/modal-translate-all.vue";
import ModalControl from "@/components/product/modal-control.vue";
import ModalEdit from "@/components/product/modal-edit.vue";
import toolTip from "@/config/tooltip.json";
import GuideModal from "@/components/guide/guide-modal.vue";
import options from "@/config/share.json";
import EditorBrowser from "@/share/editor-browser.vue";
import GridFilter from "@/share/grid-filter.vue";
import { products } from "@/config/filter.json";
import GridSearch from "@/share/grid-search.vue";
import FilterCategory from "@/share/filter-category.vue";
import GridRow from "@/share/grid-row.vue";
import ProductModel from "@/model/ProductModel.js";
import eshopData from "@/mixin/eshopData";
import { useCreditStore } from "@/store/CreditStore.js";
import { useStatsInfoStore } from "@/store/StatsInfoStore";
import HelpBox from "@/share/help-box.vue";
import Copy from "@/page/Copy.vue";
import ModalExtendExport from "@/share/modal-extend-export.vue";
import { showMessage } from "@/plugins/flashMessage";
import MultipleCopyAi from "@/components/product/multiple-copy-ai.vue";
import ModalChangeStatus from "@/share/part/modal-change-status.vue";
import ModalApproveProduct from "@/share/modal-approve-product.vue";
import BrowserPanel from "@/share/browser-panel.vue";
import {useTabsStore} from "@/store/TabsStore";
import LoadingOverlay from "@/share/loading-overlay.vue";
import LiveDesigner from "@/model/LiveDesigner";
import FilterProductList from "@/share/filter-product-list.vue";
import filterCategory from "../share/filter-category.vue";
import CategoryModel from "@/model/CategoryModel";
import eshop from "@/components/admin/part/eshop.vue";
const lang = "default";

export default {
  name: "Product",

  displayName: "Product",

  provide() {
    return {
      pageType: "product"
    }
  },

  mixins: [eshopData],

  async mounted() {
    await this.findAllCategory();
    await this.fetchProduct();
    await this.getRightTab();
  },

  data() {
    return {
      toolTip: toolTip,
      products: [],
      activeIndex: null,
      selectedProducts: [],
      checkedProducts: [],
      modalStatus: false,
      params: {
        offset: 1,
        limit: 30,
        total: 0,
        query: "",
        filter: 1,
        category: [],
      },
      all: 0,
      isLoaded: false,
      tabOpen: ["editor"],
      primaryModalOpen: false,
      secondaryModalOpen: false,
      isRunningMultipleChangeStatus: false,
      isToolOpen: false,
      productId: null,
      analyticsOpen: false,
      options: options,
      listFilter: products,
      showLoading: false,
      designerWasSave: false,
      activeStepForDesigner: 1,
      selectedCategories: [],
      allCategories: [],
      windowBrowserTab: [
        {
          event: "old",
          translate: "View original content",
          cypressTag: "product-detail-old",
          type: "main", // main or tool,
          allowPlatform: ["shoptet", "shopify", "b2b"]
        },
        {
          event: "designer",
          translate: "Edit the appearance",
          cypressTag: "product-detail-designer",
          type: "tool",
          ico: "tool-designer",
          allowPlatform: ["shoptet", "shopify", "b2b"]
        },
        {
          event: "ai",
          translate: "Create via ChatGPT",
          cypressTag: "product-detail-ai",
          type: "tool",
          ico: "tool-ai",
          allowPlatform: ["shoptet", "shopify", "b2b"]
        },
        {
          event: "copy",
          translate: "Copy widgets",
          cypressTag: "product-detail-copy",
          type: "tool",
          ico: "tool-copy",
          allowPlatform: ["shoptet", "shopify", "b2b"]
        },
        {
          event: "detail",
          translate: "Product detail",
          cypressTag: "product-detail-detail",
          type: "main",
          allowPlatform: ["shoptet", "shopify", "b2b"]
        },
        {
          event: "export",
          translate: "Export content",
          cypressTag: "product-detail-export",
          type: "main",
          allowPlatform: ["shoptet", "shopify"]
        }
      ],

      lastTab: [
        {
          event: "export",
          translate: "Export content",
          type: "main",
        },
        {
          event: "approve",
          translate: "Approve label",
          type: "main"
        },
      ]
    };
  },

  computed: {
    filterCategory() {
      return filterCategory
    },

    check() {
      return check
    },
    tabs(){
      return useTabsStore();
    },

    product() {
      return this.tabs.getTabData('product');
    },

    selectedCategoryObjects() {
      return this.allCategories.filter((category) =>
        this.selectedCategories.includes(category.id)
      );
    },

    countCredit(){
      const { price } = this;
      const { generate } = price;

      return generate * this.checkedProducts.length;
    },

    hasEnoughCredit(){
      const { summary } = this;
      const { reaming } = summary;

      return reaming >= this.countCredit;
    },

    getSize() {
      const { tabOpen } = this;

      if (tabOpen.includes("old")) {
        return "editor-lg";
      } else if (tabOpen.includes("detail")) {
        return "editor-md";
      } else if (tabOpen.includes("copy")) {
        return "hd";
      } else if (tabOpen.includes("translate")) {
        return "editor-md";
      } else if (tabOpen.includes("ai")) {
        return "editor-lg";
      } else if (tabOpen.includes("export")) {
        return "md";
      }else if (tabOpen.includes("multiple-ai")) {
        return "ai-multiple";
      }else if (tabOpen.includes("approve")) {
        return "md";
      }else if (tabOpen.includes("designer")) {
        return "editor-md";
      }

      return "editor-lg";
    },

    getHideHeader(){
      const { tabOpen } = this;
      return tabOpen.includes("designer") && this.activeStepForDesigner === 2;
    },

    getTitle() {
      const { tabOpen } = this;

      if (tabOpen.includes("old")) {
        return this.$t("product.panel.oldDescription");
      } else if (tabOpen.includes("detail")) {
        return this.$t("product.panel.shortDescription");
      } else if (tabOpen.includes("copy")) {
        return this.$t("product.panel.copyProduct");
      } else if (tabOpen.includes("translate")) {
        return this.$t("Translate content");
      } else if (tabOpen.includes("ai")) {
        return this.$t("Create via ChatGPT");
      } else if (tabOpen.includes("export")) {
        return this.$t("Export to e-eshop");
      }else if (tabOpen.includes("multiple-ai")) {
        return this.$t("Create a bulk label via AI");
      }else if (tabOpen.includes("approve")) {
        return this.$t("Approve label");
      }else if (tabOpen.includes("designer")) {
        return this.$t("Edit the appearance");
      }


      return `Warning: getTitle() not set`;
    },
  },

  components: {
    "filter-product-list": FilterProductList,
    "browser-panel": BrowserPanel,
    ModalApproveProduct,
    "multiple-copy-ai": MultipleCopyAi,
    "modal-extend-export": ModalExtendExport,
    "modal-change-status": ModalChangeStatus,
    Copy,
    "modal-translate-all": ModalTranslateAll,
    "modal-old-description": ModalOldDescription,
    "help-box": HelpBox,
    "editor-browser": EditorBrowser,
    "modal-copy-ai": ModalCopyAI,
    "modal-edit": ModalEdit,
    "modal-import": ModalImport,
    "modal-export": ModalExport,
    "modal-control": ModalControl,
    "guide-modal": GuideModal,
    "grid-filter": GridFilter,
    "grid-search": GridSearch,
    "grid-row": GridRow,
    "filter-category": FilterCategory,
    "loading-overlay": LoadingOverlay,
    "modal-designer-start": ModalDesignerStart,
  },

  methods: {
    async recoveryOldDescription() {
      await this.$refs.editorBrowser.actionRead();
      this.secondaryModalOpen = false;
    },

    async getData() {
      const data = await ProductModel.findAll(this.params);
      this.products = data.product;
      this.params.total = data.total;
      this.all = data.all;
    },

    async findAllCategory(){
      const result = await CategoryModel.findAllCategory(
        this.params,
        () => {},
        () => {}
      );

      if (result.category) {
        this.allCategories = result.category;
      }
    },

    removeCategory(id) {
      this.selectedCategories = this.selectedCategories.filter(
        (categoryId) => categoryId !== id
      );

      this.updateCategory(this.selectedCategories);
    },

    async getRightTab(){
      if(this.product.status === 'draft'){
        this.windowBrowserTab[this.windowBrowserTab.length - 1] = this.lastTab[1]
      }else{
        this.windowBrowserTab[this.windowBrowserTab.length - 1] = this.lastTab[0]
      }
    },

    async approveProduct(product){
      this.product.status = product.status;
      await this.getRightTab();
      this.secondaryModalOpen = false;
    },

    resetChecked() {
      this.checkedProducts = [];
    },

    async updateProductsState(state) {
      let x = 0;

      this.isRunningMultipleChangeStatus = true;

      for (let product of this.checkedProducts) {
        await ProductModel.update(
          product.id,
          {
            data: {
              name: product.name,
              status: state,
              design_id: product.design.id,
              short_description: product.short_description,
            },
          },
          () => {},
          () => {}
        );
        x++;
      }

      showMessage(`flash.module.${state}`);

      await this.fetchProduct();
      this.isRunningMultipleChangeStatus = false;
      this.modalStatus = false;
    },

    selectAll() {
      const { products, checkedProducts } = this;
      products.forEach(product => {
        if (!checkedProducts.find(checkedProduct => checkedProduct.id === product.id)) {
          checkedProducts.push(product);
        }
      });
    },

    changeStatus(){
      this.modalStatus = !this.modalStatus;
    },

    closeSecondaryModal() {
      this.secondaryModalOpen = false;
    },

    setActivePanel(entityId) {
      this.primaryModalOpen = (this.tabs.getGlobalActiveIndex('share') !== entityId) || !this.primaryModalOpen;

      if(!this.primaryModalOpen) {
        document.body.classList.remove("modal-open");
      }
      this.tabs.setActiveIndex('product', entityId);
    },

    updateCategory(category) {
      this.params.category = category;
      this.fetchProduct();
    },

    updateQuery(query) {
      this.params.query = query;
    },

    async resetFilter() {
      this.params.filter = 1;
      this.params.query = "";
      await this.fetchProduct();
    },



    async searchProduct() {
      this.showLoading = true;
      const { params } = this;
      await ProductModel.findAll(
        params,
        () => {},
        (data) => {
          this.products = data.product;
          this.params.total = data.total;
          this.all = data.all;
          this.showLoading = false;
        }
      )
    },

    async fetchProduct(offset = 1, fetchCredit = false) {
      this.params.offset = offset;
      this.isLoaded = false;
      const { params } = this;
      await ProductModel.findAll(
        params,
        () => {},
        (data) => {
          this.products = data.product.map(item => ({ ...item, checked: false }));
          this.params.total = data.total;
          this.all = data.all;
        }
      );

      this.isLoaded = true;

      if (fetchCredit) {
        await useCreditStore().findHistory();
      }
    },

    async filterProduct(filter) {
      this.showLoading = true;
      this.params.filter = filter;
      this.params.offset = 1;
      await this.fetchProduct();
      this.showLoading = false;
    },

    async handleUsePaginator(count) {
      this.showLoading = true;
      this.params.offset = count;
      await this.fetchProduct(count);
      this.showLoading = false;
    },

    changeProductTab(id) {
      this.tabs.setActiveIndex('product', id);
    },

    async minimizeEditor() {
      this.primaryModalOpen = false;
      this.tabOpen = ["editor"];

      document.body.classList.remove("modal-open");
      await useStatsInfoStore().fetch()
    },

    async closeEditor(entity) {
      const { id } = entity;

      await this.tabs.closeEditor('product', id);

      this.secondaryModalOpen = false;
      this.primaryModalOpen = false;

      document.body.classList.remove("modal-open");

      await this.getData();
      await useStatsInfoStore().fetch();
    },

    async openGenerator(product) {
      await  this.getProduct(product);
      this.secondaryModalOpen = true;
      this.tabOpen = ["ai"];

      setTimeout(() => {
        showMessage("To make the AI work properly, we opened an editor in the background");
      }, 750)
    },

    async openEditor(product) {
      await this.tabs.fetchTab('product', product);
      document.body.classList.add("modal-open");
      this.primaryModalOpen = true;
    },

    openToolModal(name, entity) {
      this.tabOpen = [name];
      if(name === 'designer'){
        this.activeStepForDesigner = 1;
      }
      this.secondaryModalOpen = true;

    },

    startDesigner() {
      this.designerWasSave = false;
      LiveDesigner.create({
        product_id: this.product.id,
      },
      () => {},
      (data) => {
        window.open(data.product.url);

        window.addEventListener("message", async (event) => {
          const { data } = event;
          if (typeof data === "object" && !this.designerWasSave && data.hasOwnProperty('css')) {
            this.activeStepForDesigner = 2;
            await LiveDesigner.update(
              data,
              () => {},
              (responseData) => {
                this.designerWasSave = true;
                if (typeof responseData === "object") {
                  this.updateDesignForProduct(responseData.id);
                }else{
                  this.secondaryModalOpen = false;
                  this.activeStepForDesigner = 1;
                }
              }
            );
          }
        });
      })
    },

    async updateDesignForProduct(id) {
      const { product } = this;

      await ProductModel.update(
        {
          data: {
            id: product.id,
            name: product.name,
            status: product.status,
            design_id: id,
            short_description: product.short_description,
            is_reference: product.is_reference,
          },
        },
        () => {},
        () => {
          this.activeStepForDesigner = 3;
        }
      );
    },

    async getProduct(product) {
      await this.tabs.fetchTab('product',product);

    },
  },
};
</script>
