<template>
  <b-form
    @submit.prevent="actionUpdate"
  >
    <layout-setting>
      <template #header>
        {{ $t("marketplace.header") }}
      </template>
      <template #content>
        <b-row>
          <b-col cols="4">
            <strong class="pb-3 d-block fs-15 lh-22">
              {{ $t('Sample description on the e-shop') }}
            </strong>

            <div class="px-3 pt-2 bg-light border rounded">
              <small class="fs-13 mb-2 pb-2 d-inline-block border-bottom font-weight-bolder">
                {{ $t('Content in e-shop') }}
              </small>

              <b-row align-content="center">
                <b-col cols="7">
                  <h1 class="fs-13 font-weight-bolder m-0 pb-1">
                    {{ $t('iPhone 14') }}
                  </h1>
                  <p class="fs-11">
                    {{ $t('Improved design, performance and battery life.') }}
                  </p>
                  <h2 class="fs-13  font-weight-bolder m-0 pb-1">
                    {{ $t('Revolutionary camera') }}
                  </h2>

                  <p class="fs-11 pb-3">
                    {{ $t('Sample description on the e-shop') }}
                  </p>
                </b-col>

                <b-col cols="5">
                  <b-img
                    fluid
                    rounded
                    src="https://pobo-cdn.b-cdn.net/guide/ihpone.png"
                  />
                </b-col>
              </b-row>
            </div>

            <small class="text-muted  mt-2  pt-2 d-block  fs-13 lh-20">
              <b-icon
                icon="info-circle"
                class="mr-1"
                size="10"
              />
              {{ $t('When disabled, the labels are displayed correctly in the e-shop.') }}
              {{ $t('However, on the marketplace markets, images and videos may be displayed incorrectly due to the use of LazyLoad technology for media loading.') }}
            </small>
          </b-col>
          <b-col cols="8">
            <strong class="pb-3 d-block fs-15 lh-22">
              {{ $t('Sample of the label on the e-shop and marketplace with the feature enabled') }}
            </strong>

            <b-row>
              <b-col cols="6">
                <div class="px-3 pt-2 bg-light border rounded">
                  <small class="fs-13 mb-2 pb-2 d-inline-block border-bottom font-weight-bolder">
                    {{ $t('Content in e-shop') }}
                  </small>
                  <b-row align-content="center">
                    <b-col cols="7">
                      <h1 class="fs-13 font-weight-bolder m-0 pb-1">
                        {{ $t('iPhone 14') }}
                      </h1>
                      <p class="fs-11">
                        {{ $t('Improved design, performance and battery life.') }}
                      </p>
                      <h2 class="fs-13  font-weight-bolder m-0 pb-1">
                        {{ $t('Revolutionary camera') }}
                      </h2>

                      <p class="fs-11 pb-3">
                        {{ $t('Sample description on the e-shop') }}
                      </p>
                    </b-col>

                    <b-col cols="5">
                      <b-img
                        fluid
                        rounded
                        src="https://pobo-cdn.b-cdn.net/guide/ihpone.png"
                      />
                    </b-col>
                  </b-row>
                </div>

                <small class="text-muted pt-3 d-block fs-13 lh-20">
                  <b-icon
                    icon="info-circle"
                    class="mr-1"
                    size="10"
                  />
                  {{ $t('With this feature enabled, the design is loaded dynamically, which means that HTML labels stored in XML are compatible with the marketplace.') }}
                  {{ $t(`This way, your e-shop will display the same deisgn and you won't know the difference.`) }}
                </small>
              </b-col>
              <b-col
                cols="1"
                class="fs-40 font-weight-light text-center pt-5"
              >
                +
              </b-col>
              <b-col cols="5">
                <div class="px-3 pt-2 bg-light border rounded">
                  <small class="fs-13 mb-2 pb-2 d-inline-block border-bottom font-weight-bolder">
                    {{ $t('Content in marketplace') }}
                  </small>

                  <h1 class="fs-13 font-weight-bolder m-0 pb-1">
                    {{ $t('iPhone 14') }}
                  </h1>
                  <p class="fs-11">
                    {{ $t('Improved design, performance and battery life.') }}
                  </p>


                  <div
                    style="height: 68px"
                    class="overflow-hidden mx-4"
                  >
                    <b-img
                      fluid
                      rounded
                      src="https://pobo-cdn.b-cdn.net/guide/ihpone.png"
                    />
                  </div>
                </div>

                <small class="text-muted  pt-3 d-block  fs-13 lh-20">
                  <b-icon
                    icon="info-circle"
                    class="mr-1"
                    size="10"
                  />
                  {{ $t('Marketplace marketplaces will correctly display basic deisgn descriptions with images, YouTube and other media content.') }}
                </small>
              </b-col>
            </b-row>
          </b-col>
        </b-row>


        <p class="text-sm mb-3 mt-4 border-top pt-4">
          {{ $t("marketplace.content") }}
        </p>

        <tier-control
          :allow-tariff="['profi', 'premium', 'saas']"
          minimum-tariff="profi"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="form.enable_marketplace"
              :unchecked-value="false"
              :value="true"
              class="mb-2"
            >
              {{ $t("marketplace.checkbox") }}
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mt-4">
            <b-button type="submit">
              {{ $t("marketplace.button") }} <i class="ico ico-arrow-right" />
            </b-button>
          </b-form-group>
        </tier-control>
      </template>
    </layout-setting>
  </b-form>
</template>

<script>
import TierControl from "@/share/tier-control.vue";
import eshopData from "@/mixin/eshopData";
import cloneDeep from "lodash.clonedeep";
import { useEshopStore } from "@/store/EshopStore";

export default {
  name: "marketplace-setting",

  mixins: [eshopData],

  async mounted() {
    const { eshop } = this;
    this.form = cloneDeep(eshop);
  },

  data() {
    return {
      form: {},
      errors: {},
    };
  },

  components: {
    "tier-control": TierControl,
  },

  methods: {
    async actionUpdate() {
      const { form } = this;
      await useEshopStore().updateEshop({
        data: form
      });
    },
  },
};
</script>
