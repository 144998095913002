<template>
  <b-btn
    class="pr-3"
    size="sm"
    variant="secondary"
    :disabled="isLocked"
    @click="open"
  >
    <b-spinner
      v-if="enableRequest"
      small
    />
    <i
      v-else
      class="ico ico-update"
    />
    {{
      enableRequest
        ? $t("sync.status.running.button")
        : $t("sync.status.complete.button")
    }}
  </b-btn>

  <span
    v-if="false"
    class="text-muted text-center mt-2 d-block"
    style="font-size: 13px; line-height: 16px"
  >
    {{
      enableRequest
        ? $t("sync.status.running.info")
        : $t("sync.status.complete.info")
    }}
  </span>

  <small
    v-if="cron.request_import"
    class="p-1 rounded text-center d-block mt-2"
    style="background: #ffe59a"
  >
    <i class="ico ico-warning" /> Spusťte import pro stažení aktuálních dat.
  </small>

  <modal-panel
    ref="modalPanel"
    :no-close-on-esc="enableRequest"
    :no-close-on-backdrop="enableRequest"
    :hide-header-close="enableRequest"
    :title="$t('sync.modal.waitingRequest.title')"
    help="import"
    :header="$t('Download all new products and changes from your e-shop to Pobo by clicking on the button below. All data will be up to date.') + ' ' + $t('Attention! This synchronization is only for adding new products and categories to Pobo, where you can edit them later. However, it is not used to upload text changes from Shopify.')"
    @close-modal="$emit('close-modal')"
  >
    <template #action>
      <b-btn
        data-cy-="modal-import-request"
        variant="secondary"
        :disabled="enableRequest"
        @click="actionCreate"
      >
        <i class="ico ico-update" />
        {{ $t("sync.modal.request") }}
      </b-btn>
    </template>
    <template #content>
      <div
        v-if="enableRequest"
        class="text-center"
      >
        <div>
          <b-spinner size="large" />
        </div>
        <h3 class="mt-4">
          {{ $t("sync.modal.enableRequest.title") }}
          <!---  {{ $t("sync.modal.waitingRequest.title") }} -->
        </h3>
        <p v-html="$t('sync.modal.enableRequest.info')" />
      </div>
      <div
        v-else
        class="text-center"
        data-cy="modal-import-indicator-disable"
      >
        <div>
          <p
            class="text-center"
            v-html="$t('sync.modal.enableRequest.info')"
          />
        </div>
      </div>

      <div
        v-if="cron.list.length > 0"
        class="shadow-sm bg-white rounded p-2 mt-4"
      >
        <div class="import-table import-table--finish font-weight-bold">
          <div>{{ $t("sync.modal.table.start") }}</div>
          <div>{{ $t("sync.modal.table.finish") }}</div>
        </div>

        <div
          v-for="item in cron.list"
          :key="item.id"
          :class="[
            item.updated_at ? 'import-table--finish' : 'import-table--running',
            'import-table',
          ]"
        >
          <div>
            {{ datetime(item.created_at) }}
          </div>
          <div>
            <template v-if="item.updated_at">
              {{ datetime(item.created_at) }}
            </template>
            <template v-else>
              {{ $t("sync.modal.table.waitForEnd") }}
              <b-spinner small />
            </template>
          </div>
        </div>
      </div>
    </template>
  </modal-panel>
</template>

<script>
import ImportModel from "@/model/ImportModel.js";
import { datetime } from "@/filter/vue";
import ModalPanel from "@/components/share/modal-panel.vue";
import lockedData from "@/mixin/lockedData";
export default {
  name: "ModalImport",

  mixins: [lockedData],

  async mounted() {
    await this.actionRead();

    await this.checkModal();

    setInterval(async () => {
      await this.checkModal();
    }, 5000);
  },

  data() {
    return {
      cron: {
        stats: [],
        enable_request: true,
        request_import: false,
        list: [],
      },
    };
  },

  computed: {
    enableRequest: function () {
      const { cron } = this;
      return !cron.enable_request;
    },
  },
  components: {
    "modal-panel": ModalPanel
  },

  methods: {
    datetime,
    async checkModal() {
      if (this.enableRequest) {
        this.open();
        await this.actionRead();
      }
    },

    async actionCreate() {
      await ImportModel.create(
        () => {},
        (data) => {
          this.cron = data;
        }
      );
    },

    async actionRead() {
      this.cron = await ImportModel.findAll();
    },

    open() {
      this.$refs.modalPanel.isModalOpen = true;
    },
  },
};
</script>
