<template>
  <template v-if="connections.length > 0">
    <b-row
      v-for="connection in connections"
      :key="connection.id"
      class="py-2 border-bottom"
    >
      <b-col
        class="font-weight-bolder"
        cols="8"
      >
        {{ connection.user.email }}
      </b-col>

      <b-col
        class="text-right"
        cols="4"
      >
        <confirm-delete
          :id="connection.id"
          @delete="actionDelete"
        >
          <template #trigger>
            <i class="ico ico-component-delete" />
            {{ $t("connect.user.delete") }}
          </template>
        </confirm-delete>
      </b-col>
    </b-row>
  </template>
  <div
    v-else
    class="text-center text-muted d-block text-sm"
  >
    {{ $t("connect.user.notFound") }}
  </div>
</template>

<script>
export default {
  props:{
    connections: {
      type: Array,
      required: true
    }
  },

  methods:{
    actionDelete(id){
      this.$emit('delete', id)
    }
  }
}
</script>
