<template>
  <div>
    <help-box
      author="sara"
      type="designer"
      classes="mt-2"
    >
      <p class="text-md">
        {{ $t("template.content.part1") }}
        <strong>Pobo Designer</strong>,
        {{ $t("template.content.part2") }}
      </p>

      <h3 class="mb-3 mt-5 p-0">
        {{ $t("template.content.part3") }}
      </h3>
      <p class="text-md">
        {{ $t("template.content.part4") }}
        <strong>„{{ $t("template.content.part5") }}“</strong>,
        {{ $t("template.content.part6") }}

        <strong>„{{ $t("template.content.part7") }}“</strong>
        {{ $t("template.content.part8") }}.
      </p>
    </help-box>


    <button
      class="btn btn-secondary mt-4"
      @click="handleOpenModal(true)"
    >
      {{ $t("template.create") }}
    </button>

    <b-modal
      v-model="isModalOpen"
      size="md"
      hide-footer
      :title="$t('template.modal.title')"
      no-close-on-backdrop
    >
      <b-form
        :class="submit ? 'was-validated' : ''"
        novalidate
        @submit.prevent="actionCreate"
      >
        <p>
          {{ $t("template.modalTemplate.part1") }}
        </p>

        <hr>

        <b-form-group
          id="name"
          :label="$t('template.modalTemplate.part2')"
        >
          <b-form-input
            id="name"
            v-model="model.name"
            type="text"
            required
          />

          <div
            v-if="!isValidName"
            class="invalid-feedback"
          >
            {{ $t("template.modalTemplate.error") }}
          </div>
        </b-form-group>

        <b-form-group :label="$t('template.modalTemplate.part4')">
          <product-autocomplete
            @select-product="handleSelectProduct"
          />
        </b-form-group>

        <div class="text-right">
          <b-btn
            block
            class="mt-4"
            type="submit"
          >
            {{ $t("template.modalTemplate.button") }}
          </b-btn>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ProductAutocomplete from "@/share/product-autocomplete.vue";
import AdaptationModel from "@/model/AdaptationModel";
import ProductModel from "@/model/ProductModel.js";
import HelpBox from "@/share/help-box.vue";

export default {
  name: "form-adaptation",

  async mounted(){
    await this.actionRead();
  },

  data(){
    return {
      isModalOpen: false,
      savedData: null,
      submit: false,
      params: {
        offset: 1,
        limit: 20,
        total: 0,
        query: "",
        filter: 1,
      },
      product: null,
      model: {
        name: "",
        product_id: null,
      },
    };
  },

  computed: {
    isValidName(){
      const { model } = this;
      const { name } = model;

      return name && name.length > 1;
    },
  },

  components: {
    HelpBox,
    "product-autocomplete": ProductAutocomplete,
  },

  methods: {
    handleOpenModal(state) {
      this.isModalOpen = state;
    },

    handleSelectProduct(product) {
      const { id } = product;
      this.model.product_id = id;
    },

    async actionRead() {
      const { params } = this;

      /**
       *
       * OTESTOVAT
       *
       */
      await ProductModel.findAll(
        params,
        () => {},
        (data) => {
          this.product = data;
        }
      );
    },

    async actionCreate(){
      const { model } = this;
      const { name, product_id } = model;
      this.submit = true;

      if (name === "" || !product_id) {
        return;
      }

      await AdaptationModel.create(
        model,
        () => {},
        (data) => {
          this.handleOpenModal(false);
          this.$emit("action-created");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
