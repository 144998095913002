import { defineStore } from "pinia";
import CreditModel from "../model/CreditModel";
import ProductModel from "../model/ProductModel";

export const DEFAULT_STORE = {
  history: [],
  summary: {
    reaming: 0,
    add: 0,
    remove: 0,
  },

  price: {
    generate: 0,
    translate: 0,
  },

  edited: {
    product: 0,
  },
};

export const useCreditStore = defineStore("CreditStore", {
  state: () => {
    return DEFAULT_STORE;
  },

  actions: {
    async findHistory(params = {}) {
      const { total } = await ProductModel.findAll({
        filter: 2,
        limit: 1,
      });

      this.edited.product = total;

      await CreditModel.findHistory(
        params,
        () => {},
        (data) => {
          for (const key in data) {
            this[key] = data[key];
          }
        }
      );
    },
  },
});
