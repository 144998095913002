import axios from "axios";

export default class DashboardModel {
  static async findDashboard(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/admin/dashboard")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch dashboard [findDashboard()]", error);
        return error;
      });
  }

  static async findAll(
    params,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    const query = {
      limit: params.limit,
      offset: params.offset,
      query: params.query,
      filter: params.filter,
    };

    return await axios
      .get("/api/v2/admin/dashboard/users", { params: query })
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error dashboard users [findAll()]", error);
        return error;
      });
  }
}
