<template>
  <div class="copy-modal-label">
    <div class="copy-modal-label__product pr-4">
      <match-single-product
        type="copy"
        :eshop-id="selectedEshop"
        :hide-submit-button="true"
        :hide-count-column="true"
        :selected-product="product ? product : null"
        @selected="getSelected"
      />
    </div>

    <div
      class="copy-modal-label__config mt-2"
    >
      <h6 class="mb-2">
        {{ $t("Select an e-shop and its products") }}
      </h6>
      <div class="bg-white pt-4 pb-4 px-3 shadow-sm rounded">
        <p class="fs-12 lh-15">
          {{ $t('Using the drop-down menu, you can edit the list of products located on the left side of the screen.') }}
        </p>
        <tier-control
          :allow-tariff="['profi', 'premium', 'saas']"
          minimum-tariff="profi"
          position="left"
        >
          <b-form-group>
            <b-form-select
              v-model="selectedEshop"
              size="sm"
              :options="eshopOptions"
            />
          </b-form-group>
        </tier-control>
        <b-form-group>
          <b-checkbox
            v-model="translateContent"
            :value="true"
            :unchecked-value="false"
            :disabled="true"
          >
            {{ $t("Translate content") }}
            <small class="text-secondary">
              ({{ $t('tariff.head.plan') }})
            </small>
          </b-checkbox>
        </b-form-group>
      </div>


      <h6 class="mb-2 mt-5">
        {{ $t("Options for copying") }}
      </h6>
      <div class="bg-white pt-4 shadow-sm rounded">
        <div class="px-4 pb-2">
          <p class="fs-12 lh-15 text-muted">
            {{ $t('When copying, you can choose from two ways to copy widgets.') }}
          </p>
        </div>
        <b-row no-gutters>
          <b-col
            v-for="item in modeListType"
            :key="item.id"
            cols="6"
          >
            <a
              href="#"
              class="select-mode-vertical__item"
              :class="{'select-mode-vertical__item--active' : selectedCopyType === item.value}"
              @click.prevent="selectedCopyType = item.value"
            >
              <i
                :class="['ico', item.icon]"
              />
              <span class="d-block text-center fs-12 lh-16">
                {{ $t(item.text) }}
              </span>
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>

  <div class="bg-white p-3 rounded mt-4 d-flex justify-content-end copy-modal-label__submit">
    <b-btn-group>
      <b-btn
        variant="info"
        @click="openPreviewModal(true)"
      >
        <b-icon
          icon="hand-index-thumb"
          class="mr-1"
          size="16"
        /> {{ $t("copy.preview") }}
      </b-btn>
      <b-btn
        variant="secondary"
        :disabled="!selected.length"
        data-cy="product-match-copy-button"
        @click="confirmModal(true)"
      >
        {{ $t("Copy labels") }}
        <b-icon
          icon="chevron-double-right"
          size="16"
        />
      </b-btn>
    </b-btn-group>
  </div>

  <b-modal
    v-model="openConfirmModal"
    size="md"
    hide-footer
    :title="$t('Copy confirmation')"
  >
    <div class="d-flex flex-column text-center">
      <b-icon
        class="ml-1"
        size="59"
      />
      <h2>{{ $t("Copy confirmation") }} </h2>
      <p
        class="mb-4 text-sm p-3 fs-21 lh-30"
      >
        {{ $t("This move is irreversible! If you copy the labels, it is not possible to restore them to their original state.") }}
      </p>
    </div>

    <b-btn-group class="shadow-sm d-flex justify-content-center">
      <b-btn
        variant="danger"
        size="md"
        class="w-50"
        @click.prevent="confirmModal(false)"
      >
        {{ $t("Cancel") }}
        <b-icon
          icon="x-circle"
          class="ml-1"
          size="14"
        />
      </b-btn>
      <b-btn
        variant="secondary"
        size="md"
        class="w-50"
        data-cy="product-confirm-copy"
        @click.prevent="submitCopy"
      >
        {{ $t("Copy labels") }}
        <b-icon
          icon="check-circle"
          class="ml-1"
          size="14"
        />
      </b-btn>
    </b-btn-group>
  </b-modal>


  <b-modal
    v-model="previewModal"
    size="xl"
    hide-footer
    :title="$t('copy.preview')"
  >
    <div
      class="content-editor__placeholder bg-white p-5 content-editor__preview-copy"
    >
      <div
        v-for="item in widget"
        :key="item.id"
        :class="[item.enable_typography_css ? 'widget-typography' : '']"
      >
        <div v-html="item.html" />
      </div>


      <slot name="placeholder" />
    </div>
  </b-modal>
</template>

<script>

import matchSingleProduct from "@/components/share/match-single-product.vue";
import axios from "axios";
import options from "@/config/share.json";
import ProductModel from "@/model/ProductModel";
import {showMessage} from "@/plugins/flashMessage";
import eshopData from "@/mixin/eshopData";
import copyShortcuts from "@/mixin/shortcuts/copyShortcuts";
import SelectLangVertical from "@/share/select-lang-vertical.vue";
import langList from "@/js/config/lang";
import TierControl from "@/share/tier-control.vue";
export default {
  name: "Copy",

  mixins:[eshopData, copyShortcuts],

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  async mounted() {
    await this.actionRead();
    this.prepareEshopOptions();
  },

  data: function () {
    return {
      widget: null,
      isLoaded: false,
      showFakeDoor: false,
      options: options.product,
      selected: [],
      eshopOptions: [],
      selectedLanguage: "default",
      translateWithDeepL: false,
      openConfirmModal: false,
      selectedEshop: null,
      previewModal: false,
      selectedCopyType: false,
      translateContent: false,
      modeListType: [{
        value: false,
        text: 'Insert widgets behind the current ones on the e-shop',
        icon: 'ico-copy-down'
      },{
        value: true,
        text: 'Replace the widgets with the current ones found on the e-shop',
        icon: 'ico-copy-delete'
      }]
    }
  },

  computed: {
    langList() {
      const { eshop  } = this;
      const { lang_list, lang } = eshop;

      const push = langList.filter(flag => lang_list.includes(flag)).map(flag => {
        return {
          value: flag,
          event: flag
        }
      });

      return [{
        value: lang,
        event: 'default'
      }, ...push];
    },
  },

  components: {TierControl, matchSingleProduct},

  methods:{
    async actionRead(){
      const { options, product } = this;
      const { api } = options;
      const { widget } = api;

      await axios.get(widget.replace("[id]", product.id)).then(({ data }) => {
        this.widget = data;
        this.isLoaded = true;
        this.$emit("action-update", data);
      });
    },

    prepareEshopOptions() {
      this.eshopOptions = this.eshop.available_eshop.map(eshop => {
        return {
          text: eshop.humanUrl,
          value: eshop.id
        };
      });

      this.selectedEshop = this.eshop.id;
    },
    
    changeCopyLanguage(lang = 'default') {
      this.selectedLanguage = lang;
    },

    getSelected(item){
      this.selected = item;
    },
    confirmModal(value){
      this.openConfirmModal = value;
    },
    openPreviewModal(value){
      this.previewModal = value;
    },

    async submitCopy() {
      const { product, selected, selectedCopyType, translateContent, selectedEshop } = this;

      await ProductModel.createCopy(
        {
          productId: product.id,
          checked: selected,
          deleteHistoryWidget: selectedCopyType,
          translateContent,
          eshopId: selectedEshop,
        },
        () => {},
        (data) => {
          this.selected = [];
          this.$emit('close-modal', product);
          showMessage("copy.successCopyFinish");
        },
      );

    },
  }
};
</script>

<style scoped></style>
