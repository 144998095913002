<template>
  <b-form
    class="px-2"
    @submit.prevent="submitForm"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group
          id="name"
          label="Design name"
        >
          <b-form-input
            id="name"
            v-model="formData.name"
            type="text"
            required
          />
        </b-form-group>

        <b-form-group
          id="remote_name"
          label="Remote template design name"
        >
          <b-form-input
            id="remote_name"
            v-model="formData.remote_name"
            type="text"
          />
        </b-form-group>

        <b-form-group
          id="info"
          label="Design info"
        >
          <b-form-textarea
            id="info"
            v-model="formData.info"
            type="text"
          />
        </b-form-group>

        <b-form-group
          id="image_preview"
          label="Design image preview"
        >
          <b-form-input
            id="image_preview"
            v-model="formData.image_preview"
            type="text"
          />
        </b-form-group>

        <b-form-group
          label="Design type (custom or $$$)"
        >
          <b-form-radio-group
            v-model="formData.type"
            value-field="value"
            text-field="text"
            :options="[{ text: 'Public (free)', value: 'public' }, { text: 'Custom ($$$)', value: 'custom' }]"
          />
        </b-form-group>

        <b-form-group label="Design visibility">
          <b-form-checkbox
            v-model="formData.visible"
          >
            Design is visible
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-btn
            block
            type="submit"
            variant="secondary"
          >
            Submit form
          </b-btn>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import DesignModel from "@/model/admin/DesignModel";
import {showMessage} from "@/plugins/flashMessage";

export default {
  name: "ModalDetail",

  props: {
    item: {
      type: Object,
      required: false,
      default: () => null
    }
  },

  mounted() {
    if (this.item) {
      this.formData = this.item;
    }

    setTimeout(() => {
      this.isLoaded = true;
    }, 1500)
  },

  data() {
    return {
      isLoaded: false,
      formData: {
        name: "",
        type: "public",
        visible: false,
        image_preview: "",
        css_typo: "",
        css_widget: "",
        remote_name: "",
        info: "",
        mode: "standalone",
        config: []
      }
    }
  },

  methods: {
    submitForm() {
      if (this.item) {
        this.actionUpdate();
      } else {
        this.actionCreate();
      }
    },

    async actionCreate() {
      await DesignModel.create(
        this.formData,
        () => {},
        (data) => {
          this.$emit("handle-close", data);
          showMessage("flash.saved");
        }
      );
    },

    async actionUpdate() {
      const validData = {
        name: this.formData.name,
        type: this.formData.type,
        visible: this.formData.visible,
        image_preview: this.formData.image_preview,
        css_typo: this.formData.css_typo,
        css_widget: this.formData.css_widget,
        remote_name: this.formData.remote_name,
        info: this.formData.info,
        mode: this.formData.mode,
        config: this.formData.config,
      };

      await DesignModel.update(
        this.formData.id,  
        validData,
        () => {},
        (data) => {
          this.$emit("handle-close", data);
          showMessage("flash.saved");
        }
      );
    }

  }
}
</script>