<template>
  <a
    v-tooltip.top-center="$t(toolTipMessage)"
    :class="[
      isFavourite ? 'ico-favourite-enable' : 'ico-favourite-disable',
      'ico',
    ]"
    href="#"
    @click.prevent="actionCreate"
  />
</template>

<script>
import ProductModel from "@/model/ProductModel.js";
import { showMessage } from "@/plugins/flashMessage";
export default {
  name: "GridItemFavourite",
  props: {
    state: {
      type: Boolean,
      require: true,
    },

    content: {
      type: String,
      require: true,
      default: "product",
    },

    id: {
      type: Number,
      require: true,
    },
  },

  data() {
    const { state } = this;
    return {
      isFavourite: state,
    };
  },

  computed: {
    toolTipMessage() {
      return this.isFavourite
        ? "tooltip.removeFromFavourite"
        : "tooltip.addToFavourite";
    },
  },

  methods: {
    async actionCreate() {
      const { content, id } = this;

      const newState = !this.isFavourite;
      const message = newState
        ? `flash.favourite.${content}.add`
        : `flash.favourite.${content}.remove`;

      await ProductModel.favourite(
        {
          is_favourite: newState,
          id,
          content,
        },
        () => {},
        (data) => {
          this.isFavourite = newState;
          showMessage(message);
        }
      );
    },
  },
};
</script>
