import { useLockedStore } from "@/store/lockedStore";
import { mapWritableState } from "pinia";

export default {
  computed: {
    ...mapWritableState(useLockedStore, {
      isLocked: "isLocked",
    }),
  },
};
