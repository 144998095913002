<template>
  <layout-grid>
    <template #title>
      {{ $t("share.page.title") }}
    </template>

    <template #panel>
      <b-btn-group
        class="shadow-sm"
      >
        <modal-import @close-modal="actionRead()" />
        <modal-export @close-modal="actionRead()" />
      </b-btn-group>
    </template>

    <template
      #create
    >
      <b-btn
        class="my-4"
        variant="secondary"
        data-cy="share-label-create-btn"
        :disabled="isLocked"
        @click="openShareModal(true)"
      >
        {{ $t("share.page.create") }} <i class="ico ico-arrow-right" />
      </b-btn>

      <b-btn
        variant="link"
        @click.prevent="openInfo(true)"
      >
        <b-icon
          icon="info-circle"
          size="16"
        />
      </b-btn>
    </template>

    <template #welcome>
      <help-box
        author="sara"
        type="share"
      >
        <h2 class="mb-4">
          {{ $t("How to create share description?") }}
        </h2>
        <p
          class="fs-16 lh-30 font-weight-light"
          v-html="$t('share.page.content.part1')"
        />
        <p
          class="fs-16 lh-30 font-weight-light"
          v-html="$t('share.page.content.part3')"
        />
      </help-box>
    </template>

    <template #content>
      <div
        v-if="all"
        class="share-table--head"
      >
        <div>{{ $t("share.page.table.sort") }}</div>
        <div>{{ $t("share.page.table.name") }}</div>
        <div>
          <grid-search
            @input="updateQuery"
            @submit="actionRead"
          />
        </div>
      </div>

      <template v-if="isLoaded">
        <template v-if="all">
          <template v-if="list.length > 0">
            <draggable
              v-model="list"
              tag="div"
              @end="$emit('move-end', false)"
              @start="$emit('move-start', true)"
              @change="actionUpdate($event)"
            >
              <template #item="{ element }">
                <div
                  :key="element.id"
                  class="share-table"
                >
                  <div>#{{ element.position }}</div>
                  <div>
                    <grid-item-quality :content="element.count" />
                  </div>

                  <div>
                    <span class="font-weight-bolder">{{ element.name }}</span>
                    <span
                      v-if="element.count.product"
                      class="text-black-50 d-block"
                      style="font-size: 12px"
                    >
                      ({{ $t("share.page.table.addedToProduct") }}
                      {{ product(element.count.product) }})
                    </span>
                  </div>

                  <div class="text-right">
                    <div class="d-flex justify-content-end">
                      <b-btn
                        class="mr-2"
                        size="sm"
                        variant="secondary"
                        data-cy="share-label-edit-btn"
                        :disabled="isLocked"
                        @click.prevent="openEditShareContent(element)"
                      >
                        <i class="ico ico-edit" />
                        {{ $t("share.page.table.editContent") }}
                      </b-btn>

                      <b-btn
                        class="mr-2"
                        size="sm"
                        variant="secondary"
                        data-cy="share-label-assigning-btn"
                        :disabled="isLocked"
                        @click.prevent="openMatchProduct(element)"
                      >
                        <i class="ico ico-share" />
                        {{ $t("share.page.table.matchProduct") }}
                      </b-btn>

                      <button class="btn btn-sm btn-secondary mr-2 d-flex align-items-center">
                        <i class="ico ico-component-drag m-0" />
                      </button>

                      <confirm-delete
                        :id="element.id"
                        :disable="isLocked"
                        @delete="actionDelete"
                      >
                        <template #trigger>
                          <i class="ico ico-component-delete" />
                          {{ $t("share.page.table.deleted") }}
                        </template>
                      </confirm-delete>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </template>
          <template v-else>
            <div class="text-center py-4">
              <div style="font-size: 80px">
                😢
              </div>
              <h6 class="font-weight-bolder">
                {{ $t("share.page.table.notFound") }}
              </h6>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="text-center pt-5 pb-2">
            <h3>{{ $t("share.page.table.empty") }}</h3>
            <p>{{ $t("share.page.table.emptyMessage") }}</p>
            <b-btn
              class="my-4"
              @click="openShareModal(true)"
            >
              {{ $t("share.page.create") }}
              <i class="ico ico-arrow-right" />
            </b-btn>
          </div>
        </template>
      </template>
      <template v-else>
        <div
          v-for="n in 10"
          :key="n"
          class="share-table"
        >
          <div
            v-for="m in 4"
            :key="m"
          >
            <b-skeleton-img
              height="45px"
              no-aspect
            />
          </div>
        </div>
      </template>
    </template>
  </layout-grid>

  <browser-panel
    :primary-modal-open="isOpenEditModal"
    type="share"
    @set-active-panel="setActivePanel"
  />

  <Transition name="slide-up">
    <editor-browser
      v-show="isOpenEditModal"
      :tabs="tabs.getGlobalTabs('share')"
      :active-id="tabs.getGlobalActiveIndex('share')"
      :options="options.share"
      :is-open="isOpenEditModal"
      type="share"
      :window-browser-tab="windowBrowserTab"
      @open-tool-modal="openToolModal"
      @action-close="closeModal"
      @change-tab="changeTab"
      @action-minimize="minimizeEditor"
    />
  </Transition>

  <b-modal
    v-model="isOpenMatchModal"
    :title="$t('share.match.modal.title')"
    content-class="editor-modal"
    hide-footer
    size="editor-lg"
    @hidden="actionRead"
  >
    <p v-html="$t('share.match.modal.info')" />
    <match-single-product
      type="share"
      :share-selected="wasSelected"
      :hide-submit-button="false"
      :hide-count-column="false"
      :all-selected-product-id="allSelectedProducts"
      @submit="submitShare"
    />
  </b-modal>

  <b-modal
    v-model="secondaryModalOpen"
    :size="getSize"
    :title="getTitle"
    hide-footer
  >
    <template v-if="tabOpen.includes('detail')">
      <modal-edit
        :share="share"
        @update="modalUpdate"
      />
    </template>
  </b-modal>

  <b-modal
    v-model="isOpenShareModal"
    :title="$t('share.create.header')"
    hide-footer
    size="editor-md"
    @hidden="actionRead"
  >
    <modal-create @handle-update="updateList" />
  </b-modal>

  <b-modal
    v-model="infoModal"
    :title="$t('Help')"
    hide-footer
    size="lg"
  >
    <div class="share-info-modal text-center">
      <h2
        class="fs-3"
        v-html="$t('How do <mark> shared labels work? </mark>')"
      />
      <p
        class="pl-5 pr-5 mb-5 mt-3 fs-18"
        v-html="$t('The created shared caption <strong> is not tied to a specific product.</strong> You can assign it to any number of products and easily modify the caption from one place. <strong> The shared caption is automatically inserted after the standard description of each product.</strong>')"
      />

      <div class="share-info-modal__image">
        <div class="share-info-modal__icon">
          <h2 class="share-info-modal__icon-standard">
            {{ $t('Product label') }}
          </h2>
          <h2 class="share-info-modal__icon-share">
            {{ $t('Shared label') }}
          </h2>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MatchSingleProduct from "@/components/share/match-single-product.vue";
import Draggable from "vuedraggable";
import { product } from "@/filter/vue";
import ModalCreate from "@/components/share/modal-create.vue";
import options from "@/config/share.json";
import GridSearch from "@/share/grid-search.vue";
import GridItemQuality from "@/share/grid-item-quality.vue";
import ShareModel from "@/model/ShareModel.js";
import { showMessage } from "@/plugins/flashMessage";
import { useStatsInfoStore } from "@/store/StatsInfoStore";
import HelpBox from "@/share/help-box.vue";
import eshopData from "@/mixin/eshopData";
import shareShortcuts from "@/mixin/shortcuts/shareShortcuts";
import ModalExport from "@/components/product/modal-export.vue";
import ModalImport from "@/components/product/modal-import.vue";
import EditorBrowser from "@/share/editor-browser.vue";
import BrowserPanel from "@/share/browser-panel.vue";
import {useTabsStore} from "@/store/TabsStore";
import ModelEdit from "@/components/share/modal-edit.vue";
import lockedData from "@/mixin/lockedData";
export default {
  name: "Share",

  mixins: [eshopData, shareShortcuts, lockedData],

  async mounted() {
    await this.actionRead();
    /*
    const fake = {
      "id": 1069,
      "position": 0,
      "created_at": "2023-06-10T12:17:37+00:00",
      "name": "Posledni",
      "count": {
        "product": 2,
        "standard": 5
      }
    };
     await this.openEditShareContent(fake);

    await EshopModel.findMe(
      () => {},
      (data) => {
        this.eshop = data;
        loadTemplateCss(data.template_css, this);
      }
    );
          */
  },
  data() {
    return {
      list: Array,
      all: 0,
      query: "",
      selectedShare: Object,
      wasSelected: Object,
      isOpenShareModal: false,
      isOpenMatchModal: false,
      isOpenEditModal: false,
      isLoaded: false,
      options: options,
      infoModal: false,
      tabOpen: [],
      secondaryModalOpen: false,
      allSelectedProducts: [],
      windowBrowserTab: [
        {
          event: "detail",
          type: "main",
          translate: "share.page.editor.name"
        },
      ],
    };
  },

  computed:{
    tabs(){
      return useTabsStore();
    },
    share(){
      return this.tabs.getTabData('share');
    },
    getSize() {
      const { tabOpen } = this;
      if (tabOpen.includes("detail")) {
        return "lg";
      }
      return "editor-lg";
    },

    getTitle() {
      const { tabOpen } = this;

      if (tabOpen.includes("detail")) {
        return this.$t("share.page.form.title");
      }
      return `Warning: getTitle() not set`;
    },
  },

  watch: {
    isOpenEditModal() {
      this.keyboardShortcuts();
    },
  },

  components: {
    "modal-edit": ModelEdit,
    "browser-panel": BrowserPanel,
    "editor-browser": EditorBrowser,
    HelpBox,
    draggable: Draggable,
    "modal-export": ModalExport,
    "modal-import": ModalImport,
    "grid-item-quality": GridItemQuality,
    "modal-create": ModalCreate,
    "match-single-product": MatchSingleProduct,
    "grid-search": GridSearch,
  },

  metaInfo() {
    return { title: this.$t("meta.share") };
  },

  methods: {
    product,
    updateQuery(query) {
      this.query = query;
    },

    modalUpdate(){
      this.actionRead();
      this.secondaryModalOpen = false;
    },

    openInfo(state){
      this.infoModal = state;
    },

    openToolModal(name) {
      this.tabOpen = [name];
      this.secondaryModalOpen = true
    },

    changeTab(index){
      this.tabs.setActiveIndex('share', index);
    },

    async minimizeEditor() {
      this.isOpenEditModal = false;
      document.body.classList.remove("modal-open");
      await useStatsInfoStore().fetch()
    },

    setActivePanel(entityId) {
      this.isOpenEditModal = (this.tabs.getGlobalActiveIndex('share') !== entityId) || !this.isOpenEditModal;

      if(!this.isOpenEditModal) {
        document.body.classList.remove("modal-open");
      }
      this.tabs.setActiveIndex('share', entityId);
    },

    updateList(data) {
      this.list = data;
      this.isOpenShareModal = false;
    },

    submitShare(selected){
      ShareModel.matchProduct(
        {
          selected,
          share_id: this.selectedShare.id,
        },
        () => {},
        (data) => {
          // const { selected } = data;
          // this.selected = selected;
          showMessage("flash.content.share");
        }
      );
    },

    async closeModal(item) {
      document.body.classList.remove("modal-open");
      await this.tabs.closeEditor('share', item.id);
      this.isOpenEditModal = false;
      await this.actionRead();
    },

    async fetchSelectedProduct() {
      await ShareModel.findProducts(
        this.selectedShare.id,
        () => {},
        (data) => {
          const { selected } = data;
          this.wasSelected = selected;
        }
      );
    },

    async getAllSelectedProduct() {
      this.allSelectedProducts = [];

      const promises = [];

      this.list.forEach((item) => {
        const promise = new Promise((resolve, reject) => {
          ShareModel.findProducts(
            item.id,
            () => {},
            (data) => {
              resolve(data.selected);
            }
          );
        });
        promises.push(promise);
      });

      const results = await Promise.all(promises);
      this.allSelectedProducts = results.flat();
    },


    openShareModal(state) {
      this.isOpenShareModal = state;
    },

    async openEditShareContent(share) {
      await this.tabs.fetchTab('share', share);
      this.isOpenEditModal = true;
    },

    async actionUpdate($event) {
      const { list } = this;

      let position = [];

      Array.from(list).forEach(({ id }, index) => {
        position.push({
          id: id,
          position: index,
        });
      });

      await ShareModel.update(
        { share: position },
        () => {},
        (data) => {
          this.list = data.list;
          this.all = data.all;
          showMessage("flash.drag.share");
        }
      );
    },

    async openMatchProduct(share) {
      this.selectedShare = share;
      await this.fetchSelectedProduct();
      this.isOpenMatchModal = true;

    },

    async actionRead() {
      this.isLoaded = false;
      await useStatsInfoStore().fetch()

      await ShareModel.findAll(
        this.query,
        () => {},
        (data) => {
          this.list = data.list;
          this.all = data.all;
          this.getAllSelectedProduct();
        }
      );

      this.isLoaded = true;
    },

    async actionDelete(shareId) {
      this.isLoaded = false;
      await ShareModel.delete(
        shareId,
        () => {},
        (data) => {
          this.list = data.list;
          this.all = data.all;
          this.tabs.closeEditor('share', this.share.id);
        }
      );
      this.isLoaded = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
