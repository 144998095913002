<template>
  <form @submit.prevent="actionCreate()">
    <div class="form-group">
      <label for="name">{{ $t("share.create.title") }}</label>
      <input
        id="name"
        v-model="formData.name"
        autocomplete="off"
        class="form-control"
        data-cy="share-label-create-input"
        type="text"
      >
    </div>
    <button
      :disabled="!formData.name"
      class="btn btn-secondary"
      data-cy="share-label-create-submit-btn"
      type="submit"
    >
      {{ $t("share.create.save") }}
    </button>
  </form>
</template>

<script>
import ShareModel from "@/model/ShareModel.js";
import { showMessage } from "@/plugins/flashMessage";
export default {
  data() {
    return {
      formData: {
        name: "",
      },
    };
  },

  methods: {
    async actionCreate({ formData } = this) {
      const { name } = formData;
      await ShareModel.create(
        { name },
        () => {},
        (data) => {
          this.$emit("handle-update", data);
          showMessage("flash.share.create");
        }
      );
    },
  },
};
</script>
