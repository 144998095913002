<template>
  <layout-setting
    :has-background="false"
    col="12"
  >
    <template #header>
      {{ $t("tariff.header") }}
    </template>

    <template #filter>
      <div class="d-flex float-right">
        <template v-if="data.currencies.length > 1">
          <div class="bg-white py-2 px-3 rounded-lg shadow-sm">
            <b-radio
              v-for="(item, index) in data.currencies"
              :key="index"
              v-model="currency"
              :value="item.currency"
              inline
              @change="actionRead"
            >
              {{ $t(item.title) }}
            </b-radio>
          </div>
        </template>

        <template v-if="data.vat.show">
          <div class="bg-white py-2 px-3 rounded-lg shadow-sm ml-2">
            <b-form-checkbox
              id="checkbox-1"
              v-model="data.vat.apply"
              name="checkbox-1"
            >
              {{ $t("invoice.table.priceWitVat") }}
            </b-form-checkbox>
          </div>
        </template>

        <template v-if="data.currencies.length > 1">
          <div class="bg-white py-2 px-3 ml-2 rounded-lg shadow-sm">
            <b-form-checkbox
              id="checkbox-2"
              v-model="data.periodViewYear"
              name="checkbox-2"
            >
              {{ $t('tariff.head.forYear') }}
            </b-form-checkbox>
          </div>
        </template>
      </div>
    </template>

    <template #info>
      {{ $t("tariff.current") }}
      <strong>{{ eshop.tariff.toUpperCase() }}</strong>.
    </template>

    <template #content>
      <help-box
        author="sara"
        header="Lorem ipsum is samet"
        type="tariff"
        classes="mt-3"
      >
        <h2 class="mb-3">
          {{ $t("Tarifs in Pobo Page Builder") }}
        </h2>
        <p class="fs-16 lh-26">
          {{ $t("The right Pobo Page Builder plan supports your business growth: START for beginners, PRO for growing businesses with multiple products, and PREMIUM for large businesses with unlimited content and product requirements.") }}
        </p>
      </help-box>

      <div
        v-if="isLoaded"
        class="price-list-table mt-3"
      >
        <div class="price-list-table__wrap">
          <price-table
            :data="data"
            :show-price-with-vat="data.vat.apply"
            @open-modal="openModal"
          />
        </div>
      </div>

      <b-modal
        v-model="isPayModalOpen"
        :title="getPaymentModalTitle"
        hide-footer
        size="payment"
      >
        <div class="change-tariff-modal">
          <div
            v-if="selectedPackage"
            class="px-4 change-tariff-modal__left"
          >
            <!-- Shopify payment status -->
            <template v-if="data.method.isRemotePayment">
              <div class="bg-white shadow-sm p-3 rounded mb-2 text-center">
                <template v-if="totalPrice">
                  <h2>
                    {{ totalPrice }}
                    {{ data.price.currency }}
                  </h2>
                  <small class="d-block text-black-50">
                    {{ $t("tariff.price.remote") }}
                  </small>
                </template>
                <template v-else>
                  <p class="p-0 m-0">
                    {{ $t("Click the button to cancel your existing subscription") }}
                    <strong>{{ eshop.tariff.toUpperCase() }}</strong>
                    {{ $t("and the free tariff will be activated") }} <strong>START</strong>.
                  </p>
                </template>
              </div>
            </template>
            <!-- Shoptet payment status -->
            <template v-else>
              <div class="bg-white shadow-sm p-3 rounded mb-3">
                <p class="m-0 p-0 font-weight-normal fs-15">
                  {{ $t("tariff.package.tariff") }}
                  <strong class="text-uppercase">{{
                    selectedPackage.name
                  }}</strong>
                  {{ $t("tariff.package.order") }} ({{
                    $t("tariff.package.from")
                  }}
                  <strong> {{ date.from }}</strong>
                  {{ $t("tariff.package.to") }}
                  <strong> {{ date.to }}</strong>). {{ $t("tariff.package.invoiceInfo") }}.
                </p>
              </div>
            </template>

            <form @submit.prevent="actionCreate">
              <template v-if="!data.method.isRemotePayment">
                <h4 class="mb-3 fs-17">
                  {{ $t("tariff.step.first.header") }}
                </h4>
                <div class="bg-white shadow-sm p-3 rounded">
                  <div
                    v-for="(item, index) in data.method.list"
                    :key="index"
                    :class="[index > 0 ? 'mt-2  border-top' : null]"
                  >
                    <b-radio
                      :id="item.type"
                      v-model="selectedPaymentMethod"
                      :value="item.type"
                    >
                      {{ $t(item.title) }}
                      <small class="d-block text-black-50">
                        {{ $t(item.info) }}.
                      </small>
                    </b-radio>
                  </div>
                </div>

                <h4 class="mb-3 mt-3 fs-17">
                  {{ $t("tariff.step.two.header") }}
                </h4>

                <div class="p-3 text-center bg-white shadow-sm rounded">
                  <select-billing @handle-change="handleSelectBilling" />
                  <template v-if="selectedBilling">
                    <billing-table :selected-billing="selectedBilling" />
                  </template>
                </div>

                <h4 class="mb-3 mt-3 fs-17">
                  {{ $t("tariff.step.three.header") }}
                </h4>

                <div class="p-3 pb-1 bg-white shadow-sm rounded">
                  <div
                    v-for="(item, index) in data.currencies"
                    :key="index"
                    :class="[index > 0 ? 'mt-2 border-top' : null]"
                  >
                    <b-radio
                      :id="item.currency"
                      v-model="currency"
                      :value="item.currency"
                      @change="actionRead"
                    >
                      {{ $t(item.title) }}
                    </b-radio>
                  </div>
                </div>

                <h4 class="mb-3 mt-3 fs-17">
                  {{ $t("tariff.step.four.header") }}
                </h4>

                <div class="py-3 text-center bg-white shadow-sm rounded">
                  <h2>
                    {{ totalPrice }}
                    {{ data.price.currency }}
                  </h2>
                  <small class="d-block text-black-50">
                    {{ $t("tariff.step.four.totalPriceWith") }}
                    {{
                      selectedBilling && selectedBilling.use_reverse_charge
                        ? 0
                        : company.vat
                    }}%

                    {{ $t("tariff.step.four.vat") }}
                    {{ $t("tariff.step.four.from") }}
                    {{ date.from }}
                    {{ $t("tariff.step.four.to") }}
                    {{ date.to }}
                  </small>
                </div>
              </template>

              <div class="pt-4">
                <b-btn
                  :disabled="isLockedTariff"
                  block
                  type="submit"
                  variant="secondary"
                >
                  <b-spinner
                    v-if="isSubmitPaymentRequest"
                    small
                  />
                  {{ $t("tariff.buy") }}
                </b-btn>
              </div>
            </form>
          </div>

          <div class="change-tariff-modal__right">
            <h3 v-html="$t('Fire up <br> your product pages')" />

            <h2>
              {{ $t('with tariff') }}
            </h2>

            <h1> PROFI </h1>

            <p class="pl-3 pr-3">
              {{ $t('To facilitate invoicing, we only accept payments for annual periods.') }}
              <strong> {{ $t('If you are not satisfied, we will refund you for the unused months!') }}</strong>
            </p>

            <ul>
              <li> {{ $t("Priority Customer Support") }} </li>
              <li> {{ $t("Setup and evaluation of the first A/B test") }} </li>
              <li> {{ $t("Creating a customized layout for e-shop descriptions") }} </li>
              <li> {{ $t("Adding collaborators") }} </li>
              <li> {{ $t("Switching between e-shops") }} </li>
              <li> {{ $t("Bulk editing for shared descriptions") }} </li>
              <li> {{ $t("Multi-language support (without DeepL)") }} </li>
              <li> {{ $t("Photobank with 5 000 000 pictures") }} </li>
              <li> {{ $t("Compatible labels for marketplace") }} </li>
            </ul>

            <div class="change-tariff-modal__booking mt-4">
              <div class="change-tariff-modal__booking-left mr-2">
                <img
                  src="https://pobo-cdn.b-cdn.net/static/michal-stikar-team-2.png"
                  alt="Michal Štikar"
                >
              </div>
              <div class="change-tariff-modal__booking-right">
                <h2 class="mb-2">
                  {{ $t("Want to know more?") }}
                </h2>

                <p class="mt-1 mb-1">
                  {{ $t("Arrange an online meeting with Michal, who will be happy to answer your questions and explain everything to you.") }}
                </p>


                <a
                  class="mt-0 pt-0"
                  target="_blank"
                  href="https://pobo.youcanbook.me/"
                > {{ $t("Select term") }} </a>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        v-model="isSuccessPaymentOpen"
        :title="$t('tariff.success.title')"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        no-fade
        size="md"
      >
        <div class="text-center p-4">
          <h2>{{ paymentStatusMessage }}</h2>

          <button
            class="btn btn-block btn-secondary text-uppercase mt-3"
            @click="handleClosePaymentStatus"
          >
            {{ $t("tariff.success.confirm") }}
          </button>
        </div>
      </b-modal>
    </template>
  </layout-setting>
</template>

<script>
import "dayjs/locale/cs";
import SelectBilling from "@/components/setting/part/select-billing.vue";
import TariffModel from "@/model/TariffModel.js";
import PriceTable from "@/components/setting/part/price-table.vue";
import BillingTable from "@/components/setting/part/billing-table.vue";
import eshopData from "@/mixin/eshopData";
import { showMessage } from "@/plugins/flashMessage";
import HelpBox from "@/share/help-box.vue";
import dayjs from "dayjs";

export default {
  name: "change-tariff",

  mixins: [eshopData],

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      data: {
        region: "cz",
        table: [],
        header: [],
        price: [],
        config: {},
        lang: "default",
        period: "month",
        periodViewYear: false,
        vat: {
          apply: false,
          show: false,
        },
        method: [],
        currencies: [],
        payment: {
          isRemotePayment: false,
        },
      },
      isLoaded: false,
      selectedPaymentMethod: "card",
      selectedPackage: null,
      isPayModalOpen: false,
      isSubmitPaymentRequest: false,
      selectedBilling: null,
      currency: "czk",
      isSuccessPaymentOpen: false,
      paymentStatusMessage: null,
      company: {
        vat: 20,
      },

      date: {
        from: dayjs().format("DD.MM.YYYY"),
        to: dayjs().add(1, "years").format("DD.MM.YYYY"),
      },
    };
  },

  computed: {
    totalPrice() {
      const { selectedBilling, selectedPackage, company, data, eshop } = this;
      const { period } = data;
      const { platform } = eshop;

      // Shopify - show price only without VAT
      if (platform === "shopify") {
        return data.price[selectedPackage.tariff_paid][period];
      }

      // Shoptet - calculate with VAT
      return (
        ((selectedBilling && selectedBilling.use_reverse_charge
          ? 0
          : company.vat) /
          100) *
          data.price[selectedPackage.tariff_paid][period] +
        data.price[selectedPackage.tariff_paid][period]
      ).toFixed(0);
    },

    getPaymentModalTitle() {
      const { selectedPackage } = this;

      if (!selectedPackage) {
        return "";
      }

      const { tariff_paid } = selectedPackage;
      return `${this.$t("tariff.prepare")} ${tariff_paid.toUpperCase()} `;
    },

    isLockedTariff() {
      const { selectedBilling, isSubmitPaymentRequest, data } = this;
      const { region } = data;

      if (isSubmitPaymentRequest) {
        return true;
      }

      return region === "en" ? false : !selectedBilling;
    },
  },

  components: {
    HelpBox,
    "select-billing": SelectBilling,
    "price-table": PriceTable,
    "billing-table": BillingTable,
  },

  methods: {
    openModal(data) {
      const { selectedPackage } = data;
      this.selectedPackage = selectedPackage;
      this.isPayModalOpen = true;
    },

    handleClosePaymentStatus() {
      this.isSuccessPaymentOpen = false;
      window.history.pushState({}, null, window.location.pathname);
    },

    handleSelectBilling(data) {
      this.selectedBilling = data;
    },

    async actionCreate() {
      const {
        selectedPaymentMethod,
        selectedPackage,
        currency,
        isShopify
      } = this;

      // For Shopify - create blank billing
      if (this.data.region === "en") {
        this.selectedBilling = {
          name: "",
          street: "",
          city: "",
          zip: "",
          ic: "",
          dic: "",
          use_reverse_charge: 0,
        };
      }

      const { tariff_paid } = selectedPackage;

      this.isSubmitPaymentRequest = true;

      await TariffModel.create(
        {
          tariff_paid: tariff_paid,
          type: selectedPaymentMethod,
          person: this.selectedBilling,
          currency: currency,
        },
        () => {},
        (data) => {
          const { redirect } = data;
          if (redirect) {
            window.location.href = redirect;
          } else {
            this.$emit("handle-close");
            this.isPayModalOpen = false;

            // Shopify => revert to default tariff
            if (isShopify) {
              setTimeout(() =>{
                window.location.reload()
              }, 1000)
            } else {
              this.$router.push("/merchant/setting/invoice");
            }
            showMessage("Tariff has been changed successfully");
          }
        }
      );
    },

    async actionRead() {
      const { currency } = this;
      await TariffModel.find(
        currency,
        () => {},
        (data) => {
          if (data.method.list.length > 0) {
            this.selectedPaymentMethod = data.method.list[0].type;
          }

          this.data = data;
          this.data.periodViewYear = false;
          this.currency = data.currency;
        }
      );

      await TariffModel.readCompanyInfo(
        () => {},
        (data) => {
          this.company = data;
          this.isLoaded = true;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
