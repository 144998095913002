<template>
  <b-form
    @submit.prevent="submitPreview"
  >
    <div
      v-if="eshopTariff === 'start'"
      class="rounded text-center m-auto pb-4 d-flex w-100"
    >
      <div class="w-25">
        <b-img
          fluid
          style="max-width: 80px"
          src="https://pobo-cdn.b-cdn.net/static/zamek.png"
        />
      </div>

      <div class="d-flex flex-column w-80">
        <h3 class="text-center">
          {{ $t("To activate you need a tariff") }}
          <mark> {{ $t("tariff.head.profi") }} </mark>
        </h3>
        <p class="text-center">
          {{ $t("Below you can test on any product what the label would look like") }}.
        </p>
      </div>
    </div>

    <div
      v-else
      class="w-100 d-flex justify-content-center flex-column align-items-center card p-2"
    >
      <b-icon
        icon="eye-fill"
        size="30"
      />
      <h2 class="text-center fs-18 text-uppercase font-weight-bold">
        {{ $t("Below you can test on any product what the label would look like") }}.
      </h2>
      <p
        class="fs-15 font-weight-normal text-center lh-22"
        v-html="$t('Just insert the URL of the product for which you have already created labels using the Pobo tool. <br> <strong> The preview of the template will only be available to you. </strong>')"
      />
    </div>

    <hr>

    <b-form-group
      id="url"
      :label="$t('Enter product URL')"
    >
      <b-form-input
        id="url"
        v-model="productUrl"
        type="url"
        :placeholder="eshop.url"
        required
      />

      <div class="text-right">
        <b-btn
          block
          class="mt-4"
          type="submit"
        >
          {{ $t('View template preview') }}
        </b-btn>
      </div>
    </b-form-group>
  </b-form>
</template>
<script>
import eshopData from "@/mixin/eshopData.js";
export default {
  name: "TryDesign",

  mixins: [eshopData],

  props:{
    design: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      productUrl: '',
    }
  },

  methods: {
    submitPreview(){
      const { productUrl, design } = this
      const { id } = design
      const fullUrl = `${productUrl}/?design-preview=${id}`;
      window.open(fullUrl, '_blank');
    },
  }
}
</script>