import { defineStore } from "pinia";
import EshopModel from "@/model/EshopModel";

export const DEFAULT_STORE = {
  product: {
    edited: 0,
  },

  category: {
    edited: 0,
  },
  blog: {
    edited: 0,
  },
};

export const useStatsInfoStore = defineStore("StatsInfoStore", {
  state: () => {
    return DEFAULT_STORE;
  },

  actions: {
    async fetch() {
      await EshopModel.fetchStatsInfo(
        () => {},
        (data) => {
          for (const key in data) {
            this[key] = data[key];
          }
        });
    },
  },
});
