<template>
  <div>
    <b-form
      data-cy="image-alt-form"
      @submit.prevent="update"
    >
      <b-form-group :description="$t('widgetTree.modal.image.alt.description')">
        <b-input-group>
          <b-form-input
            v-model="value"
            data-cy="image-alt-input"
            :placeholder="$t('widgetTree.modal.image.menu.alt')"
          />
          <b-input-group-append>
            <b-button
              type="submit"
              variant="secondary"
            >
              {{ $t("widgetTree.modal.image.alt.submit") }}
              <i class="ico ico-save" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
export default {
  name: "image-alt",

  props: {
    alt: {
      type: String,
      default: null,
      required: false,
    },
  },

  mounted() {
    this.value = this.alt;
  },

  data() {
    return {
      value: null,
    };
  },

  methods: {
    update() {
      const { value } = this;
      this.$emit("update-alt", value);
    },
  },
};
</script>
