<template>
  <form @submit.prevent="handleSubmit">
    <b-row class="row">
      <b-col cols="4">
        <div class="form-group">
          <label for="name">Název widgetu</label>
          <input
            id="name"
            v-model="formData.name"
            class="form-control"
            :class="errors.name ? 'is-invalid' : ''"
          >
          <div
            v-if="errors"
            class="mt-1"
          >
            <small
              v-for="(error, index) in errors.name"
              :key="index"
              class="text-danger d-block"
              v-html="error"
            />
          </div>
        </div>

        <div class="form-group">
          <template v-if="isLoaded">
            <widget-category
              :selected="formData.category_id"
              :error="errors.category_id"
              @change-category="updateCategory"
            />
          </template>

          <small
            v-for="(error, index) in errors.category_id"
            :key="index"
            class="text-danger d-block"
            v-html="error"
          />
        </div>

        <b-row>
          <b-col cols="6">
            <b-form-group label="Pozice (pořadí)">
              <b-form-input
                id="position"
                v-model="formData.position"
                type="number"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Root třída">
              <b-form-input
                id="root_class"
                v-model="formData.root_class"
                type="string"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Zoom náhledu HTML">
          <b-form-input
            id="zoom"
            v-model="formData.zoom"
            type="number"
            required
          />
        </b-form-group>

        <b-form-group label="Poměr stran obrázku">
          <b-checkbox
            v-model="allowAspect"
            class="mb-1"
            @change="changeAspect"
          >
            Povolit nastavení poměru stran
          </b-checkbox>

          <template v-if="allowAspect">
            <b-form-input
              id="aspect_ratio"
              v-model="formData.aspect_ratio"
              type="text"
              min="3"
            />
          </template>
        </b-form-group>

        <b-form-group label="Povolit zobrazení">
          <b-form-checkbox
            v-model="formData.public"
            name="check-button"
            switch
          >
            Pobolit zobrazení
          </b-form-checkbox>

          <div
            v-if="errors"
            class="mt-1"
          >
            <small
              v-for="(error, index) in errors.public"
              :key="index"
              class="text-danger d-block"
              v-html="error"
            />
          </div>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            v-model="formData.allow_reference"
            switch
            name="allow_reference"
          >
            Povolit zobrazení jako referenci
          </b-form-checkbox>

          <div
            v-if="errors"
            class="mt-1"
          >
            <small
              v-for="(error, index) in errors.allow_reference"
              :key="index"
              class="text-danger d-block"
              v-html="error"
            />
          </div>
        </b-form-group>


        <b-form-group>
          <b-form-checkbox
            v-model="formData.enable_typography_css"
            switch
            name="enable_typography_css"
          >
            Povolit globální stylování typografie
          </b-form-checkbox>

          <div
            v-if="errors"
            class="mt-1"
          >
            <small
              v-for="(error, index) in errors.enable_typography_css"
              :key="index"
              class="text-danger d-block"
              v-html="error"
            />
          </div>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            v-model="formData.enable_gallery"
            switch
            name="enable_gallery"
          >
            Povolit globální stylování typografie
          </b-form-checkbox>

          <div
            v-if="errors"
            class="mt-1"
          >
            <small
              v-for="(error, index) in errors.enable_gallery"
              :key="index"
              class="text-danger d-block"
              v-html="error"
            />
          </div>
        </b-form-group>


        <b-form-group>
          <b-form-checkbox
            v-model="formData.is_deleted"
            switch
            name="is_deleted"
          >
            Označit jako smazaný
          </b-form-checkbox>

          <div
            v-if="errors"
            class="mt-1"
          >
            <small
              v-for="(error, index) in errors.is_deleted"
              :key="index"
              class="text-danger d-block"
              v-html="error"
            />
          </div>
        </b-form-group>



        <div class="form-group">
          <button class="btn btn-secondary btn-block">
            Uložit
          </button>
        </div>
      </b-col>

      <b-col cols="8">
        <div class="form-group">
          <div class="mb-2 text-right">
            <a
              href="#"
              class="btn btn-sm btn-info"
              @click.prevent="format"
            >
              Naformatovat
            </a>
          </div>
          <monaco
            :value="formData.html_preview"
            language="html"
            height="650px"
            class="w-100"
            @change="formData.html_preview = $event"
          />
        </div>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import WidgetCategory from "@/share/widget-category-autocomplete.vue";
import pretty from "pretty";
import WidgetModel from "@/model/WidgetModel.js";
import monaco from "@/components/share/monaco.vue";

export default {
  name: "widget-detail",

  props: {
    id: {
      type: Number,
      required: false,
    },
  },

  async mounted(){
    await this.actionRead();
  },

  data(){
    return {
      isLoaded: false,
      activePanel: "html", // svg
      errors: [],
      allowAspect: false,

      formData: {
        name: null,
        id: null,
        public: false,
        enable_typography_css: false,
        enable_gallery: false,
        allow_reference: false,
        category_id: null,
        html_preview: null,
        aspect_ratio: null,
        root_class: null,
        zoom: 40,
        is_deleted: false,
        ico: null,
        position: 0,
      },
    };
  },

  components: {
    monaco,
    "widget-category": WidgetCategory,
  },

  methods: {
    updateCategory(categoryId) {
      this.formData.category_id = categoryId;
    },

    changeAspect(){
      if (!this.allowAspect) this.formData.aspect_ratio = null;
    },

    format() {
      this.formData.html_preview = pretty(this.formData.html_preview, {
        ocd: true,
      });
    },

    ratio2float(){
      const ratio = this.formData.aspect_ratio.trim();
      const parts = ratio.split(":");
      if (parts.length === 2) {
        const numerator = parseFloat(parts[0]);
        const denominator = parseFloat(parts[1]);
        if (
          !isNaN(numerator) &&
                    !isNaN(denominator) &&
                    denominator !== 0
        ) {
          const result = numerator / denominator;
          return parseFloat(result.toFixed(5));
        }
      }
      return null;
    },

    comma2point() {
      const pointValue = parseFloat(
        this.formData.aspect_ratio.replace(",", ".")
      );
      return parseFloat(pointValue.toFixed(5));
    },

    async actionRead() {
      const { id } = this;

      if (id) {
        await WidgetModel.findMe(
          id,
          () => {},
          (data) => {
            this.formData = data;

            // convert aspect ratio to float
            this.formData.public = data.public === 1;
            this.formData.enable_typography_css = data.enable_typography_css === 1;
            this.formData.enable_gallery = data.enable_gallery === 1;
            this.formData.allow_reference = data.allow_reference === 1;
            this.formData.is_deleted = data.is_deleted === 1;

            this.allowAspect = data.aspect_ratio !== null;
          }
        );
      }

      this.isLoaded = true;
    },

    async handleSubmit() {
      const { id } = this;

      const { css, tree, flat, ...filteredFormData } = this.formData;

      filteredFormData.public = + filteredFormData.public;
      filteredFormData.enable_typography_css = + filteredFormData.enable_typography_css;
      filteredFormData.is_deleted = + filteredFormData.is_deleted;
      filteredFormData.allow_reference = + filteredFormData.allow_reference;

      if (id) {
        delete filteredFormData.css;

        await WidgetModel.saveMe(
          id,
          filteredFormData,
          () => {},
          (data) => {
            this.$emit("handle-close", data);
          }
        );
      } else {
        delete filteredFormData.ico;

        await WidgetModel.create(
          filteredFormData,
          () => {},
          (data) => {
            this.$emit("handle-close", data);
          }
        );
      }
    },
  },
};
</script>
