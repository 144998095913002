<template>
  <div class="reset-password-page d-flex align-items-center justify-content-center vh-100">
    <div class="reset-password-box bg-white shadow-sm rounded p-4">
      <div class="text-center mb-4">
        <img
          alt="Pobo Page Builder logo"
          class="img-fluid logo-sm"
          src="https://pobo-cdn.b-cdn.net/static/logo.png"
        >
      </div>
      <h2 class="text-center mb-3">
        {{ $t('Password settings') }}
      </h2>
      <p class="text-center text-muted mb-4">
        {{ $t('Please fill in the password in both fields. The password is used to log in to your Pobo Page Builder account.') }}
      </p>
      <b-alert
        v-if="errorMessage"
        show
        variant="danger"
        class="mb-3"
      >
        {{ $t(errorMessage) }}
      </b-alert>
      <b-alert
        v-if="successMessage"
        show
        variant="success"
        class="mb-3"
      >
        {{ $t(successMessage) }}
      </b-alert>
      <b-form @submit.prevent="submitForm">
        <b-form-group
          :label="$t('New password')"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model="password"
            type="password"
            required
            :placeholder="$t('Minimum 5 characters long')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Repeat the password')"
          label-for="password2"
        >
          <b-form-input
            id="password2"
            v-model="password2"
            type="password"
            required
            :placeholder="$t('Repeat the password from the box above')"
          />
        </b-form-group>
        <b-button
          type="submit"
          variant="secondary"
          block
          :disabled="isLoading"
        >
          {{ isLoading ? $t('Im saving...') : $t('Save new password and log in') }}
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import AuthModel from "@/model/AuthModel";
import Cookies from "js-cookie";

export default {
  name: "ResetPassword",
  data() {
    return {
      password: "",
      password2: "",
      errorMessage: "",
      successMessage: "",
      isLoading: false,
    };
  },
  methods: {
    async submitForm() {
      this.errorMessage = "";
      this.successMessage = "";
      this.isLoading = true;

      if (this.password !== this.password2) {
        this.errorMessage = this.$t('The passwords don\'t match.');
        this.isLoading = false;
        return;
      }

      const token = this.$route.params.token;
      if (!token) {
        this.errorMessage = this.$t('Missing reset token. Please use the reset link from your email.');
        this.isLoading = false;
        return;
      }

      try {
        const response = await AuthModel.changePassword(
          { password: this.password, password2: this.password2 },
          token
        );

        if (response) {
          if (response.token) {
            Cookies.set("access_token", response.token);
          }

          this.successMessage = this.$t('The password has been successfully changed. Redirecting...');
          setTimeout(() => {
            this.$router.push({ name: "product" });
          }, 2000);
        } else if (response.error) {
          this.errorMessage = this.$t(
            response.error === "USER_NOT_FOUND"
              ? 'The user was not found. Please check your reset link.'
              : 'An error occurred. Please try again later.'
          );
        }
      } catch (error) {
        console.error("Error during password change:", error);
        this.errorMessage = this.$t('An unexpected error occurred. Please try again later.');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.reset-password-page {
  background-color: #f8f9fa;
}

.reset-password-box {
  width: 100%;
  max-width: 400px;
}

.logo-sm {
  max-width: 120px;
}
</style>
