import axios from "axios";

export default class ExportModel {
  static async findAll(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/cron/export")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch export [findAll()]", error);
        return error;
      });
  }

  static async create(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .post("/api/v2/cron/export")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch export [create()]", error);
        return error;
      });
  }

  static async delete(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    const { id } = data;

    return await axios
      .delete("/api/v2/cron/export/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error delete export [delete()]", error);
        return error;
      });
  }

  static async uploadMe(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/" + data.type + "/push/" + data.data.id, data.data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch export [uploadMe()]", error);
        return error;
      });
  }
}
