<template>
  <b-overlay
    :show="loadingUpload"
    rounded="sm"
  >
    <help-box
      author="sara"
      type="image"
      classes="mt-4"
    >
      <h2 class="mb-4">
        {{ $t("How to edit images") }}
      </h2>
      <p class="fs-16 lh-26">
        {{ $t("Pobo Page Builder allows laymen to easily add and edit images to content.") }}
        {{ $t("You have access to a rich photo library with icons and millions of images, you can easily crop photos and add descriptions and web links to improve the look and functionality of your articles or product pages.") }}
      </p>
    </help-box>

    <b-row class="row">
      <b-col cols="3">
        <modal-image-menu
          :active-type="openTypeImage"
          @select-type="selectTypeImage"
          @select-type-svg="selectTypeSvg"
        />
      </b-col>

      <b-col cols="9">
        <b-row
          class="row mb-3"
        >
          <b-col cols="7">
            <h5 class="mb-1">
              {{ $t(currentTypeLang.titleKey) }}
            </h5>

            <div class="text-muted mb-3 fs-15">
              {{ $t(currentTypeLang.descriptionKey) }}
            </div>
          </b-col>
          <b-col
            v-if="isActive(1)"
            cols="5"
          >
            <div class="d-flex float-right">
              <div class="pr-4">
                <b-btn
                  v-if="options.allowSyncFromRemoteApi"
                  size="sm"
                  type="secondary"
                  @click="actionCreate"
                >
                  <b-spinner
                    v-if="loadingSpin"
                    small
                    variant="white"
                  />
                  {{ $t("widgetTree.modal.image.syncImage") }}
                </b-btn>
              </div>
              <div
                class="file btn btn-secondary btn-sm overflow-hidden position-relative"
              >
                <i class="ico ico-export" />
                {{ $t("widgetTree.modal.image.uploadInput") }}
                <input
                  id="file"
                  ref="file"
                  data-cy="image-upload-input"
                  class="w-100"
                  lang="cs"
                  multiple
                  name="file"
                  style="
                  position: absolute;
                  opacity: 0;
                  right: 0;
                  top: 0;
                  font-size: 50px;
                "
                  type="file"
                  @change="handleFileUpload()"
                >
              </div>
            </div>
          </b-col>
        </b-row>

        <template v-if="isActive(1)">
          <div class="content-editor__image-scroll">
            <template v-if="!isLoaded">
              <b-row>
                <b-col
                  v-for="n in 12"
                  :key="n"
                  cols="4"
                >
                  <div
                    class="content-editor__image-container shadow-sm rounded mb-4"
                  >
                    <div class="content-editor__image">
                      <b-skeleton-img
                        height="120px"
                        no-aspect
                      />
                    </div>
                    <b-skeleton-img
                      class="pt-2"
                      height="35px"
                      no-aspect
                    />
                  </div>
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <b-row v-if="image.length > 0">
                <b-col
                  v-for="(img, index) in image"
                  :key="index"
                  cols="4"
                >
                  <div
                    data-cy="modal-image-single-container"
                    class="content-editor__image-container shadow-sm rounded mb-4"
                  >
                    <div class="content-editor__image">
                      <a
                        href="#"
                        class="content-editor__zoom"
                        @click.prevent="showImage(img.image)"
                      >
                        <i
                          class="ico ico-zoom"
                          @click="showImage(img.image)"
                        />
                      </a>
                      <a
                        class="content-editor__image-link"
                        :data-text="$t('widgetTree.modal.image.selectImage')"
                        href="#"
                        @click.prevent="selectImage(img.image)"
                      >
                        <b-img-lazy
                          :src="img.image"
                          class="content-editor__img"
                        />
                      </a>
                    </div>

                    <div class="pt-0">
                      <dropdown-image-section
                        :is-open="openDropdownId === img.id"
                        @toggle="handleDropdownToggle(img.id)"
                      >
                        <template #content>
                          <div class="modal-image-dropdown__content-left">
                            <b-btn
                              :disabled="!(options.enableCropper && img.format === 'jpeg')"
                              block
                              data-cy="modal-image-crop-button"
                              variant="secondary"
                              size="sm"
                              class="w-100 fs-13 mt-3 d-flex justify-content-center align-items-center"
                              @click="editImage(img.id, true)"
                            >
                              <i class="ico ico-crop mr-1" /> {{ $t("widgetTree.modal.image.editImage") }}
                            </b-btn>
                            <confirm-delete
                              :id="img.id"
                              class="mt-1"
                              @delete="actionDelete"
                            >
                              <template #trigger>
                                <span class="fs-13  d-flex justify-content-center align-items-center">
                                  <i class="ico ico-component-delete mr-1" /> {{ $t("widgetTree.modal.image.deleteImage") }}
                                </span>
                              </template>
                            </confirm-delete>
                            <div class="modal-image-dropdown__format">
                              <h2 class="fs-12 text-center m-0">
                                {{ $t("widgetTree.modal.image.format") }}:
                                {{ img.format.toUpperCase() }}
                              </h2>
                            </div>
                          </div>
                          <div class="modal-image-dropdown__content-right">
                            <h2 class="fs-12 text-center m-0 pb-1">
                              {{ $t('widgetTree.modal.image.selectSize') }}
                            </h2>
                            <b-form-radio-group
                              v-if="img.format === 'jpeg'"
                              v-model="img.image"
                              class="m-0 fs-12 p-0 lh-0"
                            >
                              <b-radio
                                v-for="i in img.list"
                                :key="i.id"
                                :value="i.path"
                                class="mt-0 lh-24"
                              >
                                {{ $t(i.label) }}
                              </b-radio>
                            </b-form-radio-group>

                            <b-form-radio-group
                              v-else
                              disabled
                              checked="1"
                              class="m-0 fs-12 p-0 lh-0"
                            >
                              <b-radio
                                value="1"
                                class="mt-0 lh-24"
                              >
                                {{ $t('widgetTree.modal.image.size.small') }}
                              </b-radio>

                              <b-radio
                                value="2"
                                class="mt-0 lh-24"
                              >
                                {{ $t('widgetTree.modal.image.size.big') }}
                              </b-radio>
                            </b-form-radio-group>

                            <div class="modal-image-dropdown__select-wrap">
                              <b-btn
                                block
                                variant="secondary"
                                size="sm"
                                data-cy="modal-image-select-button"
                                class="mt-2 w-100 fs-13"
                                @click="selectImage(img.image)"
                              >
                                {{ $t("widgetTree.modal.image.selectImage") }}
                              </b-btn>
                            </div>
                          </div>
                        </template>
                      </dropdown-image-section>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row v-else>
                <b-col
                  class="m-auto text-center"
                  cols="7"
                >
                  <h3 class="font-weight-normal mb-4 mt-4">
                    {{ $t("widgetTree.modal.image.missing.top") }}
                  </h3>
                  <p>{{ $t("widgetTree.modal.image.missing.bottom") }}</p>
                </b-col>
              </b-row>
            </template>
          </div>
        </template>

        <template v-if="isActive(3)">
          <image-icon
            :icon="currentIcons"
            @select-icon="selectImage"
          />
        </template>

        <template v-if="isActive(4)">
          <image-photo-bank
            @opacity="downloadWait"
            @select-icon="selectImage"
          />
        </template>

        <template v-if="isActive(5)">
          <image-photo-downloaded
            @select-image="selectImage"
            @change-view="selectTypeImage"
          />
        </template>

        <template v-if="isActive(6)">
          <image-alt
            :alt="item.extension[valueLang].alt"
            @update-alt="updateAlt"
          />
        </template>

        <template v-if="isActive(7)">
          <image-link
            :link="item.extension[valueLang].href"
            @update-link="updateLink"
          />
        </template>
      </b-col>
      <b-modal
        v-model="isModalOpen"
        :title="$t('widgetTree.modal.crop.title')"
        hide-footer
        size="ai"
      >
        <image-cropper
          :aspect-ratio="aspectRatio"
          :image-id="imageId"
          :options="options"
          :product-id="id"
          @crop-image="handleCropImage"
        />
      </b-modal>

      <b-modal
        v-model="isShowImageModal"
        :title="$t('widgetTree.modal.image.preview')"
        hide-footer
        size="ai"
      >
        <div class="content-editor__image-preview">
          <b-img-lazy :src="imageModalSrc" />
        </div>
      </b-modal>
    </b-row>
  </b-overlay>
</template>

<script>
import axios from "axios";
import ImageIcon from "@/components/library/image-icon.vue";
import ImageCropper from "@/share/image-cropper.vue";
import ImagePhotoBank from "@/components/library/image-photo-bank.vue";
import ImagePhotoDownloaded from "@/components/library/image-photo-downloaded.vue";
import ImageAlt from "@/components/library/image-alt.vue";
import ImageLink from "@/components/library/image-link.vue";
import HelpBox from "@/share/help-box.vue";
import ModalImageMenu from "@/components/share/part/modal-image-menu.vue"
import DropdownImageSection from "@/components/share/part/dropdown-image-section.vue"
import IconModel from "@/model/IconModel";

export default {
  name: "ModalImage",

  props: {
    id: {
      type: Number,
      required: true,
    },

    options: {
      type: Object,
      required: true,
    },

    valueLang: {
      type: String,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },

    aspectRatio: {
      type: Number,
      required: false,
      default: null,
    },
  },

  mounted() {
    this.actionRead();
  },

  data() {
    return {
      loadingSpin: false,
      isModalOpen: false,
      openTypeImage: 1,
      imageId: null,
      image: [],
      open: null,
      loadingUpload: false,
      isLoaded: false,
      isShowImageModal: false,
      imageModalSrc: null,
      openDropdownId: null,
      currentIcons: null,
    };
  },

  computed:{
    currentTypeLang() {
      const baseKey = 'widgetTree.modal.image';
      switch (this.openTypeImage) {
      case 1: return {
        titleKey: `${baseKey}.menu.picture`,
        descriptionKey: `${baseKey}.submenu.picture`
      };
      case 3: return {
        titleKey: `${baseKey}.menu.svg`,
        descriptionKey: `${baseKey}.submenu.svg`
      };
      case 4: return {
        titleKey: `${baseKey}.menu.photoBank`,
        descriptionKey: `${baseKey}.submenu.photoBank`
      };
      case 5: return {
        titleKey: `${baseKey}.menu.library`,
        descriptionKey: `${baseKey}.submenu.library`
      };
      case 6: return {
        titleKey: `${baseKey}.menu.alt`,
        descriptionKey: `${baseKey}.submenu.alt`
      };
      case 7: return {
        titleKey: `${baseKey}.menu.link`,
        descriptionKey: `${baseKey}.submenu.link`
      };
      default: return {
        titleKey: `${baseKey}.menu.default`,
        descriptionKey: `${baseKey}.submenu.default`
      };
      }
    }
  },

  components: {
    HelpBox,
    "dropdown-image-section": DropdownImageSection,
    "image-photo-bank": ImagePhotoBank,
    "image-icon": ImageIcon,
    "image-cropper": ImageCropper,
    "image-photo-downloaded": ImagePhotoDownloaded,
    "image-alt": ImageAlt,
    "image-link": ImageLink,
    "modal-image-menu": ModalImageMenu,
  },
  methods: {
    isActive(activeItem) {
      const { openTypeImage } = this;
      return openTypeImage === activeItem;
    },

    handleDropdownToggle(id){
      if (this.openDropdownId === id) {
        this.openDropdownId = null;
      } else {
        this.openDropdownId = id;
      }
    },

    updateAlt(value) {
      this.$emit("update-alt", value);
    },

    updateLink(value) {
      this.$emit("update-link", value);
    },

    showImage(path) {
      this.isShowImageModal = true;
      this.imageModalSrc = path;
    },

    editImage(id, state = true) {
      this.imageId = id;
      this.isModalOpen = state;
    },

    async handleCropImage(data) {
      this.isModalOpen = false;
      this.image = data;
    },

    selectImage(image) {
      console.log(image);
      this.$emit("select-image", image);
    },

    async readCurrentSvg(id){
      await IconModel.findMe(
        id,
        () => {},
        (data) => {
          this.currentIcons = data;
        }
      );
    },

    selectImageFromGallery(image, type) {
      let imageUrl = image;
      if(type === 'small'){
        imageUrl = image + '?width=765';
      }

      this.$emit("select-image", imageUrl);
    },

    downloadWait(opacity) {
      this.loadingUpload = opacity;
    },

    openImageSize(id) {
      if (this.open) {
        this.open = null;
      } else {
        this.open = id;
      }
    },

    selectTypeImage(type) {
      if(type === 3){
        this.selectTypeSvg(1);
      }
      this.openTypeImage = type;
    },

    selectTypeSvg(type){
      this.readCurrentSvg(type);
    },

    async actionRead() {
      const { id, options } = this;
      const { api } = options;
      const { image } = api;

      await axios
        .get(image.replace("[id]", id))
        .then(({ data }) => {
          this.image = data;
        })
        .catch((error) => {
          console.warn(error);
        });
      this.isLoaded = true;
    },

    actionDelete(imageId) {
      const { id, options } = this;
      const { api } = options;
      const { image } = api;

      axios
        .delete(`${image.replace("[id]", id)}/${imageId}`)
        .then(({ data }) => {
          this.image = data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },

    async actionCreate() {
      const { id, options } = this;
      const { api } = options;
      const { sync } = api;

      this.loadingSpin = true;

      await axios
        .post(sync.replace("[id]", id), {
          id,
        })
        .then(({ data }) => {
          this.image = data;
        });

      this.loadingSpin = false;
    },

    async handleFileUpload() {
      let formData = new FormData();
      const { options, id } = this;
      const { api, content } = options;
      const { image } = api;

      for (let i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formData.append("files[" + i + "]", file);
      }

      formData.append("content_id", id);
      formData.append("content", content);

      this.loadingUpload = true;
      await axios
        .post(image.replace("[id]", id), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          this.image = data;
          this.files = "";
        });
      this.loadingUpload = false;
    },
  },
};
</script>

<style scoped></style>