<template>
  <div id="pobo-standard-widget">
    <div
      v-for="(element) in widget"
      :key="element.id"
      class="widget-container"
    >
      <div
        :class="[element.enable_typography_css ? 'widget-typography' : 'widget-projector']"
        v-html="element.html"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default  {
  name: "widget-preview",

  props: {
    id: {
      type: Number,
      required: true
    },

    options: {
      type: Object,
      required: true
    },

    valueLang: {
      type: String,
      required: false,
      default: "default"
    }
  },

  async mounted() {
    const { options, id, valueLang } = this;
    const { api } = options;
    const { widget } = api;

    await axios.get(widget.replace("[id]", id)).then(({ data }) => {
      this.widget = data;
      this.isLoaded = true;
      this.$emit("action-update", data);
    });
  },

  data() {
    return {
      widget: []
    }
  },
}
</script>