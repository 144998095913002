<template>
  <div>
    <label for="category_id">Kategorie</label>
    <select
      id="category_id"
      v-model="model"
      :class="error ? 'is-invalid' : ''"
      class="form-control"
      @change="handleSelect"
    >
      <option
        v-for="category in items"
        :key="category.id"
        :value="category.id"
      >
        {{ category.name }}
      </option>
    </select>
  </div>
</template>
<script>
import WidgetCategoryModel from "@/model/WidgetCategoryModel.js";

export default {
  name: "widget-category-autocomplete",

  props: {
    selected: {
      type: Number,
      required: false,
    },

    error: {
      type: Boolean,
      required: true,
    },
  },

  async mounted() {
    await this.actionRead();
  },

  data() {
    return {
      items: [],
      model: null,
    };
  },

  methods: {
    handleSelect() {
      const { model } = this;
      this.$emit("change-category", model);
    },
    async actionRead() {
      await WidgetCategoryModel.findAll(
        () => {},
        (data) => {
          this.model = this.selected;
          this.items = data;
        }
      );
    },
  },
};
</script>

<style scoped></style>
