<template>
  <div class="modal-image-menu">
    <div class="modal-image-menu__group">
      <a
        class="modal-image-menu__item"
        data-cy="image-menu-gallery"
        :class="{'modal-image-menu__active' : activeType === 1}"
        @click.prevent="selectType(1)"
      >

        <i class="ico ico-gallery" />

        {{
          $t("widgetTree.modal.image.menu.picture")
        }}
      </a>
      <a
        class="modal-image-menu__item"
        data-cy="image-menu-svg"
        :class="{'modal-image-menu__active' : activeType === 3}"
        @click.prevent="selectType(3)"
      >
        <i class="ico ico-gallery-svg" />
        {{ $t("widgetTree.modal.image.menu.svg") }}

        <i
          v-if="!isDropdownOpen"
          class="ico ico-simple-arrow-down ml-2"
        />
        <i
          v-else
          class="ico ico-simple-arrow-up ml-2"
        />
      </a>

      <Transition name="slide-down">
        <div v-if="isDropdownOpen">
          <a
            v-for="(item, index) in iconsMenu"
            :key="index"
            href="#"
            :class="[{'modal-image-menu__dropdown--active': activeSvg === item.id}, 'modal-image-menu__dropdown']"
            @click.prevent="selectTypeSvg(item.id)"
          >
            {{ $t(item.name) }}
          </a>
        </div>
      </Transition>

      <a
        class="modal-image-menu__item"
        data-cy="image-menu-photobank"
        :class="{'modal-image-menu__active' : activeType === 4}"
        @click.prevent="selectType(4)"
      >
        <i class="ico ico-gallery-photobank" />
        {{ $t("widgetTree.modal.image.menu.photoBank") }}
      </a>
      <a
        class="modal-image-menu__item"
        data-cy="image-menu-library"
        :class="{'modal-image-menu__active' : activeType === 5}"
        @click.prevent="selectType(5)"
      >
        <i class="ico ico-gallery-photobank-download" />
        {{ $t("widgetTree.modal.image.menu.library") }}
      </a>
      <a
        class="modal-image-menu__item"
        data-cy="image-menu-alt"
        :class="{'modal-image-menu__active' : activeType === 6}"
        @click.prevent="selectType(6)"
      >
        <i class="ico ico-gallery-alt" />
        {{ $t("widgetTree.modal.image.menu.alt") }}
      </a>
      <a
        class="modal-image-menu__item"
        data-cy="image-menu-link"
        :class="{'modal-image-menu__active' : activeType === 7}"
        @click.prevent="selectType(7)"
      >
        <i class="ico ico-gallery-link" />
        {{ $t("widgetTree.modal.image.menu.link") }}
      </a>
    </div>
  </div>
</template>

<script>
import IconModel from "@/model/IconModel";

export default {
  props:{
    activeType: {
      type: Number,
      required: true,
    },
  },

  async mounted() {
    await IconModel.findAll(
      () => {},
      (data) => {
        this.iconsMenu = data;
      }
    );
  },

  data() {
    return {
      iconsMenu: null,
      activeSvg: 1,
      isDropdownOpen: false,
    }
  },
  methods:{
    selectType(number){
      if(number === 3){
        this.isDropdownOpen = !this.isDropdownOpen;
        if(this.isDropdownOpen){
          this.selectTypeSvg(1);
          this.$emit('select-type', number);
        }
      }else{
        this.isDropdownOpen = false;
        this.$emit('select-type', number);
      }
    },

    selectTypeSvg(number){
      this.activeSvg = number;
      this.$emit('select-type-svg', number);
    },

  }
}
</script>

<style scoped></style>