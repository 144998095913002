<template>
  <div>
    <h2 class="my-4 p-0">
      {{ $t("guide.edit.heading") }}
    </h2>

    <p class="mb-4">
      {{ $t("guide.edit.description") }}
    </p>

    <product-autocomplete @select-product="handleSelectProduct" />

    <div
      v-if="isModalOpen"
      class="modal-editor"
    >
      <div
        v-if="product"
        class="editor-modal__header"
      >
        <div class="editor-modal__header-progress">
          <b-row
            align-h="center"
            align-v="center"
            class="h-100"
          >
            <b-col cols="7">
              <b-progress
                :max="100"
                :value="currentState.progress"
                height="12px"
                variant="secondary"
              />
            </b-col>
            <b-col
              class="text-center"
              cols="5"
            >
              <template v-if="currentState.progress === 100">
                <b-btn
                  block
                  size="sm"
                  variant="secondary"
                  @click="$emit('update-step', 4)"
                  v-html="currentState.text"
                />
              </template>
              <template v-else>
                <span class="p-0 m-0 font-weight-bolder fs-16">
                  {{ currentState.text }}
                </span>
              </template>
            </b-col>
          </b-row>
        </div>

        <template v-if="false">
          <div class="editor-modal__header-tab">
            <ul class="content-editor__tab">
              <li>
                <button class="content-editor__tab-btn">
                  {{ product.name }}
                </button>
              </li>
            </ul>
          </div>

          <div class="editor-modal__header-menu">
            <ul class="content-editor__menu">
              <li />

              <li>
                <button
                  class="content-editor__menu-btn content-editor__menu-btn--save"
                  @click="$emit('handle-save')"
                >
                  {{ $t("blog.editor.save") }}
                </button>
              </li>

              <li>
                <button
                  class="content-editor__menu-btn content-editor__menu-btn--close"
                  @click="$emit('handle-close')"
                >
                  {{ $t("blog.editor.close") }}
                </button>
              </li>
            </ul>
          </div>
        </template>
      </div>
      <div
        v-if="false"
        class="content-editor__guide-edit"
      >
        <div
          v-if="product"
          class="row align-items-center"
        >
          <div class="col-7">
            <b-progress
              :max="100"
              :value="currentState.progress"
              height="38px"
              striped="striped"
              variant="secondary"
            />
          </div>
          <div class="col-5 text-center">
            <template v-if="currentState.progress === 100">
              <button
                class="btn btn-secondary btn-block text-uppercase"
                @click="$emit('update-step', 4)"
                v-html="currentState.text"
              />
            </template>
            <template v-else>
              <h3 class="p-0 m-0">
                {{ currentState.text }}
              </h3>
            </template>
          </div>
        </div>
      </div>
      <editor
        v-if="product"
        :id="product.id"
        :options="options"
        :show-left-panel="false"
        @action-update="actionUpdate"
      />
    </div>
  </div>
</template>

<script>
import ProductAutocomplete from "@/share/product-autocomplete.vue";
import Editor from "@/share/editor.vue";
import options from "@/config/share.json";
import ProductModel from "@/model/ProductModel.js";

export default {
  name: "edit",

  data() {
    return {
      product: null,
      isModalOpen: false,
      options: options.product,
      currentState: {
        progress: 0,
        text: "",
      },
    };
  },

  components: {
    "product-autocomplete": ProductAutocomplete,
    editor: Editor,
  },

  methods: {
    actionUpdate(widget) {
      if (widget.length === 0) {
        this.currentState = {
          progress: 0,
          text: this.$t("guide.edit.state.zero"),
        };
      } else if (widget.length === 1) {
        this.currentState = {
          progress: 35,
          text: this.$t("guide.edit.state.one"),
        };
      } else if (widget.length === 2) {
        this.currentState = {
          progress: 70,
          text: this.$t("guide.edit.state.two"),
        };
      } else if (widget.length >= 3) {
        this.currentState = {
          progress: 100,
          text: this.$t("guide.edit.state.three"),
        };
      }
    },

    async handleSelectProduct(product) {
      const { id } = product;

      await ProductModel.findMe(
        id,
        () => {},
        (data) => {
          this.isModalOpen = true;
          this.product = data;
          this.$emit("update-product", data);
        }
      );
    },
  },
};
</script>