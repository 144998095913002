<template>
  <b-form @submit.prevent="actionUpdate">
    <layout-setting anchor="export">
      <template #header>
        {{ $t("Appearance mode settings") }}
      </template>
      <template #content>
        <b-form-group>
          <b-form-radio
            v-model="form.template_mode"
            value="standalone"
            class="mb-3 pb-3 border-bottom"
          >
            {{ $t("Old version of the label styling system (legacy)") }}
            <small class="d-block text-black-50 pt-1">
              {{ $t("This mode is compatible with") }}
              <router-link
                :to="{ name: 'account' }"
                class="text-secondary"
              >
                Pobo Designer</router-link>,

              {{ $t("individual customization of the appearance using CSS and") }}

              <router-link
                :to="{ name: 'design' }"
                class="text-secondary"
              >
                {{ $t("appearance templates") }}
              </router-link>.

              {{ $t("Choose this option if you have problems with displaying product descriptions, categories or articles on the e-shop") }}.
            </small>

            <small
              class="text-black-50 p-2 px-2 rounded d-inline-block mt-2"
              style="background: #ffe72e"
            >
              <i class="ico ico-warning" />
              {{ $t("After switching the settings, you need to start live editing in Pobo Designer") }}
            </small>
          </b-form-radio>

          <b-form-radio
            v-model="form.template_mode"
            value="generic"
          >
            {{ $t("Generic label styling system") }}
            <small class="d-block text-black-50 pt-1">
              {{ $t("The appearance of labels is set using CSS variables and allows you to customize the design of specific widgets individually") }}.
              {{ $t("The generic system has, among other things, a function to display widgets on 100% monitor width (in case of compatible templates)") }}.
            </small>
          </b-form-radio>
        </b-form-group>

        <b-form-group class="mt-4">
          <b-button
            type="submit"
          >
            {{ $t("Save") }} <i class="ico ico-arrow-right" />
          </b-button>
        </b-form-group>


        <template v-if="isShoptet">
          <h4 class="mt-5">
            Informace k řešení potíží
          </h4>
          <ol class="fs-16 mt-2 ml-4 p-2">
            <li class="mb-2">
              Pokud máte nastavený vzhled pomocí <router-link
                :to="{ name: 'account' }"
                class="text-secondary"
              >
                Designeru
              </router-link>, dojde v případě změny volby na <strong>generický systém</strong>
              k vypnutí jeho stylování, aby nedocházelo ke konfliktům při
              stylování popisků, následně můžete využít nové vzhledy, které připravujeme.
            </li>
            <li>
              Při změně na <strong>generický systém</strong> se budou nadále propisovat
              CSS napsané v modulu <router-link
                :to="{ name: 'asset' }"
                class="text-secondary"
              >
                Editoru kódu
              </router-link>.
            </li>
          </ol>
        </template>
      </template>
    </layout-setting>

    <layout-setting
      anchor="fullscreen"
    >
      <template #header>
        {{ $t("Enable full-width display of labels") }}
      </template>

      <template #content>
        <tier-control
          :allow-tariff="['profi', 'premium', 'saas']"
          minimum-tariff="profi"
          position="bottom"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="form.enable_fullscreen"
              :unchecked-value="false"
              :value="true"
              :disabled="form.template_mode === 'standalone'"
              class="mb-2"
            >
              {{ $t("Enable full-width browser label extension") }}

              <small class="d-block text-black-50 pt-1">
                <strong> {{ $t("To use this feature, you must use the generic label styling system") }}. </strong><br>
                {{ $t("Please note: The settings may not work correctly for all templates, if you have problems with the display") }}
                <a
                  href="/contact"
                  target="_blank"
                  class="text-secondary"
                > {{ $t("please write to us") }}</a>.
              </small>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group class="mt-2">
            <b-button type="submit">
              {{ $t("Save") }} <i class="ico ico-arrow-right" />
            </b-button>
          </b-form-group>
        </tier-control>
      </template>
    </layout-setting>
  </b-form>
</template>

<script>
import eshopData from "@/mixin/eshopData";
import cloneDeep from "lodash.clonedeep";
import { useEshopStore } from "@/store/EshopStore";
import TierControl from "@/share/tier-control.vue";
export default {
  name: "SettingTemplate",

  mixins: [eshopData],

  async mounted() {
    const store = useEshopStore();
    store.fetchEshop().then(() => {
      this.form = cloneDeep(store.getEshop());
    });
  },

  data() {
    return {
      form: {},
    };
  },
  components: {'tier-control' : TierControl},

  methods: {
    async actionUpdate() {
      const { form } = this;
      await useEshopStore().updateEshop({
        data: form
      });
    },
  },
};
</script>