import axios from "axios";

export default class WidgetModel {
  static async findMe(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/widget/legacy/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch detail widget [findMe()]", error);
        return error;
      });
  }

  static async findAll(startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/widget/legacy")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch widget [findAll()]", error);
        return error;
      });
  }

  static async saveMe(
    id,
    data,
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();
    return await axios
      .put("/api/v2/widget/legacy/" + id, data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        errorCallback(error);
        console.warn("Error widget [saveMe()]", error);
        return error;
      });
  }

  static async delete(id, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .delete("/api/v2/widget/legacy/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error delete widget [delete()]", error);
        return error;
      });
  }

  static async create(data, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .post("/api/v2/widget/legacy", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error create widget [create()]", error);
        return error;
      });
  }

  static async findWidgetDraft(
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/widget/draft")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch widget [findWidgetDraft()]", error);
        return error;
      });
  }

  static async findAllComponent(
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/widget/component/grid/1")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch widget [findAllComponent()]", error);
        return error;
      });
  }

  static async uploadWidget(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/widget-upload", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error widget upload [uploadWidget()]", error);
        return error;
      });
  }

  static async copyElement(
    data,
    endpoint,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post(endpoint, data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error widget upload [copyElement()]", error);
        return error;
      });
  }

  static async connectMe(
    data,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/widget-connect", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error widget connect [connectMe()]", error);
        return error;
      });
  }
}
