import axios from "axios";

export default class DesignModel {
  static async findAll(
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/design/grid")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch front design [findAll()]", error);
        return error;
      });
  }

  static async createByEshop(
    data = {},
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/design/detail", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch design [createByEshop()]", error);
        return error;
      });
  }

  static async findByEshop(designId, startCallback = () => {}, endCallback = () => {}) {
    startCallback();

    return await axios
      .get("/api/v2/design/detail/" + designId)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch design [findByEshop()]", error);
        return error;
      });
  }

  static async updateByEshop(
    data = {},
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .put("/api/v2/design/detail/" + data.id, data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch design [updateByEshop()]", error);
        return error;
      });
  }


  static async delete(
    id,
    startCallback = () => {},
    endCallback = () => {}
  ) {
    startCallback();

    return await axios
      .delete("/api/v2/design/detail/" + id)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error delete design [delete()]", error);
        return error;
      });
  }
}
