import axios from "axios";

export default class EshopModel {
  static async findMe(
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/detail")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error fetch eshop [findMe()]", error);
        errorCallback(error);
        return error;
      });
  }

  static async saveMe(
    data,
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    if (data.config !== null) {
      data.config = Array.isArray(data.config) ? data.config : [data.config];
    }
    startCallback();
    return await axios
      .put("/api/v2/eshop/detail", data)
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        errorCallback(error);
        console.warn("Error fetch eshop [saveMe()]", error);
        return error;
      });
  }

  static async logout(
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/user/logout")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error logout eshop [logout()]", error);
        errorCallback(error);
        return error;
      });
  }

  static async sync(
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/eshop/sync")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error sync eshop [sync()]", error);
        errorCallback(error);
        return error;
      });
  }

  static async erasureAnalyticsData(
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .post("/api/v2/eshop/erasure")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error delete eshop data [erasureAnalyticsData()]", error);
        errorCallback(error);
        return error;
      });
  }

  static async fetchStatsInfo(
    startCallback = () => {},
    endCallback = () => {},
    errorCallback = () => {}
  ) {
    startCallback();

    return await axios
      .get("/api/v2/eshop/stats-info")
      .then(({ data }) => {
        endCallback(data);
        return data;
      })
      .catch((error) => {
        console.warn("Error delete eshop data [fetchStatsInfo()]", error);
        errorCallback(error);
        return error;
      });
  }
}
